import type { EditUserData } from '../../types';

import useAccount from 'hooks/useAccount';

const useData = () => {
    const { updateUserData } = useAccount();

    return async (data: EditUserData) => {
        const response = await updateUserData(data);

        return response;
    };
};

export default useData;

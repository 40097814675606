import InputLabel from 'components/InputLabel';
import LabelSecondaryText from 'components/LabelSecondaryText';
import RowSpaceBetween from 'components/RowSpaceBetween';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithLabel: FunctionComponent<Props> = ({
    children,
    className = '',
    isRequired = false,
    secondaryText,
    testId = TEST_ID,
    text
}) => (
    <div className={`${className} ${styles.withLabel}`} data-testid={testId}>
        <RowSpaceBetween>
            <InputLabel isRequired={isRequired} text={text} />

            {secondaryText && (
                <LabelSecondaryText className={styles.secondaryText} text={secondaryText} />
            )}
        </RowSpaceBetween>

        {children}
    </div>
);

export default WithLabel;

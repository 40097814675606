import { forwardRef } from 'react';

import { Button, ChevronDownIcon } from '@rantizo-software/rantizo-ui';

import LargeText from 'components/LargeText';
import Loader from 'components/Loader';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const FilterButton = forwardRef<HTMLButtonElement, Props>(
    ({ className, isLoading, onClick, testId = TEST_ID, text }, ref) => (
        <Button
            className={`${styles.filterButton} ${className}`}
            onClick={onClick}
            ref={ref}
            testId={testId}
            text={text}
        >
            <LargeText className={styles.largeText} text={text} />

            {isLoading ? (
                <Loader className={styles.loader} />
            ) : (
                <ChevronDownIcon className={styles.chevronDownIcon} />
            )}
        </Button>
    )
);

FilterButton.displayName = 'FilterButton';

export default FilterButton;

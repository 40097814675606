import { ContentText } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ContentLabeledText: FunctionComponent<Props> = ({ label, testId = TEST_ID, text }) => (
    <ContentText testId={testId} text={`${label}: ${text}`} />
);

export default ContentLabeledText;

import { createContext, useContext } from 'react';

import type { UsePilots } from './types';

const context = createContext([]);

const usePilots: UsePilots = () => {
    const pilots = useContext(context);

    return {
        context,
        pilots
    };
};

export default usePilots;

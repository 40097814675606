import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';
import {
    JOHN_DEERE_ACCESS_TOKEN_URL,
    JOHN_DEERE_AUTH_URL,
    JOHN_DEERE_CLIENT_ID,
    JOHN_DEERE_REDIRECT_URI
} from 'hooks/useJohnDeere/constants';
import type { OAuth2Params, OAuth2Token } from 'hooks/useJohnDeere/types';
import useOAuth2 from 'hooks/useOAuth2';
import useQuerystring from 'hooks/useQuerystring';

const useJohnDeereBase = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useJohnDeere' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveJohnDeereToken = useCallback(setValue('johnDeereToken'), []);

    const token = getValue('johnDeereToken') as OAuth2Token | undefined;

    const { authenticatedGet, authenticatedPost, httpPutBinary } = useFetch();

    const oAuth2Params = {
        authorizationEndpoint: JOHN_DEERE_AUTH_URL,
        clientId: JOHN_DEERE_CLIENT_ID,
        redirectUri: JOHN_DEERE_REDIRECT_URI,
        scope: ['org2', 'ag3'],
        setToken: saveJohnDeereToken,
        token: getValue('johnDeereToken') as OAuth2Token | null,
        tokenEndpoint: JOHN_DEERE_ACCESS_TOKEN_URL
    } as OAuth2Params;

    const { fetchTokenOrRedirectAndLogIn, getPreviousPageFromUrl, logout, setTokenFromRedirect } =
        useOAuth2(oAuth2Params);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const printAndThrowErrorIfExists = useCallback((error: any) => {
        if (error) {
            const stringifiedError = JSON.stringify(error);

            console.error(stringifiedError);
            throw Error(stringifiedError);
        }
    }, []);

    const { addQuery } = useQuerystring({});

    const tokenQueryString = addQuery({ token: token?.accessToken ?? '' }).toString();

    return {
        authenticatedGet,
        authenticatedPost,
        fetchTokenOrRedirectAndLogIn,
        getPreviousPageFromUrl,
        httpPutBinary,
        logout,
        printAndThrowErrorIfExists,
        setTokenFromRedirect,
        token,
        tokenQueryString
    };
};

export default useJohnDeereBase;

export const TEST_ID = 'aircraftForm';

export const NICKNAME_FIELD_TEST_ID = 'nickname';

export const MAKE_FIELD_TEST_ID = 'make';

export const MODEL_FIELD_TEST_ID = 'model';

export const SERIAL_NUMBER_FIELD_TEST_ID = 'serialNumber';

export const REGISTRATION_NUMBER_FIELD_TEST_ID = 'registrationNumber';

export const FLIGHT_CONTROLLER_SERIAL_TEST_ID = 'flightControllerSerial';

export const COA_FORM_FIELD_KEY = 'coa';

export const FAA_DOCKET_NUMBER_KEY = 'faaDocketNumber';

export const FAA_EXEMPTION_NUMBER_KEY = 'faaExemptionNumber';

export const MAX_CHARACTERS = 10;

export const MIN_CHARACTERS = 0;

export const PATTERN = '^[a-zA-Z-0-9 ]*$';

export const TEST_ID = 'certificatesAndExemptionsPage';

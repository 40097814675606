import { atom } from 'recoil';

import type { Notification } from './types';

const toastAtom = atom<Notification | null>({
    default: null,
    key: 'toast'
});

const state = {
    atoms: {
        toastAtom
    }
};

export default state;

import { ContentText, ModalTitle, VerticalContainer } from '@rantizo-software/rantizo-ui';

import ModalOverlay from 'components/ModalOverlay';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogDeleteErrorModal: FunctionComponent<Props> = ({ onClose, testId = TEST_ID }) => {
    const { DELETE_FLIGHT_LOG_ERROR_MESSAGE, DELETE_FLIGHT_LOG_ERROR_TITLE } = useTranslation();

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer className={styles.flightLogDeleteErrorModalContainer}>
                <ModalTitle text={DELETE_FLIGHT_LOG_ERROR_TITLE} />

                <ContentText text={DELETE_FLIGHT_LOG_ERROR_MESSAGE} />
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default FlightLogDeleteErrorModal;

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const SubmitButton: FunctionComponent<Props> = ({
    children,
    className = '',
    isDisabled = false,
    isLoading = false,
    onClick,
    testId = TEST_ID,
    text
}) => (
    <PrimaryButton
        className={className}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        testId={testId}
        type="submit"
    >
        {children || text}
    </PrimaryButton>
);

export default SubmitButton;

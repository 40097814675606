import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

export const AamButton: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { aamPage } = usePageRoutes();

    const { CREATE_NEW_AAM } = useTranslation();

    const handleClick = useCallback(() => navigate(aamPage), [aamPage, navigate]);

    return (
        <PrimaryButton
            className={className}
            onClick={handleClick}
            testId={testId}
            text={CREATE_NEW_AAM}
        />
    );
};

export default AamButton;

import { createContext, useContext } from 'react';

import type { UseFlightReports } from './types';

const context = createContext([]);

const useFlightReports: UseFlightReports = () => {
    const flightReports = useContext(context);

    return {
        context,
        flightReports
    };
};

export default useFlightReports;

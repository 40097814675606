import AamButton from 'components/buttons/AamButton';

import ErrorPage from 'pages/errors/ErrorPage';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const BadPathErrorPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { BAD_PATH } = useTranslation();

    return (
        <ErrorPage testId={testId} text={BAD_PATH}>
            <AamButton />
        </ErrorPage>
    );
};

BadPathErrorPage.displayName = 'BadPathErrorPage';

export default BadPathErrorPage;

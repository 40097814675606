import { forwardRef } from 'react';

import NumberInput from 'components/form/NumberInput';

import { TEST_ID } from './constants';
import type { Props } from './types';

const PhoneNumberInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError = false,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        name = '',
        onChange,
        onError,
        onKeyUp,
        onSubmit,
        onValid,
        placeholder = '',
        testId = TEST_ID,
        value
    } = props;

    return (
        <NumberInput
            className={className}
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            isRequired={isRequired}
            name={name}
            onChange={onChange}
            onError={onError}
            onKeyUp={onKeyUp}
            onSubmit={onSubmit}
            onValid={onValid}
            placeholder={placeholder}
            ref={ref}
            testId={testId}
            value={value}
        />
    );
});

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;

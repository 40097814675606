export const RATE_UNITS = [
    { label: 'Ga/Ac', value: 'GALLONS_PER_ACRE' },
    { label: 'L/m\u00b2', value: 'LITERS_PER_SQUARE_METER' },
    { label: 'Fl. Oz/Ac', value: 'FLUID_OUNCES_PER_ACRE' },
    { label: 'Pt/Ac', value: 'PINTS_PER_ACRE' },
    { label: 'Qt/Ac', value: 'QUARTS_PER_ACRE' },
    { label: 'Kg/m² (Dry)', value: 'KILOGRAMS_PER_SQUARE_METER' },
    { label: 'Lb/Ac (Dry)', value: 'POUNDS_PER_ACRE' },
    { label: 'Oz/Ac (Dry)', value: 'OUNCES_PER_ACRE' }
];

export const AMOUNT_UNITS = [
    { label: 'Fl. Oz', value: 'FLUID_OUNCES' },
    { label: 'Ga', value: 'GALLONS' },
    { label: 'L', value: 'LITERS' },
    { label: 'Pt', value: 'PINTS' },
    { label: 'Qt', value: 'QUARTS' },
    { label: 'Kg (Dry)', value: 'KILOGRAMS' },
    { label: 'Lb (Dry)', value: 'POUNDS' },
    { label: 'Oz (Dry)', value: 'OUNCES' }
];

export const DEFAULT_VOLUME_UNIT = 'GALLONS';

export const DEFAULT_VOLUME_RATE_UNIT = 'GALLONS_PER_ACRE';

export const DEFAULT_MASS_UNIT = 'KILOGRAMS';

export const DEFAULT_MASS_RATE_UNIT = 'KILOGRAMS_PER_SQUARE_METER';

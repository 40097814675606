import { useMemo } from 'react';

import { AccordionContainer } from '@rantizo-software/rantizo-ui';

import AvatarList from 'components/AvatarList';
import LargeSemiboldText from 'components/LargeSemiboldText';
import ListHeader from 'components/ListHeader';
import WithTooltip from 'components/WithTooltip';

import useDate from 'hooks/useDate';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DroneReportContainer: FunctionComponent<Props> = ({
    children,
    droneReport,
    expandDisabled = false,
    isOpen = false,
    onClose,
    onOpen,
    pilots,
    testId = TEST_ID
}) => {
    const { DRONE, FAA_REG_NUMBER, PILOTS, TOTAL_FLIGHT_TIME, TOTAL_FLIGHTS } = useTranslation();

    const { faaRegulationNumber, nickname, totalFlightTimeMilliseconds, totalFlights } =
        droneReport;

    const { convertMillisecondsToDecimalTimeString } = useDate();

    const header = useMemo(() => {
        const totalFlightTime = totalFlightTimeMilliseconds
            ? convertMillisecondsToDecimalTimeString(totalFlightTimeMilliseconds)
            : '0';

        return (
            <div className={styles.droneReportContainer} data-testid={testId}>
                <ListHeader text={DRONE} />

                <ListHeader text={FAA_REG_NUMBER} />

                <ListHeader text={TOTAL_FLIGHTS} />

                <ListHeader text={TOTAL_FLIGHT_TIME} />

                <ListHeader text={PILOTS} />

                <WithTooltip text={nickname}>
                    <LargeSemiboldText text={nickname} />
                </WithTooltip>

                <WithTooltip text={faaRegulationNumber ?? '0'}>
                    <LargeSemiboldText
                        className={styles.largeSemiboldText}
                        text={faaRegulationNumber ?? '0'}
                    />
                </WithTooltip>

                <LargeSemiboldText text={totalFlights?.toString() ?? '0'} />

                <LargeSemiboldText text={totalFlightTime} />

                <AvatarList names={pilots as string[]} />
            </div>
        );
    }, [
        convertMillisecondsToDecimalTimeString,
        totalFlightTimeMilliseconds,
        totalFlights,
        DRONE,
        FAA_REG_NUMBER,
        TOTAL_FLIGHTS,
        TOTAL_FLIGHT_TIME,
        PILOTS,
        nickname,
        faaRegulationNumber,
        pilots,
        testId
    ]);

    return (
        <AccordionContainer
            expandDisabled={expandDisabled || !totalFlights}
            headerComponent={header}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            testId={testId}
        >
            {children}
        </AccordionContainer>
    );
};

export default DroneReportContainer;

import { atom } from 'recoil';

import type { OAuth2Token } from 'hooks/useFieldView/types';

const fieldViewTokenAtom = atom<OAuth2Token | null>({
    default: null,
    key: 'fieldViewToken'
});

const state = {
    atoms: {
        fieldViewTokenAtom
    }
};

export default state;

import { ContentText, PageTitle, VerticalContainer } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ErrorPage: FunctionComponent<Props> = props => {
    const { SOMETHING_WENT_WRONG } = useTranslation();

    const { children, testId = TEST_ID, text, title = SOMETHING_WENT_WRONG } = props;

    return (
        <div className={styles.errorPage} data-testid={testId}>
            <VerticalContainer className={styles.verticalContainer}>
                <PageTitle className={styles.pageTitle} text={title} />

                {text && <ContentText text={text} />}
            </VerticalContainer>

            {children}
        </div>
    );
};

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;

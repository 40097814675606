import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentText, ModalTitle, PrimaryButton } from '@rantizo-software/rantizo-ui';

import ModalOverlay from 'components/ModalOverlay';
import MonthPicker from 'components/MonthPicker';

import useFaaReports from 'hooks/useFaaReports';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { BLANK_FAA_REPORT, MAX_YEAR, MIN_YEAR, TEST_ID } from './constants';
import type { FaaReportRequest, FunctionComponent, Props } from './types';

const FaaReportModal: FunctionComponent<Props> = ({ onClose, testId = TEST_ID }) => {
    const { BUTTON_TEXT, CREATE_FAA_REPORT_ERROR, SUBTEXT, TITLE } = useTranslation();

    const { createFaaReport } = useFaaReports();

    const navigate = useNavigate();
    const { createFaaReportPage } = usePageRoutes();

    const newReport = useRef<FaaReportRequest>(BLANK_FAA_REPORT);

    const reportingPeriodRef = useRef(null);

    const handleReportingPeriodChange = useCallback((date: Date) => {
        newReport.current.reportingPeriod = date;
    }, []);

    const handleCreateNewReport = useCallback(async () => {
        try {
            const report = await createFaaReport(newReport.current);

            navigate(createFaaReportPage(report.id));
        } catch (e) {
            alert(CREATE_FAA_REPORT_ERROR);
            console.error(e);
        }
    }, [CREATE_FAA_REPORT_ERROR, createFaaReport, createFaaReportPage, navigate]);

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <ModalTitle text={TITLE} />

            <ContentText text={SUBTEXT} />

            <MonthPicker
                isEditable={true}
                isRequired={true}
                maxYear={MAX_YEAR}
                minYear={MIN_YEAR}
                onChange={handleReportingPeriodChange}
                ref={reportingPeriodRef}
                value={newReport.current.reportingPeriod}
            />

            <PrimaryButton onClick={handleCreateNewReport} text={BUTTON_TEXT} />
        </ModalOverlay>
    );
};

export default FaaReportModal;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DropdownMenu } from '@rantizo-software/rantizo-ui';

import { useDevice } from 'components/DeviceProvider';
import FilterButton from 'components/FilterButton';
import FilterIconButton from 'components/FilterIconButton';
import MultiDropdownItem from 'components/form/MultiDropdownItem';

import useAircraft from 'hooks/useAircraft';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { DroneResponse, FilterButtonMap, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogFilterButton: FunctionComponent<Props> = ({
    className,
    onClick,
    testId = TEST_ID
}) => {
    const { deviceType } = useDevice();
    const { fetchAllAircrafts } = useAircraft();
    const { FILTER_BUTTON, SELECTED } = useTranslation();

    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const filterIconButtonRef = useRef<HTMLButtonElement>(null);
    const filterMenuRef = useRef<HTMLDivElement>(null);
    const menuAnchorRef = useMemo(() => {
        if (deviceType === 'desktop') {
            return filterButtonRef;
        }

        return filterIconButtonRef;
    }, [deviceType]);

    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [aircrafts, setaircrafts] = useState<DroneResponse[]>([]);

    const handleFilterMenuOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const handleFilterMenuClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const getAircrafts = useCallback(async () => {
        setIsLoading(true);

        try {
            const aircraftResponse = await fetchAllAircrafts();

            if (aircraftResponse.error) {
                throw new Error(
                    `Aircraft response error: '${JSON.stringify(aircraftResponse.error)}'`
                );
            }

            setaircrafts(aircraftResponse.data || []);
        } catch (error) {
            console.error(error);
            alert(error);
        }
        setIsLoading(false);
    }, [fetchAllAircrafts]);

    useEffect(() => {
        const closeMenuOnOuterClick = (e: MouseEvent) => {
            const clickedElement = e.target as HTMLElement;
            const clickedOutside =
                !filterMenuRef?.current?.contains(clickedElement) &&
                !filterButtonRef.current?.contains(clickedElement) &&
                !filterIconButtonRef.current?.contains(clickedElement);

            if (clickedOutside) {
                handleFilterMenuClose();
            }
        };

        getAircrafts();
        addEventListener('click', closeMenuOnOuterClick);

        return () => removeEventListener('click', closeMenuOnOuterClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterMenuItemClick = (index: number) => () => {
        const updatedIndices = selectedIndices.includes(index)
            ? selectedIndices.filter(selectedIndex => selectedIndex !== index)
            : [...selectedIndices, index];

        setSelectedIndices(updatedIndices);

        const selectedNicknames = updatedIndices.map(
            updatedIndex => aircrafts[updatedIndex].nickname
        );

        onClick(selectedNicknames);
    };

    const filterButtonText = useMemo(() => {
        if (selectedIndices.length === 0) {
            return FILTER_BUTTON;
        }

        return `${selectedIndices.length} ${SELECTED}`;
    }, [FILTER_BUTTON, SELECTED, selectedIndices]);

    const filterButtonMap: FilterButtonMap = useMemo(
        () => ({
            desktop: (
                <FilterButton
                    className={styles.filterButton}
                    isLoading={isLoading}
                    onClick={handleFilterMenuOpen}
                    ref={filterButtonRef}
                    testId={testId}
                    text={filterButtonText}
                />
            ),
            mobile: (
                <FilterIconButton
                    className={styles.filterButton}
                    onClick={handleFilterMenuOpen}
                    ref={filterIconButtonRef}
                    testId={testId}
                />
            )
        }),
        [handleFilterMenuOpen, isLoading, filterButtonText, testId]
    );

    return (
        <div className={`${className} ${styles.wrapper}`}>
            {filterButtonMap[deviceType]}

            {isOpen && (
                <DropdownMenu
                    className={styles.dropdownMenu}
                    inputRef={menuAnchorRef}
                    onClose={handleFilterMenuClose}
                    ref={filterMenuRef}
                >
                    {aircrafts.map(({ nickname }, index) => (
                        <MultiDropdownItem
                            isSelected={selectedIndices.includes(index)}
                            key={`${nickname}${index}`}
                            onClick={handleFilterMenuItemClick(index)}
                            text={nickname}
                        />
                    ))}
                </DropdownMenu>
            )}
        </div>
    );
};

export default FlightLogFilterButton;

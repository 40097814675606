import { useCallback } from 'react';

import useFlightReport from 'hooks/useFlightReport';

import useTranslation from './hooks/useTranslation';

import type { UseFlightReportUpdate } from './types';

const useFlightReportUpdate: UseFlightReportUpdate = ({ droneReportId, faaReportId }) => {
    const { UPDATE_PILOT_ERROR } = useTranslation();

    const { updateFlightReportPilot: updateFlightReportPilotFromApi } = useFlightReport();

    const updateFlightReportPilot = useCallback(
        async ({ flightReportId, pilot }: { flightReportId: string; pilot: string }) => {
            try {
                return await updateFlightReportPilotFromApi(
                    faaReportId,
                    droneReportId,
                    flightReportId,
                    pilot
                );
            } catch (error) {
                console.error(UPDATE_PILOT_ERROR, error);
            }
        },

        [UPDATE_PILOT_ERROR, updateFlightReportPilotFromApi, droneReportId, faaReportId]
    );

    const updateFlightReports = useCallback(
        async ({ flightReportIds, pilot }: { flightReportIds: []; pilot: string }) => {
            const succeeded: string[] = [];
            const failed: string[] = [];

            for (const flightReportId of flightReportIds) {
                if (await updateFlightReportPilot({ flightReportId, pilot })) {
                    succeeded.push(flightReportId);
                } else {
                    failed.push(flightReportId);
                }
            }

            if (failed.length > 0) {
                alert(UPDATE_PILOT_ERROR);
            }

            return succeeded;
        },
        [updateFlightReportPilot, UPDATE_PILOT_ERROR]
    );

    return {
        updateFlightReports
    };
};

export default useFlightReportUpdate;

import { type FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from '@rantizo-software/rantizo-ui';

import UserDetailsEditCard from 'components/UserDetailsEditCard';

import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AccountDetails: FunctionComponent = () => {
    const navigate = useNavigate();
    const { editUserDetailsPage } = usePageRoutes();
    const { TITLE } = useTranslation();

    const { email, firstName, lastName, phoneNumber } = useData();

    const handleEdit = useCallback(() => {
        navigate(editUserDetailsPage);
    }, [editUserDetailsPage, navigate]);

    return (
        <>
            <PageTitle className={styles.pageTitle} text={TITLE} />

            <UserDetailsEditCard
                editable={true}
                email={email}
                firstName={firstName}
                lastName={lastName}
                onEdit={handleEdit}
                phoneNumber={phoneNumber}
            />
        </>
    );
};

export default AccountDetails;

export const TEST_ID = 'companyDetails';

export const COMPANY_DETAILS = 'companyDetails';

export const EXEMPTIONS_FIELD = 'exemptions';

export const COMPANY_NAME_FIELD = 'name';

export const PHONE_NUMBER_FIELD = 'phoneNumber';

export const DOCKET_NUMBER_FIELD = 'docketNumber';

export const EXEMPTION_NUMBER_FIELD = 'exemptionNumber';

export const COA_FIELD = 'coa';

import type { Feature, FeatureCollection, Geometry, MultiPolygon, Point, Position } from 'geojson';
import type { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { circle, combine, distance, helpers } from '@turf/turf';

/* 
  Taken from an answer on this Stack Overflow post:
  https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex

  Takes hue, saturation, and luminance, as decimals ranging from 0 to 1
  and returns a hex color code as a string
 */
export const hslToHex = (h: number, s: number, l: number) => {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) {
                t += 1;
            }

            if (t > 1) {
                t -= 1;
            }

            if (t < 1 / 6) {
                return p + (q - p) * 6 * t;
            }

            if (t < 1 / 2) {
                return q;
            }

            if (t < 2 / 3) {
                return p + (q - p) * (2 / 3 - t) * 6;
            }

            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const toHex = (x: number) => {
        const hex = Math.round(x * 255).toString(16);

        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const convertGeometryToGeoJSON = (data: { geometry: Geometry; properties: object }[]) => {
    const features = data.map<Feature>(({ geometry, properties }) => ({
        geometry,
        properties,
        type: 'Feature'
    }));

    return {
        features,
        type: 'FeatureCollection'
    } as FeatureCollection;
};

export const convertCoordinatesToPoint = (
    latitude: number | null | undefined,
    longitude: number | null | undefined
) => {
    if (!latitude || !longitude) {
        return null;
    }

    const pos: Position = [longitude, latitude];

    return { coordinates: pos, type: 'Point' } as Point;
};

export const combineFeaturesToMultiPolygon = (features: Feature[] | null | undefined) => {
    if (!features) {
        return null;
    }

    const featureCollectionPolygons: FeatureCollection = {
        features: features.filter(
            f => f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon'
        ),
        type: 'FeatureCollection'
    };

    const combinedFeatures: FeatureCollection<MultiPolygon> = combine(featureCollectionPolygons);

    if (combinedFeatures.features.length > 0) {
        return combinedFeatures.features[0].geometry;
    }

    return null;
};

/*
  Reimplemented from mapbox-gl-draw-circle: 
  https://github.com/iamanvesh/mapbox-gl-draw-circle
*/

const DragCircleMode = { ...MapboxDraw.modes.draw_polygon };
const Constants = { ...MapboxDraw.constants };
const doubleClickZoom = { ...MapboxDraw.lib.doubleClickZoom };

const dragPan = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disable(ctx: any) {
        setTimeout(() => {
            if (!ctx.map || !ctx.map.doubleClickZoom) {
                return;
            }
            // Always disable here, as it's necessary in some cases.
            ctx.map.dragPan.disable();
        }, 0);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enable(ctx: any) {
        setTimeout(() => {
            // First check we've got a map and some context.
            if (
                !ctx.map ||
                !ctx.map.dragPan ||
                !ctx._ctx ||
                !ctx._ctx.store ||
                !ctx._ctx.store.getInitialConfigValue
            ) {
                return;
            }

            // Now check initial state wasn't false (we leave it disabled if so)
            if (!ctx._ctx.store.getInitialConfigValue('dragPan')) {
                return;
            }
            ctx.map.dragPan.enable();
        }, 0);
    }
};

DragCircleMode.onSetup = function () {
    const polygon = this.newFeature({
        geometry: {
            coordinates: [],
            type: Constants.geojsonTypes.POLYGON
        },
        properties: {
            center: [],
            isCircle: true
        },
        type: Constants.geojsonTypes.FEATURE
    });

    this.addFeature(polygon);

    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);
    dragPan.disable(this);
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.POLYGON);

    this.setActionableState({
        combineFeatures: true,
        trash: true,
        uncombineFeatures: true
    });

    return {
        currentVertexPosition: 0,
        polygon
    };
};

// @ts-expect-error implementation will later be changed
DragCircleMode.onMouseDown = function (state, e: MapMouseEvent) {
    const currentCenter = state.polygon.properties.center;

    if (currentCenter.length === 0) {
        state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
    }
};

// @ts-expect-error implementation will later be changed
DragCircleMode.onTouchStart = function (state, e: MapTouchEvent) {
    const currentCenter = state.polygon.properties.center;

    if (currentCenter.length === 0) {
        state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
    }
};

DragCircleMode.onDrag = DragCircleMode.onMouseMove = function (state, e) {
    const { center } = state.polygon.properties;

    if (center.length > 0) {
        const distanceInKm = distance(
            helpers.point(center),
            helpers.point([e.lngLat.lng, e.lngLat.lat]),
            {
                units: 'kilometers'
            }
        );
        const circleFeature = circle(center, distanceInKm);

        state.polygon.incomingCoords(circleFeature.geometry.coordinates);
        state.polygon.properties.radiusInKm = distanceInKm;
    }
};

DragCircleMode.onMouseUp = function (state) {
    dragPan.enable(this);

    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] });
};

DragCircleMode.onClick = function (state) {
    // don't draw the circle if its a tap or click event
    state.polygon.properties.center = [];
};

DragCircleMode.onTouchEnd = function (state) {
    dragPan.enable(this);

    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] });
};

DragCircleMode.onTap = function (state) {
    state.polygon.properties.center = [];
};

DragCircleMode.toDisplayFeatures = function (state, geojson: Feature, display) {
    const isActivePolygon = geojson.properties?.id === state.polygon.id;

    if (geojson.properties) {
        geojson.properties.active = isActivePolygon
            ? Constants.activeStates.ACTIVE
            : Constants.activeStates.INACTIVE;
    }

    return display(geojson);
};

export { DragCircleMode };

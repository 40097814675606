import { atom } from 'recoil';

import type { MyUser } from './types';

const myUserAtom = atom<MyUser>({
    default: null,
    key: 'myUser'
});

const state = {
    atoms: {
        myUserAtom
    }
};

export default state;

import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { DropdownMenu } from '@rantizo-software/rantizo-ui';

import LinkButton from 'components/LinkButton';
import WithErrorMessage from 'components/WithErrorMessage';

import useDateRangePicker from './hooks/useDateRangePicker';
import useTranslation from './hooks/useTranslation';

import DateRangePickerInputs from './components/DateRangePickerInputs';
import { TEST_ID } from './constants';
import type { Props, RangeRefs } from './types';

import styles from './styles.module.scss';

const DateRangePicker = memo(
    forwardRef<RangeRefs, Props>(
        ({ className = '', onChange, rangeInterval, testId = TEST_ID, value }, ref) => {
            const { RESET } = useTranslation();

            const startInputRef = useRef<HTMLInputElement>();
            const endInputRef = useRef<HTMLInputElement>();

            const inputRefs: RangeRefs = {
                endInputRef,
                startInputRef
            };

            useImperativeHandle(ref, () => inputRefs);

            const {
                containerRef,
                dayPickerInterval,
                disableDaysFromRange,
                dropdownRef,
                endInputValue,
                handleBlur,
                handleChange,
                handleClose,
                handleOpen,
                handleTextChange,
                isOpen,
                reset,
                selectedDateRange,
                startInputValue
            } = useDateRangePicker({
                onChange,
                rangeInterval,
                refs: inputRefs,
                value
            });

            const handleStartOpen = useCallback(() => {
                handleOpen('start');
            }, [handleOpen]);

            const handleEndOpen = useCallback(() => {
                handleOpen('end');
            }, [handleOpen]);

            return (
                <div className={`${className} ${styles.dateRangePicker}`} data-testid={testId}>
                    <WithErrorMessage
                        className={styles.withErrorMessage}
                        ref={containerRef}
                        testId={testId}
                    >
                        <DateRangePickerInputs
                            endValue={endInputValue}
                            onBlur={handleBlur}
                            onChange={handleTextChange}
                            onEndClick={handleEndOpen}
                            onStartClick={handleStartOpen}
                            refs={inputRefs}
                            startValue={startInputValue}
                            testId={testId}
                        />
                    </WithErrorMessage>

                    {isOpen && (
                        <DropdownMenu
                            className={styles.dropdownMenu}
                            inputRef={containerRef}
                            onClose={handleClose}
                            ref={dropdownRef}
                        >
                            <DayPicker
                                defaultMonth={
                                    selectedDateRange.to ? selectedDateRange.to : undefined
                                }
                                footer={
                                    <LinkButton
                                        className={styles.resetButton}
                                        onClick={reset}
                                        text={RESET}
                                    />
                                }
                                disabled={disableDaysFromRange}
                                fromMonth={rangeInterval.start as Date}
                                hidden={dayPickerInterval}
                                mode="range"
                                onSelect={handleChange}
                                selected={selectedDateRange}
                                toMonth={rangeInterval.end as Date}
                            />
                        </DropdownMenu>
                    )}
                </div>
            );
        }
    )
);

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;

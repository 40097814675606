import { useCallback, useEffect, useState } from 'react';

import { ContentText } from '@rantizo-software/rantizo-ui';

import ContentHeaderText from 'components/ContentHeaderText';
import ItemCardWithBorder from 'components/ItemCardWithBorder';

import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const LocationSection: FunctionComponent<Props> = ({
    address,
    latitude,
    longitude,
    testId = TEST_ID
}) => {
    const { LOCATION } = useTranslation();
    const { fetchAddressFromCoordinates } = useMapboxGeocoding();

    const formattedLatitude = typeof latitude === 'number' ? latitude?.toFixed(6) : latitude;
    const formattedLongitude = typeof longitude === 'number' ? longitude?.toFixed(6) : longitude;

    const [lookedUpAddress, setLookedUpAddress] = useState<string>();

    const lookUpAddress = useCallback(async () => {
        if (latitude && longitude) {
            const numberLatitude = typeof latitude === 'number' ? latitude : parseFloat(latitude);
            const numberLongitude =
                typeof longitude === 'number' ? longitude : parseFloat(longitude);

            const address = await fetchAddressFromCoordinates(numberLatitude, numberLongitude);

            setLookedUpAddress(address);
        }
    }, [fetchAddressFromCoordinates, latitude, longitude]);

    useEffect(() => {
        lookUpAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ItemCardWithBorder testId={testId}>
            <ContentHeaderText text={LOCATION} />

            <ContentText text={address || lookedUpAddress} />

            <ContentText text={`${formattedLatitude}, ${formattedLongitude}`} />
        </ItemCardWithBorder>
    );
};

export default LocationSection;

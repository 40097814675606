import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WorkOrderReadOnlyLabel: FunctionComponent<Props> = ({
    className = '',
    testId = TEST_ID,
    text
}) => (
    <p className={`${className} ${styles.workOrderReadOnlyLabel}`} data-testid={testId}>
        {text}
    </p>
);

export default WorkOrderReadOnlyLabel;

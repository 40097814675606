import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import isFunction from '@@utils/isFunction';
import { DropdownMenu, VerticalContainer, useAnchorPosition } from '@rantizo-software/rantizo-ui';

import DropdownItem from 'components/form/DropdownItem';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const WithDropdownMenu = forwardRef<HTMLElement, Props>((props, ref) => {
    const {
        children,
        className = '',
        isOpen = false,
        items = [],
        onChange,
        onClose,
        onError,
        selected,
        testId = TEST_ID,
        value
    } = props;

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(selected);
    const [_error, setError] = useState<string>();

    const menuRef = useRef<HTMLDivElement>(null);

    useAnchorPosition({ anchorRef: ref, menuRef });

    const handleError = useCallback(
        (dropdownValue: unknown, message: string) => {
            if (isFunction(onError)) {
                onError?.(dropdownValue, message, setError);
            }
        },
        [onError]
    );

    const handleChange = useCallback(
        (dropdownValue: string, index: number) => {
            onChange?.(dropdownValue, handleError, index);
        },
        [handleError, onChange]
    );

    const handleDropdownItemClick = useCallback(
        (index: number) => () => {
            const selectedItem = items[index];

            setSelectedIndex(index);

            onClose?.();

            if (index !== selectedIndex) {
                handleChange(selectedItem.value, index);
            }
        },
        [handleChange, items, onClose, selectedIndex]
    );

    useEffect(
        () => {
            if (selected !== undefined && selected !== null) {
                setSelectedIndex(selected);
                handleChange(items[selected]?.value, selected);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selected]
    );

    useEffect(() => {
        const index = items.findIndex(item => item.value === value);

        if (index > -1) {
            setSelectedIndex(index);
        }
    }, [items, value]);

    return (
        <VerticalContainer className={className} testId={testId}>
            {children}

            {isOpen && (
                <DropdownMenu className={styles.dropdownMenu} onClose={onClose} ref={menuRef}>
                    {items.map(({ label, type, value }, index) => (
                        <DropdownItem
                            isSelected={selectedIndex === index}
                            key={`${label}${index}`}
                            onClick={handleDropdownItemClick(index)}
                            text={label}
                            type={type}
                            value={value}
                        />
                    ))}
                </DropdownMenu>
            )}
        </VerticalContainer>
    );
});

WithDropdownMenu.displayName = 'WithDropdownMenu';

export default WithDropdownMenu;

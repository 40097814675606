import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('disconnectConfirmationModal');

    return {
        CONFIRM_DISCONNECT: t('confirmDisconnect'),
        CONFIRM_DISCONNECT_MESSAGE: t('confirmDisconnectMessage'),
        DISCONNECT: t('disconnect')
    };
};

export default useTranslation;

import { HorizontalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FormRow: FunctionComponent<Props> = ({ children, className = '', testId = TEST_ID }) => (
    <HorizontalContainer className={`${className} ${styles.formRow}`} testId={testId}>
        {children}
    </HorizontalContainer>
);

export default FormRow;

import * as jose from 'jose';
import { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import type { DecodedToken } from './types';

const useAccessToken = () => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

    const [decodedToken, setDecodedToken] = useState<DecodedToken>();

    const fetchToken = useCallback(
        async (orgId?: string, allowCache = true) => {
            try {
                if (isLoading || !isAuthenticated) {
                    throw new Error(
                        'System not ready for this hook. User information unavailable.'
                    );
                }

                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                        organization: orgId,
                        scope: 'read:current_user'
                    },
                    cacheMode: allowCache ? 'on' : 'off'
                });

                setDecodedToken(jose.decodeJwt(accessToken));

                return accessToken;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.error(error.message);
            }
        },
        [getAccessTokenSilently, isAuthenticated, isLoading]
    );

    return {
        decodedToken,
        fetchToken
    };
};

export default useAccessToken;

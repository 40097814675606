import { forwardRef, useEffect, useRef } from 'react';

import { TEST_ID } from './constants';
import type { InfiniteScrollProps } from './types';

import styles from './styles.module.scss';

// https://github.com/Apestein/better-react-infinite-scroll

const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
    (
        {
            children,
            className,
            endingMessage = <></>,
            fetchNextPage,
            hasNextPage,
            loader,
            nextPageToken,
            testId = TEST_ID
        },
        ref
    ) => {
        const observerTarget = useRef(null);

        useEffect(() => {
            const observer = new IntersectionObserver(
                entries => {
                    if (entries[0]?.isIntersecting && hasNextPage) {
                        fetchNextPage();
                    }
                },
                { threshold: 1 }
            );

            if (observerTarget.current) {
                observer.observe(observerTarget.current);
            }

            return () => observer.disconnect();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [nextPageToken, hasNextPage]);

        return (
            <div className={`${className} ${styles.infiniteScroll}`} data-testid={testId} ref={ref}>
                {children}

                <div ref={observerTarget} />

                {hasNextPage ? loader : endingMessage}
            </div>
        );
    }
);

InfiniteScroll.displayName = 'InfiniteScroll';

export default InfiniteScroll;

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SplitContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => (
    <div className={`${className} ${styles.splitContainer}`} data-testid={testId}>
        {children}
    </div>
);

export default SplitContainer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FlightQueryFlightModification } from 'generatedTypes/aam/models';
import { useCallback, useMemo } from 'react';

import { constructUtcDateTime } from '@@utils/dates';

import useFlights from 'hooks/useFlights';

const useData = () => {
    const { findAllFlights } = useFlights();

    const referenceDate = useMemo(() => new Date('1/1/2023'), []);

    // TODO add type to form
    const getFlightsFromForm = useCallback(
        async (form: any, modification?: FlightQueryFlightModification) => {
            const {
                applicationDate,
                applicationDateFromTime,
                applicationDateTimezone,
                applicationDateToTime,
                appliedWith
            } = form;

            const date = new Date(applicationDate);

            const startTime = constructUtcDateTime(
                date,
                applicationDateFromTime,
                applicationDateTimezone,
                referenceDate
            );

            const endTime = constructUtcDateTime(
                date,
                applicationDateToTime,
                applicationDateTimezone,
                referenceDate
            );

            const startTimeIso = startTime.toISOString();
            const endTimeIso = endTime.toISOString();

            const today = new Date();

            if (startTime > endTime) {
                // TODO have this be done with better error handling
                alert('Start Time Must Be Less Than End Time');
            }

            if (endTime > today) {
                alert('End Time Must Not Be In The Future');
            }

            return await findAllFlights({
                droneSerialNumbers: appliedWith,
                flightModification: modification,
                timeSpan: [startTimeIso, endTimeIso]
            });
        },
        [findAllFlights, referenceDate]
    );

    return { getFlightsFromForm };
};

export default useData;

import { type FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { Content } from '@rantizo-software/rantizo-ui';

import SimpleHeader from 'components/SimpleHeader';
import Toast from 'components/Toast';

import styles from './styles.module.scss';

const SimpleLayout: FunctionComponent = () => (
    <div className={styles.simpleLayout}>
        <SimpleHeader className={styles.simpleHeader} />

        <Toast />

        <Content className={styles.content}>
            <Outlet />
        </Content>
    </div>
);

export default SimpleLayout;

import { forwardRef } from 'react';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const InputWithLabel = forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            className = '',
            hasError = false,
            isEditable = true,
            onClick,
            testId = TEST_ID,
            text = ''
        },
        ref
    ) => {
        const noLabelClass = !text ? styles.noLabel : '';
        const viewOnlyClass = isEditable ? styles.editable : styles.viewOnly;
        const errorClassName = hasError ? styles.error : '';

        return (
            <div
                className={`${className} ${viewOnlyClass}  ${noLabelClass}  ${styles.inputWithLabel} ${errorClassName}`}
                data-testid={testId}
                onClick={onClick}
                ref={ref}
            >
                {children}
            </div>
        );
    }
);

InputWithLabel.displayName = 'InputWithLabel';

export default InputWithLabel;

import { useCallback } from 'react';

import CityStateZipContainer from 'components/CityStateZipContainer';
import FormSectionContainer from 'components/FormSectionContainer';
import WithLabel from 'components/WithLabel';
import AddressOneField from 'components/form/FormFields/AddressOneField';
import AddressTwoField from 'components/form/FormFields/AddressTwoField';
import CityField from 'components/form/FormFields/CityField';
import StatesDropdownField from 'components/form/FormFields/StatesDropdownField';
import ZipCodeField from 'components/form/FormFields/ZipCodeField';

import useFormState from 'hooks/useFormState';

import useTranslation from './hooks/useTranslation';

import {
    ADDRESS_ONE_FIELD,
    ADDRESS_TWO_FIELD,
    CITY_FIELD,
    STATE_FIELD,
    TEST_ID,
    ZIP_CODE_FIELD
} from './constants';
import type { AddressFormSchema, NestedKeys, PrefixedKeys, Props } from './types';

const AddressForm = (props: Props) => {
    const { ADDRESS, CITY, STATE, ZIP_CODE } = useTranslation();

    const {
        className = '',
        formName,
        handleSubmit,
        isDisabled,
        isEditable,
        parentForm,
        testId = TEST_ID
    } = props;

    const prependFormName = useCallback(
        (
            fieldName: keyof AddressFormSchema
        ): NestedKeys<AddressFormSchema> | PrefixedKeys<AddressFormSchema, string> =>
            formName
                ? (`${formName}.${fieldName}` as PrefixedKeys<AddressFormSchema, typeof formName>)
                : fieldName,
        [formName]
    );

    const localForm = useFormState<AddressFormSchema>({
        initialSchema: {
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            zipCode: null
        },
        onFormChange(data) {
            // won't fire if parentForm is provided
            console.log({ data });
        },
        onSubmit: handleSubmit
    });

    const form = parentForm ?? localForm;

    return (
        <FormSectionContainer className={className} data-testid={testId}>
            <WithLabel text={ADDRESS}>
                <AddressOneField<AddressFormSchema>
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    isRequired={true}
                    name={prependFormName(ADDRESS_ONE_FIELD)}
                />

                <AddressTwoField<AddressFormSchema>
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    name={prependFormName(ADDRESS_TWO_FIELD)}
                />
            </WithLabel>

            <CityStateZipContainer>
                <WithLabel text={CITY}>
                    <CityField<AddressFormSchema>
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={prependFormName(CITY_FIELD)}
                    />
                </WithLabel>

                <WithLabel text={STATE}>
                    <StatesDropdownField<AddressFormSchema>
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={prependFormName(STATE_FIELD)}
                    />
                </WithLabel>

                <WithLabel text={ZIP_CODE}>
                    <ZipCodeField<AddressFormSchema>
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={prependFormName(ZIP_CODE_FIELD)}
                    />
                </WithLabel>
            </CityStateZipContainer>
        </FormSectionContainer>
    );
};

AddressForm.displayName = 'AddressForm';

export default AddressForm;

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import LocationSection from 'components/LocationSection';
import WithLabel from 'components/WithLabel';

import FlightsMap from 'pages/GenerateAsAppliedMapPage/components/FlightsMap';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const FlightOverview: FunctionComponent<Props> = ({
    centroidLocation,
    data,
    isLoading,
    onAlternatePathClick,
    onLoad,
    onPathClick,
    testId = TEST_ID
}) => {
    const { FLIGHT_OVERVIEW, MAP_OVERVIEW } = useTranslation();

    return (
        <VerticalContainer>
            <WithLabel testId={testId} text={FLIGHT_OVERVIEW}>
                <LocationSection
                    address={centroidLocation?.address}
                    latitude={centroidLocation?.coords[1]}
                    longitude={centroidLocation?.coords[0]}
                />
            </WithLabel>

            <WithLabel text={MAP_OVERVIEW}>
                <FlightsMap
                    center={centroidLocation?.coords}
                    data={data}
                    isLoading={isLoading}
                    onAlternateFlightPathClick={onAlternatePathClick}
                    onLoad={onLoad}
                    onPathClick={onPathClick}
                />
            </WithLabel>
        </VerticalContainer>
    );
};

export default FlightOverview;

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const TopBottomContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    testId = TEST_ID
}) => (
    <VerticalContainer
        className={`${className} ${styles.topBottomContainer}`}
        onClick={onClick}
        testId={testId}
    >
        {children}
    </VerticalContainer>
);

export default TopBottomContainer;

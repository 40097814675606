import useData from '../useData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useDate from 'hooks/useDate';
import useFlights from 'hooks/useFlights';

import { DJI_API, XAG_API, XAG_KML_API } from './constants';
import type {
    DateRange,
    FetchFlightDataArgs,
    FlightSummariesGroupedByDate,
    FlightSummaryQuery,
    PagedFlightSummaries,
    Props,
    SingleDeleteFlightLog
} from './types';

const defaultSort = ['startTime,desc'];

const useFlightLogPage = ({
    setDeleteConfirmationModalStatus,
    setDeleteErrorModalStatus
}: Props) => {
    const { fetch: fetchFlights } = useData();
    const { subDaysFromDate } = useDate();
    const { deleteUploadFlight } = useFlights();

    const isBulkDelete = useRef(false);

    const defaultEndTime = useMemo(() => new Date(), []);
    const defaultStartTime = useMemo(
        () => subDaysFromDate({ date: defaultEndTime, days: 730 }),
        [defaultEndTime, subDaysFromDate]
    );

    const [selectedAircraftNicknames, setSelectedAircraftNicknames] = useState<string[]>([]);
    const [singleDeleteFlightLog, setSingleDeleteFlightLog] =
        useState<SingleDeleteFlightLog | null>(null);
    const [loading, setLoading] = useState(true);
    const [dateRangeValue, setDateRangeValue] = useState<DateRange>();
    const [selectedFlightIds, setSelectedFlightIds] = useState<string[]>([]);
    const [flights, setFlights] = useState<PagedFlightSummaries>({
        hasNext: false,
        nextPageToken: '',
        objects: {} as FlightSummariesGroupedByDate
    });

    const deleteApiMap = useMemo(
        () => ({
            DJI_SYNCED: DJI_API,
            DJI_UPLOADED: DJI_API,
            XAG_KML_UPLOADED: XAG_KML_API,
            XAG_UPLOADED: XAG_API
        }),
        []
    );

    const fetchFlightData = useCallback(
        async ({
            dateRange,
            filter = false,
            hasMore,
            nextToken,
            nicknames
        }: FetchFlightDataArgs) => {
            const query: FlightSummaryQuery = {
                endTime: dateRange?.to?.toISOString() || defaultEndTime.toISOString(),
                nicknames,
                startTime: dateRange?.from?.toISOString() || defaultStartTime.toISOString()
            };

            try {
                const {
                    hasNext,
                    nextPageToken,
                    objects: newFlights
                } = await fetchFlights(query, defaultSort, hasMore ? nextToken : null);

                setFlights((currentState: PagedFlightSummaries) => ({
                    ...currentState,
                    hasNext,
                    nextPageToken,
                    objects: filter ? newFlights : { ...currentState.objects, ...newFlights }
                }));
            } catch (error) {
                console.error(error);
                alert(error);
            }
        },

        [defaultEndTime, defaultStartTime, fetchFlights]
    );

    const handleDateRangeFilterChange = useCallback(
        async (dateRange: DateRange) => {
            if (!dateRange.from && !dateRange.to) {
                return;
            }
            setDateRangeValue(dateRange);

            await fetchFlightData({
                dateRange,
                filter: true,
                hasMore: false,
                nextToken: '',
                nicknames: selectedAircraftNicknames
            });
            setSelectedFlightIds([]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedAircraftNicknames]
    );

    const handleAircraftFilterChange = useCallback(
        async (nicknames: string[]) => {
            setSelectedAircraftNicknames(nicknames);

            await fetchFlightData({
                dateRange: dateRangeValue,
                filter: true,
                hasMore: false,
                nextToken: '',
                nicknames
            });
            setSelectedFlightIds([]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dateRangeValue]
    );

    useEffect(() => {
        const initialFlightFetch = async () => {
            const isFiltered =
                selectedAircraftNicknames.length > 0 ||
                !!dateRangeValue?.to ||
                !!dateRangeValue?.from;

            await fetchFlightData({
                dateRange: dateRangeValue,
                filter: isFiltered,
                hasMore: false,
                nextToken: '',
                nicknames: selectedAircraftNicknames
            });
        };

        if (loading) {
            initialFlightFetch();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSingleDelete = useCallback(
        ({ dateString, id, url }: SingleDeleteFlightLog) => {
            setSingleDeleteFlightLog({ dateString, id, url });
            isBulkDelete.current = false;
            setDeleteConfirmationModalStatus(true);
        },
        [setDeleteConfirmationModalStatus]
    );

    const handleSingleDelete = useCallback(async () => {
        if (!singleDeleteFlightLog) {
            return;
        }
        const { dateString, id, url } = singleDeleteFlightLog;

        try {
            await deleteUploadFlight(id, url);

            setFlights(({ hasNext, nextPageToken, objects }) => {
                const updatedObjects = {
                    ...objects,
                    [dateString]: objects[dateString].filter(flight => flight.id !== id)
                };

                const isDateEmpty = updatedObjects[dateString].length === 0;

                if (isDateEmpty) {
                    delete updatedObjects[dateString];
                }

                setSelectedFlightIds(previouslySelectedFlightIds =>
                    previouslySelectedFlightIds.filter(selectedId => selectedId !== id)
                );

                return {
                    hasNext,
                    nextPageToken,
                    objects: updatedObjects
                };
            });

            setDeleteConfirmationModalStatus(false);
            setSingleDeleteFlightLog(null);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }, [deleteUploadFlight, setDeleteConfirmationModalStatus, singleDeleteFlightLog]);

    const hasSyncedFlightsInSelected = useMemo(
        () =>
            Object.keys(flights.objects)
                .map((date: string) =>
                    flights.objects[date].find(
                        ({ flightSource, id }) =>
                            selectedFlightIds.includes(id) && flightSource === 'DJI_SYNCED'
                    )
                )
                .filter(test => test).length > 0,
        [flights, selectedFlightIds]
    );

    const onBulkDelete = useCallback(() => {
        if (hasSyncedFlightsInSelected) {
            setDeleteErrorModalStatus(true);

            return;
        } else {
            isBulkDelete.current = true;
            setDeleteConfirmationModalStatus(true);
        }
    }, [hasSyncedFlightsInSelected, setDeleteConfirmationModalStatus, setDeleteErrorModalStatus]);

    const handleBulkDelete = useCallback(async () => {
        const selectedFlightSummaries = Object.keys(flights.objects)
            .map((date: string) =>
                flights.objects[date].filter(flight => selectedFlightIds.includes(flight.id))
            )
            .flat();

        const deletePromises = selectedFlightSummaries.map(({ flightSource, id }) => {
            const url = deleteApiMap[flightSource as keyof typeof deleteApiMap];

            return deleteUploadFlight(id, url);
        });

        try {
            await Promise.all(deletePromises);

            await fetchFlightData({
                dateRange: dateRangeValue,
                filter: true,
                hasMore: false,
                nextToken: '',
                nicknames: selectedAircraftNicknames
            });
            setSelectedFlightIds([]);
            setDeleteConfirmationModalStatus(false);
        } catch (error) {
            console.error('Error deleting some items:', error);
        }
    }, [
        dateRangeValue,
        deleteApiMap,
        deleteUploadFlight,
        fetchFlightData,
        flights.objects,
        selectedAircraftNicknames,
        selectedFlightIds,
        setDeleteConfirmationModalStatus
    ]);

    return {
        dateRangeValue,
        fetchFlightData,
        flights,
        handleAircraftFilterChange,
        handleBulkDelete,
        handleDateRangeFilterChange,
        handleSingleDelete,
        isBulkDelete: isBulkDelete.current,
        loading,
        onBulkDelete,
        onSingleDelete,
        selectedAircraftNicknames,
        selectedFlightIds,
        setSelectedFlightIds,
        singleDeleteFlightLog
    };
};

export default useFlightLogPage;

import { useCallback, useMemo, useRef, useState } from 'react';

import BackNextButtons from 'components/BackNextButtons';
import FormSectionContainer from 'components/FormSectionContainer';
import MonthPicker from 'components/MonthPicker';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WithLabel from 'components/WithLabel';
import DroneMakeDropdown from 'components/form/DroneMakeDropdown';
import MultiFileUpload from 'components/form/MultiFileUpload';

import useForm from 'hooks/useForm';

import useFileUpload from './hooks/useFileUpload';
import useTranslation from './hooks/useTranslation';

import { MAX_YEAR, MIN_YEAR, REPORTING_PERIOD_FIELD, TEST_ID } from './constants';
import type { FileUpload, FunctionComponent, Props } from './types';

const OperationDetails: FunctionComponent<Props> = ({
    faaReportId,
    formData,
    isEditable = true,
    isSaveDisabled,
    onBack,
    onChange,
    onNext,
    onSave,
    testId = TEST_ID
}) => {
    const {
        addFlightReport,
        addUploadedFile,
        droneMake,
        filePickerExtensionMap,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        updateDroneMake,
        validateFile
    } = useFileUpload();

    const [isProcessingFlightLog, setIsProcessingFlightLog] = useState<boolean>(false);

    const { ERROR_ADDING_FLIGHT_REPORT, FILE_UPLOADS, FLIGHT_REPORT_UPLOAD, REPORTING_PERIOD } =
        useTranslation();

    const form = useForm(formData);

    const { handleChange, handleValid } = form;

    const currentFormData = form.form.current;

    const flightReportRef = useRef(null);
    const reportingPeriodRef = useRef(null);

    const handleAddFlightReport = useCallback(async () => {
        if (getUploadedFiles().length === 0) {
            return;
        }
        setIsProcessingFlightLog(true);

        try {
            await addFlightReport(faaReportId);
        } catch (e) {
            alert(ERROR_ADDING_FLIGHT_REPORT);
            console.error('ERROR', e);
        } finally {
            setIsProcessingFlightLog(false);
        }
    }, [ERROR_ADDING_FLIGHT_REPORT, addFlightReport, faaReportId, getUploadedFiles]);

    const handleBack = useCallback(async () => {
        onBack?.({ form });
    }, [form, onBack]);

    const handleNext = useCallback(async () => {
        if (isEditable) {
            await handleAddFlightReport();
            onSave();
        }
        onNext?.({ form });
    }, [form, handleAddFlightReport, isEditable, onNext, onSave]);

    const onDroneMakeChange = useCallback(
        (make: string) => {
            updateDroneMake(make);
        },
        [updateDroneMake]
    );

    const handleDateChange = useCallback(
        (dateOfIncident: Date) => {
            handleChange(REPORTING_PERIOD_FIELD)(dateOfIncident);
            handleValid(REPORTING_PERIOD_FIELD)(dateOfIncident);
            onChange(form);
        },
        [form, handleChange, handleValid, onChange]
    );

    const handleGetPresignedUrl = useCallback(
        (fileUpload: FileUpload) => getPresignedUrl(fileUpload, [faaReportId]),
        [faaReportId, getPresignedUrl]
    );

    const isNextDisabled = useMemo(
        () => (isProcessingFlightLog || getUploadedFiles().length === 0) && isEditable,
        [getUploadedFiles, isEditable, isProcessingFlightLog]
    );

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer>
                <FormSectionContainer>
                    <WithLabel text={REPORTING_PERIOD}>
                        <MonthPicker
                            isEditable={isEditable}
                            maxYear={MAX_YEAR}
                            minYear={MIN_YEAR}
                            onChange={handleDateChange}
                            ref={reportingPeriodRef}
                            value={currentFormData.reportingPeriod!}
                        />
                    </WithLabel>

                    <WithLabel text={FLIGHT_REPORT_UPLOAD}>
                        <DroneMakeDropdown
                            isEditable={isEditable}
                            onChange={onDroneMakeChange}
                            ref={flightReportRef}
                        />
                    </WithLabel>

                    <WithLabel text={FILE_UPLOADS}>
                        <MultiFileUpload
                            acceptedFileTypes={
                                droneMake
                                    ? filePickerExtensionMap[
                                          droneMake as keyof typeof filePickerExtensionMap
                                      ]
                                    : ''
                            }
                            addUploadedFile={addUploadedFile}
                            getPresignedUrl={handleGetPresignedUrl}
                            getUploadedFiles={getUploadedFiles}
                            isDisabled={!droneMake}
                            isEditable={isEditable}
                            modifyFileRequest={modifyFileRequest}
                            previouslyUploadedFiles={formData.files}
                            setUploadedFiles={setUploadedFiles}
                            validateFile={validateFile}
                        />
                    </WithLabel>
                </FormSectionContainer>

                <BackNextButtons
                    isNextDisabled={isNextDisabled || isSaveDisabled}
                    isNextLoading={false}
                    onBack={handleBack}
                    onNext={handleNext}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default OperationDetails;

import { subDays } from 'date-fns';
import { useCallback } from 'react';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import { AAM_TYPE, COVERAGE_TYPE, DRY_COVERAGE_TYPE } from './constants';
import type { AsAppliedMapJob, Maps, ReducedAAM } from './types';

const useData = () => {
    const { fetchAsAppliedMapJobs } = useAsAppliedMaps();

    const findMap = useCallback(
        (maps: Maps) =>
            maps.find(({ type }) => type === AAM_TYPE) ||
            maps.find(({ type }) => type === COVERAGE_TYPE) ||
            maps.find(({ type }) => type === DRY_COVERAGE_TYPE),
        []
    );

    const normalize = useCallback(
        (list: AsAppliedMapJob[]) =>
            list
                .filter(({ status }) => status === 'COMPLETE')
                .reduce(
                    (
                        currentList,
                        { applicationStartDate, createdAt, id: jobId, maps, productUsageReport }
                    ) => {
                        const map = findMap(maps);

                        const { id, type } = map || {};

                        if (id && type) {
                            currentList.push({
                                applicationStartDate: applicationStartDate
                                    ? new Date(applicationStartDate).toLocaleDateString('en-US')
                                    : '',
                                contact: productUsageReport?.contact ?? '',
                                createdAt: new Date(createdAt).toLocaleDateString('en-US'),
                                growerName: productUsageReport?.growerName ?? '',
                                id: id,
                                jobId: jobId,
                                mapType: type,
                                productUsageReportId: productUsageReport?.id ?? '',
                                siteName: productUsageReport?.siteName ?? ''
                            });
                        }

                        return currentList;
                    },
                    [] as ReducedAAM[]
                ),
        [findMap]
    );

    const fetch = useCallback(
        async (pageToken: string | null, pageSize = 25) => {
            const endDate = new Date();
            const startDate = subDays(endDate, 365);

            const jobQuery = {
                maxApplicationStartDate: endDate.toISOString(),
                minApplicationStartDate: startDate.toISOString()
            };

            const {
                hasNext,
                nextPageToken,
                objects: list
            } = await fetchAsAppliedMapJobs(jobQuery, pageSize, pageToken ?? null);

            return { hasNext, nextPageToken, objects: normalize(list) };
        },
        [fetchAsAppliedMapJobs, normalize]
    );

    return {
        fetch
    };
};

export default useData;

import { formatPhoneNumber } from '@@utils/string';
import { ContentText } from '@rantizo-software/rantizo-ui';

import EditableSection from 'components/EditableSection';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const UserDetailsEditCard: FunctionComponent<Props> = ({
    editable = false,
    email = '',
    firstName = '',
    lastName = '',
    onEdit,
    phoneNumber = ''
}) => {
    const { TITLE } = useTranslation();

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber) ?? '';

    return (
        <EditableSection editable={editable} onEdit={onEdit} title={TITLE}>
            <ContentText text={`${firstName} ${lastName}`} />

            <ContentText text={formattedPhoneNumber} />

            <ContentText text={email} />
        </EditableSection>
    );
};

export default UserDetailsEditCard;

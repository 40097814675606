import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import type { UseFileDownload } from './types';

const useFileDownload: UseFileDownload = ({ downloadApi }) => {
    const { authenticatedPost } = useFetch();

    const getDownloadLink = useCallback(
        async (documentId: string) => {
            const { data, error } = await authenticatedPost(
                downloadApi,

                JSON.stringify({
                    documentId: documentId
                })
            );

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data.downloadUrl;
        },
        [authenticatedPost, downloadApi]
    );

    return { getDownloadLink };
};

export default useFileDownload;

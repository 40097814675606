import { useCallback } from 'react';

import type { UseValidationHookInput, UseValidationHookResponse, ValidateValue } from './types';

const useValidation = ({ validators }: UseValidationHookInput): UseValidationHookResponse => {
    const validateValue: ValidateValue = useCallback(
        value => {
            for (const validator of validators) {
                if (!validator.isValid(value)) {
                    return validator.errorText;
                }
            }

            return undefined;
        },
        [validators]
    );

    return {
        validateValue
    };
};

export default useValidation;

export const MANAGE_EQUIPMENT_ROLE = 'equipment:manage';

export const MANAGE_ORGANIZATIONS_ROLE = 'organizations:manage';

export const MANAGE_USERS_ROLE = 'users:manage';

export const MANAGE_FAA_REPORTS_ROLE = 'faa-reports:manage';

export const MANAGE_FLIGHTS_ROLE = 'flights:manage';

export const MANAGE_WORK_ORDERS_ROLE = 'work-orders:manage';

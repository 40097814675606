import Title from 'components/Title';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const DatedListSeparatorTitle = ({ testId = TEST_ID, text }: Props) => (
    <Title className={styles.datedListSeparatorTitle} testId={testId} text={text} />
);

export default DatedListSeparatorTitle;

import { useCallback, useMemo } from 'react';

import SideBarContainer from 'components/SideBarContainer';
import SidebarItem from 'components/SideBarItem';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, ReactNode } from './types';

const SideBar: FunctionComponent<Props> = ({
    className = '',
    currentSection,
    onSectionChange,
    sideBarItems,
    testId = TEST_ID
}) => {
    const handleSectionClick = useCallback(
        (section: string) => {
            onSectionChange(section);
        },
        [onSectionChange]
    );

    const sideBarElements = useMemo(
        () =>
            Object.keys(sideBarItems).reduce(
                (accumulator, sidebarItemKey) => {
                    const sidebarItem = sideBarItems[sidebarItemKey];

                    const { index = 0, isDisabled, isInvalid, title } = sidebarItem;

                    accumulator.splice(
                        index,
                        1,
                        <SidebarItem
                            onClick={() => {
                                handleSectionClick(sidebarItemKey);
                            }}
                            isActive={currentSection === sidebarItemKey}
                            isDisabled={isDisabled}
                            isInvalid={isInvalid}
                            key={sidebarItemKey}
                            text={title}
                        />
                    );

                    return accumulator;
                },
                new Array(Object.keys(sideBarItems).length) as ReactNode[]
            ),
        [sideBarItems, currentSection, handleSectionClick]
    );

    return (
        <SideBarContainer className={className} testId={testId}>
            {sideBarElements}
        </SideBarContainer>
    );
};

export default SideBar;

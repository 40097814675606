import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';
import {
    FIELD_VIEW_AUTH_URL,
    FIELD_VIEW_CLIENT_ID,
    FIELD_VIEW_CONNECTION_API,
    FIELD_VIEW_REDIRECT_URI,
    FIELD_VIEW_SCOPES
} from 'hooks/useFieldView/constants';
import type { OAuth2Params, OAuth2Token } from 'hooks/useFieldView/types';
import useGlobalStore from 'hooks/useGlobalStore';
import useOAuth2 from 'hooks/useOAuth2';

const useFieldView = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useFieldView' });
    const [isLoading, setIsLoading] = useState(false);
    const [isFieldViewConnected, setIsFieldViewConnected] = useState<boolean | undefined>(
        undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveFieldViewToken = useCallback(setValue('fieldViewToken'), []);

    const token = getValue('fieldViewToken') as OAuth2Token | undefined;

    const { authenticatedDelete, authenticatedGet, authenticatedPost } = useFetch();

    const oAuth2Params = {
        authorizationEndpoint: FIELD_VIEW_AUTH_URL,
        clientId: FIELD_VIEW_CLIENT_ID,
        redirectUri: FIELD_VIEW_REDIRECT_URI,
        scope: FIELD_VIEW_SCOPES,
        setToken: saveFieldViewToken,
        token: token,
        tokenEndpoint: ''
    } as OAuth2Params;

    const { fetchTokenOrRedirectAndLogIn, setTokenFromRedirect } = useOAuth2(oAuth2Params);

    const createFieldviewConnection = useCallback(
        async (code: string) => {
            setIsLoading(true);
            const { data, error } = await authenticatedPost(
                FIELD_VIEW_CONNECTION_API,
                JSON.stringify({
                    code,
                    redirectUri: FIELD_VIEW_REDIRECT_URI
                })
            );

            setIsFieldViewConnected(true);

            if (error) {
                setIsLoading(false);
                throw error;
            }
            setIsLoading(false);

            return data;
        },
        [authenticatedPost]
    );

    const getFieldViewConnectionStatus = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await authenticatedGet(FIELD_VIEW_CONNECTION_API);

            setIsFieldViewConnected(data.enabled);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            throw new Error(JSON.stringify(error));
        }
    }, [authenticatedGet]);

    const deleteFieldViewConnection = useCallback(async () => {
        try {
            setIsLoading(true);
            await authenticatedDelete(FIELD_VIEW_CONNECTION_API, JSON.stringify({}));
            setIsFieldViewConnected(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            throw new Error(JSON.stringify(error));
        }
    }, [authenticatedDelete]);

    return {
        createFieldviewConnection,
        deleteFieldViewConnection,
        fetchTokenOrRedirectAndLogIn,
        getFieldViewConnectionStatus,
        isFieldViewConnected,
        isLoading,
        setTokenFromRedirect
    };
};

export default useFieldView;

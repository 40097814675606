import { PencilIcon } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithPenIcon: FunctionComponent<Props> = ({
    active = false,
    children,
    className = '',
    onEdit,
    testId = TEST_ID
}) => (
    <div className={`${className} ${styles.withPencilIcon}`} data-testid={testId}>
        {children}

        {active && (
            <button className={styles.pencilIconButton} onClick={onEdit}>
                <PencilIcon
                    className={`${styles.pencilIcon} ${
                        active ? styles.pencilIconActive : styles.pencilIconInactive
                    }`}
                />
            </button>
        )}
    </div>
);

export default WithPenIcon;

import { createPortal } from 'react-dom';

import ErrorNotification from 'components/ErrorNotification';
import InformationNotification from 'components/InformationNotification';
import SuccessNotification from 'components/SuccessNotification';
import WarningNotification from 'components/WarningNotification';

import useToast from 'hooks/useToast';
import type { NotificationType } from 'hooks/useToast/types';

import type { NotificationsMap } from './types';

import styles from './styles.module.scss';

const notificationsMap: NotificationsMap = {
    error: ErrorNotification,
    info: InformationNotification,
    success: SuccessNotification,
    warning: WarningNotification
};

const Toast = () => {
    const { setToastData, toastData } = useToast();

    if (!toastData) {
        return null;
    }

    const Toast = notificationsMap[toastData.type as NotificationType];

    return createPortal(
        <div className={styles.toast}>
            <Toast onClose={() => setToastData(null)} text={toastData.text} />
        </div>,
        document.body
    );
};

export default Toast;

import { forwardRef, memo } from 'react';

import TextInput from 'components/form/TextInput';

import { TEST_ID } from './constants';
import type { Props } from './types';

const ExemptionDocketNumberInput = memo(
    forwardRef<HTMLInputElement, Props>((props, ref) => {
        const {
            className = '',
            hasError = false,
            isDisabled = false,
            isEditable = true,
            isRequired = false,
            name = '',
            onChange,
            onError,
            onKeyUp,
            onSubmit,
            onValid,
            placeholder = '',
            testId = TEST_ID,
            value
        } = props;

        return (
            <TextInput
                className={className}
                hasError={hasError}
                isDisabled={isDisabled}
                isEditable={isEditable}
                isRequired={isRequired}
                name={name}
                onChange={onChange}
                onError={onError}
                onKeyUp={onKeyUp}
                onSubmit={onSubmit}
                onValid={onValid}
                placeholder={placeholder}
                ref={ref}
                testId={testId}
                value={value}
            />
        );
    })
);

ExemptionDocketNumberInput.displayName = 'ExemptionDocketNumberInput';

export default ExemptionDocketNumberInput;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentText } from '@rantizo-software/rantizo-ui';

import EditableSection from 'components/EditableSection';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

const CompanyDetailsEditCard: FunctionComponent<Props> = ({ editable = false }) => {
    const { currentOrganization } = useCurrentOrganization();
    const navigate = useNavigate();
    const { editCompanyDetailsPage } = usePageRoutes();
    const { TITLE } = useTranslation();

    const handleEdit = useCallback(() => {
        navigate(editCompanyDetailsPage);
    }, [editCompanyDetailsPage, navigate]);

    if (currentOrganization === undefined) {
        return null;
    }

    const { address1, address2, city, name, phoneNumber, state, zipCode } = currentOrganization;

    const orgPhoneSections = phoneNumber ? phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/) : null;
    const formattedPhoneNumber =
        orgPhoneSections !== null
            ? `(${orgPhoneSections[1]}) ${orgPhoneSections[2]}-${orgPhoneSections[3]}`
            : '';

    const address = `${address1}${address2 ? `,  ${address2}` : ''}, ${city}, ${state} ${
        zipCode || ''
    }`;

    return (
        <EditableSection editable={editable} onEdit={handleEdit} title={TITLE}>
            <ContentText text={name} />

            <ContentText text={address} />

            <ContentText text={formattedPhoneNumber} />
        </EditableSection>
    );
};

export default CompanyDetailsEditCard;

import { useCallback } from 'react';

import {
    ContentText,
    DeleteButton,
    ModalTitle,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import ModalOverlay from 'components/ModalOverlay';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Map, Props } from './types';

import styles from './styles.module.scss';

const FlightLogDeleteConfirmationModal: FunctionComponent<Props> = ({
    isBulkDelete = false,
    onClose,
    onConfirm,
    testId = TEST_ID
}) => {
    const {
        BUTTON_TEXT,
        CONFIRM_DELETE_FLIGHT_LOG,
        CONFIRM_DELETE_FLIGHT_LOG_MESSAGE,
        CONFIRM_DELETE_FLIGHT_LOG_SUBTEXT_MESSAGE,
        CONFIRM_DELETE_FLIGHT_LOGS,
        CONFIRM_DELETE_FLIGHT_LOGS_MESSAGE
    } = useTranslation();

    const handleDelete = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    const titleMap: Map = {
        false: CONFIRM_DELETE_FLIGHT_LOG,
        true: CONFIRM_DELETE_FLIGHT_LOGS
    };

    const messageMap: Map = {
        false: CONFIRM_DELETE_FLIGHT_LOGS_MESSAGE,
        true: CONFIRM_DELETE_FLIGHT_LOG_MESSAGE
    };

    const isBulkDeleteString = String(isBulkDelete);
    const message = messageMap[String(isBulkDeleteString)];
    const title = titleMap[String(isBulkDeleteString)];

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer className={styles.flightLogDeleteConfirmationModalContainer}>
                <ModalTitle text={title} />

                <ContentText text={message} />

                <ContentText text={CONFIRM_DELETE_FLIGHT_LOG_SUBTEXT_MESSAGE} />

                <DeleteButton onClick={handleDelete} text={BUTTON_TEXT} />
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default FlightLogDeleteConfirmationModal;

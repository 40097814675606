import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';

import { AAM, COVERAGE, DRY_COVERAGE } from './constants';
import type { AsAppliedMapJob } from './types';

const normalize = ({ maps, productUsageReport }: AsAppliedMapJob) => ({
    asAppliedMapId: maps.find(({ type }) => type === AAM)?.id,
    coverageMapId: maps.find(({ type }) => type === COVERAGE)?.id,
    dryCoverageMapId: maps.find(({ type }) => type === DRY_COVERAGE)?.id,
    productUsageReportId: productUsageReport?.id
});

const useData = () => {
    const { jobId } = useParams();
    const { closeAuthenticatedConnection, fetchAsAppliedMapJobStatus } = useAsAppliedMaps();

    const status = useRef('idle');

    const fetch = useCallback(
        () =>
            new Promise((resolve, reject) => {
                try {
                    status.current = 'loading';

                    const handleComplete = (job: AsAppliedMapJob) => {
                        closeAuthenticatedConnection();
                        status.current = 'done';

                        resolve(normalize(job));
                    };

                    const handleError = () => {
                        closeAuthenticatedConnection();

                        console.log('There was an error');

                        reject();
                    };

                    const jobMap: Record<string, (job: AsAppliedMapJob) => void> = {
                        COMPLETE: handleComplete,
                        ERROR: handleError,
                        EXPIRED: handleError
                    };

                    const jobStatusHandler = {
                        onError: handleError,
                        onJobUpdate: (job: AsAppliedMapJob) => {
                            jobMap[job.status]?.(job);
                        }
                    };

                    fetchAsAppliedMapJobStatus(jobId as string, jobStatusHandler);
                } catch (e) {
                    console.error(e);
                    // TODO have this come from translate
                    alert('Unable to load aam try again later');
                }
            }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [jobId]
    );

    return {
        fetch,
        status: status.current
    };
};

export default useData;

import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DisplayResourceWithNavMenuPage from 'components/DisplayResourceWithNavMenuPage';
import ApplicationDetails from 'components/ProductUsageReport/ApplicationDetails';
import ProductDetails from 'components/ProductUsageReport/ProductDetails';
import ProductUsageReportNotes from 'components/ProductUsageReport/ProductUsageReportNotes';
import ProductUsageReportFooter from 'components/ProductUsageReportFooter';
import ProductUsageReportMenu from 'components/ProductUsageReportMenu';

import usePageRoutes from 'hooks/usePageRoutes';
import useProductUsageReports from 'hooks/useProductUsageReports';
import useWorkOrder from 'hooks/useWorkOrder';

import { defaultWorkOrder } from 'pages/WorkOrderReportPage/defaults.ts';

import useTranslation from './hooks/useTranslation';

import { defaultProductUsageReport } from './defaults';
import type {
    FunctionComponent,
    ProductUsageReport,
    ProductUsageReportSection,
    Props,
    ReactNode,
    SideBarItem,
    WorkOrder
} from './types';

const ProductUsageReportPage: FunctionComponent<Props> = ({ isEditable = false }) => {
    const { productUsageReportId } = useParams();
    const navigate = useNavigate();
    const { productUsageReportEditPage, productUsageReportsPage } = usePageRoutes();
    const { deleteProductUsageReport, fetchProductUsageReport } = useProductUsageReports();

    const { APPLICATION_DETAILS, NOTES, PRODUCT_DETAILS, TITLE } = useTranslation();

    const { fetchWorkOrder } = useWorkOrder();

    const [productUsageReport, setProductUsageReport] =
        useState<ProductUsageReport>(defaultProductUsageReport);

    const [workOrder, setWorkOrder] = useState<WorkOrder>(defaultWorkOrder);

    const [applicatorName, setApplicatorName] = useState<string>('');

    const isViewOnly = !isEditable;

    const sideBarItems: Record<ProductUsageReportSection, SideBarItem> = {
        applicationDetails: {
            index: 0,
            isDisabled: false,
            isInvalid: false,
            isViewOnly,
            nextSection: 'productDetails',
            title: APPLICATION_DETAILS
        },
        notes: {
            index: 2,
            isDisabled: false,
            isInvalid: false,
            isViewOnly,
            previousSection: 'productDetails',
            title: NOTES
        },
        productDetails: {
            index: 1,
            isDisabled: false,
            isInvalid: false,
            isViewOnly,
            nextSection: 'notes',
            previousSection: 'applicationDetails',
            title: PRODUCT_DETAILS
        }
    };

    const firstPage: ProductUsageReportSection = 'applicationDetails';

    const getWorkOrder = useCallback(
        async (id: string) => {
            const workOrder = await fetchWorkOrder(id);

            setApplicatorName(
                `${workOrder.data.applicator?.firstName ?? ''} ${workOrder.data.applicator?.lastName ?? ''}`
            );
            setWorkOrder(workOrder.data);
        },
        [fetchWorkOrder, setWorkOrder]
    );

    const onProductUsageReportFetched = useCallback(
        (data: ProductUsageReport) => {
            setProductUsageReport(data);
            getWorkOrder(data.workOrderId);
        },
        [setProductUsageReport, getWorkOrder]
    );

    const onDelete = useCallback(async () => {
        if (productUsageReportId) {
            try {
                await deleteProductUsageReport(productUsageReportId);
                navigate(productUsageReportsPage);
            } catch (error) {
                console.log(error);
                alert(error);
            }
        }
    }, [deleteProductUsageReport, productUsageReportId, navigate, productUsageReportsPage]);

    const contentMap: Record<ProductUsageReportSection, ReactNode> = {
        applicationDetails: (
            <ApplicationDetails
                applicatorName={applicatorName}
                productUsageReport={productUsageReport}
                workOrder={workOrder}
            />
        ),
        notes: <ProductUsageReportNotes productUsageReport={productUsageReport} />,
        productDetails: <ProductDetails productUsageReport={productUsageReport} />
    };

    const onEdit = useCallback(() => {
        if (productUsageReportId) {
            navigate(productUsageReportEditPage(productUsageReportId));
        }
    }, [navigate, productUsageReportEditPage, productUsageReportId]);

    const headerActions = (
        <ProductUsageReportMenu
            onDelete={onDelete}
            onEdit={productUsageReportId ? onEdit : undefined}
        />
    );

    return (
        <DisplayResourceWithNavMenuPage
            contentMap={contentMap}
            defaultSection={firstPage}
            fetchResource={fetchProductUsageReport}
            headerActions={headerActions}
            notFoundText={TITLE}
            onResourceFetched={onProductUsageReportFetched}
            resourceId={productUsageReportId}
            sideBarItems={sideBarItems}
            title={TITLE}
        >
            {productUsageReport && (
                <ProductUsageReportFooter
                    applicatorName={applicatorName}
                    productUsageReport={productUsageReport}
                    workOrder={workOrder}
                />
            )}
        </DisplayResourceWithNavMenuPage>
    );
};

export default ProductUsageReportPage;

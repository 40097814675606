import { forwardRef, useCallback, useEffect, useId, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { CalendarIcon, DropdownMenu } from '@rantizo-software/rantizo-ui';

import InputWithIcons from 'components/InputWithIcons';
import InputWithLabel from 'components/InputWithLabel';
import WithErrorMessage from 'components/WithErrorMessage';

import useDate from 'hooks/useDate';
import useDateFormat from 'hooks/useDateFormat';

import useTranslation from './hooks/useTranslation';

import {
    ALLOWED_DATE_RANGE,
    DATE_FORMAT,
    MAX_YEAR,
    MIN_YEAR,
    PLACEHOLDER,
    TEST_ID
} from './constants';
import type { MutableRefObject, Props, SelectSingleEventHandler } from './types';

import styles from './styles.module.scss';

const DatePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        dateFormat = DATE_FORMAT,
        hasError = false,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        label = '',
        maxDate,
        onChange,
        onError,
        onSubmit,
        onValid,
        placeholder = PLACEHOLDER,
        testId = TEST_ID,
        value
    } = props;
    const inputId = useId();
    const [month, setMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
    const [inputValue, setInputValue] = useState('');

    const { REQUIRED } = useTranslation();
    const { formatDate } = useDateFormat();
    const { isDateValid, isDateWithinInterval, parseDate } = useDate();

    const inputRef = ref as MutableRefObject<HTMLInputElement>;
    const dropdownRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (value) {
            const parsedDate =
                dateFormat === DATE_FORMAT
                    ? parseDate({ dateString: value, format: DATE_FORMAT })
                    : new Date(Date.parse(value));

            if (isDateWithinInterval({ date: parsedDate, interval: ALLOWED_DATE_RANGE })) {
                setSelectedDate(parsedDate);
                setMonth(parsedDate);
                setInputValue(formatDate(parsedDate));
            }
        }
    }, [dateFormat, formatDate, isDateValid, isDateWithinInterval, parseDate, value]);

    const handleError = useCallback(
        (message: string) => {
            onError?.(message);
            setError(message);
        },
        [onError]
    );

    const handleRequired = useCallback(
        (item: string) => {
            if (isRequired && !item) {
                handleError?.(REQUIRED);
            } else {
                onValid?.(item);
                setError('');
            }
        },
        [handleError, isRequired, onValid, setError, REQUIRED]
    );

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            const { relatedTarget } = event;

            if (selectedDate && isDateValid({ date: selectedDate })) {
                setInputValue(formatDate(selectedDate));
            } else {
                inputRef.current.value = '';
            }

            if (relatedTarget === null || !dropdownRef.current?.contains(relatedTarget)) {
                handleClose();
            } else {
                // If we don't want to close the dropdown on this blur event,
                // we need to refocus the input so that it isn't stuck open
                inputRef.current.focus();
            }
        },
        [formatDate, handleClose, inputRef, isDateValid, selectedDate]
    );

    const handleChange: SelectSingleEventHandler = useCallback(
        date => {
            if (!date) {
                setInputValue('');
                setSelectedDate(undefined);

                return;
            } else {
                setSelectedDate(date);
                setMonth(date);
                setInputValue(formatDate(date));
            }

            const formattedDate = formatDate(date);

            onChange?.(date, handleError);

            handleClose();

            handleRequired(formattedDate ?? '');

            inputRef.current.value = formattedDate;
        },
        [formatDate, handleClose, handleRequired, inputRef, onChange, handleError]
    );

    const handleTextChange = useCallback(() => {
        setInputValue(inputRef.current.value);
        const parsedDate = parseDate({ dateString: inputRef.current.value, format: DATE_FORMAT });

        if (
            isDateWithinInterval({
                date: parsedDate,
                interval: {
                    ...ALLOWED_DATE_RANGE,
                    end: maxDate ?? ALLOWED_DATE_RANGE.end
                }
            })
        ) {
            setSelectedDate(parsedDate);
            setMonth(parsedDate);
        } else {
            setSelectedDate(undefined);
        }
        onChange?.(parsedDate, handleError);
    }, [handleError, inputRef, isDateWithinInterval, maxDate, onChange, parseDate]);

    const handleOpen = useCallback(() => {
        inputRef?.current?.focus();

        setIsOpen(true);
    }, [inputRef, setIsOpen]);

    useEffect(() => {
        const currentReference = (ref as MutableRefObject<HTMLInputElement>).current;

        const handleSubmit = () => {
            const inputValue = inputRef?.current?.value;

            handleRequired(inputValue);

            onSubmit?.(inputValue, error);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [error, handleChange, handleRequired, inputRef, onSubmit, ref]);

    const hasAnError = Boolean(hasError || error);

    return (
        <WithErrorMessage className={className} text={error}>
            <InputWithLabel
                hasError={hasAnError}
                isDisabled={isDisabled}
                isEditable={isEditable}
                testId={testId}
                text={label}
            >
                <InputWithIcons
                    className={styles.input}
                    hasError={hasAnError}
                    id={inputId}
                    isDisabled={!isEditable || isDisabled}
                    isEditable={isEditable}
                    isReadOnly={hasAnError}
                    onBlur={handleBlur}
                    onChange={handleTextChange}
                    onClick={handleOpen}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={inputValue}
                >
                    <CalendarIcon className={isEditable ? '' : styles.noIcon} />
                </InputWithIcons>

                {isOpen && isEditable && (
                    <DropdownMenu
                        className={styles.dropdownMenu}
                        inputRef={inputRef}
                        onClose={handleClose}
                        ref={dropdownRef}
                        testId={`${testId}-dropdown`}
                    >
                        <DayPicker
                            disabled={maxDate ? { after: maxDate } : undefined}
                            fromYear={MIN_YEAR}
                            mode="single"
                            month={month}
                            onMonthChange={setMonth}
                            onSelect={handleChange}
                            selected={selectedDate}
                            toYear={MAX_YEAR}
                        />
                    </DropdownMenu>
                )}
            </InputWithLabel>
        </WithErrorMessage>
    );
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;

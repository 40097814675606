import { useCallback, useRef, useState } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import SplitButtonWithArrow from 'components/SplitButtonWithArrow';

import useEvent from 'hooks/useEvent';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OptionsButtonTextWithArrow: FunctionComponent<Props> = ({
    children,
    className = '',
    feature,
    isDisabled = false,
    isLoading = false,
    testId = TEST_ID,
    text
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = useCallback(
        (event: Event) => {
            if (!containerRef?.current?.contains(event.target as Node)) {
                setIsOpen(false);
            }
        },
        [containerRef]
    );

    useEvent({ event: 'mousedown', handler: handleClickOutside });

    const toggleButton = useCallback(() => {
        setIsOpen(open => !open);
    }, []);

    return (
        <div
            className={`${className} ${styles.optionsButton}`}
            data-testid={testId}
            ref={containerRef}
        >
            <SplitButtonWithArrow
                feature={feature}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onClick={toggleButton}
                onSplitClick={toggleButton}
                text={text}
            />

            <VerticalContainer
                className={`${styles.optionsItemContainer} ${!isOpen ? styles.gone : ''}`}
                onClick={() => setIsOpen(false)}
            >
                {children}
            </VerticalContainer>
        </div>
    );
};

export default OptionsButtonTextWithArrow;

import { BackArrowButton } from '@rantizo-software/rantizo-ui';

import BackX from 'components/BackX';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithBackArrow: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    testId = TEST_ID,
    useX = false
}) => (
    <div className={`${className} ${styles.withBackArrow}`} data-testid={testId}>
        {!useX && <BackArrowButton onClick={onClick} />}

        {useX && <BackX onClick={onClick} />}

        {children}
    </div>
);

export default WithBackArrow;

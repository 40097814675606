import { NotificationInfoIcon } from '@rantizo-software/rantizo-ui';

import BaseNotification from 'components/BaseNotification';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const InformationNotification: FunctionComponent<Props> = ({ onClose, testId = TEST_ID, text }) => (
    <BaseNotification
        className={styles.informationNotification}
        onClose={onClose}
        testId={testId}
        text={text}
    >
        <NotificationInfoIcon testId={TEST_ID} />
    </BaseNotification>
);

export default InformationNotification;

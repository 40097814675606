import { useCallback, useMemo, useState } from 'react';

import PageWithSidebar from 'components/PageWithSidebar';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import ApplicationDetailsTab from './components/ApplicationDetailsTab';
import DjiFileUploadTab from './components/DjiFileUploadTab';
import FlightOverviewTab from './components/FlightOverviewTab';
import XagFileUploadTab from './components/XagFileUploadTab';
import { TEST_ID } from './constants';
import type {
    DroneResponse,
    Flight,
    FlightLogSection,
    FunctionComponent,
    Props,
    SideBarItem
} from './types';

const UploadFlightLogPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { flightLogPage } = usePageRoutes();
    const back = useBack(flightLogPage);
    const { APPLICATION_DETAILS, FILE_UPLOAD, FLIGHT_OVERVIEW, UPLOAD_FLIGHT_LOG } =
        useTranslation();

    const [currentSection, setCurrentSection] = useState<FlightLogSection>('applicationDetails');
    const [selectedDrone, setSelectedDrone] = useState<DroneResponse | null>(null);
    const [flights, setFlights] = useState<Flight[]>([]);

    const sidebarItems: Record<FlightLogSection, SideBarItem> = useMemo(
        () => ({
            applicationDetails: {
                index: 0,
                isDisabled: currentSection === 'flightOverview',
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'fileUpload',
                title: APPLICATION_DETAILS
            },
            fileUpload: {
                index: 1,
                isDisabled: currentSection === 'flightOverview' || !selectedDrone,
                isInvalid: false,
                isViewOnly: true,
                nextSection: 'flightOverview',
                previousSection: 'applicationDetails',
                title: FILE_UPLOAD
            },
            flightOverview: {
                index: 2,
                isDisabled: flights.length === 0,
                isInvalid: false,
                isViewOnly: true,
                previousSection: 'fileUpload',
                title: FLIGHT_OVERVIEW
            }
        }),
        [
            APPLICATION_DETAILS,
            FILE_UPLOAD,
            FLIGHT_OVERVIEW,
            currentSection,
            flights.length,
            selectedDrone
        ]
    );

    const onFlightsCreated = useCallback((newFlights: Flight[]) => {
        setFlights(newFlights);
        setCurrentSection('flightOverview');
    }, []);

    const fileUploadTab: { [key: string]: () => React.ReactNode } = useMemo(
        () => ({
            DJI: () => (
                <DjiFileUploadTab
                    onBack={() => setCurrentSection('applicationDetails')}
                    onFlightsCreated={onFlightsCreated}
                    selectedAircraft={selectedDrone as DroneResponse}
                />
            ),
            XAG: () => (
                <XagFileUploadTab
                    onBack={() => setCurrentSection('applicationDetails')}
                    onFlightsCreated={onFlightsCreated}
                    selectedAircraft={selectedDrone as DroneResponse}
                />
            )
        }),
        [onFlightsCreated, selectedDrone]
    );

    const contentMap = useMemo(
        () => ({
            applicationDetails: (
                <ApplicationDetailsTab
                    onDroneSelected={(drone: DroneResponse) => {
                        setSelectedDrone(drone);
                        setCurrentSection('fileUpload');
                    }}
                />
            ),
            fileUpload: selectedDrone?.make ? fileUploadTab[selectedDrone.make as string]() : <></>,
            flightOverview: (
                <>
                    <FlightOverviewTab flights={flights} />
                </>
            )
        }),
        [fileUploadTab, flights, selectedDrone?.make]
    );

    return (
        <PageWithSidebar
            state={{
                setSideBarItem: (item: string) => {
                    setCurrentSection(item as FlightLogSection);
                },
                sideBarItem: currentSection
            }}
            onExit={back}
            sidebarItems={sidebarItems}
            testId={testId}
            title={UPLOAD_FLIGHT_LOG}
        >
            {contentMap[currentSection]}
        </PageWithSidebar>
    );
};

export default UploadFlightLogPage;

export const FIELD_VIEW_CLIENT_ID = import.meta.env.VITE_FIELD_VIEW_CLIENT_ID;

export const FIELD_VIEW_AUTH_URL = import.meta.env.VITE_FIELD_VIEW_AUTH_URL;

export const FIELD_VIEW_ACCESS_TOKEN_URL = import.meta.env.VITE_FIELD_VIEW_ACCESS_TOKEN_URL;

export const FIELD_VIEW_REDIRECT_URI = import.meta.env.VITE_FIELD_VIEW_REDIRECT_URI;

export const FIELD_VIEW_CONNECTION_API = '/api/fieldview/connection';

export const FIELD_VIEW_SCOPES = [
    'asApplied:read',
    'asApplied:write',
    'asHarvested:read',
    'asPlanted:read',
    'boundaries:read',
    'farmOrganizations:read',
    'fields:read',
    'fields:write',
    'imagery:write',
    'resourceOwners:read',
    'rx:write',
    'rxZones:write',
    'soil:write'
];

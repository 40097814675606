import { useCallback, useRef, useState } from 'react';

import type { FormErrors, HandleChange, HandleError, HandleSubmit, HandleValid } from './types';

const useForm = <T>(formSchema: T) => {
    const [errorMessage, setErrorMessage] = useState('');

    const formErrors = useRef<FormErrors>({});
    const formData = useRef<T>(formSchema);

    const handleError: HandleError<T> = useCallback(
        formField => errorMessage => {
            formErrors.current[formField as string] = errorMessage;
        },
        []
    );

    const hasErrors = useCallback(() => {
        const errors = Object.values(formErrors.current);

        return errors.some(value => typeof value === 'string');
    }, [formErrors]);

    const handleForm = useCallback(() => {
        const errors = Object.values(formErrors.current);
        const hasErrors = errors.filter(value => typeof value === 'string');

        if (hasErrors.length) {
            setErrorMessage(
                hasErrors.length > 1
                    ? 'Please correct the errors on this form.'
                    : String(hasErrors[0])
            );

            return errorMessage;
        }

        return formData.current;
    }, [errorMessage, formData, formErrors]);

    const handleSubmit: HandleSubmit<T> = useCallback(
        formField => (_, error) => {
            if (error) {
                handleError(formField)(error);
            }
        },
        [handleError]
    );

    const handleValid: HandleValid<T> = useCallback(
        formField => fieldValue => {
            formErrors.current[formField as string] = undefined;
            formData.current[formField] = fieldValue;
        },
        [formData, formErrors]
    );

    const handleChange: HandleChange<T> = useCallback(
        formField => newValue => {
            formData.current[formField] = newValue;
        },
        []
    );

    return {
        form: formData,
        formErrors: formErrors,
        handleChange,
        handleError,
        handleForm,
        handleSubmit,
        handleValid,
        hasErrors
    };
};

export default useForm;

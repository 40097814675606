import { Field, Formik } from 'formik';
import type { InferType } from 'yup';

import { PageTitle, VerticalContainer } from '@rantizo-software/rantizo-ui';

import FormContainer from 'components/FormContainer';
import OnboardingButtonContainer from 'components/OnboardingButtonContainer';
import OnboardingLogout from 'components/OnboardingLogout';
import OnboardingPageTitle from 'components/OnboardingPageTitle';
import OneColumnContainer from 'components/OneColumnContainer';
import PageContainer from 'components/PageContainer';
import SubmitButton from 'components/SubmitButton';
import WithBackArrow from 'components/WithBackArrow';
import FormInput from 'components/deprecating/FormInput';
import FormPhoneInput from 'components/deprecating/FormPhoneInput';

import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { FIRST_NAME_TEST_ID, LAST_NAME_TEST_ID, PHONE_TEST_ID, TEST_ID } from './constants';
import type { FormikProps, FunctionComponent, Props } from './types';

const UserDetailsForm: FunctionComponent<Props> = ({
    buttonText,
    initialValues,
    isLoading,
    isOnboarding,
    onBack,
    onSubmit,
    testId = TEST_ID,
    title
}) => {
    const { PLACEHOLDERS } = useTranslation();
    const accountSchema = useSchema();

    type AccountSchemaType = InferType<typeof accountSchema>;

    const titleComponent =
        title && (isOnboarding ? <OnboardingPageTitle text={title} /> : <PageTitle text={title} />);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={accountSchema}
        >
            {({ isSubmitting }: FormikProps<AccountSchemaType>) => (
                <FormContainer>
                    <PageContainer testId={testId}>
                        <VerticalContainer>
                            {onBack && (
                                <WithBackArrow onClick={onBack}>{titleComponent}</WithBackArrow>
                            )}

                            {!onBack && titleComponent}

                            <OneColumnContainer>
                                <Field
                                    component={FormInput}
                                    name="firstName"
                                    placeholder={PLACEHOLDERS.FIRST_NAME}
                                    testId={FIRST_NAME_TEST_ID}
                                    type="text"
                                    withAsterisk
                                />

                                <Field
                                    component={FormInput}
                                    name="lastName"
                                    placeholder={PLACEHOLDERS.LAST_NAME}
                                    testId={LAST_NAME_TEST_ID}
                                    type="text"
                                    withAsterisk
                                />

                                <Field
                                    component={FormPhoneInput}
                                    name="phone"
                                    placeholder={PLACEHOLDERS.PHONE}
                                    testId={PHONE_TEST_ID}
                                    type="text"
                                />
                            </OneColumnContainer>
                        </VerticalContainer>

                        <OnboardingButtonContainer>
                            <SubmitButton
                                isDisabled={isSubmitting || isLoading}
                                isLoading={isSubmitting || isLoading}
                                text={buttonText}
                            />

                            {isOnboarding && <OnboardingLogout />}
                        </OnboardingButtonContainer>
                    </PageContainer>
                </FormContainer>
            )}
        </Formik>
    );
};

export default UserDetailsForm;

import { Loader } from '@mantine/core';

import InfiniteScroll from 'components/InfiniteScroll';
import TeamMemberListItem from 'components/TeamMemberListItem';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const TeamMemberList: FunctionComponent<Props> = ({
    hasMore = false,
    items,
    onNext,
    testId = TEST_ID
}) => (
    <InfiniteScroll
        className={styles.teamMemberList}
        fetchNextPage={onNext}
        hasNextPage={hasMore}
        loader={<Loader className={styles.loader} />}
        testId={testId}
    >
        {items.map((item, index) => (
            <TeamMemberListItem key={index} text={`${item.firstName} ${item.lastName}`} />
        ))}
    </InfiniteScroll>
);

export default TeamMemberList;

import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import WithErrorMessage from 'components/WithErrorMessage';
import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';

import { TEST_ID } from './constants';
import type { NumberWithUnit, Props } from './types';

import styles from './styles.module.scss';

const NumberInputWithUnit = forwardRef<HTMLInputElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isRequired = false,
            items = [],
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            selected,
            testId = TEST_ID,
            value
        },
        ref
    ) => {
        const [errorMessage, setErrorMessage] = useState('');

        const selectRef = useRef<HTMLSelectElement>(null);

        useEffect(() => {
            if (value) {
                setInputValue(value);
            }
        }, [value]);

        const handleError = useCallback(
            (_dropdownValue: unknown, error: string) => {
                onError?.(error);
                setErrorMessage(error);
            },
            [onError]
        );

        const [inputValue, setInputValue] = useState<NumberWithUnit>({
            unit: value?.unit,
            value: value?.value
        });

        const onDropdownChange = useCallback(
            (value: unknown) => {
                setInputValue(prev => ({
                    ...prev,
                    unit: value as string
                }));

                onChange?.(
                    {
                        unit: value as string,
                        value: inputValue.value
                    },
                    onError
                );
            },
            [inputValue, onChange, onError]
        );

        const onInputChange = useCallback(
            (value: string, onError: (message: string) => void) => {
                setInputValue(prev => ({
                    ...prev,
                    value: parseFloat(value)
                }));

                onChange?.(
                    {
                        unit: inputValue.unit,
                        value: value ? parseFloat(value) : undefined
                    },
                    onError
                );
            },
            [inputValue, onChange]
        );

        return (
            <WithErrorMessage
                className={`${className} ${styles.inputContainer}`}
                text={errorMessage}
            >
                <Input
                    className={styles.input}
                    hasError={hasError}
                    isRequired={isRequired}
                    onChange={onInputChange}
                    onSubmit={onSubmit}
                    ref={ref}
                    type="number"
                    value={inputValue.value?.toString()}
                />

                <Dropdown
                    className={styles.dropdown}
                    hasError={hasError}
                    isRequired={isRequired}
                    items={items}
                    label={label}
                    onChange={onDropdownChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    ref={selectRef}
                    selected={selected}
                    testId={testId}
                    value={inputValue.unit}
                />
            </WithErrorMessage>
        );
    }
);

NumberInputWithUnit.displayName = 'NumberInputWithUnit';

export default NumberInputWithUnit;

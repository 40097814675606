import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerticalHighlight: FunctionComponent<Props> = ({ className = '', css, testId = TEST_ID }) => (
    <div className={`${className} ${styles.verticalHighlight}`} data-testid={testId} style={css} />
);

export default VerticalHighlight;

import { useNavigate } from 'react-router-dom';

import useAccount from 'hooks/useAccount';
import usePageRoutes from 'hooks/usePageRoutes';

import type { EditUserData } from './types';

const useVerifyAccount = () => {
    const { resolveUserData, updateUserData } = useAccount();
    const navigate = useNavigate();
    const { verifyOrganizationPage } = usePageRoutes();

    return async (data: EditUserData) => {
        const response = await updateUserData(data);

        if (response?.data) {
            // fetch user data again to make sure user data is updated
            await resolveUserData();
            navigate(verifyOrganizationPage);
        }
    };
};

export default useVerifyAccount;

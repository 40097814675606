import { format } from 'date-fns';
import { useCallback } from 'react';
import { frontendUnitMap } from 'types/units.ts';
import { emptyIfNullOrUndefined } from 'utils/data';
import { v4 as uuid } from 'uuid';

import { round } from '@@utils/math';
import { helpers } from '@turf/turf';

import useCalculations from 'components/work-order/InvoiceAssistant/hooks/useCalculations';

import useChemical from 'hooks/useChemical';
import useConvert from 'hooks/useConvert';
import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';

import { WORK_ORDER_API } from './constants';
import type {
    Chemical,
    ChemicalProduct,
    Feature,
    FetchWorkOrder,
    Polygon,
    RawWorkOrder,
    WorkOrder
} from './types';

const useWorkOrder = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useWorkOrder' });

    const { calculateEstimatedTotal } = useCalculations();

    const {
        convertLitersPerSquareMeterToGallonsPerAcre,
        convertMillisecondsToDaysAndHours,
        convertSquareMetersToAcres
    } = useConvert();

    const { authenticatedDelete, authenticatedGet } = useFetch();

    const { fetchChemicalProduct } = useChemical();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveWorkOrderToStore = useCallback(setValue('workOrder'), []);

    const normalize = useCallback(
        async (json: RawWorkOrder) => {
            const expirationDate = json.expirationDate ? new Date(json.expirationDate) : null;

            const proposedDate = json.proposedDate ? new Date(json.proposedDate) : null;

            const scheduledDate = json.scheduledDate ? new Date(json.scheduledDate) : null;

            const scheduledTime = json.scheduledDate
                ? format(new Date(json.scheduledDate), 'HH:mm:ss')
                : '';
            const status = json.workOrderStatus?.toUpperCase() || '';

            const customerDataFromServer = { ...json.customer };
            const growerDataFromServer = { ...json.growerResponse };

            const proposedAcres = json.proposedArea
                ? round(convertSquareMetersToAcres(json.proposedArea), 2)
                : null;
            const targetSprayRate = json.targetSprayRate
                ? round(convertLitersPerSquareMeterToGallonsPerAcre(json.targetSprayRate), 2)
                : null;

            const reEntryIntervalFormatted = json.reEntryInterval
                ? convertMillisecondsToDaysAndHours(json.reEntryInterval)
                : null;

            const preHarvestInterval = json.preHarvestInterval
                ? convertMillisecondsToDaysAndHours(json.preHarvestInterval)
                : null;

            let estimatedTotal = 0;

            if (json.invoice) {
                const {
                    appliedAreaSquareMeters,
                    chemicalCostDollars,
                    fieldApplicationFlatFeeDollars,
                    fieldApplicationHours,
                    fieldApplicationRate,
                    fieldApplicationRateUnits,
                    invoiceItems,
                    taxAmount,
                    taxAmountUnits
                } = json.invoice;

                estimatedTotal = calculateEstimatedTotal(
                    convertSquareMetersToAcres(appliedAreaSquareMeters),
                    chemicalCostDollars,
                    fieldApplicationFlatFeeDollars,
                    fieldApplicationHours,
                    fieldApplicationRate,
                    fieldApplicationRateUnits,
                    invoiceItems,
                    undefined,
                    undefined,
                    taxAmount,
                    taxAmountUnits
                );
            }

            const normalizedJson = {
                ...json,
                applicationSites: json.applicationSites.map(
                    ({
                        address1,
                        address2,
                        boundary,
                        city,
                        id,
                        imageObjectKey,
                        imageUrl,
                        location,
                        siteName,
                        state,
                        zipCode
                    }) => {
                        const [longitude, latitude] = location?.coordinates || [];

                        const newItem = {
                            boundary: [] as Feature[],
                            coordinates: {
                                latitude,
                                longitude
                            },
                            id,
                            imageObjectKey,
                            imageUrl,
                            location: {
                                address1: emptyIfNullOrUndefined(address1),
                                address2: emptyIfNullOrUndefined(address2),
                                city: emptyIfNullOrUndefined(city),
                                state: emptyIfNullOrUndefined(state),
                                zipCode: emptyIfNullOrUndefined(zipCode)
                            },
                            point: location,
                            siteName
                        };

                        if (boundary !== null) {
                            newItem.boundary = boundary.coordinates.reduce(
                                (aggregate, coordinates) => {
                                    const polygon: Feature<Polygon> = helpers.polygon(coordinates);
                                    const newBoundary = { ...polygon, id: uuid() };

                                    aggregate.push(newBoundary);

                                    return aggregate;
                                },
                                [] as Feature[]
                            );
                        }

                        return newItem;
                    }
                ),
                applicator: json.applicator,
                applicatorInformation: emptyIfNullOrUndefined(json.applicator?.id),
                appliedAcres: round(
                    convertSquareMetersToAcres(json.invoice?.appliedAreaSquareMeters ?? 0),
                    2
                ),
                chemicalCost: json.invoice?.chemicalCostDollars ?? 0,
                chemicals: await Promise.all(
                    json.chemicals.map(async item => {
                        const chemicalRate = item.sprayRate ? item.sprayRate : item.massRate;

                        const newItem = {
                            activeIngredients: item.activeIngredients,
                            chemicalId: item.chemicalId,
                            chemicalProduct: (await fetchChemicalProduct(
                                item.chemicalId
                            )) as ChemicalProduct,
                            epaNumber: item.epaNumber,
                            id: item.id,
                            labelName: item.labelName,
                            pest: item.pest,
                            signalWord: item.signalWord,
                            sprayRate: chemicalRate?.value,
                            sprayRateUnit: chemicalRate?.unit
                                ? frontendUnitMap[chemicalRate?.unit]?.value
                                : null
                        };

                        return newItem as Chemical;
                    })
                ),
                commodity: emptyIfNullOrUndefined(json.siteCommodity),
                estimatedTotal,
                expirationDate,
                fieldApplicationFlatFee: json.invoice?.fieldApplicationFlatFeeDollars,
                fieldApplicationHours: json.invoice?.fieldApplicationHours || 0,
                fieldApplicationRate: json.invoice?.fieldApplicationRate || null,
                fieldApplicationRateUnit: json.invoice?.fieldApplicationRateUnits || 'PER_ACRE',
                fileId: json.file?.id,
                invoiceItems: json.invoice?.invoiceItems ?? [],
                invoicedApplicationSites: json.invoice?.applicationSites.map(({ id }) => id) ?? [],
                notes: emptyIfNullOrUndefined(json.notes),
                preHarvestInterval: preHarvestInterval?.days,
                proposedAcres,
                proposedAcresUnit: 'ac',
                proposedDate,
                reEntryIntervalDays: reEntryIntervalFormatted?.days,
                reEntryIntervalHours: reEntryIntervalFormatted?.hours,
                scheduledDate,
                scheduledTime,
                status,
                targetSprayRate,
                targetSprayRateUnit: 'gaPerAc',
                taxAmount: json.invoice?.taxAmount,
                taxAmountUnit: json.invoice?.taxAmountUnits
            };

            delete normalizedJson.customer;
            delete normalizedJson.growerResponse;
            delete normalizedJson.proposedArea;
            delete normalizedJson.reEntryInterval;
            delete normalizedJson.siteCommodity;
            delete normalizedJson.workOrderStatus;

            normalizedJson.customer = {
                companyName: emptyIfNullOrUndefined(customerDataFromServer?.company),
                contactName: emptyIfNullOrUndefined(customerDataFromServer?.contact),
                id: emptyIfNullOrUndefined(customerDataFromServer?.id),
                location: {
                    address1: emptyIfNullOrUndefined(customerDataFromServer?.address1),
                    address2: emptyIfNullOrUndefined(customerDataFromServer?.address2),
                    city: emptyIfNullOrUndefined(customerDataFromServer?.city),
                    state: emptyIfNullOrUndefined(customerDataFromServer?.state),
                    zipCode: emptyIfNullOrUndefined(customerDataFromServer?.zipCode)
                },
                phone: emptyIfNullOrUndefined(customerDataFromServer?.phoneNumber)
            };

            normalizedJson.grower = {
                companyName: emptyIfNullOrUndefined(growerDataFromServer?.company),
                contactName: emptyIfNullOrUndefined(growerDataFromServer?.contact),
                id: emptyIfNullOrUndefined(growerDataFromServer?.id),
                location: {
                    address1: emptyIfNullOrUndefined(growerDataFromServer?.address1),
                    address2: emptyIfNullOrUndefined(growerDataFromServer?.address2),
                    city: emptyIfNullOrUndefined(growerDataFromServer?.city),
                    state: emptyIfNullOrUndefined(growerDataFromServer?.state),
                    zipCode: emptyIfNullOrUndefined(growerDataFromServer?.zipCode)
                },
                phone: emptyIfNullOrUndefined(growerDataFromServer?.phoneNumber),
                sameAsCustomer: false
            };

            return normalizedJson as WorkOrder;
        },
        [
            calculateEstimatedTotal,
            convertLitersPerSquareMeterToGallonsPerAcre,
            convertMillisecondsToDaysAndHours,
            convertSquareMetersToAcres,
            fetchChemicalProduct
        ]
    );

    const fetchWorkOrder: FetchWorkOrder = useCallback(
        async (id: string) => {
            const { data, error } = await authenticatedGet(`${WORK_ORDER_API}/${id}`);
            const workOrder = await normalize(data);

            saveWorkOrderToStore(workOrder);

            return {
                data: workOrder,
                error
            };
        },
        [authenticatedGet, normalize, saveWorkOrderToStore]
    );

    const deleteWorkOrder = useCallback(
        async (id: string) => {
            const { error } = await authenticatedDelete(`${WORK_ORDER_API}/${id}`);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedDelete]
    );

    return {
        deleteWorkOrder,
        fetchWorkOrder,
        normalize,
        workOrder: getValue('workOrder') as WorkOrder
    };
};

export default useWorkOrder;

import { useCallback } from 'react';

import { nullIfEmpty, trimAndNullIfEmptyOrUndefined } from '@@utils/data';
import { combineFeaturesToMultiPolygon, convertCoordinatesToPoint } from '@@utils/mapUtils';
import { booleanEqual, truncate } from '@turf/turf';

import useFetch from 'hooks/useFetch';

import { APPLICATION_SITE_API } from './constants';
import type { ApplicationSite, MultiPolygon, Point, RawApplicationSite } from './types';

const truncateOptions = { coordinates: 2, precision: 8 };

const useApplicationSite = () => {
    const { authenticatedPost, authenticatedPut } = useFetch();

    const formSiteEqualWithApi = useCallback(
        (formSite: ApplicationSite, apiSite: RawApplicationSite) => {
            const {
                boundary: formBoundary,
                coordinates: formCoordinates,
                id: formId,
                location: formAddress,
                siteName: formSiteName
            } = formSite;

            const {
                address1: apiAddress1 = null,
                address2: apiAddress2 = null,
                boundary: apiBoundary,
                city: apiCity = null,
                id: apiId,
                location: apiCoordinates,
                siteName: apiSiteName,
                state: apiState = null,
                zipCode: apiZipCode = null
            } = apiSite;

            if (formId === apiId) {
                return true;
            }

            const formHasBoundary = Boolean(formBoundary ? formBoundary?.length > 0 : false);
            const apiHasBoundary = Boolean(apiBoundary);

            const formHasPoint = Boolean(
                formBoundary && formBoundary.length > 0 && formBoundary[0].geometry.type === 'Point'
            );

            const formHasCoordinates =
                Boolean(formCoordinates?.latitude) && Boolean(formCoordinates?.longitude);

            const apiHasCoordinates = Boolean(apiCoordinates);

            const formHasAddress = Boolean(
                formAddress?.address1 ||
                    formAddress?.address2 ||
                    formAddress?.city ||
                    formAddress?.state ||
                    formAddress?.zipCode
            );

            const apiHasAddress = Boolean(
                apiAddress1 || apiAddress2 || apiCity || apiState || apiZipCode
            );

            if (
                formSiteName !== apiSiteName ||
                (formHasBoundary && !apiHasBoundary && !formHasPoint) ||
                (formHasAddress && !apiHasAddress) ||
                (formHasCoordinates && !apiHasCoordinates)
            ) {
                return false;
            }

            if (formHasAddress && formAddress && apiHasAddress) {
                const {
                    address1: formAddress1 = null,
                    address2: formAddress2 = null,
                    city: formCity = null,
                    state: formState = null,
                    zipCode: formZipCode = null
                } = formAddress;

                if (
                    nullIfEmpty(formAddress1) !== apiAddress1 ||
                    nullIfEmpty(formAddress2) !== apiAddress2 ||
                    nullIfEmpty(formCity) !== apiCity ||
                    nullIfEmpty(formState) !== apiState ||
                    nullIfEmpty(formZipCode) !== apiZipCode
                ) {
                    return false;
                }
            }

            if (formHasCoordinates && formCoordinates && apiHasCoordinates) {
                const { latitude: formLatitude, longitude: formLongitude } = formCoordinates;

                const formPoint = convertCoordinatesToPoint(formLatitude, formLongitude);

                if (formPoint && apiCoordinates) {
                    const truncatedPoint = truncate(formPoint, truncateOptions);
                    const pointsEqual = booleanEqual(truncatedPoint, apiCoordinates) as boolean;

                    if (!pointsEqual) {
                        return false;
                    }
                }

                if (formHasPoint && formBoundary && formBoundary.length > 0 && apiCoordinates) {
                    const mapPoint = formBoundary[0].geometry;
                    const truncatedPoint = truncate(mapPoint, truncateOptions);

                    const pointsEqual = booleanEqual(truncatedPoint, apiCoordinates);

                    if (!pointsEqual) {
                        return false;
                    }
                }
            }

            if (formHasBoundary && apiHasBoundary && formBoundary && apiBoundary) {
                if (formBoundary.length > 1 && apiBoundary.type !== 'MultiPolygon') {
                    return false;
                }

                const formGeometry = combineFeaturesToMultiPolygon(formBoundary);
                const truncatedGeometry = truncate(formGeometry, truncateOptions);

                if (formGeometry) {
                    const boundariesEqual = booleanEqual(truncatedGeometry, apiBoundary) as boolean;

                    if (!boundariesEqual) {
                        return false;
                    }
                }
            }

            return true;
        },
        []
    );

    const handleApplicationSites = useCallback(
        async (sites: ApplicationSite[], isNew: boolean) => {
            const newSites: RawApplicationSite[] = [];

            for (const { boundary, coordinates, id, imageObjectKey, location, siteName } of sites) {
                if (!siteName) {
                    continue;
                }

                if (typeof coordinates?.latitude === 'string') {
                    coordinates.latitude = parseFloat(coordinates.latitude);
                }

                if (typeof coordinates?.longitude === 'string') {
                    coordinates.longitude = parseFloat(coordinates.longitude);
                }

                const siteLocation: Point | null = convertCoordinatesToPoint(
                    coordinates?.latitude,
                    coordinates?.longitude
                );

                const siteBoundary: MultiPolygon | null = combineFeaturesToMultiPolygon(boundary);

                const payload = {
                    address1: trimAndNullIfEmptyOrUndefined(location?.address1),
                    address2: trimAndNullIfEmptyOrUndefined(location?.address2),
                    boundary: siteBoundary,
                    city: trimAndNullIfEmptyOrUndefined(location?.city),
                    imageObjectKey: imageObjectKey || null,
                    location: siteLocation,
                    siteName: siteName.trim(),
                    state: trimAndNullIfEmptyOrUndefined(location?.state),
                    zipCode: trimAndNullIfEmptyOrUndefined(location?.zipCode)
                };

                const updateOrCreateApplicationSiteUrl = isNew
                    ? APPLICATION_SITE_API
                    : `${APPLICATION_SITE_API}/${id}`;

                const { data, error } = await (isNew ? authenticatedPost : authenticatedPut)(
                    updateOrCreateApplicationSiteUrl,
                    JSON.stringify(payload)
                );

                if (error) {
                    throw new Error(JSON.stringify(error));
                }

                newSites.push(data);
            }

            return newSites;
        },
        [authenticatedPost, authenticatedPut]
    );

    return {
        formSiteEqualWithApi,
        handleApplicationSites
    };
};

export default useApplicationSite;

import {
    addMonths,
    isAfter,
    isBefore,
    isValid,
    isWithinInterval,
    millisecondsToHours,
    millisecondsToMinutes,
    parse,
    subDays
} from 'date-fns';
import { useCallback } from 'react';

import {
    MILLISECONDS_PER_HOUR,
    MILLISECONDS_PER_MINUTE,
    MILLISECONDS_PER_SECOND
} from './constants';
import type {
    AddMonthsArgs,
    ComparisonArgs,
    ComparisonReturn,
    DateRange,
    DateValidationArgs,
    GetDurationArgs,
    IntervalArgs,
    ParseArgs,
    ParseRangeArgs,
    RangeValidationArgs,
    SubDaysArgs
} from './types';

const useDate = () => {
    const addMonthsToDate = useCallback(
        ({ date, months }: AddMonthsArgs): Date => addMonths(date, months),
        []
    );

    const convertMillisecondsToHours = useCallback(
        (milliseconds: number): number => millisecondsToHours(milliseconds),
        []
    );

    const convertMillisecondsToMinutes = useCallback(
        (milliseconds: number): number => millisecondsToMinutes(milliseconds),
        []
    );

    const formatTimeString = useCallback(
        (dateString: string) =>
            new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        []
    );

    const formatDateSeparator = useCallback(
        (dateString: string) =>
            new Date(dateString).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                weekday: 'long',
                year: 'numeric'
            }),
        []
    );

    const formatMonthYearSeparator = useCallback(
        (dateString: string) =>
            new Date(dateString).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric'
            }),
        []
    );

    const getDurationInMinutes = useCallback(
        ({ endDateString, startDateString }: GetDurationArgs): number => {
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);
            const flightDurationInMs = endDate.getTime() - startDate.getTime();

            const durationInMinutes = convertMillisecondsToMinutes(flightDurationInMs);

            return durationInMinutes;
        },
        [convertMillisecondsToMinutes]
    );

    const isDateAfter = useCallback(({ date, dateToCompare }: ComparisonArgs): ComparisonReturn => {
        if (!dateToCompare) {
            return undefined;
        }

        return isAfter(date, dateToCompare);
    }, []);

    const isDateBefore = useCallback(
        ({ date, dateToCompare }: ComparisonArgs): ComparisonReturn => {
            if (!dateToCompare) {
                return undefined;
            }

            return isBefore(date, dateToCompare);
        },
        []
    );

    const isDateValid = useCallback(({ date }: DateValidationArgs): boolean => isValid(date), []);

    const isDateWithinInterval = useCallback(
        ({ date, interval }: IntervalArgs): boolean => isWithinInterval(date, interval),
        []
    );

    const localizeDateString = useCallback(
        (dateString: string) => new Date(dateString).toLocaleDateString('en-US'),
        []
    );

    const parseDate = useCallback(
        ({ dateString, format }: ParseArgs): Date => parse(dateString, format, new Date()),
        []
    );

    const parseDateRange = useCallback(({ format, from, to }: ParseRangeArgs): DateRange => {
        const { from: parsedFrom, to: parsedTo } = {
            from: parse(from, format, new Date()),
            to: parse(to, format, new Date())
        };

        parsedFrom.setHours(0);
        parsedTo.setHours(23);

        return {
            from: parsedFrom,
            to: parsedTo
        };
    }, []);

    const subDaysFromDate = useCallback(
        ({ date, days }: SubDaysArgs): Date => subDays(date, days),
        []
    );

    const validateDateRange = useCallback(({ dates }: RangeValidationArgs): DateRange | false => {
        const { from, to } = dates;

        if (!from || !to) {
            return false;
        }

        if (!isValid(from) || !isValid(to)) {
            return false;
        }

        if (isAfter(from, to) || isBefore(to, from)) {
            const switchedUpdate = { from: to.setHours(0), to: from.setHours(23) };

            return switchedUpdate;
        }

        return dates;
    }, []);

    const convertMillisecondsToTimeString = useCallback((milliseconds: number): string => {
        const hours = Math.floor(milliseconds / MILLISECONDS_PER_HOUR);
        const minutes = Math.floor(
            (milliseconds % MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE
        );
        const seconds = Math.floor(
            (milliseconds % MILLISECONDS_PER_MINUTE) / MILLISECONDS_PER_SECOND
        );

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }, []);

    const convertMillisecondsToDecimalTimeString = useCallback((milliseconds: number): string => {
        const hours = milliseconds / MILLISECONDS_PER_HOUR;

        return `${hours.toFixed(2).padStart(1, '0')}`;
    }, []);

    return {
        addMonthsToDate,
        convertMillisecondsToDecimalTimeString,
        convertMillisecondsToHours,
        convertMillisecondsToMinutes,
        convertMillisecondsToTimeString,
        formatDateSeparator,
        formatMonthYearSeparator,
        formatTimeString,
        getDurationInMinutes,
        isDateAfter,
        isDateBefore,
        isDateValid,
        isDateWithinInterval,
        localizeDateString,
        parseDate,
        parseDateRange,
        subDaysFromDate,
        validateDateRange
    };
};

export default useDate;

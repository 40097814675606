import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, CheckIcon, CopyIcon } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const CopyButton = ({ className, testId = TEST_ID, text }: Props) => {
    const { COPY_ERROR } = useTranslation();
    const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const writeToClipboard = useCallback(() => {
        if (!navigator.clipboard) {
            alert(COPY_ERROR);

            return;
        } else {
            setShowCopyConfirmation(true);
            navigator.clipboard.writeText(text);
        }
    }, [text, COPY_ERROR]);

    useEffect(() => {
        if (showCopyConfirmation) {
            timeoutRef.current = setTimeout(() => {
                setShowCopyConfirmation(false);
            }, 3000);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [text, showCopyConfirmation]);

    return (
        <Button
            className={`${className} ${styles.copyButton}`}
            onClick={writeToClipboard}
            testId={testId}
        >
            {showCopyConfirmation ? <CheckIcon /> : <CopyIcon />}
        </Button>
    );
};

export default CopyButton;

import { ContentText, PrimaryButton } from '@rantizo-software/rantizo-ui';

import ModalOverlay from 'components/ModalOverlay';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ExitWorkOrderModal: FunctionComponent<Props> = ({ onClose, onConfirm, testId = TEST_ID }) => {
    const { CONFIRM, WARNING } = useTranslation();

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <ContentText className={styles.contentText} text={WARNING} />

            <PrimaryButton onClick={onConfirm} text={CONFIRM} />
        </ModalOverlay>
    );
};

export default ExitWorkOrderModal;

import { emptyIfNullOrUndefined } from 'utils/data.ts';

import { helpers } from '@turf/turf';

import Document from 'components/pdf/Document';
import HorizontalFlexDivider from 'components/pdf/HorizontalFlexDivider';
import PageWithHeaderOnly from 'components/pdf/PageWithHeaderOnly';
import ProductUsageReportApplicationDetails from 'components/pdf/ProductUsageReportApplicationDetails';
import ProductUsageReportProductDetails from 'components/pdf/ProductUsageReportProductDetails';
import WorkOrderApplicationSiteBoundary from 'components/pdf/WorkOrderApplicationSiteBoundary';
import WorkOrderNotes from 'components/pdf/WorkOrderNotes';
import WorkOrderSiteCommodity from 'components/pdf/WorkOrderSiteCommodity';

import useTranslation from './hooks/useTranslation';

import type { ApplicationSite, Feature, FunctionComponent, Polygon, Props } from './types';

const ProductUsageReportPDF: FunctionComponent<Props> = ({
    applicatorName,
    productUsageReport,
    workOrder
}) => {
    const { APPLICATION_DETAILS, PRODUCT_DETAILS, PRODUCT_USAGE_REPORT_HEADER } = useTranslation();

    const { applicationSites, notes } = productUsageReport;

    return (
        <Document>
            <PageWithHeaderOnly headerText={PRODUCT_USAGE_REPORT_HEADER(`${workOrder.key}`)}>
                <ProductUsageReportApplicationDetails
                    applicationDetails={productUsageReport}
                    applicatorName={applicatorName}
                    title={APPLICATION_DETAILS}
                    workOrderKey={workOrder.key}
                />

                <HorizontalFlexDivider />

                <WorkOrderSiteCommodity workOrder={workOrder} />

                <HorizontalFlexDivider />

                <ProductUsageReportProductDetails
                    productDetails={productUsageReport}
                    title={PRODUCT_DETAILS}
                />

                <HorizontalFlexDivider />

                <WorkOrderNotes notes={notes} />
            </PageWithHeaderOnly>

            {applicationSites
                .filter(site => site.boundary !== null)
                .map(site => {
                    const [longitude, latitude] = site.location?.coordinates || [];

                    const newItem: ApplicationSite = {
                        boundary: [] as Feature[],
                        coordinates: {
                            latitude,
                            longitude
                        },
                        id: site.id,
                        location: {
                            address1: emptyIfNullOrUndefined(site?.address1),
                            address2: emptyIfNullOrUndefined(site?.address2),
                            city: emptyIfNullOrUndefined(site?.city),
                            state: emptyIfNullOrUndefined(site?.state),
                            zipCode: emptyIfNullOrUndefined(site?.zipCode)
                        },
                        siteName: site.siteName
                    };

                    newItem.boundary = site.boundary!.coordinates.reduce(
                        (aggregate, coordinates) => {
                            const polygon: Feature<Polygon> = helpers.polygon(coordinates);

                            aggregate.push(polygon);

                            return aggregate;
                        },
                        [] as Feature[]
                    );

                    return newItem;
                })
                .map((site, index) => (
                    <PageWithHeaderOnly key={(workOrder.key ?? 0) + index}>
                        <WorkOrderApplicationSiteBoundary index={index} site={site} />
                    </PageWithHeaderOnly>
                ))}
        </Document>
    );
};

export default ProductUsageReportPDF;

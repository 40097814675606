import type { MonthOption } from './types';

export const DATE_FORMAT = 'MM/dd/yyyy';

export const PLACEHOLDER = 'MM/DD/YYYY';

export const TEST_ID = 'monthPicker';

export const MAX_YEAR = new Date().getFullYear() + 5;

export const MIN_YEAR = new Date().getFullYear() - 5;

export const MONTH_LABELS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export const MONTH_OPTIONS: MonthOption[] = Array.from({ length: 12 }, (_, i) => ({
    label: MONTH_LABELS[i],
    value: i
}));

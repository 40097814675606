import { CrownIcon } from '@rantizo-software/rantizo-ui';

import GridContainer from 'components/GridContainer';

import useFeatureAccess from 'hooks/useFeatureAccess';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WithFeatureAccess: FunctionComponent<Props> = ({
    children,
    className = '',
    feature,
    testId = TEST_ID
}) => {
    const features = useFeatureAccess();

    const isFeatureAccessible = features[feature] || false;

    const crownClass = !isFeatureAccessible ? styles.showCrown : '';

    return (
        <GridContainer
            className={`${className} ${styles.withFeatureAccess} ${crownClass}`}
            testId={testId}
        >
            {!isFeatureAccessible && <CrownIcon />}

            {children}
        </GridContainer>
    );
};

export default WithFeatureAccess;

import { useCallback, useState } from 'react';

import PageContainer from 'components/PageContainer';
import AircraftForm from 'components/deprecating/AircraftForm';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { AircraftFormType, FunctionComponent, Props } from './types';

const NewAircraftPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { organizationEquipmentAircraft } = usePageRoutes();
    const back = useBack(organizationEquipmentAircraft);
    const { createAircraft } = useData();
    const [submitting, setSubmitting] = useState(false);
    const { API_ERROR, FLIGHT_CONTROLLER_SERIAL_CONFLICT, TITLE } = useTranslation();

    const initialValues: AircraftFormType = {
        flightControllerSerial: null,
        make: '',
        model: '',
        nickname: '',
        registrationNumber: '',
        serialNumber: ''
    };

    const handleSubmit = useCallback(
        async (values: AircraftFormType) => {
            setSubmitting(true);

            try {
                const response = await createAircraft(values);

                if (response) {
                    back();
                }
            } catch (error: unknown) {
                if (error instanceof Error && error.message?.includes('409')) {
                    alert(FLIGHT_CONTROLLER_SERIAL_CONFLICT);
                } else {
                    alert(API_ERROR);
                }
            }

            setSubmitting(false);
        },
        [createAircraft, back, API_ERROR, FLIGHT_CONTROLLER_SERIAL_CONFLICT]
    );

    return (
        <PageContainer testId={testId}>
            <AircraftForm
                initialValues={initialValues}
                isLoading={submitting}
                onBack={back}
                onSubmit={handleSubmit}
                title={TITLE}
            />
        </PageContainer>
    );
};

export default NewAircraftPage;

import BackButton from 'components/BackButton';

import ErrorPage from 'pages/errors/ErrorPage';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const NotFoundErrorPage: FunctionComponent<Props> = props => {
    const { onBack, resourceName, testId = TEST_ID } = props;

    const { NOT_FOUND } = useTranslation();

    return (
        <ErrorPage testId={testId} text={NOT_FOUND({ resourceName })}>
            {onBack && <BackButton onClick={onBack} />}
        </ErrorPage>
    );
};

NotFoundErrorPage.displayName = 'NotFoundErrorPage';

export default NotFoundErrorPage;

export const DATE_FORMAT = 'MM/dd/yyyy';

export const PLACEHOLDER = 'MM/DD/YYYY';

export const TEST_ID = 'datePicker';

export const MAX_YEAR = new Date().getFullYear() + 5;

export const MIN_YEAR = new Date().getFullYear() - 5;

export const ALLOWED_DATE_RANGE = {
    end: new Date(MAX_YEAR, 0, 1),
    start: new Date(MIN_YEAR, 0, 1)
};

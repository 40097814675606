import WithLabel from 'components/WithLabel';
import FormText from 'components/form/FormText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const TextWithLabel: FunctionComponent<Props> = ({
    className = '',
    isRequired = false,
    label = '',
    testId = TEST_ID,
    text
}) => (
    <WithLabel className={className} isRequired={isRequired} testId={testId} text={label}>
        <FormText text={text} />
    </WithLabel>
);

export default TextWithLabel;

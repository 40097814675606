import replaceTextToComponents from 'utils/replaceTextToComponents';

import { ContentText, ImportantText } from '@rantizo-software/rantizo-ui';

import HeadingText from 'components/HeadingText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const TermsAndConditionsPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => (
    <div className={styles.termsAndConditionsPage} data-testid={testId}>
        <HeadingText text="RANTIZO TERMS OF SERVICE" />

        <ContentText text="Last Updated: November 8, 2023" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Professional Services"': (text?: string) => <ImportantText text={text} />,
                    '"Rantizo"': (text?: string) => <ImportantText text={text} />,
                    '"Service(s)"': (text?: string) => <ImportantText text={text} />,
                    '"Software"': (text?: string) => <ImportantText text={text} />,
                    '"Terms"': (text?: string) => <ImportantText text={text} />,
                    '"User Content"': (text?: string) => <ImportantText text={text} />,
                    '"User Guides"': (text?: string) => <ImportantText text={text} />,
                    '"our"': (text?: string) => <ImportantText text={text} />,
                    '"us"': (text?: string) => <ImportantText text={text} />,
                    '"we"': (text?: string) => <ImportantText text={text} />
                },
                `
      Please read these terms of service (these "Terms") carefully as they form a contract between you and Rantizo, Inc.
      ("Rantizo", "we", "us", or "our") that governs your access and use of: (a) the Rantizo platform for online storage,
      sharing and processing of: files, materials, information, data, text, or other user-uploaded content (the 
      "User Content"); (b) any software provided or made available by Rantizo (the "Software"); (c) the Rantizo websites 
      or applications; (d) any written or electronic use or features guides or other documentation provided or made 
      available by Rantizo (the "User Guides"); (e) any Third Party Content; (f) any hardware sold by Rantizo, and (g) or other
      professional services content created by Rantizo that isn't a part of an existing
      agreement between you and Rantizo (the "Professional Services") (collectively the "Service(s)").
      `
            )}
        </ContentText>

        <ContentText>
            {replaceTextToComponents(
                {
                    'By registering or using any of the Services you agree to be bound by these Terms.':
                        (text?: string) => <ImportantText text={text} />,
                    'IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU MUST NOT USE THE SERVICES.':
                        (text?: string) => <ImportantText text={text} />
                },
                `
        By registering or using any of the Services you agree to be bound by these Terms. 
        If you are using the Services on behalf of an organization, you hereby agree to these Terms
        for that organization and promise to Rantizo that you have the authority to bind that
        organization to these Terms (in which event, "you" and "your" will refer
        to that organization) unless that organization has a separate written contract in effect
        with us, in which event the terms of that contract will govern your use of the Service. You
        may use the Services only in compliance with these Terms and only if you have the power to
        form a contract with Rantizo and are not barred under any applicable laws from doing so.
        IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU MUST NOT USE THE SERVICES. Should you have 
        any questions concerning these Terms, please contact us at Support@Rantizo.com.
      `
            )}
        </ContentText>

        <ImportantText
            text={`
      Please note that Rantizo doesn't provide warranties for the Services. This contract
      also limits our liability to you and provides for arbitration of any disputes arising
      under these Terms. See Sections 14, 16, 18 and 20 for details.
    `}
        />

        <HeadingText text="1. CHANGES TO THESE TERMS" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Site"': (text?: string) => <ImportantText text={text} />
                },
                `
        We reserve the right to revise these Terms from time to time. We will date and post the most
        current version of these Terms on the Rantizo web platform. Any changes will be effective
        upon posting the revised version of these Terms on the Service (or such later effective date
        as may be indicated at the top of the revised Terms). If in our sole discretion we deem a
        revision to these Terms to be material, we will notify you via the Service and/or by email
        to the email address associated with your account. Notice of other changes may be provided
        via https://rantizo.com/ or any related Rantizo blog (collectively, the "Site"). Therefore, we 
        encourage you to check the date of these Terms whenever you visit the
        Site to see if these Terms have been updated. Your continued access or use of any portion of
        the Service constitutes your acceptance of such changes. If you don't agree to any of
        the changes, we're not obligated to continue providing the Service, and you must cancel
        and stop using the Service.
     `
            )}
        </ContentText>

        <HeadingText text="2. ACCESS TO THE SERVICE" />

        <ContentText
            text={`
        Subject to your continued compliance with these Terms and timely payment of any applicable
        Fees, we will use commercially reasonable efforts to provide the Services to you on a
        non-exclusive, non-transferable, and revocable basis. We reserve the right, in our sole
        discretion, to make necessary unscheduled deployments of changes, updates or enhancements to
        the Service at any time. We may add or remove functionalities or features, and or suspend or
        stop a Service altogether, at any time and without prior notice to you.
    `}
        />

        <HeadingText text="3. YOUR ACCOUNT" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Registered User"': (text?: string) => <ImportantText text={text} />,
                    '"Registration Data"': (text?: string) => <ImportantText text={text} />
                },
                `
      (a)
      To obtain access to certain Services, you may be required to obtain an account with Rantizo
      (become a "Registered User"), by completing a registration form and designating a user ID and password. Until you
        apply for and become a Registered User, your access to the Service will be limited to the
        areas of the Service, if any, that Rantizo makes available to the general public. When
        registering with Rantizo you must: (i) provide true, accurate, current, and complete
        information about yourself as requested by the Service's registration form (such
        information being the "Registration Data") and (ii) maintain and promptly update the Registration
        Data to keep it true, accurate, current and complete.
    `
            )}
        </ContentText>

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Rantizo will not be liable for any loss or damage arising from any unauthorized use of your accounts."':
                        (text?: string) => <ImportantText text={text} />
                },
                `
        (b)
        Only you may use your Rantizo account, except that if you are an entity, you may permit your
        employees or contractors to use your Rantizo account solely for your benefit. You must keep
        your account and passwords confidential and not authorize any third party to access or use
        the Service on your behalf, unless we provide an approved mechanism for such use. You must
        contact us right away if you suspect any misuse of your account or any security breach in
        the Service has occurred. You are responsible for all activities that take place with your account. 
        Rantizo will not be liable for any loss or damage arising from any unauthorized use of your accounts.
    `
            )}
        </ContentText>

        <ContentText
            text={`
        (c)
        If a third party such as an employer gave you your account, that party has rights to your
        account and may: manage your account, reset your password, or suspend or cancel your
        account; view your account's usage and profile data, including how and when your
        account is used; and read or store Content in your account. If you are an individual
        Registered User of the Service, and the domain of the primary email address associated with
        your account is owned by an organization and was assigned to you as an employee, contractor
        or member of such organization, and that organization wishes to establishes a commercial
        relationship with us and add your account to such relationship, then, if you do not change
        the email address associated with your account, your account may become subject to the
        commercial relationship between Rantizo and such organization and controlled by such
        organization.
      `}
        />

        <HeadingText text="4. CONSENT TO ELECTRONIC COMMUNICATIONS AND SOLICITATION." />

        <ContentText
            text={`
      By registering with Rantizo, you understand that we may send you communications or data
      regarding the Services, including but not limited to (a) notices about your use of the
      Services, including any notices concerning violations of use, (b) updates, and (c)
      promotional information and materials regarding Rantizo's products and services, via
      electronic mail. We give you the opportunity to opt-out of receiving electronic mail from us
      by following the opt-out instructions provided in the message.
    `}
        />

        <HeadingText text="5. REGULATED DATA AND OTHER USER CONTENT" />

        <ol className="awlist1" type="a">
            <li>
                <ContentText
                    text={`
          Rantizo does not claim any ownership of the User Content that is uploaded to the Rantizo
          platform. However, Rantizo reserves the right, but not the obligation to verify, audit,
          backup, edit, copy, or transmit the User Content as required or permitted by this
          Agreement, applicable law or regulations, or as necessary to provide the Services.
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Rantizo provides functions that allow Customer to control who may access and modify
          Customer's User Content. If Customer enables or allows the features that allows the
          sharing of User Content with others, anyone Customer has shared content with may have
          access to and the right to modify its User Content. Even if Customer disables the sharing
          features, Rantizo will still have the right to access, store, and use Customer's
          User Content as provided below.
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Customer hereby grants Rantizo and its contractors the perpetual, irrevocable, worldwide,
          royalty-free right, to use, modify, adapt, reproduce, distribute, display and disclose
          User Content posted on the Service: (i) to the extent necessary to provide the Service to
          Customer; (ii) to share Customer's User Content as directed via the sharing features
          of the Service; (iii) to directly or indirectly share, aggregate, analyze, or keep
          Customer's anonymized data (as determined by Rantizo in our sole discretion for any
          purpose, including for commercial purposes); (iv) as required by law; or, (v) as otherwise
          permitted by this Agreement.
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Customer represents and warrants that: (i) Customer has all the rights in the User Content
          necessary for Customer to use the Services and to grant the rights set forth in this
          Agreement; and, (ii) the storage, use or transmission of the User Content doesn't
          violate any law, regulation or the terms of this Agreement.
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Customer will: (i) be solely responsible for the nature, accuracy and content of the User
          Content; (ii) ensure that the User Content (including the storage, use or transmission
          thereof) complies with the terms of this Agreement and any and all applicable laws, and
          regulations; and, (iii) maintain appropriate security to protect the User Content from
          unauthorized access; (iv) promptly handle and resolve any notices and claims relating to
          the User Content.
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Customer will immediately notify Rantizo in writing or by email (at Support@Rantizo.com)
          of any unauthorized use of (i) User Content (ii) Customer's account(s) or (iii)
          Services that comes to Customer's attention. In the event of any such unauthorized
          use by any third party that obtained access through Customer, Customer will take all steps
          reasonably necessary to terminate such unauthorized use. Customer will provide Rantizo
          with such cooperation and assistance related to any such unauthorized use as Rantizo may
          reasonably request.
        `}
                />
            </li>
        </ol>

        <HeadingText text="6. TERM; TERMINATION" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Initial Term"': (text?: string) => <ImportantText text={text} />,
                    '"Renewal Term"': (text?: string) => <ImportantText text={text} />,
                    '"Term."': (text?: string) => <ImportantText text={text} />,
                    '6.1 Term.': (text?: string) => <ImportantText text={text} />
                },
                `
      6.1 Term. This Agreement will remain in force and the Services under it will continue to be provided
        unless and until terminated by either Party in accordance with the terms and conditions of
        this Agreement. The initial term of the Agreement will commence on the Effective Date and
        will expire on the expiration date of the term for Services referenced in the applicable
        Service Order (the "Initial Term"). At the end of the Initial Term, this Agreement will automatically renew for
        successive 12-month periods (each a "Renewal Term") for all active subscription units in existence at 
        the time of the renewal, unless notice is given in writing at least thirty (30) days prior to the 
        end of the Initial Term or the then-current Renewal Term by either Party of its intent to cancel 
        or modify the terms of the Agreement. The Initial Term and the Renewal Terms are referred to herein 
        collectively as the "Term." To the extent Customer orders additional subscription units pursuant to one or more
        subsequent Service Order(s) during the Initial Term or any Renewal Term, the Term with
        respect to such additional subscription units shall be as set forth in the relevant Service
        Order, subject to auto-renewal for successive Renewal Terms as set forth in this Section.
      `
            )}
        </ContentText>

        <HeadingText text="6.2 Termination" />

        <ol className="awlist2" type="a">
            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Termination for Convenience.': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `
        Termination for Convenience. Unless otherwise specified in this Agreement and subject to Section 6.2(b) below, either
          Party may terminate this Agreement or specific Services at any time for any reason upon
          providing to the other Party no less than thirty (30) days' prior written notice.
          Termination pursuant to this Section 6.2(a) takes effect on: (i) the last day of the month
          following the thirty (30) day notice period, or (ii) if the cancelling Party specifies a
          later date, the day that Rantizo terminates, disconnects or otherwise deactivates the
          Service (or in the case of termination of this Agreement, the day Rantizo terminates,
          disconnects or otherwise deactivates the last Service).
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Termination for Cause or Insolvency.': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `
        Termination for Cause or Insolvency.
          Subject to any applicable cure periods provided hereunder, either Party may give notice
          and immediately terminate this Agreement or any Service for Cause: (i) with respect to
          this Agreement, to the extent the Cause impacts all Services provided under this
          Agreement, and (ii) with respect to any specific Service, to the extent the Cause impacts
          such Service. Either Party may also give notice and immediately terminate this Agreement
          or any Services (to the extent permitted by applicable law and regulation) if the other
          Party experiences an Insolvency Event.
          `
                    )}
                </ContentText>
            </li>
        </ol>

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Cause"': (text?: string) => <ImportantText text={text} />,
                    '"Insolvency Event"': (text?: string) => <ImportantText text={text} />
                },
                `
      As used herein, the term "Cause" means (a) a breach by the other Party of any material provision of the Agreement
        (including in relation to a particular Service Order or SOW) which: (1) is incapable of
        remedy; (2) if capable of remedy, remains uncured for thirty (30) days from written notice
        of such breach; or (3) in the case of Customer's failure to pay any past due amount,
        such amount remains unpaid five (5) days from notice of such failure, or (b) if Rantizo
        reasonably suspects that Customer's use of Services or any portion thereof violates
        applicable law, poses a risk to Rantizo or its suppliers and affiliates, or is otherwise
        compelled to suspend or terminate Customer's use of Services or any portion thereof.
        An "Insolvency Event" occurs when a Party: (s) files for bankruptcy; (t) becomes or is declared insolvent,
        or is the subject of any bona fide proceedings related to its liquidation, administration,
        provisional liquidation, insolvency or the appointment of a receiver or similar officer for
        it; (u) passes a resolution for its voluntary dissolution or liquidation; (v) has a receiver
        or manager appointed over all, or substantially all, of its assets; (w) makes an assignment
        for the benefit of all, or substantially all, of its creditors; (x) enters into an agreement
        or arrangement for the composition, extension, or readjustment of all, or substantially all,
        of its obligations or any class of such obligations; (y) becomes incapable of paying its
        undisputed debts when due; or (z) experiences an event analogous to any of the foregoing in
        any jurisdiction in which any of its assets are situated.
        `
            )}
        </ContentText>

        <ContentText
            text={`
        For the avoidance of doubt and notwithstanding anything contained herein to the contrary,
        Rantizo may suspend or terminate one or more Services (or any part thereof), in its sole
        discretion and without penalty, if: (a) Customer fails to pay any past due amounts for
        Equipment or Services within five (5) days after Customer receives notice of such
        non-payment; or (b) Rantizo determines that suspension is necessary to: (i) prevent or
        mitigate fraud, (ii) protect persons, property or the integrity or normal operation of
        Rantizo assets, (iii) comply with law or regulation, or (iv) undertake any work, the
        execution of which, at the time it is proposed to be executed, is required to put an end to,
        or prevent, the arising of circumstances then existing or imminent that are likely to cause:
        (1) danger to persons or property; (2) the interruption of any Services provided by the
        Rantizo; (3) substantial loss to Rantizo or any third party; and/or (4) such other works as
        in all the circumstances it is reasonable to execute with those works. Rantizo will give
        Customer reasonable notice of any such suspension or termination where practicable, save in
        relation to suspension pursuant to sub-clause (a) above, where no notice is required beyond
        the five (5) days stated therein. If Rantizo exercises its right to suspend a Service, it
        will resume the Service as soon as practicable after the reason for suspension no longer
        exists (subject to the exercise of any termination right on the part of Rantizo). If
        Services are suspended as a consequence of the breach, fault, act or omission of Customer or
        any Customer affiliate, Customer shall pay to Rantizo all reasonable costs and expenses
        incurred by the implementation of such suspension and/or reconnection of the Service.
        Rantizo may enforce the foregoing rights without waiving any and all other rights or
        remedies it may have for any breach of this Agreement.
      `}
        />

        <HeadingText text="7. CHARGES, PAYMENT, AND TAXES" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '7.1 Charges.': (text?: string) => <ImportantText text={text} />
                },
                `

      7.1 Charges. 
        Customer agrees to timely pay all Charges in accordance with the terms and conditions of
        this Agreement. Unless otherwise expressly set out in the Agreement, Rantizo may change its
        fees for Services prior to the start of any Renewal Term with thirty (30) days' prior written notice to
        Customer. Any such price change, and any other price change mutually agreed to by the
        Parties in writing, shall take effect on the first day of the month following the start of
        such Renewal Term, or upon execution of a written agreement by the Parties. Customer
        acknowledges and agrees that the number of Service subscriptions ordered cannot be reduced
        during the Term. Customer may add Service subscriptions, however, via a Service Order at any
        time during the Term.
      `
            )}
        </ContentText>

        <ol className="awlist3" type="a">
            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Support Service Charges.': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `Support Service Charges. Billing for optional Support Services will commence on the first day of the month
          following the Order Effective Date of such Support Services.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Professional Service Charges.': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `
          Professional Service Charges. Rantizo will invoice Customer for Professional Services in accordance 
          with the terms and conditions of the applicable SOW.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Paper Invoice Charges.': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `
          Paper Invoice Charges. Rantizo provides invoices online as its standard method of invoicing. If Customer wishes
          to receive paper invoices in addition to online invoices, Rantizo reserves the right,
          where permitted by law and regulation, to apply a Charge (which may be modified from time
          to time at Rantizo's sole election) to every such paper invoice provided to a
          Customer. This Charge does not apply where Customer has established to Rantizo's
          satisfaction, or Rantizo determines on its own, that online invoicing is not a reasonable
          substitute for paper invoicing.
          `
                    )}
                </ContentText>
            </li>
        </ol>

        <HeadingText text="7.2 Payment." />

        <ol className="awlist4" type="a">
            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Invoices.': (text?: string) => <ImportantText text={text} />
                        },
                        `
          Invoices. Customer will pay Rantizo all invoiced amounts within thirty (30) days of the
          relevant invoice date in accordance with the remittance instructions on the invoice.
          Except as may be agreed to in writing by the Parties, all Services shall be prepaid for
          the period selected (e.g., monthly, yearly or otherwise) and are non-refundable. This
          includes any Services that are renewed pursuant to Section.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Disputed Amounts.': (text?: string) => <ImportantText text={text} />
                        },
                        `
          Disputed Amounts. If Customer notifies Rantizo in writing of any invoiced Charged which Customer
          disputes in good faith (a "Disputed Amount") by the date on which payment for
          such Charge by Customer is to be received by Rantizo (the "Due Date") pursuant
          to the terms of this Agreement, then the Disputed Amount may be withheld. The writing must
          set forth the basis for the dispute. If Rantizo determines that the Disputed Amount is
          owed by Customer, then Customer must pay the withheld amount within five (5) days after
          Rantizo notifies Customer of the determination and such amount shall no longer be
          considered a Disputed Amount. If Customer does not give Rantizo notice of a Disputed
          Amount with respect to Charges, including the application of Taxes, by the Due Date, the
          invoice will be deemed to be correct and binding on Customer.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            'Past Due Amounts.': (text?: string) => <ImportantText text={text} />
                        },
                        `
          Past Due Amounts. Amounts not paid on or before the Due Date are past due and will accrue interest
          from the Due Date until payment at the rate of: (a) 1.5% per month (compounded monthly);
          or, where that rate is not permitted by applicable law or regulation (b) the maximum
          amount allowed. Without prejudice to any other rights or remedies under applicable law or
          regulation, Rantizo may exercise its rights of termination or suspension of Services in
          accordance with the Agreement in respect of any past due amount other than Disputed
          Amounts. Customer agrees to pay Rantizo all its reasonable costs and expenses, including
          court costs, and legal and collection agency fees, incurred in enforcing its rights under
          this Agreement.
          `
                    )}
                </ContentText>
            </li>
        </ol>

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Taxes"': (text?: string) => <ImportantText text={text} />,
                    '7.3 Taxes': (text?: string) => <ImportantText text={text} />
                },
                `
        7.3 Taxes. All prices for Services provided on Rantizo or any Service Order or SOW are exclusive of
        applicable federal, state, local, foreign, sales, use, excise, utility, gross receipts,
        value-added and other taxes, tax-like charges, and tax-related and other surcharges,
        including any related penalties and interests however designated (collectively, "Taxes"). Customer 
        shall pay any Taxes that apply. Rantizo will exempt Customer from Taxes
        in accordance with law and regulation, after receiving a valid tax exemption certificate. If
        Customer is required by law or regulation to make any deduction or withholding from any
        payment, then the gross amount payable by Customer to Rantizo will be increased so that,
        after any such deduction or withholding, the net amount received by Rantizo will not be less
        than Rantizo would have received had no such deduction or withholding been required.
        Customer will reimburse, indemnify, and hold harmless Rantizo for all Customer liabilities
        for Taxes.
        `
            )}
        </ContentText>

        <HeadingText text="8. CONTENT STORED IN THE UNITED STATES" />

        <ContentText
            text={`
        The Service is provided from the United States. By using and accessing the Service, you
        understand and consent to the storage and processing of the User Content, including Flight
        Safety Compliance Data and any other personal information in the United States.
        Notwithstanding the foregoing, Rantizo reserves the right to store and process User Content
        and other personal information outside of the United States.
        `}
        />

        <HeadingText text="9. SUSPENSION AND TERMINATION OF CUSTOMER'S USE OF THE SERVICE" />

        <ContentText
            text={`
        (a)
        Rantizo may, in its sole discretion, for any or no reason, and without penalty, suspend or
        terminate any account (or any part thereof) you may have with Rantizo, or your use of or
        access to the Service, or remove or discard all or any part of your account, user profile,
        and any User Content. If Rantizo plans to terminate or suspend your account or deny you
        access to your account, we will provide you five (5) business days' notice, unless
        your use of the account violates applicable law, poses a risk to Rantizo, or Rantizo is
        otherwise legally compelled to suspend or terminate your account, in which case we may do so
        immediately and without notice. Rantizo will not be liable to you or to any third party for
        any such termination or suspension regardless of the reason for such termination or
        suspension. Rantizo may refer any suspected fraudulent, abusive, or illegal activity may to
        appropriate law enforcement authorities. These remedies are in addition to any other
        remedies Rantizo may have at law or in equity. Rantizo does not permit copyright infringing
        activities on the Service, and may terminate access to the Service, and remove any User
        Content or other content submitted by any users who are found to be repeat infringers.
      `}
        />

        <ContentText
            text={`
        (b)
        Your only remedy with respect to any dissatisfaction with (i) the Service; (ii) any term of
        these Terms; or, (iii) any policy or practice of Rantizo in operating the Service is to
        terminate these Terms and your account. You may terminate these Terms at any time by
        discontinuing use of any and all parts of the Service and providing Rantizo written notice
        of such termination. For clarity, no fees payable by you hereunder are refundable upon your
        termination of your account with Rantizo.
        `}
        />

        <ContentText
            text={`
        (c)
        Upon termination by Rantizo, whether with or without cause, or at your direction, you may
        request access to your User Content, which we will make reasonable efforts to provide. You
        must make such request within thirty (30) days following termination. Otherwise,
        any User Content you have stored with the Service may not be retrievable, and we will have
        no obligation to maintain any data stored in your account.
        `}
        />

        <ContentText
            text={`
        (d)
        The following provisions survive the termination of these Terms: 4, 5, 7 (with respect to
        any amounts owed prior to termination), 11, 13, 14, 15, 16, 17, 18, 20, 21, and 22.
    `}
        />

        <HeadingText text="10. ACCEPTABLE USE" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"AUP"': (text?: string) => <ImportantText text={text} />
                },
                `
      Use of Services must comply with Rantizo's acceptable use policy ("AUP"), as described here:
      `
            )}
        </ContentText>

        <ContentText
            text={`
      (a) Users of a Service must not use such Service to harm others, 
      Rantizo or the Service. For example, Customer must not use the Service to harm, threaten, or
      harass another person, organization, or Rantizo and/or to build a similar service or
      website. Customer must not: damage, disable, overburden, or impair the Service (or any
      network connected to the Service); resell or redistribute the Service or any part of it; use
      any unauthorized means to modify, reroute, or gain access to the Service or attempt to carry
      out these activities; or use any automated process or Service (such as a bot, a spider, or
      periodic caching of information stored by Rantizo) to access or use the Service. In
      addition, Customer will not and will not encourage or assist any third party to:
    `}
        />

        <ContentText
            text={`
      (i) modify, alter, tamper with, repair or otherwise create derivative works of Services or
      any Software; (ii) reverse engineer, disassemble or decompile any software used to provide
      or access the Service, including the Software, or attempt to discover or recreate the source
      code used to provide or access the Service; (iii) use the Service in any manner or for any
      purpose other than as expressly permitted by this Agreement, the Privacy Policy, any User
      Guides or any other policy, instruction or terms applicable to the Service that are
      available on the Service ("Policies"); (iv) sell, lend, rent, resell, lease,
      sublicense or otherwise transfer any of the rights granted to Customer with respect to the
      Services to any third party; (v) remove, obscure or alter any proprietary rights notice
      pertaining to the Service; (vi) access or use the Service in a way intended to improperly
      avoid incurring fees or exceeding usage limits or quotas; (vii) use the Service in any
      situation in which the failure of the Service could lead to death, personal injury, or
      physical property or environmental damage; (viii) use the Service to: (1) engage in any
      unlawful or fraudulent activity or perpetrate a hoax or engage in phishing schemes or
      forgery or other similar falsification or manipulation of data; (2) send unsolicited or
      unauthorized junk mail, spam, chain letters, pyramid schemes or any other form of
      duplicative or unsolicited messages, whether commercial or otherwise; (3) advertise or
      promote a commercial product or service that is not available through Rantizo, in a manner
      not expressly authorized by Rantizo; (4) store or transmit inappropriate content, such as
      content: (a) containing unlawful, defamatory, threatening, pornographic, abusive, libelous
      or otherwise objectionable material of any kind or nature, (b) containing any material that
      encourages conduct that could constitute a criminal offense, or (c) that violates the
      intellectual property rights or rights to the publicity or privacy of others; (5) store or
      transmit any content that contains or is used to initiate a denial of service attack,
      software viruses or other harmful or deleterious computer code, files or programs such as
      Trojan horses, worms, time bombs, cancelbots, or spyware; or (6) abuse, harass, stalk or
      otherwise violate the legal rights of a third party; (ix) interfere with or disrupt servers
      or networks used by Rantizo to provide the Service or used by other users' to access
      the Service, or violate any third party regulations, policies or procedures of such servers
      or networks or harass or interfere with another user's full use and enjoyment of any
      Software or the Service; (x) access or attempt to access Rantizo's other accounts,
      computer systems or networks not covered by these Terms, through password mining or any
      other means; (xi) cause, in Rantizo's sole discretion, inordinate burden on the Service
      or Rantizo's system resources or capacity; or (xii) share passwords or other access
      information or devices or otherwise authorize any unauthorized third party to access or use
      the Software or the Service.
    `}
        />

        <ContentText
            text={`
      (b) Rantizo reserves the right, in its sole discretion, to deactivate, change and/or require
      Customer to change its Rantizo user ID for any reason or for no reason. Rantizo may exercise
      such right at any time, with or without prior notice. Rantizo will make all judgments
      concerning the applicability of these guidelines in its sole and exclusive discretion.
      Rantizo reserves the right, in its sole discretion, to determine whether and what action to
      take in response to each such notification, and any action or inaction in a particular
      instance will not dictate or limit its response to a future complaint. Except as explicitly
      set forth herein, Rantizo will not assume or have any liability for any action or inaction
      with respect to any User Content or Data.
    `}
        />

        <HeadingText text="11. CONFIDENTIALITY" />

        <ContentText
            text={`
      Except as required by law or regulation, each Party shall, during and for no less than three
      years after the termination or expiration of the Agreement (except for information that
      qualifies as trade secret information, which must be maintained as confidential as required
      by applicable law): (i) use the other Party's Confidential Information only for
      purposes of the Agreement; (ii) not disclose it to third parties except as provided in this
      Agreement, including without limitation, Section 11 hereof; and (iii) protect it from
      disclosure using the same degree of care it uses for its own similar Confidential
      Information (but no less than a reasonable degree of care). Either Party may disclose the
      other Party's Confidential Information only to its employees, agents, and
      subcontractors (including professional advisors and auditors), and to those of its
      Affiliates, who have a need to know for purposes of the Agreement, and who are bound to
      protect it from unauthorized use and disclosure under the terms of a written agreement at
      least as protective of the other Party's Confidential Information as the related terms
      of the Agreement. In addition, information, whether or not Confidential Information, may be
      disclosed by a receiving Party as may be required or authorized by applicable law, rule,
      regulation, or lawful process provided that the receiving Party, to the extent practicable
      and permitted by applicable law, rule, regulation or lawful process, first notifies the
      disclosing Party in order to permit the disclosing Party to seek protective arrangements.
      Confidential Information remains the property of the disclosing Party and, upon written
      request of the disclosing Party, must be returned or destroyed provided however that a party
      may retain one copy of the other Party's Confidential Information solely for archiving
      and auditing purposes or as otherwise may be required by law or regulation. Any such
      retained Confidential Information will continue to be subject to requirements of
      confidentiality set out in this Section.
    `}
        />

        <ContentText
            text={`
      As used in this Agreement, the term "Confidential Information" means information
      (in whatever form): (a) designated as confidential; (b) relating to the Agreement including
      the existence of the Agreement itself; (c) relating to the Party's business affairs,
      customers, products, developments, trade secrets, intellectual property rights, know-how or
      personnel; or (d) received or discovered at any time that the Agreement is in effect, or
      otherwise in connection with the Agreement, by a Party (including through an Affiliate or
      other agent), which information should reasonably have been understood as Confidential
      Information of the Party (or one of its Affiliates or subcontractors), either because of
      legends or other markings, the circumstances of disclosure or the nature of the information
      itself. Confidential Information does not include information that: (i) is in the possession
      of the receiving Party free of any obligation of confidentiality at the time of its
      disclosure, (ii) is or becomes publicly known other than by a breach of this provision,
      (iii) is received without restriction from a third party free to disclose it, or (iv) is
      developed independently by the receiving Party without reference to the Confidential
      Information.
    `}
        />

        <HeadingText text="12. EXPORT CONTROL" />

        <ContentText
            text={`
      The Software and Services are subject to applicable U.S. export laws and regulations. You
      will comply with all domestic and international export laws and regulations that apply to
      the Software or Service. These laws include restrictions on destinations, end users, and end
      use. Without limitation, you may not transfer the Software or Service without U.S.
      government permission to anyone on U.S. government exclusion lists (see the Commerce
      Department's compliance list
      https://www.bis.doc.gov/index.php/about-bis/resource-links). You represent and warrant that
      you're not on any of those lists or under the control of or an agent for anyone on
      those lists or the entities listed above.
    `}
        />

        <HeadingText text="13. THIRD PARTY SERVICES AND CONTENT" />

        <ol className="awlist5" type="a">
            <li>
                <ContentText
                    text={`
          Customer will not use the Services to conduct any transactions unless expressly permitted
          by the terms of this Agreement or as otherwise agreed to in writing in advance by Rantizo.
          All transactions using the Services are between the transacting parties only. The Services
          may contain features and functionalities linking you or providing you with certain
          functionality and access to third party content, including Web sites, directories,
          servers, networks, systems, information and databases, applications, software, programs,
          products or services, and the Internet as a whole; Customer acknowledges that Rantizo is
          not responsible for such third-party content or services. Rantizo may also provide some
          content to Customer as part of the Services. However, Rantizo is not an agent of any
          transacting party, nor are we a direct party in any such transaction. Any such activities,
          and any terms associated with such activities, are solely between you and the applicable
          third-party.
          `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          Rantizo has no liability, obligation, or responsibility for any such correspondence,
          purchase, arrangement, or promotion between Customer and any such third-party. Customer
          shall make whatever investigation it feels necessary or appropriate, in its sole
          discretion, before proceeding with any online or offline transaction with any of these
          third parties and Customer shall be solely responsible for its dealings with any third
          party related to the Services, including the delivery of and payment for goods and
          services. Rantizo will not be responsible for any problems resulting from Customer's
          use of any third-party services, or should Customer suffer data loss or other losses
          because of problems with any of Customer's other service providers or any
          third-party services.
        `}
                />
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            '"Third-Party Content"': (text?: string) => (
                                <ImportantText text={text} />
                            )
                        },
                        `
          When using Services, Rantizo may provide Customer with access to content and information
          from a variety of sources, including content generated by Rantizo (collectively, "Third-Party Content"). 
          The Third-Party Content may have additional obligations and restrictions that
          apply. Customer hereby agrees to abide by any such obligations or restrictions. Unless
          otherwise expressly permitted in writing by Rantizo or the owner of the Third-Party
          Content, Customer will only use Third Party Content for its internal business purposes.
        `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            '"Representatives"': (text?: string) => <ImportantText text={text} />
                        },
                        `
          Unless otherwise expressly permitted in writing by Rantizo or the owner of the Third-Party
          Content, Customer will: (i) treat as confidential and preserve the confidentiality of all
          Third-Party Content; (ii) take the same degree of care to prevent disclosure of the
          Third-Party Content as Customer takes to preserve and safeguard its own confidential or
          proprietary information, but, in any event, no less than a reasonable degree of care;
          (iii) not copy, disclose or make available such Third Party Content (or permit others to
          do so); (iv) limit dissemination of the Third-Party Content solely to employees and
          contractors ("Representatives") to whom disclosure is necessary for Customer's internal business purposes,
          but only if such Representative is bound to obligations of confidentiality on terms at
          least as protective as the terms of this Section and Customer has notified such
          Representative that the Third-Party Content must be kept in confidence in accordance with
          this Agreement; and, (v) promptly return or destroy all Confidential Information at our
          request. Customer will be liable if any Representative(s) disclose or use Third-Party
          Content other than as authorized in this Agreement. If Customer becomes aware of any loss
          or unauthorized disclosure of Third-Party Content, Customer will promptly notify Rantizo
          of such and use reasonable efforts to retrieve such Third-Party Content.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText
                    text={`
          Such Third-Party Content may be inaccurate, outdated, or have other problems. Rantizo is
          not responsible for the accuracy, usefulness, safety, or intellectual property rights of
          or relating to any Third-Party Content. Rantizo does not endorse any User Content or
          Third-Party Content, or any opinion, recommendation, or advice expressed therein, and
          Rantizo expressly disclaims any and all liability arising from the User Content or
          Third-Party Content. CUSTOMER HEREBY WAIVES, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES IT
          HAS OR MAY HAVE AGAINST RANTIZO WITH RESPECT THERETO AND WILL INDEMNIFY AND HOLD RANTIZO
          AND ITS LICENSORS HARMLESS TO THE FULLEST EXTENT ALLOWED BY LAW REGARDING ALL MATTERS
          RELATED TO THE USER CONTENT AND/OR THE USE OF THE SERVICE.
        `}
                />
            </li>
        </ol>

        <HeadingText text="14. RANTIZO PROPRIETARY RIGHTS" />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"Feedback"': (text?: string) => <ImportantText text={text} />
                },
                `
        As between Rantizo and Customer, Rantizo or its licensors own and reserve all right, title
        and interest in and to the Service, or any portion thereof including without limitation any
        and all hardware, software and other items used to provide the Service, including any Third
        Party Content, other than the rights explicitly granted to Customer to use Service(s) in
        accordance with the terms and conditions of this Agreement. No title to or ownership of any
        proprietary rights related to the Service is transferred to Customer pursuant to this
        Agreement. All rights not explicitly granted to Customer herein are reserved by Rantizo. In
        the event that Customer or any of its employees, agents, contractors or other authorized
        users of Services provide Rantizo with or contribute in any way to any comments, ideas,
        suggestions, enhancement requests, feedback or recommendations with respect to the Service
        (including, without limitation, any changes, modifications, enhancements, improvements and
        other changes to any Service that Rantizo otherwise conceives of or creates as a result of
        the same) (collectively, "Feedback"), then Rantizo will be free to use and disclose such 
        Feedback without any restriction or further obligation to Customer. Other than any Confidential 
        Information of Customer included in such Feedback, Rantizo will own all right, title, and 
        interest in and to any such Feedback, including Rantizo's implementation of the same, 
        and all intellectual property rights covering or derived therefrom. If any Feedback is deemed 
        to be the proprietary property of Customer, Customer hereby grants to Rantizo a world-wide, royalty
        free, irrevocable, perpetual license to use and otherwise incorporate any Feedback in
        connection with Services.
    `
            )}
        </ContentText>

        <HeadingText text="15. PRIVACY" />

        <ol className="awlist6" type="a">
            <li>
                <ContentText>
                    {replaceTextToComponents(
                        {
                            '"Collected Data"': (text?: string) => <ImportantText text={text} />
                        },
                        `
          To operate and provide Services, Rantizo, its Affiliates and third-party service providers
          may receive or collect certain information about Customer and its authorized users,
          including but not limited to location data, personally identifiable information of
          employees, contractors, suppliers and/or customers of Customer, addresses, Service type
          and Service performance (collectively, "Collected Data"). Customer acknowledges and agrees 
          that, during and after termination of Services,
          (i) Rantizo may, to the extent permitted under applicable law and the terms and conditions
          of this Agreement, retain and use Collected Data for purposes of providing and improving
          the Services or any portion thereof, and conducting research and development; (ii) Rantizo
          may share Collected Data with Affiliates and other third-parties providing services to
          Rantizo, subject to obligations of confidentiality; (iii) Rantizo may disclose Collected
          Data if required under applicable law, regulation or court order; (iv) Rantizo may use and
          share with Affiliates and third-parties any Collected Data in aggregated or de-identified
          form such that the source of the Collected Data cannot be identified; (v) Rantizo may use
          or share any Collected Data that is publicly available without any restriction; and (vi)
          Rantizo and its Affiliates may collect, use, share and commercially exploit Collected
          Data, subject to the permissions and restrictions set forth elsewhere in this Agreement.
          Customer further acknowledges and agrees that Rantizo may access or disclose information
          about Customer and its authorized users, including without limitation, the content of
          applicable communications, in order to: (i) comply with the law or respond to lawful
          requests or legal process; (ii) protect the rights or property of Rantizo or its
          customers, including the enforcement of agreements or policies governing Customer's
          use of Services; or (iii) act on a good faith belief that such access or disclosure is
          necessary to protect the personal safety of Rantizo employees, customers, or the public.
          `
                    )}
                </ContentText>
            </li>

            <li>
                <ContentText
                    text={`
          To the extent required by applicable law or regulation, Customer agrees to provide notice
          to, and to receive consents from, its authorized users of: (i) the nature of the Services,
          (ii) Rantizo's collection, use, and disclosure of Collected Data and Confidential
          Information, which may contain personal Information from time to time, and (iii)
          Rantizo's tracking, recordation, processing and use of Collected Data and other
          information relating to Customer's use of the Services.
        `}
                />
            </li>
        </ol>

        <HeadingText text="16. WARRANTIES" />

        <HeadingText text="16.1 Mutual Representations and Warranties" />

        <ContentText
            text={`
      Each Party represents and warrants to the other Party that:
    `}
        />

        <ol className="awlist7" type="a">
            <li>
                <ContentText
                    text={`
          it is duly organized, validly existing, and in good standing as a corporation or other
          entity under the laws of the jurisdiction of its incorporation or other organization;
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          it has the full right, power, and authority to enter into and perform its obligations and
          grant the rights, licenses, consents, and authorizations it grants or is required to grant
          under this Agreement; and
        `}
                />
            </li>

            <li>
                <ContentText
                    text={`
          when executed and delivered by both parties, this Agreement will constitute the legal,
          valid, and binding obligation of such party, enforceable against such party in accordance
          with its terms.
        `}
                />
            </li>
        </ol>

        <HeadingText text="16.2 Additional Customer Representations, Warranties and Covenants." />

        <ContentText
            text={`
      Customer represents, warrants, and covenants to Rantizo that Customer owns or otherwise has
      and will have the necessary rights and consents in and relating to the User Data so that, as
      received by Rantizo and processed and used in accordance with this Agreement, they do not
      and will not infringe, misappropriate, or otherwise violate any intellectual property, or
      any privacy or other rights of any third party or violate any applicable law or regulation.
    `}
        />

        <HeadingText text="16.3 Warranty Exclusions." />

        <ContentText>
            {replaceTextToComponents(
                {
                    '"RANTIZO PARTIES"': (text?: string) => <ImportantText text={text} />
                },
                `
        EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION 16, RANTIZO, ITS AFFILIATES, AND EACH OF THEIR
        RESPECTIVE PAST AND PRESENT OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS, LICENSORS,
        SUPPLIERS, SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE "RANTIZO PARTIES") (I) MAKES NO OTHER REPRESENTATION, AGREEMENT OR WARRANTY WITH RESPECT TO THE
        PROVISION OF SERVICES (OR ANY PORTION THEREOF) HEREUNDER OR OTHERWISE, AND ALL OTHER
        REPRESENTATIONS, AGREEMENTS OR WARRANTIES OF ANY KIND, WHETHER ORAL OR IN WRITING AND
        WHETHER EXPRESS OR IMPLIED, EITHER BY OPERATION OF LAW, STATUTE OR THERWISE, ARE EXCLUDED TO
        THE FULLEST EXTENT PERMITTED BY LAW AND REGULATION; (II) PROVIDE THE SERVICES AND ANY THIRD
        PARTY CONTENT ON AN "AS IS", "WITH ALL FAULTS" AND "AS
        AVAILABLE" BASIS; AND (III) EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES, INCLUDING
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. EXCEPT AS OTHERWISE
        SPECIFICALLY PROVIDED HEREIN, THE RANTIZO PARTIES DO NOT WARRANT, GUARANTEE, OR MAKE ANY
        REPRESENTATION (1) RELATING TO THE TRANSMISSION OR DELIVERY OF THE SERVICE; (2) RELATING TO
        DELAYS, INTERRUPTIONS, ERRORS, OR OMISSIONS (OR THE LACK THEREOF) IN THE SERVICE, OR ANY
        PART THEREOF; (3) THAT THE SERVICE, USER CONTENT OR THIRD PARTY CONTENT WILL BE
        UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS; (4) THAT THE USER CONTENT WILL BE
        SECURE OR NOT OTHERWISE LOST OR DAMAGED; (5) RELATING TO THE TRANSMISSION OR DELIVERY OF THE
        SERVICE; (6) RELATING TO PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS OF THE
        RANTIZO PARTIES; (7) THAT THE SERVICE WILL MEET CUSTOMER'S NEEDS OR REQUIREMENTS OR
        THE NEEDS OR REQUIREMENTS OF ANY OTHER PERSON; AND (8) ARISING OUT OF ANY COURSE OF
        PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. NO ORAL OR WRITTEN ADVICE OR INFORMATION
        PROVIDED BY THE RANTIZO PARTIES SHALL CREATE A WARRANTY, AND CUSTOMER IS NOT ENTITLED TO,
        AND WILL NOT, RELY ON ANY SUCH ADVICE OR INFORMATION. CUSTOMER'S USE, ACCESS,
        DOWNLOAD, OR OTHERWISE OBTAINING INFORMATION, MATERIALS, OR DATA THROUGH THE SERVICE
        (INCLUDING RSS FEEDS) OR ANY THIRD-PARTY SERVICES IS AT CUSTOMER'S OWN DISCRETION AND
        RISK AND CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO PROPERTY (INCLUDING COMPUTER
        SYSTEMS OR UNMANNED AERIAL VEHICLES) OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE
        OF SUCH MATERIAL OR DATA.
    `
            )}
        </ContentText>

        <ContentText
            text={`
      CUSTOMER IS SOLELY RESPONSIBLE FOR ITS OWN CONDUCT BOTH IN USING THE SERVICES AND IN ANY
      ACTIVITIES RELATED TO USING THE SERVICES, INCLUDING BUT NOT LIMITED TO FLYING AN UNMANNED
      AERIAL VEHICLE. RANTIZO MAY PROVIDE A MAP AS A PART OF THE SERVICES. THE MAP AND ALL RELATED
      SERVICES ARE TO BE FOR PLANNING PURPOSES ONLY AND DO NOT REPLACE CUSTOMER'S OWN
      JUDGMENT ABOUT WHETHER IT IS SAFE TO FLY AT THAT TIME OR WHETHER CUSTOMER IS PERMITTED TO
      FLY IN THAT LOCATION. CUSTOMER IS SOLELY RESPONSIBLE FOR KNOWING AND COMPLYING WITH ANY
      LOCAL, STATE, FEDERAL, OR INTERNATIONAL LAWS GOVERNING ITS ABILITY TO FLY AN UNMANNED AERIAL
      VEHICLE AND RANTIZO SPECIFICALLY DISCLAIMS THAT ANY INFORMATION PRESENTED ON THE SERVICES IS
      MERELY ADVICE AND MAY NOT REFLECT THE CURRENT STATE OF THE LAW IN THE RELEVANT JURISDICTION.
      THIS DISCLAIMER OF WARRANTIES IS AN ESSENTIAL CONDITION OF THIS AGREEMENT.
    `}
        />

        <HeadingText text="17. INDEMNIFICATION" />

        <ContentText
            text={`
      (a)
      You will indemnify, defend and hold harmless the Rantizo Parties from and against any
      losses, claims, damages, liabilities, including legal fees and expenses, arising out of: (i)
      any claim due to or arising out of your violation of these Terms, including but not limited
      to a claim arising out of a breach of your representations or warranties made hereunder;
      (ii) your use or misuse of or access to the Service; (iii) your violation of any law,
      regulation or third party right, including without limitation any copyright, property, or
      privacy right; (iv) any claim that you or your Content caused damage to a third party; or,
      (v) any other of your activities or omissions.
    `}
        />

        <ContentText
            text={`
      (b)
      Rantizo reserves the right, at your expense, to assume the exclusive defense and control of
      any matter for which you are required to indemnify Rantizo, and you will cooperate with
      Rantizo's defense of these claims.
    `}
        />

        <HeadingText text="18. LIMITATION OF LIABILITY" />

        <ContentText
            text={`
      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL RANTIZO, ITS
      AFFILIATES, AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE
      LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES
      OF ANY KIND ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO COSTS
      OF COVER, COSTS OF PROCURING SUBSTITUTE SERVICES, SUPPORT SERVICES, OR PROFESSIONAL
      SERVICES, ANY LOSS OF BUSINESS, LOSS OF DATA, INABILITY TO USE DATA, DELAYED AVAILABILITY OF
      DATA, INTERRUPTION OF BUSINESS, LOST PROFITS, LOST SAVINGS OR ANTICIPATED SAVINGS, LOST OR
      DIMINISHED GOODWILL OR REPUTATION, IN ANY CASE REGARDLESS OF WHETHER THE FOREGOING MAY BE
      CONSIDERED DIRECT OR INDIRECT DAMAGES (OR OTHERWISE), OR WHETHER THE CLAIM IS BASED ON
      BREACH OF CONTRACT, CONTRACT CLAIM, NEGLIGENCE, TORT LIABILITY, OR OTHERWISE, EVEN IF
      RANTIZO HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES.
    `}
        />

        <ContentText
            text={`
      IN NO EVENT WILL THE TOTAL CUMULATIVE LIABILITY OF RANTIZO, ITS AFFILIATES, AND THEIR
      RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS UNDER AND DURING THE ENTIRE
      TERM OF THIS AGREEMENT AND FOR ANY AND ALL ACTIONS ARISING FROM OR RELATED TO THIS AGREEMENT
      EXCEED THE TOTAL AMOUNT PAID TO RANTIZO UNDER THIS AGREEMENT DURING THE 12 MONTH PERIOD
      PRECEDING THE EVENT WHICH GAVE RISE TO THE CLAIM. THE EXISTENCE OF MULTIPLE CLAIMS WILL NOT
      INCREASE THIS LIMIT. FOR THE PURPOSE OF THIS CLAUSE AND CALCULATION, WHERE: (I) AN EVENT GIVES RISE TO A NUMBER
      OF SEPARATE LIABILITIES, CLAIMS OR CAUSES OF ACTION, AND/OR (II) THERE ARE MULTIPLE EVENTS,
      WHETHER CONNECTED OR SEPARATE, RELATED TO THIS AGREEMENT, SUCH WILL BE CONSIDERED A SINGLE
      EVENT, WILL BE DEEMED TO HAVE OCCURRED IN THE ANNUAL PERIOD IN WHICH THE FIRST EVENT
      OCCURRED AND WILL NOT INCREASE THIS LIMIT. CUSTOMER ACKNOWLEDGES THAT THIS SECTION WILL BE GIVEN 
      FULL EFFECT EVEN IF THE WARRANTIES AND THE REMEDIES ARE DEEMED TO HAVE FAILED OF THEIR ESSENTIAL PURPOSE.
    `}
        />

        <HeadingText text="19. UPDATES TO THE SERVICE" />

        <ContentText
            text={`
      Rantizo reserves the right, in its sole discretion, to make necessary unscheduled
      deployments of changes, updates or enhancements to the Service at any time. 
      WE MAY ALTER, ADD OR REMOVE FUNCTIONALITIES OR FEATURES, AND WE MAY SUSPEND OR STOP
      PROVIDING THE SERVICE, OR A PORTION THEREOF, IN OUR SOLE DISCRETION. RANTIZO NOT BE LIABLE
      IN ANY WAY FOR POSSIBLE CONSEQUENCES OF SUCH CHANGES.
    `}
        />

        <HeadingText text="20. GOVERNING LAW; LOCATION FOR RESOLVING DISPUTES" />

        <ContentText
            text={`
      The construction, interpretation and performance of this Agreement shall be governed by and
      construed in accordance with the laws of the State of Delaware without regard to any
      conflicts of law principles that would require the application of the laws of any other
      jurisdiction. Any controversy, claim, or dispute arising out of or relating to the Agreement
      (including incorporated terms), must be resolved by panel arbitration administered by the
      American Arbitration Association ("AAA") in accordance with its Commercial
      Arbitration Rules (the "Rules"). If a court rules that this arbitration
      requirement is unenforceable, or if a court proceeding is allowed instead of arbitration,
      the Parties explicitly (1) agree to waive their right to trial by jury; and (2) consent to
      the exclusive jurisdiction of the courts in the State of Delaware and agree to accept the
      service of process of such courts such that any suit brought by either Party against the
      other Party for claims arising out of this Agreement shall be brought in the State courts,
      or Federal courts if applicable, of the State of Delaware. Except as provided in this
      Agreement, the schedule and rules for the arbitration hearing will be set by the arbitration
      panel (the "Panel") appointed by the AAA in accordance with the Rules. The Parties
      agree that: (A) each Party will bear its own costs and fees in connection with the
      arbitration, except the arbitrators' fees which will be borne equally by the Parties;
      (B) any award of the Panel shall be accompanied by a written opinion setting forth the
      rationale for the decision; (C) the Panel may not award punitive, consequential or exemplary
      damages; (D) this provision and the arbitration will be governed by the Federal Arbitration
      Act, 9 U.S.C. §§ 1-16, as amended; (E) the decision of the Panel must be based
      upon the Agreement and applicable law, will be final and binding with no right of appeal,
      except for fraud, misconduct or errors of law, and judgement upon the decision rendered may
      be entered in any court having jurisdiction; and (F) no arbitration can be on a class basis
      or be joined or consolidated with another arbitration. If the prohibition in subsection (F)
      is found to be unenforceable, then neither party shall be required to arbitrate. Even if
      applicable law or regulation permits class actions or class arbitrations, the dispute
      resolution procedure specified here applies and Rantizo and Customer waive any rights to
      pursue any claim arising under or related to the Agreement on a class basis. In all
      arbitrations, arbitrators must give effect to applicable statutes of limitation subject to
      limitation of actions terms in the Agreement and has no authority to award relief in excess
      of what the Agreement provides.
    `}
        />

        <ContentText
            text={`
      Notwithstanding the foregoing, the Parties understand and agree that any dispute relating to
      the title, use, validity, or other similar claims related to intellectual property,
      including copyright, trademark, patent or trade secrets, and Confidential Information shall
      not be subject to the provisions in this Section related to arbitration.
    `}
        />

        <ContentText
            text={`
      Nothing in this Section shall limit the right of a party to seek or obtain provisional or
      preliminary relief from a court of competent jurisdiction to (a) compel arbitration in
      accordance with this Agreement, (b) obtain orders to require witnesses to obey subpoenas
      issued by the arbitrators, (c) seek temporary injunctive relief related to the breach of the
      other Party's confidentiality obligations under this Agreement or (d) secure
      enforcement of any arbitration award rendered pursuant to this Section.
    `}
        />

        <HeadingText text="21. NOTICES" />

        <ContentText
            text={`
      All notices (including notices to terminate the Agreement for Convenience) must be in
      writing and sent to the notice address specified herein, or if no such address is specified,
      the registered address of the relevant Party. With respect to Rantizo, legal notices may be
      transmitted via email to Support@Rantizo.com with a duplicate copy send via any of overnight 
      courier, hand delivery, a class of certified or registered mail that includes proof of receipt 
      to the following address: 4165 Alyssa Court, Iowa City, IA. With respect to Customer, legal 
      notices may be transmitted via the contact information you provide upon registration with 
      Rantizo. Notices sent in accordance with this Section is effective when received, except for 
      email notice, which is effective the business day after being sent.
    `}
        />

        <HeadingText text="22. MISCELLANEOUS" />

        <HeadingText text="22.1. Severability; Entire Agreement" />

        <ContentText
            text={`
      If any provision of this Agreement is held by any entity of competent jurisdiction to be
      illegal, invalid or unenforceable for any reason, or to cause this Agreement as a whole to
      be unenforceable, that provision or portion of this Agreement will be deemed modified to the
      extent necessary to make it and this Agreement as a whole enforceable and the remaining
      provisions or portions will remain in full force and effect. Failure or delay to exercise or
      enforce any right under this Agreement is not a waiver of that right. Certain provisions
      contained herein are intended by their nature to survive expiration or termination
      (including, without limitation, Privacy, Limitation of Liability, Indemnities, and
      Confidentiality). This Agreement may not be amended except by a written instrument that both
      Parties agree to be bound by (whether by execution or some other method). The Agreement: (a)
      expresses the entire understanding of the respective Parties with respect to their subject
      matter; (b) supersedes all prior or contemporaneous representations, solicitations, offers,
      understandings or agreements regarding their subject matter which are not fully expressed
      herein; and (c) contains all the terms, conditions, understandings, and representations of
      the Parties. Any terms and conditions sent to Rantizo by Customer as a purchase order or
      otherwise, are void and of no effect and, will not supersede any terms and conditions in the
      Agreement.
    `}
        />

        <HeadingText text="22.2. Assignment and transfer" />

        <ContentText
            text={`
      Either Party may assign its rights or obligations under (and subject to) the Agreement, to:
      (a) an Affiliate; or (b) a successor to the business or assets of a Party that includes this
      Agreement without the other Party's consent, provided in the case of the Customer, the
      assignee is an entity of the same country as Rantizo and meets Rantizo's generally
      applicable credit standards. Except as stated, no Party may novate, assign, encumber, or
      transfer the Agreement in whole or in part without the prior written consent of the other
      Party (which may not be unreasonably withheld, conditioned or delayed). Except as expressly
      permitted in the Agreement, Customer may not resell, charge, transfer or otherwise dispose
      of Services or any part thereof to a third party.
    `}
        />

        <HeadingText text="22.3. Independent Contractors; No third-party beneficiaries" />

        <ContentText
            text={`
      Nothing in this Agreement, and no course of dealing between the parties, shall be construed
      to create an employment or agency relationship or a partnership between a Party and the
      other Party or the other Party's employees or agents.
    `}
        />

        <HeadingText text="22.4. Claims" />

        <ContentText
            text={`
      Claims must be filed within one year. You must bring any claim related to these Terms or the
      Service within one year of the date you could first bring the claim, unless your local law
      requires a longer time to file claims. If it isn't filed in time, the claim is
      permanently barred.
    `}
        />

        <HeadingText text="22.5. Waiver" />

        <ContentText
            text={`
      All waivers must be in writing. The failure by a Party to enforce at any time any provisions
      in this Agreement, to insist upon strict performance of any provision of this Agreement, or
      to exercise any right provided for herein, shall not be deemed to be a waiver for the future
      of such provision or right, and no waiver of any provision or right shall affect the right
      of the waiving Party to enforce any other provision or right herein.
    `}
        />

        <HeadingText text="22.6. Government Use" />

        <ContentText
            text={`
      If you are a U.S. government entity, you acknowledge that any Software and User Guides that
      are provided are "Commercial Items" as defined at 48 C.F.R. 2.101, and are being
      provided as commercial computer software subject to the restricted rights described in 48
      C.F.R. 2.101 and 12.212.
    `}
        />

        <HeadingText text="22.7 Equitable Relief" />

        <ContentText
            text={`
      Customer agrees that any actual or threatened breach of this Agreement may cause irreparable
      injury to Rantizo and its suppliers for which monetary damages alone may not be an adequate
      remedy. The Parties agree that in addition to all other available remedies, Rantizo will be
      entitled to seek specific performance and injunctive or other equitable relief, without the
      requirement of posting a bond (where applicable). Rantizo will recover from Customer any
      out-of-pocket expenses incurred in seeking and enforcing any such remedies, including,
      without limitation, any legal expenses, including court costs and attorneys' fees.
    `}
        />

        <HeadingText text="22.8 Force Majeure" />

        <ContentText
            text={`
      Any failure by a Party to perform an obligation under this Agreement that is the result of a
      Force Majeure Event is not a breach of this Agreement (other than Customer's failure to
      make timely payment of its payment obligations hereunder, which will constitute a material
      breach of this Agreement). A Party claiming non-performance from a Force Majeure Event must
      promptly provide the other Party notice of the relevant details, and the obligations of the
      notifying Party are suspended to the extent caused by the Force Majeure Event. The time for
      performance of the affected obligation will be extended by the delay caused by the Force
      Majeure Event, provided, however, that if Rantizo is prevented by the Force Majeure Event
      from performing its obligation(s) with respect to a Service for thirty (30) days or more,
      then Rantizo may in its sole discretion immediately terminate such Rantizo with notice to
      Customer. Upon such termination, Rantizo is entitled to payment of all accrued but unpaid
      Charges incurred through the date of such termination. The Parties will otherwise bear their
      own costs and Rantizo will be under no further liability or obligation to perform the
      Service affected by the Force Majeure Event.
    `}
        />

        <HeadingText text="22.9 Use of Subcontractors or Affiliates" />

        <ContentText
            text={`
      Without releasing it from any of its obligations, Rantizo may at any time utilize the
      services of one or more Rantizo Affiliates or subcontractors in connection with the
      performance of its obligations. Rantizo may subcontract any portion of any Services provided
      under this Agreement to any data center where the Services are hosted or any other
      third-party contractor without the prior consent of Customer, provided that Rantizo remains
      fully responsible to Customer for the delivery of the Services.
    `}
        />

        <HeadingText text="22.10 UN Convention" />

        <ContentText
            text={`
      Neither the United Nations Convention on Contracts for the International Sale of Goods nor
      the Uniform Computer Transactions Act applies to transactions under this Agreement.
    `}
        />

        <HeadingText text="23. INTELLECTUAL PROPERTY NOTICES" />

        <ContentText
            text={`
      All contents of the Site and Services including but not limited to design, text, software,
      technical drawings, configurations, graphics, other files, and their selection and
      arrangement are Copyright © 2023 Rantizo, Inc., and/or the proprietary property of its
      suppliers, affiliates, or licensors. All Rights Reserved.
    `}
        />

        <ContentText
            text={`
      Rantizo and the Rantizo logo are including without limitation, either trademarks, service
      marks or registered trademarks of Rantizo, Inc., and may not be copied, imitated, or used,
      in whole or in part, without Rantizo's prior written permission or that of our
      suppliers or licensors. Other product and company names may be trade or service marks of
      their respective owners.
    `}
        />

        <ContentText
            text={`
      Rantizo may have patents, patent applications, trademarks, copyrights, or other intellectual
      property rights covering subject matter that is part of the Service. Unless we have granted
      you licenses to our intellectual property in these Terms, our providing you with the Service
      does not give you any license to our intellectual property. Any rights not expressly granted
      herein are reserved.
    `}
        />
    </div>
);

export default TermsAndConditionsPage;

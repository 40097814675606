import { VerticalContainer } from '@rantizo-software/rantizo-ui';
import { PDFDownloadLink } from '@react-pdf/renderer';

import ProductUsageReportPDF from 'components/ProductUsageReportPDF';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportFooter: FunctionComponent<Props> = ({
    applicatorName,
    productUsageReport,
    workOrder
}) => {
    const { DOWNLOAD_PDF } = useTranslation();
    const placeholderPdfName = `ProductUsageReport_${productUsageReport.id.slice(0, 9)}.pdf`;

    return (
        <VerticalContainer className={styles.footerContainer}>
            <PDFDownloadLink
                document={
                    <ProductUsageReportPDF
                        applicatorName={applicatorName}
                        productUsageReport={productUsageReport}
                        workOrder={workOrder}
                    />
                }
                className={styles.downloadLink}
                fileName={placeholderPdfName}
            >
                {
                    // @ts-expect-error implementation will later be changed
                    ({ loading }) => <>{!loading && DOWNLOAD_PDF}</>
                }
            </PDFDownloadLink>
        </VerticalContainer>
    );
};

export default ProductUsageReportFooter;

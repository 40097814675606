import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import FormFileUpload from 'components/form/FormFileUpload';

import useUploadFile from 'hooks/useUploadFile';

import useTranslation from './hooks/useTranslate';

import FileListContainer from './components/FileListContainer';
import { TEST_ID } from './constants';
import type {
    ErrorFile,
    FileListItem,
    FileUpload,
    FileValidationErrorType,
    FunctionComponent,
    Props,
    UploadedFile
} from './types';

const MultiFileUpload: FunctionComponent<Props> = ({
    acceptedFileTypes = '',
    addUploadedFile,
    getPresignedUrl,
    getUploadedFiles,
    isDisabled = false,
    isEditable,
    modifyFileRequest,
    previouslyUploadedFiles = [],
    setUploadedFiles,
    testId = TEST_ID,
    validateFile
}) => {
    const {
        AIRCRAFT_MISMATCH,
        CONFLICT_ERROR,
        CSV_MULTI_AIRCRAFT_ERROR,
        INVALID_DATA_ERROR,
        INVALID_EXTENSION_ERROR,
        INVALID_FLIGHT_MODE_ERROR,
        SELECT_FILES,
        UNKNOWN_ERROR,
        UPLOAD_ERROR
    } = useTranslation();

    const [errorFiles, setErrorFiles] = useState<ErrorFile[]>([]);

    const { getObjectKeyFromUrl, uploadFile } = useUploadFile();

    const errorMessageMap: Record<FileValidationErrorType, string> = useMemo(
        () => ({
            AIRCRAFT_MISMATCH: AIRCRAFT_MISMATCH,
            CONFLICT: CONFLICT_ERROR,
            CSV_MULTI_AIRCRAFT_ERROR: CSV_MULTI_AIRCRAFT_ERROR,
            INVALID_DATA: INVALID_DATA_ERROR,
            INVALID_EXTENSION: INVALID_EXTENSION_ERROR,
            INVALID_FLIGHT_MODE: INVALID_FLIGHT_MODE_ERROR,
            UNKNOWN: UNKNOWN_ERROR,
            UPLOAD_ERROR: UPLOAD_ERROR
        }),
        [
            AIRCRAFT_MISMATCH,
            CONFLICT_ERROR,
            CSV_MULTI_AIRCRAFT_ERROR,
            INVALID_DATA_ERROR,
            INVALID_EXTENSION_ERROR,
            INVALID_FLIGHT_MODE_ERROR,
            UNKNOWN_ERROR,
            UPLOAD_ERROR
        ]
    );

    const onChange = useCallback(
        async (fileUpload: FileUpload) => {
            try {
                const modifiedFileUpload = await modifyFileRequest(fileUpload);

                await validateFile(modifiedFileUpload);

                const { uploadUrl } = await getPresignedUrl(modifiedFileUpload);

                const objectKey = getObjectKeyFromUrl(uploadUrl);

                const didUpload = await uploadFile(uploadUrl, modifiedFileUpload.file);

                if (!didUpload) {
                    throw new Error('UPLOAD_ERROR');
                }

                addUploadedFile({ fileName: modifiedFileUpload.fileRequest.filename, objectKey });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.error(error);

                const errorFile = {
                    error: (errorMessageMap[error.message as FileValidationErrorType] ??
                        errorMessageMap.UNKNOWN) as string,
                    fileName: fileUpload.fileRequest.filename,
                    objectKey: uuid()
                };

                console.log(errorFile);

                setErrorFiles(prevErrorFiles => [...prevErrorFiles, errorFile]);
            }
        },
        [
            addUploadedFile,
            errorMessageMap,
            getObjectKeyFromUrl,
            getPresignedUrl,
            modifyFileRequest,
            uploadFile,
            validateFile
        ]
    );

    const handleDeleteAll = useCallback(() => {
        // TODO have it actually delete the files from s3 once we get the endpoint to delete the files
        setUploadedFiles([]);
        setErrorFiles([]);
    }, [setErrorFiles, setUploadedFiles]);

    const handleRemoveFile = useCallback(
        (objectKey: string) => {
            const updatedUploadedFiles = getUploadedFiles?.()?.filter(
                (uploadedFile: UploadedFile) => uploadedFile.objectKey !== objectKey
            );
            const updatedErrorFiles = errorFiles.filter(
                (errorFile: ErrorFile) => errorFile.objectKey !== objectKey
            );

            setUploadedFiles(updatedUploadedFiles);
            setErrorFiles(updatedErrorFiles);

            // TODO have it actually delete these files once we get the endpoint to delete the files
        },
        [errorFiles, getUploadedFiles, setUploadedFiles]
    );

    const fileListContainerItems = useMemo(() => {
        const files = getUploadedFiles()
            .map(
                (uploadedFile: UploadedFile) =>
                    ({
                        error: '',
                        fileName: uploadedFile.fileName,
                        onDelete: () => handleRemoveFile(uploadedFile.objectKey)
                    }) as FileListItem
            )
            .concat(
                errorFiles.map(
                    (errorFile: ErrorFile) =>
                        ({
                            error: errorFile.error,
                            fileName: errorFile.fileName,
                            onDelete: () => handleRemoveFile(errorFile.objectKey)
                        }) as FileListItem
                )
            )
            .concat(
                previouslyUploadedFiles.map(
                    file =>
                        ({
                            error: '',
                            fileName: file.filename
                        }) as FileListItem
                )
            )
            .filter((file: unknown) => typeof file !== 'undefined');

        return files as FileListItem[];
    }, [errorFiles, getUploadedFiles, handleRemoveFile, previouslyUploadedFiles]);

    return (
        <VerticalContainer testId={testId}>
            {fileListContainerItems.length > 0 && (
                <FileListContainer files={fileListContainerItems} onDeleteAll={handleDeleteAll} />
            )}

            {isEditable && (
                <FormFileUpload
                    accept={acceptedFileTypes}
                    disabled={isDisabled}
                    displayFileAfterUpload={false}
                    multiple={true}
                    onFileDelete={async () => handleDeleteAll()}
                    onFileRequestChange={onChange}
                    text={SELECT_FILES}
                />
            )}
        </VerticalContainer>
    );
};

export default MultiFileUpload;

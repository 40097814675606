import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const FlightReportDeleteModal: FunctionComponent<Props> = ({
    onClose,
    onConfirm,
    testId = TEST_ID
}) => {
    const { CONFIRM_DELETE, CONFIRM_DELETE_MESSAGE } = useTranslation();

    return (
        <DeleteConfirmationModal
            onClose={onClose}
            onConfirm={onConfirm}
            testId={testId}
            text={CONFIRM_DELETE_MESSAGE}
            title={CONFIRM_DELETE}
        />
    );
};

export default FlightReportDeleteModal;

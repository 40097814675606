import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { AAM_FLIGHTS_API, FLIGHTS_API, FLIGHT_SUMMARIES_API } from './constants';
import type {
    FetchFlights,
    Flight,
    FlightApiResponse,
    FlightQuery,
    FlightQueryFlightModification,
    FlightSummaryApiResponse,
    FlightSummaryQuery
} from './types';

const useFlights = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPost } = useFetch();

    const { addQuery } = useQuerystring({});

    const deleteUploadFlight = useCallback(
        async (id: string, url: string) => {
            const { error } = await authenticatedDelete(`${url}/${id}`);

            if (error) {
                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedDelete]
    );

    const findFlights = useCallback(
        async (
            flightQuery: FlightQuery,
            pageSize: number,
            pageToken: string | null
        ): Promise<FlightApiResponse> => {
            const payload = JSON.stringify(flightQuery);
            const query = addQuery({
                pageToken: pageToken,
                size: `${pageSize}`
            });
            const url = `${FLIGHTS_API}?${query.toString()}`;

            const { data, error } = await authenticatedPost(url, payload);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as FlightApiResponse;
        },
        [addQuery, authenticatedPost]
    );

    const findFlightSummaries = useCallback(
        async (
            flightSummaryQuery: FlightSummaryQuery,
            sort: string[],
            pageSize: number,
            pageToken: string | null
        ): Promise<FlightSummaryApiResponse> => {
            const { endTime, nicknames, startTime } = flightSummaryQuery;
            const queryString = addQuery({
                endTime,
                nicknames: nicknames ? nicknames?.join(',') : [],
                pageToken,
                size: `${pageSize}`,
                sort,
                startTime
            });
            const url = `${FLIGHT_SUMMARIES_API}?${queryString.toString()}`;

            const { data, error } = await authenticatedGet(url);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as FlightSummaryApiResponse;
        },
        [addQuery, authenticatedGet]
    );

    const findAllFlights = useCallback(
        async (flightQuery: FlightQuery): Promise<Flight[]> => {
            const pageSize = 25;
            let pageToken = null;
            let flights: Flight[] = [];
            let keepFetching = true;

            while (keepFetching) {
                const apiResults = await findFlights(flightQuery, pageSize, pageToken);

                keepFetching = apiResults.hasNext;
                pageToken = apiResults.nextPageToken;
                flights = flights.concat(apiResults.objects);
            }

            return flights;
        },
        [findFlights]
    );

    const fetchFlightsForAAM: FetchFlights = useCallback(
        async ({ aamId }) => {
            const flightsUrl = AAM_FLIGHTS_API(aamId);

            return await authenticatedGet(flightsUrl);
        },
        [authenticatedGet]
    );

    const fetchFlight = useCallback(
        async (id: string, modification?: FlightQueryFlightModification) => {
            let url = `${FLIGHTS_API}/${id}`;

            if (modification) {
                const query = addQuery({ modification: modification });

                url = `${FLIGHTS_API}/${id}?${query.toString()}`;
            }

            const { data, error } = await authenticatedGet(url);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as Flight;
        },
        [addQuery, authenticatedGet]
    );

    return {
        deleteUploadFlight,
        fetchFlight,
        fetchFlightsForAAM,
        findAllFlights,
        findFlightSummaries,
        findFlights
    };
};

export default useFlights;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextContent } from '@@i18n/TextContent';
import { Button, Text } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

// pass color as prop to get proper hover and active styles
const buttonColor = 'blue.7';

const BackButton: FunctionComponent<Props> = ({ onClick, testId = TEST_ID }) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => onClick?.() || navigate('..'), [navigate, onClick]);

    return (
        <>
            <Text>
                <TextContent id="error.buttonLabel.goBack" />
            </Text>

            <Button
                className={styles.button}
                color={buttonColor}
                data-testid={testId}
                onClick={handleClick}
            >
                <TextContent id="error.button.goBack" />
            </Button>
        </>
    );
};

export default BackButton;

export const TEST_ID = 'flightLogPage';

export const MAX_YEAR = new Date().getFullYear();

export const CURRENT_MONTH = new Date().getMonth();

export const CURRENT_DAY = new Date().getDate();

export const ALLOWED_RANGE_END = new Date(MAX_YEAR, CURRENT_MONTH, CURRENT_DAY);

export const ALLOWED_RANGE_START = new Date('2022-01-01');

export const FLIGHT_LOG_LIST_FEATURE = 'flightLogs';

export const ALLOWED_DATE_RANGE: Interval = {
    end: ALLOWED_RANGE_END,
    start: ALLOWED_RANGE_START
};

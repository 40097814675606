import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import EmphasizeText from 'components/EmphasizeText';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const NoItems: FunctionComponent<Props> = ({ className = '', testId = TEST_ID, text = '' }) => (
    <VerticalContainer className={`${className} ${styles.noItems}`} testId={testId}>
        <EmphasizeText className={styles.emphasizeText} text={text} />
    </VerticalContainer>
);

export default NoItems;

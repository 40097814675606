import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DisplayResourcePage from 'components/DisplayResourcePage';
import FormContainer from 'components/FormContainer';
import FormNavigationButtons from 'components/FormNavigationButtons';
import SidebarOrDropdownLayout from 'components/SidebarOrDropdownLayout';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, SideBarItem } from './types';

const MultiPartFormWithFetchedResource: FunctionComponent<Props> = ({
    canFinalSubmit,
    children,
    contentMap,
    customNextText,
    defaultSection,
    fetchResource,
    initialValues,
    notFoundText,
    onResourceFetched,
    onSubmit,
    resourceId,
    saveButtonText,
    sideBarItems,
    testId = TEST_ID,
    title,
    validationSchemas
}) => {
    const [section, setSection] = useState<string>(defaultSection);
    const [visitedMap, setVisitedMap] = useState<Record<string, boolean>>();
    const [isFinalSubmit, setIsFinalSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [sideBarItemsState, setSideBarItemsState] = useState(sideBarItems);

    useEffect(() => {
        if (!visitedMap) {
            const defaultVisitedMap = Object.keys(sideBarItemsState).reduce(
                (acc, key) => {
                    acc[key] = false;

                    return acc;
                },
                {} as Record<string, boolean>
            );

            const defaultSideBarState = Object.keys(sideBarItemsState).reduce(
                (acc, key) => {
                    sideBarItemsState[key].isDisabled = true;
                    acc[key] = sideBarItemsState[key];

                    return acc;
                },
                {} as Record<string, SideBarItem>
            );

            defaultSideBarState[defaultSection].isDisabled = false;
            setSideBarItemsState(defaultSideBarState);

            // The first section is always visited
            defaultVisitedMap[defaultSection] = true;
            setVisitedMap(defaultVisitedMap);
        }
    }, [defaultSection, setVisitedMap, sideBarItems, sideBarItemsState, visitedMap]);

    const onSectionChange = useCallback(
        (section: string) => {
            if (visitedMap) {
                if (visitedMap[section]) {
                    setSection(section);
                }
            }
        },
        [setSection, visitedMap]
    );

    const setSectionAndVisitedMap = useCallback(
        (section: string) => {
            if (visitedMap) {
                if (!visitedMap[section]) {
                    setVisitedMap(s => ({ ...s, [section]: true }));

                    setSideBarItemsState(state => ({
                        ...state,
                        [section]: { ...state[section], isDisabled: false }
                    }));
                }
            }

            setSection(section);
        },
        [setSection, visitedMap, setVisitedMap]
    );

    const handleBackButton = useMemo(() => {
        const previousSection = sideBarItemsState[section]?.previousSection;

        return previousSection
            ? () => {
                  setSectionAndVisitedMap(previousSection);
              }
            : undefined;
    }, [section, setSectionAndVisitedMap, sideBarItemsState]);

    const nextSection = sideBarItemsState[section]?.nextSection;

    // eslint-disable-next-line
    const handleNextButton = nextSection
        ? () => {
              setSectionAndVisitedMap(nextSection);
          }
        : undefined;

    const onFormSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (values: any) => {
            setIsSubmitting(true);
            await onSubmit(values, isFinalSubmit, section, handleNextButton);
            setIsSubmitting(false);
            setIsFinalSubmit(false);
        },
        [handleNextButton, isFinalSubmit, onSubmit, setIsFinalSubmit, section]
    );

    const formContent = useMemo(
        () => (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                validateOnBlur
                validationSchema={validationSchemas[section]}
            >
                <FormNavigationButtons
                    customNextText={customNextText[section]}
                    hasNext={Boolean(nextSection)}
                    isLoading={isSubmitting}
                    onBack={handleBackButton}
                    saveButtonDisabled={!canFinalSubmit}
                    saveButtonText={saveButtonText[section]}
                    setFinalSubmit={() => setIsFinalSubmit(true)}
                >
                    <FormContainer>{contentMap[section]}</FormContainer>

                    {children}
                </FormNavigationButtons>
            </Formik>
        ),
        [
            canFinalSubmit,
            children,
            contentMap,
            customNextText,
            handleBackButton,
            initialValues,
            isSubmitting,
            nextSection,
            onFormSubmit,
            saveButtonText,
            section,
            validationSchemas
        ]
    );

    return (
        <DisplayResourcePage
            fetchResource={fetchResource}
            notFoundText={notFoundText}
            onResourceFetched={onResourceFetched}
            resourceId={resourceId}
            testId={testId}
            title={title}
        >
            <SidebarOrDropdownLayout
                currentSection={section}
                onSectionChange={onSectionChange}
                sidebarItems={sideBarItemsState}
            >
                {formContent}
            </SidebarOrDropdownLayout>
        </DisplayResourcePage>
    );
};

export default MultiPartFormWithFetchedResource;

import { Field, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';

import { rateUnitMap } from '@@types/units';

import FormSectionContainer from 'components/FormSectionContainer';
import SplitContainer from 'components/SplitContainer';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormSelect from 'components/deprecating/FormSelect';
import FormBoldLabel from 'components/form/FormBoldLabel';
import FormLabel from 'components/form/FormLabel';

import useConvert from 'hooks/useConvert';
import { massRateUnits, massUnits, volumeRateUnits, volumeUnits } from 'hooks/useConvert/constants';

import useTranslation from './hooks/useTranslation';

import {
    AMOUNT_UNITS,
    DEFAULT_MASS_RATE_UNIT,
    DEFAULT_MASS_UNIT,
    DEFAULT_VOLUME_RATE_UNIT,
    DEFAULT_VOLUME_UNIT,
    RATE_UNITS
} from './constants';
import type {
    AmountUnit,
    FunctionComponent,
    MassRateUnit,
    MassUnit,
    ProductUsageReportForm,
    Props,
    RateUnit,
    VolumeRateUnit,
    VolumeUnit
} from './types';

import styles from './styles.module.scss';

const getCompatibleRateUnit = (amountUnit: AmountUnit) => {
    const amountUnitMap: Record<AmountUnit, () => RateUnit> = {
        FLUID_OUNCES: () => 'FLUID_OUNCES_PER_ACRE',
        GALLONS: () => 'GALLONS_PER_ACRE',
        KILOGRAMS: () => 'KILOGRAMS_PER_SQUARE_METER',
        LITERS: () => 'LITERS_PER_SQUARE_METER',
        OUNCES: () => 'OUNCES_PER_ACRE',
        PINTS: () => 'PINTS_PER_ACRE',
        POUNDS: () => 'POUNDS_PER_ACRE',
        QUARTS: () => 'QUARTS_PER_ACRE'
    };

    const convertFunction: () => string =
        amountUnitMap[amountUnit] || (() => DEFAULT_MASS_RATE_UNIT);

    return convertFunction();
};

const getCompatibleAmountUnit = (rateUnit: RateUnit) => rateUnitMap[rateUnit] || DEFAULT_MASS_UNIT;

const ChemicalAppliedForm: FunctionComponent<Props> = ({
    index,
    updateRateAppliedOnAmountAppliedChange,
    updateTotalVolumeApplied
}) => {
    const { AMOUNT_APPLIED, RATE, RATE_APPLIED, TOTAL_PRODUCT_USED, UNITS } = useTranslation();
    const { setFieldValue, values } = useFormikContext<ProductUsageReportForm>();
    const { chemicalsApplied, totalAreaAppliedAcres } = values;

    const { convertDryMass, convertDryMassRate, convertLiquidVolume, convertLiquidVolumeArea } =
        useConvert();

    const [previousAmountUnit, setPreviousAmountUnit] = useState<AmountUnit>(
        chemicalsApplied[index].preferredAmountUnits || DEFAULT_VOLUME_UNIT
    );
    const [previousRateUnit, setPreviousRateUnit] = useState<RateUnit>(
        chemicalsApplied[index].preferredRateUnits || DEFAULT_VOLUME_RATE_UNIT
    );

    const onAmountUnitsChange = useCallback(
        async (amountUnit: AmountUnit) => {
            if (
                (volumeUnits.includes(amountUnit as VolumeUnit) &&
                    massUnits.includes(previousAmountUnit as MassUnit)) ||
                (volumeUnits.includes(previousAmountUnit as VolumeUnit) &&
                    massUnits.includes(amountUnit as MassUnit))
            ) {
                const compatibleRateUnit = getCompatibleRateUnit(amountUnit);

                await setFieldValue(
                    `chemicalsApplied.${index}.preferredRateUnits`,
                    compatibleRateUnit
                );
                await setFieldValue(`chemicalsApplied.${index}.amountApplied`, 0);
                await setFieldValue(`chemicalsApplied.${index}.rateApplied`, 0);
            }

            if (previousAmountUnit) {
                const currentAmount = chemicalsApplied[index].amountApplied;

                if (
                    volumeUnits.includes(previousAmountUnit as VolumeUnit) &&
                    volumeUnits.includes(amountUnit as VolumeUnit) &&
                    amountUnit !== previousAmountUnit
                ) {
                    const volume = convertLiquidVolume(
                        currentAmount ?? 0.0,
                        previousAmountUnit as VolumeUnit,
                        amountUnit as VolumeUnit
                    );

                    await setFieldValue(`chemicalsApplied.${index}.amountApplied`, volume);
                } else if (
                    massUnits.includes(previousAmountUnit as MassUnit) &&
                    massUnits.includes(amountUnit as MassUnit) &&
                    amountUnit !== previousAmountUnit
                ) {
                    const mass = convertDryMass(
                        currentAmount ?? 0.0,
                        previousAmountUnit as MassUnit,
                        amountUnit as MassUnit
                    );

                    await setFieldValue(`chemicalsApplied.${index}.amountApplied`, mass);
                }
            }
            setPreviousAmountUnit(amountUnit);
        },
        [
            chemicalsApplied,
            convertDryMass,
            convertLiquidVolume,
            index,
            previousAmountUnit,
            setFieldValue
        ]
    );

    const onRateAppliedChange = useCallback(
        async (rateApplied: number) => {
            const updatedChemical = { ...chemicalsApplied[index], rateApplied };

            updateTotalVolumeApplied(updatedChemical, index, totalAreaAppliedAcres ?? 0.0);
        },
        [chemicalsApplied, index, totalAreaAppliedAcres, updateTotalVolumeApplied]
    );

    const onAmountAppliedChanged = useCallback(
        async (amountApplied: number) => {
            const updatedChemical = { ...chemicalsApplied[index], amountApplied };

            updateRateAppliedOnAmountAppliedChange(
                updatedChemical,
                index,
                totalAreaAppliedAcres ?? 0.0
            );
        },
        [chemicalsApplied, index, totalAreaAppliedAcres, updateRateAppliedOnAmountAppliedChange]
    );

    const onPreferredRateUnitsChange = useCallback(
        async (rateUnit: RateUnit) => {
            if (
                (volumeRateUnits.includes(rateUnit as VolumeRateUnit) &&
                    massRateUnits.includes(previousRateUnit as MassRateUnit)) ||
                (volumeRateUnits.includes(previousRateUnit as VolumeRateUnit) &&
                    massRateUnits.includes(rateUnit as MassRateUnit))
            ) {
                const compatibleAmountUnit = getCompatibleAmountUnit(rateUnit);

                await setFieldValue(
                    `chemicalsApplied.${index}.preferredAmountUnits`,
                    compatibleAmountUnit
                );
                await setFieldValue(`chemicalsApplied.${index}.amountApplied`, 0);
                await setFieldValue(`chemicalsApplied.${index}.rateApplied`, 0);

                return;
            }

            let currentRate = chemicalsApplied[index].rateApplied ?? 0.0;

            if (
                volumeRateUnits.includes(rateUnit as VolumeRateUnit) &&
                volumeRateUnits.includes(previousRateUnit as VolumeRateUnit) &&
                rateUnit !== previousRateUnit
            ) {
                currentRate = convertLiquidVolumeArea(
                    currentRate,
                    previousRateUnit as VolumeRateUnit,
                    rateUnit as VolumeRateUnit
                );
            } else if (
                massRateUnits.includes(rateUnit as MassRateUnit) &&
                massRateUnits.includes(previousRateUnit as MassRateUnit) &&
                rateUnit !== previousRateUnit
            ) {
                currentRate = convertDryMassRate(
                    currentRate,
                    previousRateUnit as MassRateUnit,
                    rateUnit as MassRateUnit
                );
            }
            setPreviousRateUnit(rateUnit);
            await setFieldValue(`chemicalsApplied.${index}.rateApplied`, currentRate);
        },
        [
            convertDryMassRate,
            chemicalsApplied,
            convertLiquidVolumeArea,
            index,
            previousRateUnit,
            setFieldValue
        ]
    );

    return (
        <FormSectionContainer>
            <FormBoldLabel
                className={styles.chemicalNameLabel}
                label={chemicalsApplied[index].label}
            >
                <Field
                    name={`chemicalsApplied.${index}.chemicalId`}
                    type="hidden"
                    value={chemicalsApplied[index].chemicalId}
                />

                <FormLabel className={styles.formLabel} label={TOTAL_PRODUCT_USED}>
                    <SplitContainer>
                        <Field
                            component={FormNumberInput}
                            customOnChange={onAmountAppliedChanged}
                            isEditable={true}
                            name={`chemicalsApplied.${index}.amountApplied`}
                            placeholder={AMOUNT_APPLIED}
                            precision={2}
                            rightSection={<></>}
                        />

                        <Field
                            component={FormSelect}
                            data={AMOUNT_UNITS}
                            isEditable={true}
                            name={`chemicalsApplied.${index}.preferredAmountUnits`}
                            onChange={onAmountUnitsChange}
                            placeholder={UNITS}
                        />
                    </SplitContainer>
                </FormLabel>

                <FormLabel className={styles.formLabel} label={RATE}>
                    <SplitContainer>
                        <Field
                            precision={
                                values.chemicalsApplied[index].preferredRateUnits ===
                                'LITERS_PER_SQUARE_METER'
                                    ? 5
                                    : 2
                            }
                            component={FormNumberInput}
                            customOnChange={onRateAppliedChange}
                            isEditable={true}
                            name={`chemicalsApplied.${index}.rateApplied`}
                            placeholder={RATE_APPLIED}
                            rightSection={<></>}
                        />

                        <Field
                            component={FormSelect}
                            data={RATE_UNITS}
                            isEditable={true}
                            name={`chemicalsApplied.${index}.preferredRateUnits`}
                            onChange={onPreferredRateUnitsChange}
                            placeholder={UNITS}
                        />
                    </SplitContainer>
                </FormLabel>
            </FormBoldLabel>
        </FormSectionContainer>
    );
};

export default ChemicalAppliedForm;

import { SectionTitle } from '@rantizo-software/rantizo-ui';

import ItemCardWithBorder from 'components/ItemCardWithBorder';
import WithPencilIcon from 'components/WithPencilIcon';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const EditableSection: FunctionComponent<Props> = ({
    children,
    className = '',
    editable = false,
    onEdit,
    testId = TEST_ID,
    title = ''
}) => (
    <div className={`${className} ${styles.editableSection}`} data-testid={testId}>
        <WithPencilIcon active={editable} onEdit={editable ? onEdit : undefined}>
            <SectionTitle text={title} />
        </WithPencilIcon>

        <ItemCardWithBorder onClick={editable ? onEdit : undefined}>{children}</ItemCardWithBorder>
    </div>
);

export default EditableSection;

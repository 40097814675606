import { Select } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FormMinimalSelect: FunctionComponent<Props> = ({
    className,
    data,
    defaultValue,
    disabled,
    field,
    form,
    isEditable,
    label,
    placeholder,
    selectOnBlur,
    testId = TEST_ID
}) => {
    const { name } = field;

    const onBlur = async () => {
        await form.setFieldTouched(name);
    };

    const onChange = async (value: string) => {
        await form.setFieldValue(name, value);
    };

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';

    return (
        <Select
            className={`${className} ${styles.formSelect} ${viewOnlyClass}`}
            data={data}
            data-testid={testId}
            defaultValue={defaultValue}
            disabled={!isEditable || disabled}
            label={label}
            placeholder={placeholder}
            rightSection={<></>}
            rightSectionWidth={'0px'}
            selectOnBlur={selectOnBlur}
            {...field}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export default FormMinimalSelect;

import { ChevronDownIcon } from '@rantizo-software/rantizo-ui';

import SplitButton from 'components/SplitButton';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SplitButtonWithArrow: FunctionComponent<Props> = ({
    className = '',
    feature,
    isDisabled = false,
    isLoading = false,
    onClick,
    onSplitClick,
    testId = TEST_ID,
    text
}) => (
    <SplitButton
        className={className}
        feature={feature}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        onSplitClick={onSplitClick}
        testId={testId}
        text={text}
    >
        <ChevronDownIcon className={styles.arrow} />
    </SplitButton>
);

export default SplitButtonWithArrow;

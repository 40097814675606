import { getIn } from 'formik';
import { merge } from 'lodash';

import { Loader, MultiSelect, rem } from '@mantine/core';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

// Copied from form select. Should find a way to have better
// sharing between these classes but there's not enough time and react doesn't allow subclassing
/**
 * @deprecated Use `src/components/form/MultiSelectDropdown` instead.
 */
const FormMultiSelect: FunctionComponent<Props> = ({
    className,
    data,
    disabled,
    field,
    form,
    isEditable = true,
    isLoading = false,
    label,
    placeholder,
    required,
    rightSection: inputRightSection,
    rightSectionWidth,
    searchable,
    selectOnBlur,
    testId = TEST_ID,
    type
}) => {
    const { name } = field;
    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    let rightSection;

    if (isLoading) {
        rightSection = <Loader className={styles.selectLoader} />;
    } else if (inputRightSection) {
        rightSection = inputRightSection;
    } else {
        rightSection = undefined;
    }

    const onBlur = async () => {
        const touched = merge(form.touched, { [name]: true });

        await form.setTouched(touched);
    };

    const onChange = async (value: string[]) => {
        await form.setFieldValue(name, value);
    };

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const placeholderText = isEditable ? placeholder : '';

    return (
        <MultiSelect
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : '0'
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formMultiSelect} ${viewOnlyClass}`}
            data={data}
            data-testid={testId}
            disabled={disabled}
            error={visibleError ? error : undefined}
            label={label}
            placeholder={placeholderText}
            readOnly={isLoading}
            required={required}
            rightSection={rightSection}
            rightSectionWidth={rightSectionWidth}
            searchable={searchable}
            selectOnBlur={selectOnBlur}
            type={type}
            {...field}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export default FormMultiSelect;

import { useCallback, useState } from 'react';

import HighlightTab from 'components/HighlightTab';
import HighlightTabs from 'components/HighlightTabs';

import useTranslation from './hooks/useTranslation';

import { INITIAL_TAB, TEST_ID } from './constants';
import type { FunctionComponent, ProductUsageReportTab, Props } from './types';

import styles from './styles.module.scss';

// TODO refactor this so that we have a genic tabs that we can just pass a map of strings and an on change function too
// Also get it so that we don't have the width of the highlight hardcoded and it matches the size of the tab. But,
// given our very tight deadline this'll follow the same pattern as the WorkOrderTabs component for now.
const ProductUsageReportTabs: FunctionComponent<Props> = ({
    className = '',
    onChange,
    testId = TEST_ID
}) => {
    const [tab, setTab] = useState(INITIAL_TAB);

    const { DRAFTS, REPORTS } = useTranslation();

    const handleTabClick = useCallback(
        (value: ProductUsageReportTab) => {
            setTab(value);
            onChange?.(value);
        },
        [onChange]
    );

    return (
        <HighlightTabs className={className} selectedClass={styles[tab] || ''} testId={testId}>
            <HighlightTab
                isActive={tab === 'reports'}
                onClick={() => handleTabClick('reports')}
                text={REPORTS}
            />

            <HighlightTab
                isActive={tab === 'drafts'}
                onClick={() => handleTabClick('drafts')}
                text={DRAFTS}
            />
        </HighlightTabs>
    );
};

export default ProductUsageReportTabs;

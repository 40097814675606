import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Time: FunctionComponent<Props> = ({ className = '', testId = TEST_ID, text = '' }) => (
    <span className={`${className} ${styles.time}`} data-testid={testId}>
        {text}
    </span>
);

export default Time;

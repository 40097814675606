import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@rantizo-software/rantizo-ui';

import PageWithSidebar from 'components/PageWithSidebar';

import usePageRoutes from 'hooks/usePageRoutes';

import AsAppliedMapPage from 'pages/AsAppliedMapPage';

import useSidebarItems from './hooks/useSidebarItems';
import useTranslation from './hooks/useTranslation';

import ApplicationDetailsForm from './components/ApplicationDetailsForm';
import FlightLogForm from './components/FlightLogForm';
import { TEST_ID } from './constants';
import type {
    AsAppliedMapJob,
    Flight,
    FunctionComponent,
    GenerateAsAppliedMapSection,
    MapSummary,
    MasterForm,
    Props
} from './types';

const GenerateAsAppliedMapPage: FunctionComponent<Props> = ({
    onLayoutSwitch,
    testId = TEST_ID
}) => {
    const [productUsageReportId, setProductUsageReportId] = useState<string | null>(null);

    const { TITLE, VIEW_AS_APPLIED_MAP, VIEW_COVERED_MAP } = useTranslation();

    const masterForm = useRef<MasterForm>({
        applicationDetails: {},
        asAppliedMap: {},
        asCovered: {},
        flightLog: {}
    });
    const [currentSection, setCurrentSection] =
        useState<GenerateAsAppliedMapSection>('applicationDetails');

    const [flights, setFlights] = useState<Flight[]>([]);
    const [alternateFlights, setAlternateFlights] = useState<Flight[]>([]);

    const [aamMap, setAamMap] = useState<MapSummary>();
    const [coverageMap, setCoverageMap] = useState<MapSummary>();
    const [isDryCoverage, setIsDryCoverage] = useState<boolean>(false);

    const [jobId, setJobId] = useState<string>();

    const { sidebarItems } = useSidebarItems({
        aamMap,
        coverageMap,
        flights
    });

    const onMapComplete = useCallback(
        (job: AsAppliedMapJob) => {
            const completedMaps = job.maps as MapSummary[];
            const asAppliedMap = completedMaps.find(map => map.type === 'AAM');
            const coverageMap = completedMaps.find(map => map.type === 'COVERAGE');
            const dryCoverageMap = completedMaps.find(map => map.type === 'DRY_COVERAGE');

            if (job.id) {
                setJobId(job.id);
            }

            if (asAppliedMap) {
                setAamMap(asAppliedMap);
            }

            if (coverageMap) {
                setCoverageMap(coverageMap);
            }

            if (dryCoverageMap) {
                setIsDryCoverage(true);
                setCoverageMap(dryCoverageMap);
            }

            if (asAppliedMap) {
                setCurrentSection('asAppliedMap');
            } else {
                setCurrentSection('asCovered');
            }
        },
        [setAamMap, setCoverageMap]
    );

    const onFlightsFound = useCallback((flights: Flight[], alternateFlights: Flight[]) => {
        setFlights(flights);
        setAlternateFlights(alternateFlights);
    }, []);

    const handleBack = useCallback(() => {
        const previousSection = sidebarItems[currentSection]
            .previousSection as GenerateAsAppliedMapSection;

        if (previousSection) {
            setCurrentSection(previousSection);
        }
    }, [currentSection, sidebarItems]);

    const handleSubmit = useCallback(() => {
        Object.keys(masterForm.current).map(section => {
            const formSection = section as GenerateAsAppliedMapSection;

            if (!('handleForm' in masterForm.current[formSection])) {
                return;
            }
        });
    }, []);

    const handleNext = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ form }: { form: any }) => {
            masterForm.current[currentSection] = form;

            if (
                currentSection === 'applicationDetails' &&
                form?.form?.current?.productUsageReportId
            ) {
                setProductUsageReportId(form?.form?.current?.productUsageReportId);
            }
            const nextSection = sidebarItems[currentSection]
                .nextSection as GenerateAsAppliedMapSection;

            if (nextSection) {
                setCurrentSection(nextSection);
            } else {
                handleSubmit();
            }
        },
        [currentSection, handleSubmit, masterForm, sidebarItems]
    );

    const handleOnLoad = useCallback(() => {
        onLayoutSwitch?.('wide');
    }, [onLayoutSwitch]);

    const handleOnUnload = useCallback(() => {
        onLayoutSwitch?.('');
    }, [onLayoutSwitch]);

    const contentMap = useMemo(
        () => ({
            applicationDetails: (
                <ApplicationDetailsForm
                    onBack={handleBack}
                    onFlightsFound={onFlightsFound}
                    onNext={handleNext}
                />
            ),
            asAppliedMap:
                aamMap && jobId ? (
                    <AsAppliedMapPage
                        aamId={aamMap.id}
                        jobId={jobId}
                        key={Math.random()}
                        mapType="AAM"
                        onLoad={handleOnLoad}
                        onUnload={handleOnUnload}
                        productUsageReportId={productUsageReportId ?? undefined}
                    >
                        {coverageMap && (
                            <PrimaryButton
                                onClick={() => {
                                    setCurrentSection('asCovered');
                                }}
                                text={VIEW_COVERED_MAP}
                            />
                        )}
                    </AsAppliedMapPage>
                ) : null,
            asCovered:
                coverageMap && jobId ? (
                    <AsAppliedMapPage
                        aamId={coverageMap.id}
                        jobId={jobId}
                        key={Math.random()}
                        mapType={isDryCoverage ? 'DRY_COVERAGE' : 'COVERAGE'}
                        onLoad={handleOnLoad}
                        onUnload={handleOnUnload}
                        productUsageReportId={productUsageReportId ?? undefined}
                    >
                        {aamMap && (
                            <PrimaryButton
                                onClick={() => {
                                    setCurrentSection('asAppliedMap');
                                }}
                                text={VIEW_AS_APPLIED_MAP}
                            />
                        )}
                    </AsAppliedMapPage>
                ) : null,
            flightLog: (
                <FlightLogForm
                    alternateFlights={alternateFlights}
                    flights={flights}
                    onBack={handleBack}
                    onLoad={handleOnLoad}
                    onMapComplete={onMapComplete}
                    onNext={handleNext}
                    onUnload={handleOnUnload}
                    productUsageReportId={productUsageReportId}
                />
            )
        }),
        [
            VIEW_AS_APPLIED_MAP,
            VIEW_COVERED_MAP,
            aamMap,
            alternateFlights,
            coverageMap,
            flights,
            handleBack,
            handleNext,
            handleOnLoad,
            handleOnUnload,
            isDryCoverage,
            jobId,
            onFlightsFound,
            onMapComplete,
            productUsageReportId
        ]
    );

    const navigate = useNavigate();
    const { mapsPage } = usePageRoutes();

    return (
        <PageWithSidebar
            state={{
                setSideBarItem: (item: string) => {
                    setCurrentSection(item as GenerateAsAppliedMapSection);
                },
                sideBarItem: currentSection
            }}
            onExit={() => navigate(mapsPage)}
            sidebarItems={sidebarItems}
            testId={testId}
            title={TITLE}
        >
            {contentMap[currentSection]}
        </PageWithSidebar>
    );
};

export default GenerateAsAppliedMapPage;

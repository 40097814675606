import { forwardRef } from 'react';

import { Button } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const IconButton = forwardRef<HTMLButtonElement, Props>(
    ({ children, className, isDisabled, onClick, testId = TEST_ID }, ref) => (
        <Button
            className={`${className} ${styles.iconButton}`}
            isDisabled={isDisabled}
            onClick={onClick}
            ref={ref}
            testId={testId}
        >
            {children}
        </Button>
    )
);

IconButton.displayName = 'IconButton';

export default IconButton;

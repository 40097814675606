import { forwardRef, useCallback, useState } from 'react';

import WithErrorMessage from 'components/WithErrorMessage';
import Dropdown from 'components/form/Dropdown';

import { TEST_ID } from './constants';
import type { Props } from './types';

const generateTimezones = () => [
    {
        label: 'CT',
        type: '',
        value: 'America/Chicago'
    },
    {
        label: 'ET',
        type: '',
        value: 'America/New_York'
    },
    {
        label: 'MT',
        type: '',
        value: 'America/Denver'
    },
    {
        label: 'PT',
        type: '',
        value: 'America/Los_Angeles'
    }
];

const TimezoneDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            selected,
            testId = TEST_ID,
            value
        },
        ref
    ) => {
        const [errorMessage, setErrorMessage] = useState('');

        const timezones = generateTimezones();

        const handleError = useCallback(
            (_dropdownValue: unknown, error: string) => {
                onError?.(error);
                setErrorMessage(error);
            },
            [onError]
        );

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasError}
                    isRequired={isRequired}
                    items={timezones}
                    label={label}
                    onChange={onChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    ref={ref}
                    selected={selected}
                    testId={testId}
                    value={value}
                />
            </WithErrorMessage>
        );
    }
);

TimezoneDropdown.displayName = 'TimezoneDropdown';

export default TimezoneDropdown;

import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { USERS_API } from './constants';
import type { FetchUser, UserApiResponse } from './types';

const useUsers = () => {
    const { authenticatedGet } = useFetch();

    const fetchTeamMembers = useCallback(
        async (
            pageToken?: string,
            pageSize = 10
        ): Promise<{ data: UserApiResponse; error: unknown }> => {
            const pageTokenQuery = pageToken ? `&pageToken=${pageToken}` : '';

            const uri = `${USERS_API}?size=${pageSize}${pageTokenQuery}`;

            const response = await authenticatedGet(uri, null);

            return response;
        },
        [authenticatedGet]
    );

    const fetchUser: FetchUser = useCallback(
        async ({ userId }) => {
            const usersUrl = `${USERS_API}/${userId}`;

            return await authenticatedGet(usersUrl, null);
        },
        [authenticatedGet]
    );

    return {
        fetchTeamMembers,
        fetchUser
    };
};

export default useUsers;

import { useMemo } from 'react';

import { round } from '@@utils/math';

import ProductUsageReportContentContainer from 'components/ProductUsageReport/ProductUsageReportContentContainer';
import TopBottomContainer from 'components/TopBottomContainer';
import WorkOrderLabelValuePair from 'components/WorkOrderLabelValuePair';
import WorkOrderReadOnlyLabel from 'components/WorkOrderReadOnlyLabel';

import useConvert from 'hooks/useConvert';

import useTranslation from './hooks/useTranslation';

import ChemicalAppliedView from './ChemicalAppliedView';
import DryChemicalAppliedView from './DryChemicalAppliedView';
import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ProductDetails: FunctionComponent<Props> = ({ productUsageReport, testId = TEST_ID }) => {
    const { AC, CARRIER, TOTAL_ACRES_APPLIED, TOTAL_PRODUCT_USED } = useTranslation();

    const { carrier, chemicalsApplied, dryChemicalsApplied, totalAreaAppliedSquareMeters } =
        productUsageReport;

    const { convertAreaToAcres } = useConvert();

    const chemicalViews = useMemo(
        () =>
            chemicalsApplied.map(product => (
                <ChemicalAppliedView chemicalApplied={product} key={product.chemical.id} />
            )),
        [chemicalsApplied]
    );

    const dryChemicalViews = useMemo(
        () =>
            dryChemicalsApplied.map(product => (
                <DryChemicalAppliedView chemicalApplied={product} key={product.chemical.id} />
            )),
        [dryChemicalsApplied]
    );

    return (
        <ProductUsageReportContentContainer testId={testId}>
            <WorkOrderLabelValuePair
                label={TOTAL_ACRES_APPLIED}
                value={`${round(convertAreaToAcres(totalAreaAppliedSquareMeters, 'm2'), 2)} ${AC}`}
            />

            <TopBottomContainer>
                <WorkOrderReadOnlyLabel text={`${TOTAL_PRODUCT_USED}`} />

                {chemicalViews}

                {dryChemicalViews}
            </TopBottomContainer>

            <WorkOrderLabelValuePair label={CARRIER} value={carrier} />
        </ProductUsageReportContentContainer>
    );
};

export default ProductDetails;

import { useCallback } from 'react';

import { HorizontalContainer } from '@rantizo-software/rantizo-ui';

import Link from 'components/Link';
import OnboardingBodyText from 'components/OnboardingBodyText';
import OnboardingLogout from 'components/OnboardingLogout';
import OnboardingPageTitle from 'components/OnboardingPageTitle';

import useResendEmail from './hooks/useResendEmail';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerifyEmailPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const resendEmail = useResendEmail();
    const { ACTIVATION_LINK_SENT, NO_LINK, SEND_AGAIN, TITLE } = useTranslation();

    const resendVerificationEmail = useCallback(async () => {
        await resendEmail();
    }, [resendEmail]);

    return (
        <div className={styles.verifyEmailPage} data-testid={testId}>
            <OnboardingPageTitle text={TITLE} />

            <OnboardingBodyText text={ACTIVATION_LINK_SENT} />

            <HorizontalContainer className={styles.horizontalContainer}>
                <OnboardingBodyText text={NO_LINK} />

                <Link
                    className={styles.resendLink}
                    onClick={resendVerificationEmail}
                    text={SEND_AGAIN}
                />
            </HorizontalContainer>

            <OnboardingLogout emailVerified={false} />
        </div>
    );
};

export default VerifyEmailPage;

import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { FEATURE, TEST_ID } from './constants';
import type { FunctionComponent, Items, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportMenu: FunctionComponent<Props> = ({
    className = '',
    onDelete,
    onEdit,
    onView,
    testId = TEST_ID
}) => {
    const { DELETE, EDIT, VIEW } = useTranslation();

    const items: Items = [];

    if (onView) {
        items.push({
            onClick: onView,
            text: VIEW
        });
    }

    if (onEdit) {
        items.push({
            feature: FEATURE,
            onClick: onEdit,
            text: EDIT
        });
    }

    if (onDelete) {
        items.push({
            isDelete: true,
            onClick: onDelete,
            text: DELETE
        });
    }

    return (
        <MenuButton
            className={`${className} ${styles.productUsageReportMenu}`}
            items={items}
            testId={testId}
        />
    );
};

ProductUsageReportMenu.displayName = 'ProductUsageReportMenu';

export default ProductUsageReportMenu;

import { TEST_ID } from '../constants';

import Link from 'components/Link';
import WithFeatureAccess from 'components/WithFeatureAccess';

import useFeatureAccess from 'hooks/useFeatureAccess';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OptionsItemWithLink: FunctionComponent<Props> = ({
    className = '',
    feature,

    isDisabled = false,
    linkText = '',
    linkTo = '',
    onClick,
    testId = TEST_ID,
    text
}) => {
    const features = useFeatureAccess();

    const isFeatureAccessible = feature ? features[feature] || false : true;

    const disabledClass = isDisabled ? styles.disabled : '';

    return (
        <span
            className={`${className} ${styles.optionsItem} ${disabledClass}`}
            data-testid={testId}
            onClick={isFeatureAccessible && !isDisabled ? onClick : undefined}
        >
            {feature && <WithFeatureAccess feature={feature}>{text}</WithFeatureAccess>}

            {!feature && text}

            <Link text={linkText} to={linkTo} />
        </span>
    );
};

export default OptionsItemWithLink;

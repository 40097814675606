import { useMemo } from 'react';

import { DeleteButton } from '@rantizo-software/rantizo-ui';

import { useDevice } from 'components/DeviceProvider';
import TrashIconButton from 'components/TrashIconButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { BulkDeleteButtonMap, Props } from './types';

const FlightLogBulkDeleteButton = ({ className, isDisabled, onClick, testId = TEST_ID }: Props) => {
    const { DELETE_BUTTON } = useTranslation();
    const { deviceType } = useDevice();
    const bulkDeleteButtonMap: BulkDeleteButtonMap = useMemo(
        () => ({
            desktop: (
                <DeleteButton
                    className={className}
                    isDisabled={isDisabled}
                    onClick={onClick}
                    testId={testId}
                    text={DELETE_BUTTON}
                />
            ),
            mobile: (
                <TrashIconButton
                    className={className}
                    isDisabled={isDisabled}
                    onClick={onClick}
                    testId={testId}
                />
            )
        }),
        [className, DELETE_BUTTON, isDisabled, onClick, testId]
    );

    return bulkDeleteButtonMap[deviceType];
};

export default FlightLogBulkDeleteButton;

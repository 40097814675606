import { Link } from 'react-router-dom';

import image from './image.svg';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Logo: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => (
    <Link className={className} data-testid={testId} to="/">
        <img className={styles.logo} src={image} />
    </Link>
);

export default Logo;

import { type FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { useDevice } from 'components/DeviceProvider';
import SiteFooter from 'components/SiteFooter';
import SiteHeader from 'components/SiteHeader';
import Toast from 'components/Toast';
import WideContentContainer from 'components/WideContentContainer';

const WideLayout: FunctionComponent = () => {
    const device = useDevice();

    return (
        <>
            <SiteHeader />

            <Toast />

            <WideContentContainer>
                <Outlet />
            </WideContentContainer>

            {device.deviceType === 'desktop' && <SiteFooter />}
        </>
    );
};

export default WideLayout;

import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import { Button, CalendarIcon, DropdownMenu } from '@rantizo-software/rantizo-ui';

import ArrowLeftIconButton from 'components/ArrowLeftButton';
import ArrowRightIconButton from 'components/ArrowRightButton';
import InputWithIcons from 'components/InputWithIcons';
import InputWithLabel from 'components/InputWithLabel';
import LargeSemiboldText from 'components/LargeSemiboldText';

import useTranslation from './hooks/useTranslation';

import MonthsGrid from './components/MonthsGrid';
import YearSelectorContainer from './components/YearSelectorContainer';
import { MAX_YEAR, MIN_YEAR, MONTH_LABELS, MONTH_OPTIONS, TEST_ID } from './constants';
import type { FocusEvent, MonthOption, MutableRefObject, Props } from './types';

import styles from './styles.module.scss';

const MonthPicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError = false,
        isDisabled = false,
        isEditable = true,
        isRequired = false,
        maxYear = MAX_YEAR,
        minYear = MIN_YEAR,
        onChange,
        placeholder,
        testId = TEST_ID,
        value
    } = props;

    const { PLACEHOLDER } = useTranslation();

    const [selectedDate, setSelectedDate] = useState<Date>(value);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const inputRef = ref as MutableRefObject<HTMLInputElement>;
    const dropdownRef = useRef<HTMLInputElement>(null);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleBlur = useCallback(
        (event: FocusEvent<unknown>) => {
            if (
                !event.relatedTarget?.classList.contains(styles.dropdownMenu) &&
                event.relatedTarget?.closest(`[class~=${styles.dropdownMenu}]`) == null
            ) {
                handleClose();
            }
        },
        [handleClose]
    );

    const focusOnInput = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleMonthSelection = useCallback(
        (month: MonthOption) => {
            setSelectedDate(previouslySelectedDate => {
                const newDate = new Date(previouslySelectedDate.getFullYear(), month.value, 1);

                onChange?.(newDate);

                handleClose();

                return newDate;
            });

            focusOnInput();
        },
        [focusOnInput, handleClose, onChange]
    );

    const decrementYear = useCallback(() => {
        setSelectedDate(previouslySelectedDate => {
            if (previouslySelectedDate.getFullYear() - 1 < minYear) {
                return previouslySelectedDate;
            } else {
                const newDate = new Date(
                    previouslySelectedDate.getFullYear() - 1,
                    previouslySelectedDate.getMonth(),
                    1
                );

                onChange?.(newDate);
                focusOnInput();

                return newDate;
            }
        });
    }, [focusOnInput, minYear, onChange]);

    const incrementYear = useCallback(() => {
        setSelectedDate(previouslySelectedDate => {
            if (previouslySelectedDate.getFullYear() + 1 > maxYear) {
                return previouslySelectedDate;
            } else {
                const newDate = new Date(
                    previouslySelectedDate.getFullYear() + 1,
                    previouslySelectedDate.getMonth(),
                    1
                );

                onChange?.(newDate);
                focusOnInput();

                return newDate;
            }
        });
    }, [focusOnInput, maxYear, onChange]);

    const monthOptions = useMemo(
        () =>
            MONTH_OPTIONS.map(month => {
                const selectedClassName =
                    selectedDate.getMonth() === month.value ? styles.selectedMonth : '';

                return (
                    <Button
                        className={`${styles.month} ${selectedClassName}`}
                        key={month.value}
                        onClick={() => handleMonthSelection(month)}
                        testId={`${testId}-${month.label}`}
                    >
                        <LargeSemiboldText key={month.value} text={month.label} />
                    </Button>
                );
            }),
        [handleMonthSelection, selectedDate, testId]
    );

    const viewOnlyClassName = !isEditable ? styles.viewOnlyDropDown : '';

    const selectedMonthLabel = useMemo(() => MONTH_LABELS[selectedDate.getMonth()], [selectedDate]);
    const selectedYear = useMemo(() => selectedDate.getFullYear(), [selectedDate]);

    const inputValue = useMemo(
        () => `${selectedMonthLabel} ${selectedYear}` as string,
        [selectedMonthLabel, selectedYear]
    );

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdown} ${viewOnlyClassName}`}
            hasError={hasError}
            isDisabled={isDisabled}
            isEditable={isEditable}
            testId={testId}
            text={''}
        >
            <InputWithIcons
                className={styles.input}
                hasError={hasError}
                isDisabled={!isEditable || isDisabled}
                isEditable={isEditable}
                isReadOnly={true}
                isRequired={isRequired}
                onBlur={handleBlur}
                onClick={handleOpen}
                placeholder={placeholder || PLACEHOLDER}
                ref={inputRef}
                value={inputValue}
            >
                <CalendarIcon className={isEditable ? '' : styles.noIcon} />
            </InputWithIcons>

            {isOpen && (
                <DropdownMenu
                    className={styles.dropdownMenu}
                    inputRef={inputRef}
                    onClose={handleClose}
                    ref={dropdownRef}
                >
                    <YearSelectorContainer>
                        <ArrowLeftIconButton
                            isDisabled={selectedYear <= minYear}
                            onClick={decrementYear}
                        />

                        <LargeSemiboldText text={selectedYear.toString()} />

                        <ArrowRightIconButton
                            isDisabled={selectedYear >= maxYear}
                            onClick={incrementYear}
                        />
                    </YearSelectorContainer>

                    <MonthsGrid>{monthOptions}</MonthsGrid>
                </DropdownMenu>
            )}
        </InputWithLabel>
    );
});

MonthPicker.displayName = 'MonthPicker';

export default MonthPicker;

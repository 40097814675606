import type { ProductUsageReportForm, ProductUsageReportOptions } from './types';

export const defaultProductUsageReport: ProductUsageReportForm = {
    applicationDate: new Date(),
    applicationSites: [],
    carrier: 'Water',
    chemicalsApplied: [],
    droneId: null,
    endTime: '',
    endTimeTimeZone: '',
    noWind: false,
    notes: '',
    startTime: '',
    startTimeTimeZone: '',
    temperature: null,
    temperatureUnit: '\u00b0F',
    totalAreaAppliedAcres: null,
    windDirection: '',
    windGustSpeed: null,
    windGustSpeedUnit: 'MPH',
    windSpeed: null,
    windSpeedUnit: 'MPH'
};

export const defaultProductUsageReportOptions: ProductUsageReportOptions = {
    applicationSites: [],
    applicatorName: '',
    chemicals: [],
    drones: [],
    proposedAcres: null,
    workOrderId: '',
    workOrderKey: null
};

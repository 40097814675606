import { useCallback, useState } from 'react';

import type { FlightLogListTab } from './types';

const useTabs = () => {
    const [currentTab, setCurrentTab] = useState<FlightLogListTab>('flights');
    const handleTabChange = useCallback((tab: FlightLogListTab) => setCurrentTab(tab), []);

    return {
        currentTab,
        handleTabChange
    };
};

export default useTabs;

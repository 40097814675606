import { useCallback, useMemo } from 'react';

import SidebarContainer from 'components/SidebarContainerV2';
import SidebarItem from 'components/SidebarItemV2';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props, ReactNode } from './types';

const Sidebar: FunctionComponent<Props> = ({
    currentSection,
    onSectionChange,
    sideBarItems,
    testId = TEST_ID
}) => {
    const handleSectionClick = useCallback(
        (section: string) => () => {
            onSectionChange(section);
        },
        [onSectionChange]
    );

    const sideBarElements = useMemo(
        () =>
            Object.keys(sideBarItems).reduce(
                (accumulator, sidebarItemKey) => {
                    const sidebarItem = sideBarItems[sidebarItemKey];

                    const { index = 0, isDisabled, isInvalid, title } = sidebarItem;

                    accumulator.splice(
                        index,
                        1,
                        <SidebarItem
                            isActive={currentSection === sidebarItemKey}
                            isDisabled={isDisabled}
                            isInvalid={isInvalid}
                            key={sidebarItemKey}
                            onClick={handleSectionClick(sidebarItemKey)}
                            text={title}
                        />
                    );

                    return accumulator;
                },
                new Array(Object.keys(sideBarItems).length) as ReactNode[]
            ),
        [currentSection, sideBarItems, handleSectionClick]
    );

    return <SidebarContainer testId={testId}>{sideBarElements}</SidebarContainer>;
};

export default Sidebar;

import { ContentText } from '@rantizo-software/rantizo-ui';

import ItemCard from 'components/ItemCard';
import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Items, Props } from './types';

import styles from './styles.module.scss';

const BaseEquipmentCard: FunctionComponent<Props> = ({
    children,
    className = '',
    equipment,
    onDelete,
    onEdit,
    testId = TEST_ID
}) => {
    const { DELETE, DRONE_NAME, EDIT, MAKE, MODEL, SERIAL_NUMBER } = useTranslation();
    const { make, model, nickname, serialNumber } = equipment;

    const items: Items = [
        {
            onClick: onEdit,
            text: EDIT
        },
        {
            isDelete: true,
            onClick: onDelete,
            text: DELETE
        }
    ];

    return (
        <ItemCard className={className} testId={equipment?.nickname ? equipment.nickname : testId}>
            <div className={styles.withEllipses}>
                <ContentText text={`${DRONE_NAME}: ${nickname}`} />

                <MenuButton items={items} />
            </div>

            <ContentText text={`${MAKE}: ${make}`} />

            <ContentText text={`${MODEL}: ${model}`} />

            <ContentText text={`${SERIAL_NUMBER}: ${serialNumber}`} />

            {children}
        </ItemCard>
    );
};

export default BaseEquipmentCard;

import { useRecoilValue, useSetRecoilState } from 'recoil';

import store from 'app/store';

import type { UseRecoilHook } from './types';

const useRecoil: UseRecoilHook = ({ namespace }) => {
    const useStoreValue = (type: string) => {
        const atom = store[namespace].atoms[`${type}Atom`];

        return useRecoilValue(atom);
    };

    const useSetState = (type: string) => {
        const atom = store[namespace].atoms[`${type}Atom`];

        return useSetRecoilState(atom);
    };

    return {
        getValue: useStoreValue,

        setValue: useSetState,

        store
    };
};

export default useRecoil;

import { useCallback, useEffect } from 'react';

import OptionsItem from 'components/OptionsButton/OptionsItem';
import OptionsItemWithLink from 'components/OptionsButton/OptionsItemWithLink';

import useFieldView from 'hooks/useFieldView';
import useShapeFile from 'hooks/useShapeFile';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { CONNECTIONS_PATH, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ShareToFieldView: FunctionComponent<Props> = ({ aam, setIsLoading, testId = TEST_ID }) => {
    const { FIELD_VIEW, MANAGE_CONNECTIONS } = useTranslation();
    const fetchShapeFile = useData();

    const handleShapeFile = useCallback(
        async ({ shapeFile }: { shapeFile: string }) => {
            await fetchShapeFile({ shapeFile });
        },
        [fetchShapeFile]
    );

    const { initiateConnection, isLoading } = useShapeFile({ aamId: aam.id, handleShapeFile });

    useEffect(() => setIsLoading(isLoading), [isLoading, setIsLoading]);

    const { getFieldViewConnectionStatus, isFieldViewConnected } = useFieldView();

    useEffect(() => {
        getFieldViewConnectionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isFieldViewConnected ? (
        <OptionsItem onClick={initiateConnection} testId={testId} text={FIELD_VIEW} />
    ) : (
        <OptionsItemWithLink
            isDisabled={true}
            linkText={MANAGE_CONNECTIONS}
            linkTo={CONNECTIONS_PATH}
            testId={testId}
            text={FIELD_VIEW}
        />
    );
};

export default ShareToFieldView;

import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { KML_IMPORT_ENDPOINT, KML_UPLOAD_ENDPOINT } from './constants';
import type { Flight, KmlImportRequest, KmlUploadRequest, KmlUploadResponse } from './types';

const useKmlUpload = () => {
    const { authenticatedPost } = useFetch();

    const getUploadUrl = useCallback(
        async (request: KmlUploadRequest): Promise<KmlUploadResponse> => {
            const payload = JSON.stringify(request);
            const { data, error } = await authenticatedPost(KML_UPLOAD_ENDPOINT, payload);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as KmlUploadResponse;
        },
        [authenticatedPost]
    );

    const importKmlFlight = useCallback(
        async (request: KmlImportRequest): Promise<Flight> => {
            const payload = JSON.stringify(request);
            const { data, error } = await authenticatedPost(KML_IMPORT_ENDPOINT, payload);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as Flight;
        },
        [authenticatedPost]
    );

    const validateKmlFile = async (kmlFile: File) => {
        const parser = new DOMParser();
        const text = await kmlFile.text();

        const parsedFile = parser.parseFromString(text, 'application/xml');

        const modeSelection = parsedFile.querySelector('Data[name="Mode Selection"]');

        if (!modeSelection) {
            return false;
        }

        const modeValue = modeSelection?.querySelector('value')?.textContent;

        if (modeValue?.includes('Auto')) {
            return true;
        }

        return false;
    };

    return {
        getUploadUrl,
        importKmlFlight,
        validateKmlFile
    };
};

export default useKmlUpload;

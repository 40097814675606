import VerticalHighlightTabs from 'components/VerticalHighlightTabs';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SidebarContainer: FunctionComponent<Props> = ({ children, testId = TEST_ID }) => (
    <VerticalHighlightTabs className={styles.sideBarContainer} testId={testId}>
        {children}
    </VerticalHighlightTabs>
);

export default SidebarContainer;

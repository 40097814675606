import type { FaaReportRequest } from './types';

export const TEST_ID = 'faaReportModal';

export const MAX_YEAR = new Date().getFullYear();

export const MIN_YEAR = MAX_YEAR - 3;

export const BLANK_FAA_REPORT: FaaReportRequest = {
    companyDetails: {
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        name: undefined,
        phoneNumber: undefined,
        state: undefined,
        zipCode: undefined
    },
    droneIds: [],
    exemptions: {
        docketNumber: undefined,
        exemptionNumber: undefined
    },
    reportingPeriod: new Date()
};

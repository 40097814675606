import useAccount from 'hooks/useAccount/state';
import useCurrentOrganization from 'hooks/useCurrentOrganization/state';
import useFieldView from 'hooks/useFieldView/state';
import useJohnDeere from 'hooks/useJohnDeere/state';
import useLogout from 'hooks/useLogout/state';
import useOAuth2 from 'hooks/useOAuth2/state';
import useOrganizations from 'hooks/useOrganizations/state';
import useToast from 'hooks/useToast/state';
import useWorkOrder from 'hooks/useWorkOrder/state';

import type { Store } from './types';

const store: Store = {
    useAccount,
    useCurrentOrganization,
    useFieldView,
    useJohnDeere,
    useLogout,
    useOAuth2,
    useOrganizations,
    useToast,
    useWorkOrder
};

export default store;

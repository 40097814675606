import { useCallback, useRef } from 'react';

import { OAuth2Client, generateCodeVerifier } from '@badgateway/oauth2-client';

import useGlobalStore from 'hooks/useGlobalStore';

import type { OAuth2Params, OAuth2Token } from './types';

const useOAuth2 = (oauth2Params: OAuth2Params) => {
    const { authorizationEndpoint, clientId, redirectUri, scope, setToken, token, tokenEndpoint } =
        oauth2Params;

    const { getValue, setValue } = useGlobalStore({ namespace: 'useOAuth2' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveCodeVerifier = useCallback(setValue('codeVerifier'), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveState = useCallback(setValue('state'), []);

    const state = getValue('state') as string | undefined;

    const codeVerifier = getValue('codeVerifier') as string | undefined;

    const clientRef = useRef<OAuth2Client>(
        new OAuth2Client({
            authorizationEndpoint: authorizationEndpoint,
            clientId: clientId,
            tokenEndpoint: tokenEndpoint
        })
    );

    const getPreviousPageFromUrl = useCallback(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get('state');
    }, []);

    const setTokenFromRedirect = useCallback(async () => {
        const oauth2Token = await clientRef.current.authorizationCode.getTokenFromCodeRedirect(
            document.location.href,
            {
                codeVerifier: codeVerifier,
                redirectUri: redirectUri,
                state: state
            }
        );

        setToken(oauth2Token);
        saveCodeVerifier(null);
    }, [codeVerifier, redirectUri, saveCodeVerifier, setToken, state]);

    const fetchTokenOrRedirectAndLogIn = useCallback(
        async (
            redirectBackUri: string = document.location.pathname
        ): Promise<OAuth2Token | null> => {
            try {
                if (token) {
                    if (Date.now() >= (token.expiresAt ?? 0)) {
                        const refreshedToken = await clientRef.current.refreshToken(token);

                        setToken(refreshedToken);

                        return refreshedToken;
                    }

                    return token;
                }
                const verificationCode = await generateCodeVerifier();

                saveCodeVerifier(verificationCode);

                saveState(redirectBackUri);

                document.location = await clientRef.current.authorizationCode.getAuthorizeUri({
                    codeVerifier: verificationCode,
                    redirectUri: redirectUri,
                    scope: scope,
                    state: redirectBackUri
                });

                return null;
            } catch (error) {
                setToken(null);
                console.error(error);
                throw error;
            }
        },
        [token, scope, saveCodeVerifier, saveState, redirectUri, setToken]
    );

    const logout = useCallback(() => {
        setToken(null);
    }, [setToken]);

    return {
        fetchTokenOrRedirectAndLogIn,
        getPreviousPageFromUrl,
        logout,
        setTokenFromRedirect
    };
};

export default useOAuth2;

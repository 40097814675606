import FormTime from '../FormTime';

import { timezones } from '@@types/timezones';

import FormFieldWithUnit from 'components/form/FormFieldWithUnit';

import type { FunctionComponent, Props } from './types';

// For some reason using our custom FormTime component doesn't work with the
// timezone selector but using TimeInput Directly does
const FormTimeWithTimeZone: FunctionComponent<Props> = ({ label, name, timeZoneName }) => (
    <FormFieldWithUnit
        fieldComponent={FormTime}
        fieldLabel={label}
        fieldName={name}
        placeholder="00:00 --"
        unitName={timeZoneName}
        unitValues={timezones}
    />
);

export default FormTimeWithTimeZone;

import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useFetch from 'hooks/useFetch';

import { FLIGHT_REPORT_API } from './constants';
import type { FlightReportApiResponse } from './types';

const useFlightReport = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPut } = useFetch();

    const fetchFlightReports = useCallback(
        async (faaReportId: string, droneReportId: string, pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${FLIGHT_REPORT_API(faaReportId, droneReportId)}?${queryParams.toString()}`;

            const { data, error } = await authenticatedGet(uri);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data as FlightReportApiResponse;
        },
        [authenticatedGet]
    );

    const updateFlightReportPilot = useCallback(
        async (
            faaReportId: string,
            droneReportId: string,
            flightReportId: string,
            pilot: string
        ) => {
            const uri = `${FLIGHT_REPORT_API(faaReportId, droneReportId)}/${flightReportId}`;
            const payload = {
                pilotName: pilot
            };

            const { data, error } = await authenticatedPut(uri, JSON.stringify(payload));

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    const deleteFlightReport = useCallback(
        async (faaReportId: string, droneReportId: string, flightReportId: string) => {
            const uri = `${FLIGHT_REPORT_API(faaReportId, droneReportId)}/${flightReportId}`;

            const { data, error } = await authenticatedDelete(uri);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedDelete]
    );

    return { deleteFlightReport, fetchFlightReports, updateFlightReportPilot };
};

export default useFlightReport;

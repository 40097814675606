import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { type ChangeEvent, type FC, type FormEvent, useCallback, useState } from 'react';

import { timezones } from '@@types/timezones';
import { Button, Center, FileInput, Select, TextInput, Title } from '@mantine/core';

import useAccessToken from 'hooks/useAccessToken';

const KmlImportForm: FC = () => {
    const { fetchToken } = useAccessToken();

    const defaultState = {
        address: '',
        kmlFile: null as File | null,
        startTime: '',
        timeZone: ''
    };

    const [formData, setFormData] = useState(defaultState);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target;

            setFormData(currentFormData => ({ ...currentFormData, [name]: value }));
        },
        [setFormData]
    );

    const handleFileChange = useCallback(
        (file: File) => {
            setFormData(currentFormData => ({ ...currentFormData, kmlFile: file }));
        },
        [setFormData]
    );

    const handleTimeZoneChange = useCallback(
        (timeZoneValue: string) => {
            setFormData(currentFormData => ({ ...currentFormData, timeZone: timeZoneValue }));
        },
        [setFormData]
    );

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { address, kmlFile, startTime, timeZone } = formData;

        const utcStartTime = zonedTimeToUtc(startTime, timeZone);

        const classicFormDataFormat = new FormData();

        classicFormDataFormat.append('address', address);
        classicFormDataFormat.append('startTime', utcStartTime.toISOString());

        if (kmlFile) {
            classicFormDataFormat.append('kmlFile', kmlFile);
        }

        try {
            const token = await fetchToken();
            const response = await fetch(
                import.meta.env.VITE_RANTIZO_API_BASE_URL + '/api/dji-flights/kml',
                {
                    body: classicFormDataFormat,
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    method: 'POST'
                }
            );

            if (response.ok) {
                alert('Kml Flight Successfully Imported Into Database.');
            } else {
                alert(
                    'Failed to upload KML file please check to ensure all properties in the kml file are defined'
                );
            }
        } catch (error) {
            console.log(error);
            alert('Error submitting form please try again later.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // See https://github.com/mantinedev/mantine/issues/5401 with regards to placeholders
    return (
        <Center>
            <form id="kmlForm" onSubmit={handleSubmit}>
                <Title order={2}>Import DJI KML File</Title>

                <br />

                <TextInput
                    label="Address:"
                    name="address"
                    onChange={handleInputChange}
                    placeholder=""
                    required
                    value={formData.address}
                />

                <br />

                <TextInput
                    label="Start Time:"
                    name="startTime"
                    onChange={handleInputChange}
                    required
                    type="datetime-local"
                    value={formData.startTime}
                />

                <br />

                <Select
                    data={timezones}
                    label="Time Zone"
                    name="timeZone"
                    onChange={handleTimeZoneChange}
                    required
                />

                <br />

                <FileInput
                    accept=".kml"
                    label="KML File:"
                    onChange={handleFileChange}
                    {...{ placeholder: 'Choose a KML file' }}
                    required
                />

                <br />

                <div>
                    <Button fullWidth type="submit">
                        Submit
                    </Button>
                </div>
            </form>
        </Center>
    );
};

export default KmlImportForm;

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('aamButton');

    return {
        CREATE_NEW_AAM: t('createNewAam')
    };
};

export default useTranslation;

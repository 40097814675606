import { useCallback, useEffect, useMemo, useState } from 'react';

import DownloadLink from 'components/DownloadLink';

import useWorkOrderFileDownload from 'hooks/useWorkOrderFileDownload';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const WorkOrderFiles: FunctionComponent<Props> = ({
    className = '',
    rantizoFile,
    testId = TEST_ID
}) => {
    const { FILE_DOWNLOAD_ERROR } = useTranslation();

    const { getDownloadLink } = useWorkOrderFileDownload();

    const [downloadLink, setDownloadLink] = useState('');

    const workOrderFileName = useMemo(() => {
        if (rantizoFile) {
            const { objectKey } = rantizoFile;
            const split = objectKey.split('/');

            return split[split.length - 1];
        }

        return '';
    }, [rantizoFile]);

    useEffect(
        () => {
            if (rantizoFile && !downloadLink) {
                fetchAndSetDownloadLink(rantizoFile.id);
            }
        },
        // eslint-disable-next-line
        [rantizoFile]
    );

    const fetchAndSetDownloadLink = useCallback(
        async (documentId: string) => {
            try {
                const downloadUrl = await getDownloadLink(documentId);

                setDownloadLink(downloadUrl);
            } catch (e) {
                console.log(e);
                alert(FILE_DOWNLOAD_ERROR);
            }
        },
        [getDownloadLink, setDownloadLink, FILE_DOWNLOAD_ERROR]
    );

    const hasFile = downloadLink && rantizoFile;

    if (!hasFile) {
        return null;
    }

    return (
        <DownloadLink
            className={className}
            href={downloadLink}
            testId={testId}
            text={workOrderFileName}
        />
    );
};

export default WorkOrderFiles;

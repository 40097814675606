import { forwardRef } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import InputErrorMessage from 'components/InputErrorMessage';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const WithErrorMessage = forwardRef<HTMLDivElement, Props>(
    ({ children, className = '', testId = TEST_ID, text }, ref) => (
        <VerticalContainer
            className={`${className} ${styles.verticalContainer}`}
            ref={ref}
            testId={testId}
        >
            {text && <InputErrorMessage className={styles.inputErrorMessage} text={text} />}

            {children}
        </VerticalContainer>
    )
);

WithErrorMessage.displayName = 'WithErrorMessage';

export default WithErrorMessage;

import type { Unit } from 'types/units.ts';

export const timezones: Unit[] = [
    { label: 'ET', value: 'America/New_York' } as Unit,
    { label: 'CT', value: 'America/Chicago' } as Unit,
    { label: 'MT', value: 'America/Denver' } as Unit,
    { label: 'PT', value: 'America/Los_Angeles' } as Unit
] as const;

export type TimeZoneMapping = {
    [key: string]: 'ET' | 'CT' | 'MT' | 'PT';
};

export const timeZoneLabelToZoneMap = {
    CT: 'America/Chicago',
    ET: 'America/New_York',
    MT: 'America/Denver',
    PT: 'America/Los_Angeles'
};

const unitedStatesTimeZones: TimeZoneMapping = {
    'America/Adak': 'PT',
    'America/Boise': 'MT',
    'America/Chicago': 'CT',
    'America/Denver': 'MT',
    'America/Detroit': 'ET',
    'America/Indiana/Indianapolis': 'ET',
    'America/Indiana/Knox': 'CT',
    'America/Indiana/Marengo': 'ET',
    'America/Indiana/Petersburg': 'ET',
    'America/Indiana/Tell_City': 'CT',
    'America/Indiana/Vevay': 'ET',
    'America/Indiana/Vincennes': 'ET',
    'America/Indiana/Winamac': 'ET',
    'America/Juneau': 'PT',
    'America/Kentucky/Louisville': 'ET',
    'America/Kentucky/Monticello': 'ET',
    'America/Los_Angeles': 'PT',
    'America/Menominee': 'CT',
    'America/Metlakatla': 'PT',
    'America/New_York': 'ET',
    'America/Nome': 'PT',
    'America/North_Dakota/Beulah': 'CT',
    'America/North_Dakota/Center': 'CT',
    'America/North_Dakota/New_Salem': 'CT',
    'America/Phoenix': 'MT',
    'America/Shiprock': 'MT',
    'America/Sitka': 'PT',
    'America/Yakutat': 'PT',
    'Pacific/Honolulu': 'PT'
};

export default unitedStatesTimeZones;

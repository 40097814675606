import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const PageContainer: FunctionComponent<Props> = ({ children, testId = TEST_ID }) => (
    <div className={styles.pageContainer} data-testid={testId}>
        {children}
    </div>
);

export default PageContainer;

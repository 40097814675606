import { useCallback } from 'react';

import { constructUtcDateTime } from '@@utils/dates';

import useKmlUpload from 'hooks/useKmlUpload';

import type {
    Flight,
    KmlFlightFormQuery
} from 'pages/UploadFlightLogPage/components/DjiFileUploadTab/types';

const useData = () => {
    const { importKmlFlight } = useKmlUpload();

    const getKmlFlightFromForm = useCallback(
        async (formQuery: KmlFlightFormQuery): Promise<Flight> => {
            const {
                applicationDate,
                applicationStartTime,
                applicationTimeZone,
                areaUnits,
                distanceUnits,
                flightType,
                objectKey
            } = formQuery;

            const date = new Date(applicationDate);

            const startTime = constructUtcDateTime(
                date,
                applicationStartTime,
                applicationTimeZone,
                new Date()
            ).toISOString();

            const importRequest = {
                areaUnits: areaUnits,
                distanceUnits: distanceUnits,
                flightType: flightType,
                objectKey: objectKey,
                startTime: startTime
            };

            return await importKmlFlight(importRequest);
        },
        [importKmlFlight]
    );

    return {
        getKmlFlightFromForm
    };
};

export default useData;

import { isToday } from 'date-fns';
import { getIn } from 'formik';
import { merge } from 'lodash';
import { type FunctionComponent } from 'react';

import { rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';

import { TEST_ID } from './constants';
import type { DatePickerProps, Props } from './types';

import styles from './styles.module.scss';

const FormDate: FunctionComponent<Props> = ({
    className,
    clearable,
    disabled,
    field,
    form,
    isEditable = true,
    label,
    maxDate,
    minDate,
    placeholder,
    required,
    testId = TEST_ID,
    type,
    withAsterisk
}) => {
    const { name } = field;
    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const { onChange: _, ...otherField } = field;

    const onChange = async (value: Date | null) => {
        const date = value;

        date?.setSeconds(0);
        date?.setMilliseconds(0);
        await form.setFieldValue(name, date);
        const touched = merge(form.touched, { [name]: true });

        await form.setTouched(touched);
    };

    const dayRenderer: DatePickerProps['renderDay'] = date => {
        const dateStyle = isToday(date) ? styles.currentDate : '';
        const day = date.getDate();

        return <div className={dateStyle}>{day}</div>;
    };

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';

    // See https://github.com/mantinedev/mantine/issues/5401 with regards to placeholders
    return (
        <DatePickerInput
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : '0'
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formDate} ${viewOnlyClass}`}
            clearable={clearable}
            closeOnChange={true}
            data-testid={testId}
            defaultDate={new Date()}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            firstDayOfWeek={0}
            label={label}
            maxDate={maxDate}
            minDate={minDate}
            onChange={onChange}
            {...{ placeholder }}
            renderDay={dayRenderer}
            required={required}
            type={type}
            valueFormat="ddd, MMMM D YYYY"
            withAsterisk={withAsterisk}
            wrapperProps={{ 'data-testid': `${testId}.modal` }}
            {...otherField}
        />
    );
};

export default FormDate;

import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const MapMenu: FunctionComponent<Props> = ({
    className = '',
    onDelete,
    onView,
    testId = TEST_ID
}) => {
    const { DELETE, VIEW } = useTranslation();

    const items = [
        {
            onClick: onView,
            text: VIEW
        },
        {
            isDelete: true,
            onClick: onDelete,
            text: DELETE
        }
    ];

    return (
        <MenuButton className={`${className} ${styles.mapMenu}`} items={items} testId={testId} />
    );
};

export default MapMenu;

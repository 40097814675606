export const JOHN_DEERE_CLIENT_ID = import.meta.env.VITE_JOHN_DEERE_CLIENT_ID;

export const JOHN_DEERE_AUTH_URL =
    'https://signin.johndeere.com/oauth2/aus78tnlaysMraFhC1t7/v1/authorize';

export const JOHN_DEERE_ACCESS_TOKEN_URL =
    'https://signin.johndeere.com/oauth2/aus78tnlaysMraFhC1t7/v1/token';

export const JOHN_DEERE_REDIRECT_URI = import.meta.env.VITE_JOHN_DEERE_REDIRECT_URI;

export const ORGANIZATIONS_API = '/api/john-deere/organizations';

export const MAP_API = '/api/john-deere/maps';

import { useMemo } from 'react';

import { round } from '@@utils/math';
import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import ItemLabel from 'components/ItemLabel';
import WorkOrderReadOnlyText from 'components/WorkOrderReadOnlyText';

import useConvert from 'hooks/useConvert';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ChemicalAppliedView: FunctionComponent<Props> = ({ chemicalApplied }) => {
    const {
        convertLiquidVolume,
        convertLitersPerSquareMeterToPreferredRate,
        convertPreferredRateToLabel
    } = useConvert();

    const {
        chemical,
        preferredRateUnits,
        preferredVolumeUnits,
        rateAppliedLitersPerSquareMeter,
        volumeAppliedLiters
    } = chemicalApplied;

    const volume = useMemo(() => {
        const convertedVolume = round(
            convertLiquidVolume(volumeAppliedLiters, 'LITERS', preferredVolumeUnits),
            2
        );

        switch (preferredVolumeUnits) {
            case 'GALLONS':
                return `${convertedVolume} Ga`;
            case 'PINTS':
                return `${convertedVolume} Pt`;
            case 'QUARTS':
                return `${convertedVolume} Qt`;
            case 'FLUID_OUNCES':
                return `${convertedVolume} Fl. Oz`;
            default:
                return `${volumeAppliedLiters} L`;
        }
    }, [preferredVolumeUnits, volumeAppliedLiters, convertLiquidVolume]);

    const rate = useMemo(
        () =>
            `${convertLitersPerSquareMeterToPreferredRate(rateAppliedLitersPerSquareMeter, preferredRateUnits)} ${convertPreferredRateToLabel(preferredRateUnits)}`,
        [
            convertLitersPerSquareMeterToPreferredRate,
            convertPreferredRateToLabel,
            preferredRateUnits,
            rateAppliedLitersPerSquareMeter
        ]
    );

    return (
        <VerticalContainer className={styles.chemicalAppliedView}>
            <ItemLabel text={chemical.labelName ? chemical.labelName : ''} />

            <WorkOrderReadOnlyText text={volume} />

            <WorkOrderReadOnlyText text={rate} />
        </VerticalContainer>
    );
};

export default ChemicalAppliedView;

import type { Validator } from '../types';

const requiredValidator = (errorText: string, isRequired: boolean): Validator => ({
    errorText: errorText,
    isValid: (value: string) => {
        if (!isRequired) {
            return true;
        }

        return (value.length !== 0 || value !== '') && isRequired;
    }
});

export default requiredValidator;

import LinkButton from 'components/LinkButton';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const ListHeaderLinkButton = ({ onClick, testId = TEST_ID, text }: Props) => (
    <LinkButton className={styles.linkButton} onClick={onClick} testId={testId} text={text} />
);

export default ListHeaderLinkButton;

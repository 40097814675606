import ListAddButton from 'components/ListAddButton';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';
import ProductUsageReportTabs from 'components/ProductUsageReportTabs';

import useFeatureAccess from 'hooks/useFeatureAccess';

import useTranslation from './hooks/useTranslation';

import { FEATURE, TEST_ID } from './constants';
import type { Props } from './types';

const ProductUsageReportListPageLayoutHeader = ({
    onAddClick,
    onTabChange,
    pageTitle,
    testId = TEST_ID
}: Props) => {
    const { CREATE_PRODUCT_USAGE_REPORT } = useTranslation();

    const features = useFeatureAccess();

    const isFeatureAccessible = features[FEATURE];

    return (
        <ListPageLayoutHeader pageTitle={pageTitle} testId={testId}>
            <ListAddButton
                isDisabled={!isFeatureAccessible}
                onClick={onAddClick}
                text={CREATE_PRODUCT_USAGE_REPORT}
            />

            <ProductUsageReportTabs onChange={onTabChange} />
        </ListPageLayoutHeader>
    );
};

export default ProductUsageReportListPageLayoutHeader;

import ListAddButton from 'components/ListAddButton';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { Props } from './types';

const FaaReportsListPageLayoutHeader = ({
    isButtonDisabled = false,
    onAddClick,
    pageTitle,
    testId = TEST_ID
}: Props) => {
    const { CREATE_FAA_REPORT } = useTranslation();

    return (
        <ListPageLayoutHeader pageTitle={pageTitle} testId={testId}>
            <ListAddButton
                isDisabled={isButtonDisabled}
                onClick={onAddClick}
                text={CREATE_FAA_REPORT}
            />
        </ListPageLayoutHeader>
    );
};

export default FaaReportsListPageLayoutHeader;

import {
    FLUID_OUNCES_PER_ACRE,
    GALLONS_PER_ACRE,
    KILOGRAMS_PER_SQUARE_METER,
    OUNCES_PER_ACRE,
    PINTS_PER_ACRE,
    POUNDS_PER_ACRE,
    QUARTS_PER_ACRE,
    type Unit
} from 'types/units.ts';

export const TEST_ID = 'chemicalInformationFields';

export const signalWordValues = [
    {
        label: 'Caution',
        value: 'CAUTION'
    },
    {
        label: 'Danger',
        value: 'DANGER'
    },
    {
        label: 'Dangerous Poison',
        value: 'DANGEROUS_POISON'
    },
    {
        label: 'Poison',
        value: 'POISON'
    },
    {
        label: 'Warning',
        value: 'WARNING'
    }
];

export const sprayRateUnitOptions: Unit[] = [
    FLUID_OUNCES_PER_ACRE,
    GALLONS_PER_ACRE,
    KILOGRAMS_PER_SQUARE_METER,
    OUNCES_PER_ACRE,
    PINTS_PER_ACRE,
    POUNDS_PER_ACRE,
    QUARTS_PER_ACRE
];

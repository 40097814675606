import { forwardRef } from 'react';

import { FilterIcon } from '@rantizo-software/rantizo-ui';

import IconButton from 'components/IconButton';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const FilterIconButton = forwardRef<HTMLButtonElement, Props>(
    ({ className, isDisabled, onClick, testId = TEST_ID }, ref) => (
        <IconButton
            className={`${className} ${styles.iconButton}`}
            isDisabled={isDisabled}
            onClick={onClick}
            ref={ref}
            testId={testId}
        >
            <FilterIcon className={styles.filterIcon} />
        </IconButton>
    )
);

FilterIconButton.displayName = 'FilterIconButton';

export default FilterIconButton;

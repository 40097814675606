import { TEST_ID } from '../constants';

import WithFeatureAccess from 'components/WithFeatureAccess';

import useFeatureAccess from 'hooks/useFeatureAccess';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OptionsItem: FunctionComponent<Props> = ({
    className = '',
    feature,
    isDelete = false,
    onClick,
    testId = TEST_ID,
    text
}) => {
    const deleteClass = isDelete ? styles.optionItemDelete : '';

    const features = useFeatureAccess();

    const isFeatureAccessible = feature ? features[feature] || false : true;

    return (
        <span
            className={`${className} ${deleteClass} ${styles.optionsItem}`}
            data-testid={testId}
            onClick={isFeatureAccessible ? onClick : undefined}
        >
            {feature && <WithFeatureAccess feature={feature}>{text}</WithFeatureAccess>}

            {!feature && text}
        </span>
    );
};

export default OptionsItem;

import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import { XAG_CSV_IMPORT_API, XAG_KML_IMPORT_API } from './constants';
import type { CsvImportRequest, KmlImportRequest } from './types';

const useXag = () => {
    const { authenticatedPost } = useFetch();

    const createFlights = useCallback(
        async (request: CsvImportRequest) => {
            const { data, error } = await authenticatedPost(
                XAG_CSV_IMPORT_API,
                JSON.stringify(request)
            );

            if (error) {
                console.error(error);
                throw new Error(error);
            }

            return data;
        },
        [authenticatedPost]
    );

    const createKmlFlight = useCallback(
        async (request: KmlImportRequest) => {
            const { data, error } = await authenticatedPost(
                XAG_KML_IMPORT_API,
                JSON.stringify(request)
            );

            if (error) {
                console.error(error);
                throw new Error(error);
            }

            return data;
        },
        [authenticatedPost]
    );

    return {
        createFlights,
        createKmlFlight
    };
};

export default useXag;

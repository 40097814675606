import { useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SectionTitle } from '@rantizo-software/rantizo-ui';

import ConnectionListItem from 'components/ConnectionListItem';
import DisconnectConfirmationModal from 'components/DisconnectConfirmationModal';
import ListPageLayout from 'components/ListPageLayout';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';
import PageContainer from 'components/PageContainer';
import FieldViewIcon from 'components/icons/FieldViewIcon';

import useTranslation from './hooks/useTranslation';

import { CODE, FIELD_VIEW, MANAGE_CONNECTIONS_PAGE, TEST_ID, UNDEFINED } from './constants';
import type { ConnectionListItemType, Props } from './types';
import useFieldViewConnection from './useFieldViewConnection';

import styles from './styles.module.scss';

const ManageConnectionsPage = ({ testId = TEST_ID }: Props) => {
    const {
        CONNECTIONS_SECTION_HEADER,
        FIELD_VIEW_DESCRIPTION,
        FIELD_VIEW_TITLE,
        MANAGE_CONNECTIONS_PAGE_TITLE
    } = useTranslation();
    const [disconnectConfirmationModal, setDisconnectConfirmationModal] = useState(false);
    const [searchParams] = useSearchParams();
    const code = useRef(searchParams.get(CODE) === UNDEFINED ? undefined : searchParams.get(CODE));

    const {
        fetchTokenOrRedirectAndLogIn,
        handleDeleteFieldViewConnection,
        isFieldViewConnected,
        isFieldViewLoading
    } = useFieldViewConnection({ code });

    const connectionListItems: ConnectionListItemType[] = useMemo(
        () => [
            {
                description: FIELD_VIEW_DESCRIPTION,
                icon: <FieldViewIcon />,
                id: FIELD_VIEW,
                isConnected: isFieldViewConnected,
                isDisabled: isFieldViewLoading,
                name: FIELD_VIEW_TITLE,
                onClick: isFieldViewConnected
                    ? () => setDisconnectConfirmationModal(true)
                    : () => fetchTokenOrRedirectAndLogIn(MANAGE_CONNECTIONS_PAGE)
            }
        ],
        [
            FIELD_VIEW_DESCRIPTION,
            FIELD_VIEW_TITLE,
            fetchTokenOrRedirectAndLogIn,
            isFieldViewConnected,
            isFieldViewLoading
        ]
    );

    const handleCloseConfirmationModal = useCallback(() => {
        setDisconnectConfirmationModal(false);
    }, []);

    return (
        <PageContainer testId={testId}>
            <ListPageLayout>
                {disconnectConfirmationModal && (
                    <DisconnectConfirmationModal
                        onClose={handleCloseConfirmationModal}
                        onConfirm={handleDeleteFieldViewConnection}
                    />
                )}

                <ListPageLayoutHeader pageTitle={MANAGE_CONNECTIONS_PAGE_TITLE} />

                <SectionTitle className={styles.sectionTitle} text={CONNECTIONS_SECTION_HEADER} />

                {connectionListItems.map(
                    ({ description, icon, id, isConnected, isDisabled, name, onClick }) => (
                        <ConnectionListItem
                            description={description}
                            icon={icon}
                            id={id}
                            isConnected={isConnected}
                            isDisabled={isDisabled}
                            key={id}
                            name={name}
                            onClick={onClick}
                        />
                    )
                )}
            </ListPageLayout>
        </PageContainer>
    );
};

export default ManageConnectionsPage;

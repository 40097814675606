import { useCallback, useEffect, useMemo, useState } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import DatedList from 'components/DatedList';
import FaaReportDeleteConfirmationModal from 'components/FaaReportDeleteConfirmationModal';
import FaaReportListHeaders from 'components/FaaReportListHeaders';
import FaaReportListItem from 'components/FaaReportListItem';
import FaaReportModal from 'components/FaaReportModal';
import FaaReportsListPageLayoutHeader from 'components/FaaReportsListPageLayoutHeader';
import InfiniteScroll from 'components/InfiniteScroll';
import ListPageLayout from 'components/ListPageLayout';
import PageContainer from 'components/PageContainer';
import PageLoader from 'components/PageLoader';
import WithFeatureGate from 'components/WithFeatureGate';

import useFaaReports from 'hooks/useFaaReports';
import useFeatureAccess from 'hooks/useFeatureAccess';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { DATE_FORMAT, FEATURE, TEST_ID } from './constants';
import type {
    DatedListItem,
    FaaReportSummaryResponse,
    FunctionComponent,
    PagedResponseFaaReportSummaryResponse,
    Props
} from './types';

import styles from './styles.module.scss';

const FaaReportsPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const [loading, setLoading] = useState(true);
    const [deleteConfirmationModalStatus, setDeleteConfirmationModalStatus] = useState(false);
    const [selectedReportId, setSelectedReportId] = useState<string | undefined>(undefined);

    const { DELETE_FAA_REPORT_ERROR, ERROR, NO_DATA, TITLE } = useTranslation();

    const [shouldShowCreateModal, setShouldShowCreateModal] = useState(false);

    const features = useFeatureAccess();
    const isFeatureAccessible = features[FEATURE];

    const { fetch, groupByMonthAndYear } = useData();
    const { deleteFaaReport } = useFaaReports();

    const [data, setData] = useState<PagedResponseFaaReportSummaryResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const fetchData = useCallback(
        async (hasMore: boolean, nextToken: string | null) => {
            try {
                const response = await fetch(hasMore ? nextToken : null);

                const { hasNext, nextPageToken, objects: newReports } = response;

                setData((currentData: PagedResponseFaaReportSummaryResponse) => {
                    const newReportState = [...currentData.objects, ...newReports];

                    return { hasNext, nextPageToken, objects: newReportState };
                });
            } catch (e) {
                alert(ERROR);
                console.error(e);
            }
        },

        [ERROR, fetch]
    );

    const handleSelectedReportIds = useCallback((id: string) => {
        setDeleteConfirmationModalStatus(true);

        setSelectedReportId(id);
    }, []);

    const handleDelete = useCallback(async () => {
        if (!selectedReportId) {
            return;
        }

        try {
            await deleteFaaReport(selectedReportId);

            setData((currentReports: PagedResponseFaaReportSummaryResponse) => {
                const newReportState = currentReports.objects.filter(
                    (report: FaaReportSummaryResponse) => report.id !== selectedReportId
                );

                return { ...currentReports, objects: newReportState };
            });
        } catch (e) {
            alert(DELETE_FAA_REPORT_ERROR);
            console.error(e);
        }
    }, [DELETE_FAA_REPORT_ERROR, deleteFaaReport, selectedReportId]);

    const handleNewItemClick = useCallback(() => {
        setShouldShowCreateModal(true);
    }, []);

    const noData = useMemo(() => data.objects.length === 0, [data.objects.length]);

    useEffect(() => {
        if (!isFeatureAccessible) {
            return;
        }

        const initialDataFetch = async () => {
            await fetchData(false, '');
        };

        if (loading) {
            initialDataFetch();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFeatureAccessible, loading]);

    const faaReportItems = useMemo(() => {
        const datedFaaReportItems = groupByMonthAndYear(data.objects);

        const faaReportDatedItems = {} as DatedListItem;

        Object.keys(datedFaaReportItems).map(date => {
            const datedItems = datedFaaReportItems[date];

            faaReportDatedItems[date] = [<FaaReportListHeaders key={date} />].concat(
                datedItems.map((report: FaaReportSummaryResponse) => (
                    <FaaReportListItem
                        handleDelete={handleSelectedReportIds}
                        key={report.id}
                        report={report}
                    />
                ))
            );
        });

        return faaReportDatedItems;
    }, [data.objects, groupByMonthAndYear, handleSelectedReportIds]);

    return (
        <PageContainer testId={testId}>
            <ListPageLayout className={styles.listLayout}>
                <FaaReportsListPageLayoutHeader
                    isButtonDisabled={!isFeatureAccessible}
                    onAddClick={handleNewItemClick}
                    pageTitle={TITLE}
                />

                <WithFeatureGate feature="faaReports">
                    <VerticalContainer>
                        {loading && <PageLoader />}

                        {deleteConfirmationModalStatus && (
                            <FaaReportDeleteConfirmationModal
                                onClose={() => setDeleteConfirmationModalStatus(false)}
                                onConfirm={handleDelete}
                            />
                        )}

                        {noData && !loading && <p>{NO_DATA}</p>}

                        {!noData && (
                            <InfiniteScroll
                                fetchNextPage={async () =>
                                    await fetchData(data.hasNext, data.nextPageToken ?? null)
                                }
                                hasNextPage={data.hasNext}
                                loader={<PageLoader />}
                                nextPageToken={data.nextPageToken ?? undefined}
                            >
                                <DatedList
                                    className={styles.datedList}
                                    dateFormat={DATE_FORMAT}
                                    items={faaReportItems}
                                />
                            </InfiniteScroll>
                        )}
                    </VerticalContainer>

                    {shouldShowCreateModal && (
                        <FaaReportModal onClose={() => setShouldShowCreateModal(false)} />
                    )}
                </WithFeatureGate>
            </ListPageLayout>
        </PageContainer>
    );
};

export default FaaReportsPage;

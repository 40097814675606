import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PageWithSidebar from 'components/PageWithSidebar';

import usePageRoutes from 'hooks/usePageRoutes';

import AsAppliedMapPage from 'pages/AsAppliedMapPage';
import AsFlownPage from 'pages/AsFlownPage';
import NotFoundErrorPage from 'pages/errors/NotFoundErrorPage';

import useData from './hooks/useData';
import useSidebarItems from './hooks/useSidebarItems';
import useTranslation from './hooks/useTranslation';

import type { AsAppliedMapResultsSection, FunctionComponent, Props } from './types';

const AsAppliedMapResultsPage: FunctionComponent<Props> = ({ onLayoutSwitch }) => {
    const { fetch, status } = useData();

    const { jobId, mapType } = useParams();

    const { RESOURCE_NAME } = useTranslation();

    const [data, setData] = useState<Record<string, string>>({});

    const [currentSection, setCurrentSection] = useState<AsAppliedMapResultsSection>(
        mapType == 'AAM' ? 'asAppliedMap' : 'asCovered'
    );

    const { sidebarItems } = useSidebarItems(mapType === 'AAM');

    useEffect(() => {
        const fetchData = async () => {
            const result = (await fetch()) as Record<string, string>;

            setData(result);
        };

        fetchData();

        if (mapType === 'AAM') {
            setCurrentSection('asAppliedMap');
        } else {
            setCurrentSection('asCovered');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { asAppliedMapId, coverageMapId, dryCoverageMapId, productUsageReportId } = data;

    const handleOnLoad = useCallback(() => {
        onLayoutSwitch?.('wide');
    }, [onLayoutSwitch]);

    const handleOnUnload = useCallback(() => {
        onLayoutSwitch?.('');
    }, [onLayoutSwitch]);

    const asCoveredId = useMemo(
        () => (coverageMapId ? coverageMapId : dryCoverageMapId),
        [coverageMapId, dryCoverageMapId]
    );

    const contentMap = useMemo(
        () => ({
            asAppliedMap:
                asAppliedMapId && jobId ? (
                    <AsAppliedMapPage
                        aamId={asAppliedMapId}
                        jobId={jobId ?? ''}
                        key={Math.random()}
                        mapType="AAM"
                        onLoad={handleOnLoad}
                        onUnload={handleOnUnload}
                        productUsageReportId={productUsageReportId ?? undefined}
                    />
                ) : null,
            asCovered:
                asCoveredId && jobId ? (
                    <AsAppliedMapPage
                        aamId={asCoveredId}
                        jobId={jobId}
                        key={Math.random()}
                        mapType="COVERAGE"
                        onLoad={handleOnLoad}
                        onUnload={handleOnUnload}
                        productUsageReportId={productUsageReportId ?? undefined}
                    />
                ) : null,
            asFlown: asCoveredId && (
                <AsFlownPage
                    aamId={asCoveredId}
                    key={Math.random()}
                    onLoad={handleOnLoad}
                    onUnload={handleOnUnload}
                />
            )
        }),
        [asAppliedMapId, asCoveredId, handleOnLoad, handleOnUnload, jobId, productUsageReportId]
    );

    const navigate = useNavigate();
    const { mapsPage } = usePageRoutes();

    if (status === 'error' || (status === 'done' && !(asAppliedMapId || asCoveredId))) {
        return <NotFoundErrorPage resourceName={RESOURCE_NAME} />;
    }

    const { title } = sidebarItems[currentSection];

    return (
        <PageWithSidebar
            state={{
                setSideBarItem: (item: string) => {
                    setCurrentSection(item as AsAppliedMapResultsSection);
                },
                sideBarItem: currentSection
            }}
            onExit={() => navigate(mapsPage)}
            sidebarItems={sidebarItems}
            title={title}
        >
            {contentMap[currentSection]}
        </PageWithSidebar>
    );
};

export default AsAppliedMapResultsPage;

import { useCallback } from 'react';

import type { FileUpload } from './types';

const useKmlValidation = () => {
    const acceptedFileType = 'application/vnd.google-earth.kml+xml';

    const validateKmlFileAttribute = useCallback((parsedFile: Document, attributeName: string) => {
        const attributeElement = parsedFile.querySelector(`Data[name="${attributeName}"]`);

        const attributeValue = attributeElement?.querySelector('value')?.textContent;

        if (!attributeValue || !attributeElement) {
            throw new Error('INVALID_DATA');
        }

        return false;
    }, []);

    const sanitizeFile = useCallback(async (fileUpload: FileUpload): Promise<FileUpload> => {
        if (!fileUpload.fileRequest.filename.endsWith('.kml')) {
            return fileUpload;
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = e => {
                try {
                    const fileContent = e.target?.result;

                    const sanitizedFileContent =
                        fileContent?.toString().replace(/&(?!amp;)/g, '&amp;') || '';

                    const sanitizedBlob = new Blob([sanitizedFileContent], {
                        type: fileUpload.file.name
                    });

                    const sanitizedFile = new File([sanitizedBlob], fileUpload.file.name, {
                        lastModified: fileUpload.file.lastModified,
                        type: acceptedFileType
                    });

                    resolve({
                        file: sanitizedFile,
                        fileRequest: {
                            ...fileUpload.fileRequest,
                            contentType: acceptedFileType,
                            filesize: sanitizedFile.size
                        }
                    });
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            reader.readAsText(fileUpload.file);
        });
    }, []);

    return {
        sanitizeFile,
        validateKmlFileAttribute
    };
};

export default useKmlValidation;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton, VerticalContainer } from '@rantizo-software/rantizo-ui';

import OnboardingBodyText from 'components/OnboardingBodyText';
import OnboardingButtonContainer from 'components/OnboardingButtonContainer';
import OnboardingLogout from 'components/OnboardingLogout';
import OnboardingPageTitle from 'components/OnboardingPageTitle';
import OrganizationList from 'components/OrganizationList';
import PageContainer from 'components/PageContainer';

import useAccount from 'hooks/useAccount';
import useOrganizations from 'hooks/useOrganizations';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerifyOrganizationPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { organizations } = useOrganizations();
    const { user } = useAccount();
    const { createOrganizationPage } = usePageRoutes();
    const navigate = useNavigate();
    const { CREATE_ORGANIZATION, CREATE_ORGANIZATION_TO_START, SELECT_ORGANIZATION, TITLE } =
        useTranslation();

    const alreadyOwner = organizations?.some(org => org.ownerId === user?.id);

    const handleClick = useCallback(() => {
        navigate(createOrganizationPage);
    }, [createOrganizationPage, navigate]);

    const text = organizations?.length ? SELECT_ORGANIZATION : CREATE_ORGANIZATION_TO_START;

    return (
        <PageContainer testId={testId}>
            <VerticalContainer className={styles.body}>
                <OnboardingPageTitle text={TITLE} />

                <OnboardingBodyText text={text} />

                <OrganizationList onClick={handleClick} />
            </VerticalContainer>

            <OnboardingButtonContainer>
                {!alreadyOwner && organizations !== null && (
                    <PrimaryButton
                        isDisabled={alreadyOwner || organizations == null}
                        onClick={handleClick}
                        testId={testId}
                        text={CREATE_ORGANIZATION}
                    />
                )}

                <OnboardingLogout />
            </OnboardingButtonContainer>
        </PageContainer>
    );
};

export default VerifyOrganizationPage;

import { Cancel, Menu } from 'iconoir-react';
import { useCallback } from 'react';

import { ActionIcon, Container, Drawer, Group, Header, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ProfileIcon } from '@rantizo-software/rantizo-ui';

import CTANavigationLink from 'components/CTANavigationLink';
import DesktopNavigationDropdown from 'components/DesktopNavigationDropdown';
import DesktopNavigationDropdownLink from 'components/DesktopNavigationDropdownLink';
import { useDevice } from 'components/DeviceProvider';
import HamburgerMenuDropdown from 'components/HamburgerMenuDropdown';
import LegalNavigationLink from 'components/LegalNavigationLink';
import Logo from 'components/Logo';
import MainNavigationLink from 'components/MainNavigationLink';
import WithFeatureAccess from 'components/WithFeatureAccess';

import useFeatureAccess from 'hooks/useFeatureAccess';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePageRoutes from 'hooks/usePageRoutes';
import useRoles from 'hooks/useRoles';

import { AS_COVERED_FEATURE } from 'pages/AsAppliedMapResultsPage/components/FlightLogForm/constants';

import useTranslation from './hooks/useTranslation';

import {
    ACCOUNT_TEST_ID,
    FAA_REPORTS_FEATURE,
    FLIGHT_LOG_FEATURE,
    LOGOUT_TEST_ID,
    MANAGE_CONNECTIONS_TEST_ID,
    MANAGE_ORGANIZATION_TEST_ID,
    PRODUCT_USAGE_REPORTS_FEATURE,
    WORK_ORDERS_FEATURE
} from './constants';
import type { FunctionComponent } from './types';

import styles from './styles.module.scss';

const SiteHeader: FunctionComponent = () => {
    const { canManageOrganizations } = useRoles();
    const device = useDevice();
    const {
        FAA_REPORTS: FAA_REPORTS_FEATURE_FLAG,
        FLIGHT_LOG_LIST,
        MANAGE_CONNECTIONS: MANAGE_CONNECTIONS_FEATURE_FLAG
    } = useFeatureFlags();
    const {
        AAM,
        ACCOUNT,
        COPYRIGHT,
        CREATE_PRODUCT_USAGE_REPORT,
        CREATE_WORK_ORDER,
        FAA_REPORTS,
        FLIGHT_LOG,
        LOGOUT,
        MANAGE_CONNECTIONS,
        MANAGE_ORGANIZATION,
        MY_ORGANIZATION,
        PRODUCT_USAGE_REPORTS,
        TERMS_OF_SERVICE,
        WORK_ORDERS
    } = useTranslation();
    const {
        accountLogoutPage,
        accountPage,
        faaReportsPage,
        flightLogPage,
        homePage,
        manageConnectionsPage,
        manageOrganizationPage,
        mapsPage,
        productUsageReportPageWithModal,
        productUsageReportsPage,
        termsOfServicePage,
        uploadFlightLogPage,
        workOrderPage
    } = usePageRoutes();
    const [opened, { close, toggle }] = useDisclosure(false);
    const currentYear = new Date().getFullYear().toString();

    const featureAccess = useFeatureAccess();

    const handleNavigation = useCallback(() => {
        if (device.deviceType === 'mobile') {
            close();
        }
    }, [device.deviceType, close]);

    const flightLogPath = FLIGHT_LOG_LIST ? flightLogPage : uploadFlightLogPage;

    return (
        <Header className={styles.navbarTopContainer} data-testid="site-header" height={rem(60)}>
            <Container className={styles.navbarTop}>
                <Logo className={styles.logo} />

                <Group className={styles.navLinkGroup}>
                    <DesktopNavigationDropdown
                        dropdownElement={
                            <WithFeatureAccess feature={WORK_ORDERS_FEATURE}>
                                <MainNavigationLink link={homePage} text={WORK_ORDERS} />
                            </WithFeatureAccess>
                        }
                    >
                        {featureAccess[WORK_ORDERS_FEATURE] && (
                            <DesktopNavigationDropdownLink
                                link={workOrderPage}
                                text={CREATE_WORK_ORDER}
                            />
                        )}
                    </DesktopNavigationDropdown>

                    <DesktopNavigationDropdown
                        dropdownElement={
                            <WithFeatureAccess feature={PRODUCT_USAGE_REPORTS_FEATURE}>
                                <MainNavigationLink
                                    link={productUsageReportsPage}
                                    text={PRODUCT_USAGE_REPORTS}
                                />
                            </WithFeatureAccess>
                        }
                    >
                        {featureAccess[PRODUCT_USAGE_REPORTS_FEATURE] && (
                            <DesktopNavigationDropdownLink
                                link={productUsageReportPageWithModal}
                                text={CREATE_PRODUCT_USAGE_REPORT}
                            />
                        )}
                    </DesktopNavigationDropdown>

                    <WithFeatureAccess feature={AS_COVERED_FEATURE}>
                        <MainNavigationLink link={mapsPage} text={AAM} />
                    </WithFeatureAccess>

                    <WithFeatureAccess feature={FLIGHT_LOG_FEATURE}>
                        <MainNavigationLink link={flightLogPath} text={FLIGHT_LOG} />
                    </WithFeatureAccess>

                    {FAA_REPORTS_FEATURE_FLAG && (
                        <WithFeatureAccess feature={FAA_REPORTS_FEATURE}>
                            <MainNavigationLink link={faaReportsPage} text={FAA_REPORTS} />
                        </WithFeatureAccess>
                    )}

                    <DesktopNavigationDropdown dropdownElement={<ProfileIcon />}>
                        <DesktopNavigationDropdownLink
                            link={accountPage}
                            testId={ACCOUNT_TEST_ID}
                            text={ACCOUNT}
                        />

                        <DesktopNavigationDropdownLink
                            link={manageOrganizationPage}
                            testId={MANAGE_ORGANIZATION_TEST_ID}
                            text={canManageOrganizations ? MANAGE_ORGANIZATION : MY_ORGANIZATION}
                        />

                        {MANAGE_CONNECTIONS_FEATURE_FLAG && (
                            <DesktopNavigationDropdownLink
                                link={manageConnectionsPage()}
                                testId={MANAGE_CONNECTIONS_TEST_ID}
                                text={MANAGE_CONNECTIONS}
                            />
                        )}

                        <DesktopNavigationDropdownLink
                            link={accountLogoutPage}
                            testId={LOGOUT_TEST_ID}
                            text={LOGOUT}
                        />
                    </DesktopNavigationDropdown>
                </Group>

                <div className={styles.menuButton}>
                    <ActionIcon
                        className={styles.menuIcon}
                        data-testid="hamburger-menu"
                        onClick={toggle}
                    >
                        {
                            // @ts-expect-error implementation will later be changed
                            <Menu strokeWidth={1.75} />
                        }
                    </ActionIcon>
                </div>

                <Drawer.Root
                    className={styles.drawer}
                    onClose={close}
                    opened={opened}
                    position="right"
                    size="sm"
                    zIndex={1100}
                >
                    <Drawer.Overlay opacity={0.15} />

                    <Drawer.Content
                        className={styles.sidebarContainer}
                        data-testid="mobile-sidebar"
                    >
                        <Drawer.Header className={styles.sidebarHeader}>
                            <Drawer.CloseButton
                                className={styles.sidebarClose}
                                color="purple"
                                data-testid="drawer-close"
                                size="md"
                            >
                                {
                                    // @ts-expect-error implementation will later be changed
                                    <Cancel strokeWidth={2} />
                                }
                            </Drawer.CloseButton>
                        </Drawer.Header>

                        <Drawer.Body className={styles.sidebar}>
                            <div className={styles.sidebarMainLinkGroup}>
                                <HamburgerMenuDropdown
                                    dropdownElement={
                                        <WithFeatureAccess feature={WORK_ORDERS_FEATURE}>
                                            <MainNavigationLink
                                                link={homePage}
                                                onClick={handleNavigation}
                                                text={WORK_ORDERS}
                                            />
                                        </WithFeatureAccess>
                                    }
                                >
                                    {featureAccess[WORK_ORDERS_FEATURE] && (
                                        <CTANavigationLink
                                            link={workOrderPage}
                                            onClick={handleNavigation}
                                            text={CREATE_WORK_ORDER}
                                        />
                                    )}
                                </HamburgerMenuDropdown>

                                <HamburgerMenuDropdown
                                    dropdownElement={
                                        <WithFeatureAccess feature={PRODUCT_USAGE_REPORTS_FEATURE}>
                                            <MainNavigationLink
                                                link={productUsageReportsPage}
                                                onClick={handleNavigation}
                                                text={PRODUCT_USAGE_REPORTS}
                                            />
                                        </WithFeatureAccess>
                                    }
                                >
                                    {featureAccess[PRODUCT_USAGE_REPORTS_FEATURE] && (
                                        <CTANavigationLink
                                            link={productUsageReportPageWithModal}
                                            onClick={handleNavigation}
                                            text={CREATE_PRODUCT_USAGE_REPORT}
                                        />
                                    )}
                                </HamburgerMenuDropdown>

                                <WithFeatureAccess feature={AS_COVERED_FEATURE}>
                                    <MainNavigationLink
                                        link={mapsPage}
                                        onClick={handleNavigation}
                                        text={AAM}
                                    />
                                </WithFeatureAccess>

                                <WithFeatureAccess feature={FLIGHT_LOG_FEATURE}>
                                    <MainNavigationLink
                                        link={flightLogPath}
                                        onClick={handleNavigation}
                                        text={FLIGHT_LOG}
                                    />
                                </WithFeatureAccess>

                                {FAA_REPORTS_FEATURE_FLAG && (
                                    <WithFeatureAccess feature={FAA_REPORTS_FEATURE}>
                                        <MainNavigationLink
                                            link={faaReportsPage}
                                            text={FAA_REPORTS}
                                        />
                                    </WithFeatureAccess>
                                )}
                            </div>

                            <div>
                                <div className={styles.sidebarCTALinkGroup}>
                                    <ProfileIcon />

                                    <CTANavigationLink
                                        link={accountPage}
                                        onClick={handleNavigation}
                                        testId={ACCOUNT_TEST_ID}
                                        text={ACCOUNT}
                                    />

                                    <CTANavigationLink
                                        text={
                                            canManageOrganizations
                                                ? MANAGE_ORGANIZATION
                                                : MY_ORGANIZATION
                                        }
                                        link={manageOrganizationPage}
                                        onClick={handleNavigation}
                                        testId={MANAGE_ORGANIZATION_TEST_ID}
                                    />

                                    <CTANavigationLink
                                        link={accountLogoutPage}
                                        onClick={handleNavigation}
                                        testId={LOGOUT_TEST_ID}
                                        text={LOGOUT}
                                    />
                                </div>

                                <div className={styles.sidebarLegalLinkGroup}>
                                    <LegalNavigationLink
                                        link={termsOfServicePage}
                                        onClick={handleNavigation}
                                        text={TERMS_OF_SERVICE}
                                    />
                                </div>

                                <p className={styles.copyright}>{COPYRIGHT(currentYear)}</p>
                            </div>
                        </Drawer.Body>
                    </Drawer.Content>
                </Drawer.Root>
            </Container>
        </Header>
    );
};

export default SiteHeader;

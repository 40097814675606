import { SecondaryTitle } from '@rantizo-software/rantizo-ui';

import SplitContainer from 'components/SplitContainer';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const DataItem: FunctionComponent<Props> = ({ children, testId = TEST_ID, text }) => (
    <SplitContainer testId={testId}>
        <SecondaryTitle text={text} />

        {children}
    </SplitContainer>
);

export default DataItem;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import usePageRoutes from 'hooks/usePageRoutes';

import type { FunctionComponent } from './types';

const FieldViewRedirectPage: FunctionComponent = () => {
    const { manageConnectionsPage } = usePageRoutes();

    const navigate = useNavigate();

    useEffect(() => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');

        if (code) {
            navigate(manageConnectionsPage(code, 'fieldView'));
        }
    }, [manageConnectionsPage, navigate]);

    return null;
};

export default FieldViewRedirectPage;

import { memo, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { BodyText } from '@rantizo-software/rantizo-ui';

import DatedList from 'components/DatedList';
import FlightLogDeleteConfirmationModal from 'components/FlightLogDeleteConfirmationModal';
import FlightLogDeleteErrorModal from 'components/FlightLogDeleteErrorModal';
import FlightLogListFilters from 'components/FlightLogListFilters';
import FlightLogListHeader from 'components/FlightLogListHeader';
import FlightLogListItem from 'components/FlightLogListItem';
import FlightLogListPageLayoutHeader from 'components/FlightLogListPageLayoutHeader';
import InfiniteScroll from 'components/InfiniteScroll';
import ListPageLayout from 'components/ListPageLayout';
import PageContainer from 'components/PageContainer';
import PageLoader from 'components/PageLoader';
import WithFeatureGate from 'components/WithFeatureGate';

import usePageRoutes from 'hooks/usePageRoutes';

import useFlightLogPage from './hooks/useFlightLogPage';
import useModals from './hooks/useModals';
import useTabs from './hooks/useTabs';
import useTranslation from './hooks/useTranslation';

import { ALLOWED_DATE_RANGE, FLIGHT_LOG_LIST_FEATURE, TEST_ID } from './constants';
import type { DatedListItem, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightLogPage: FunctionComponent<Props> = memo(({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { uploadFlightLogPage } = usePageRoutes();
    const { NO_FLIGHTS, TITLE } = useTranslation();

    const { handleTabChange } = useTabs();

    const {
        deleteConfirmationModalStatus,
        deleteErrorModalStatus,
        handleDeleteConfirmationModalClose,
        handleDeleteErrorModalClose,
        setDeleteConfirmationModalStatus,
        setDeleteErrorModalStatus
    } = useModals();

    const {
        dateRangeValue,
        fetchFlightData,
        flights,
        handleAircraftFilterChange,
        handleBulkDelete,
        handleDateRangeFilterChange,
        handleSingleDelete,
        isBulkDelete,
        loading,
        onBulkDelete,
        onSingleDelete,
        selectedAircraftNicknames,
        selectedFlightIds,
        setSelectedFlightIds
    } = useFlightLogPage({ setDeleteConfirmationModalStatus, setDeleteErrorModalStatus });

    const dateRangeRef = useRef({
        endInputRef: useRef<HTMLInputElement>(),
        startInputRef: useRef<HTMLInputElement>()
    });

    const disableDelete = useMemo(() => selectedFlightIds.length === 0, [selectedFlightIds]);

    const noFlights = useMemo(
        () => flights.objects && Object.keys(flights.objects).length === 0,
        [flights]
    );

    const handleNewItemClick = useCallback(() => {
        navigate(uploadFlightLogPage);
    }, [navigate, uploadFlightLogPage]);

    const handleFlightLogDelete = useMemo(() => {
        if (!isBulkDelete) {
            return handleSingleDelete;
        } else {
            return handleBulkDelete;
        }
    }, [handleSingleDelete, handleBulkDelete, isBulkDelete]);

    const handleNextPage = useCallback(
        async () =>
            await fetchFlightData({
                dateRange: dateRangeValue,
                hasMore: flights.hasNext,
                nextToken: flights.nextPageToken,
                nicknames: selectedAircraftNicknames
            }),
        [dateRangeValue, fetchFlightData, flights, selectedAircraftNicknames]
    );

    const flightLogCards = useMemo(() => {
        const flightLogList = {} as DatedListItem;

        Object.keys(flights.objects).map((date: string) => {
            const flightsByDate = flights.objects[date];

            const flightIds = flightsByDate.map(({ id }) => id);

            const currentlySelectedFlightIds: string[] = flightIds.filter(id =>
                selectedFlightIds.includes(id)
            );

            const hasCurrentlySelectedFlights = currentlySelectedFlightIds.length > 0;

            const onSelectAllByDate = () => {
                if (hasCurrentlySelectedFlights) {
                    setSelectedFlightIds(previouslySelectedFlightIds =>
                        previouslySelectedFlightIds.filter(id => !flightIds.includes(id))
                    );
                } else {
                    setSelectedFlightIds(previouslySelectedFlightIds => [
                        ...previouslySelectedFlightIds,
                        ...flightIds
                    ]);
                }
            };

            flightLogList[date] = [
                <FlightLogListHeader
                    hasCurrentlySelectedFlights={hasCurrentlySelectedFlights}
                    key={date}
                    onSelectAll={onSelectAllByDate}
                />
            ].concat(
                flightsByDate.map(
                    ({ endTime, flightSource, flightType, id, location, nickname, startTime }) => (
                        <FlightLogListItem
                            onDelete={({ id, url }) =>
                                onSingleDelete({ dateString: date, id, url })
                            }
                            applicationType={flightType}
                            droneNickname={nickname}
                            endTime={endTime}
                            fileType={flightSource}
                            id={id}
                            key={id}
                            location={location}
                            selectedFlightIds={selectedFlightIds}
                            setSelectedFlightIds={setSelectedFlightIds}
                            startTime={startTime}
                        />
                    )
                )
            );
        });

        return flightLogList;
    }, [flights, onSingleDelete, selectedFlightIds, setSelectedFlightIds]);

    return (
        <PageContainer testId={testId}>
            <ListPageLayout className={styles.flightLogListPage}>
                <FlightLogListPageLayoutHeader
                    onAddClick={handleNewItemClick}
                    onTabChange={handleTabChange}
                    pageTitle={TITLE}
                />

                <WithFeatureGate feature={FLIGHT_LOG_LIST_FEATURE} />

                {loading && <PageLoader />}

                {deleteConfirmationModalStatus && (
                    <FlightLogDeleteConfirmationModal
                        isBulkDelete={isBulkDelete}
                        onClose={handleDeleteConfirmationModalClose}
                        onConfirm={handleFlightLogDelete}
                    />
                )}

                {deleteErrorModalStatus && (
                    <FlightLogDeleteErrorModal onClose={handleDeleteErrorModalClose} />
                )}

                <FlightLogListFilters
                    allowedDateRange={ALLOWED_DATE_RANGE}
                    dateRangeValue={dateRangeValue}
                    isDeleteDisabled={disableDelete}
                    onAircraftFilterChange={handleAircraftFilterChange}
                    onBulkDelete={onBulkDelete}
                    onDateRangeChange={handleDateRangeFilterChange}
                    ref={dateRangeRef}
                />

                {noFlights && !loading && <BodyText testId={'noFlights'} text={NO_FLIGHTS} />}

                {flights && (
                    <InfiniteScroll
                        fetchNextPage={handleNextPage}
                        hasNextPage={flights.hasNext}
                        loader={<PageLoader />}
                        nextPageToken={flights.nextPageToken ?? undefined}
                    >
                        <DatedList className={styles.datedList} items={flightLogCards} />
                    </InfiniteScroll>
                )}
            </ListPageLayout>
        </PageContainer>
    );
});

FlightLogPage.displayName = 'FlightLogPage';

export default FlightLogPage;

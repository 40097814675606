import { useCallback, useEffect, useMemo, useRef } from 'react';

import { round } from '@@utils/math';
import { ContentText, ModalTitle, PrimaryButton } from '@rantizo-software/rantizo-ui';

import FormSectionContainer from 'components/FormSectionContainer';
import Link from 'components/Link';
import ModalOverlay from 'components/ModalOverlay';
import DropdownWithErrorMessage from 'components/form/DropdownWithErrorMessage';

import useAsAppliedMaps from 'hooks/useAsAppliedMaps';
import useForm from 'hooks/useForm';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import {
    FIELD_FORM_KEY,
    FIELD_TEST_ID,
    JOHN_EXPORTED_MAP_REDIRECT,
    ORGANIZATION_FORM_KEY,
    ORGANIZATION_TEST_ID,
    TEST_ID,
    formSchema
} from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const JohnDeereExportModal: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    jobId,
    mapType,
    onClose,
    testId = TEST_ID
}) => {
    const organizationDropdownRef = useRef<HTMLSelectElement>(null);
    const fieldDropdownRef = useRef<HTMLSelectElement>(null);

    const johnDeereExportForm = useForm(formSchema);

    const {
        DESCRIPTION,
        DISCONNECT_FROM_JOHN_DEERE,
        EXPORT_ERROR,
        MAP_TITLE,
        SELECT_FIELD,
        TITLE,
        UPLOAD
    } = useTranslation();

    const { form, handleChange, handleError, handleSubmit, handleValid } = johnDeereExportForm;

    const { getUnitLabel } = useAsAppliedMaps();

    const {
        disconnectFromJohnDeere,
        exportMapToJohnDeere,
        fields,
        hasConnection,
        loadFieldsForOrganization,
        loadJohnDeereData,
        organizations
    } = useData();

    const organizationDropdownItems = useMemo(
        () =>
            organizations.map(({ id, name }) => ({
                label: name,
                type: '',
                value: id
            })),
        [organizations]
    );

    const fieldDropdownItems = useMemo(
        () =>
            fields.map(({ id, name }) => ({
                label: name,
                type: '',
                value: id
            })),
        [fields]
    );

    const dispatchSubmitEvent = useCallback(() => {
        const event = new Event('submit');

        organizationDropdownRef.current?.dispatchEvent(event);
        fieldDropdownRef.current?.dispatchEvent(event);
    }, []);

    useEffect(() => {
        loadJohnDeereData(jobId, mapType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasConnection]);

    const disconnect = useCallback(() => {
        disconnectFromJohnDeere();
        onClose();
    }, [disconnectFromJohnDeere, onClose]);

    const uploadToJohnDeere = useCallback(async () => {
        dispatchSubmitEvent();

        const { fieldId, organizationId } = form.current;

        try {
            const appliedRateLabel = getUnitLabel(aam.appliedRate.unitOfMeasurement);

            const metaData = [
                {
                    name: 'Applied With',
                    value: chemicalNames
                },
                {
                    name: 'Application Rate',
                    value: `${round(aam.appliedRate.value, 2)} ${appliedRateLabel}`
                }
            ];

            await exportMapToJohnDeere({
                aam,
                description: '',
                fieldId,
                mapType,
                metaData,
                organizationId,
                title: MAP_TITLE(
                    new Date(aam.startTime).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'long',
                        weekday: 'long',
                        year: 'numeric'
                    })
                )
            });
            window.open(JOHN_EXPORTED_MAP_REDIRECT(organizationId, fieldId), '_blank');
            onClose();
        } catch (error) {
            console.error(error);
            alert(EXPORT_ERROR);
        }
    }, [
        EXPORT_ERROR,
        MAP_TITLE,
        aam,
        chemicalNames,
        dispatchSubmitEvent,
        exportMapToJohnDeere,
        form,
        getUnitLabel,
        mapType,
        onClose
    ]);

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <FormSectionContainer>
                <ModalTitle text={TITLE} />

                <ContentText className={styles.centerText} text={DESCRIPTION} />

                <DropdownWithErrorMessage
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(dropdownValue: any, _onError, _index) => {
                        loadFieldsForOrganization(dropdownValue);
                        handleChange(ORGANIZATION_FORM_KEY)(dropdownValue);
                    }}
                    items={organizationDropdownItems}
                    onError={handleError(ORGANIZATION_FORM_KEY)}
                    onSubmit={handleSubmit(ORGANIZATION_FORM_KEY)}
                    onValid={handleValid(ORGANIZATION_FORM_KEY)}
                    ref={organizationDropdownRef}
                    testId={ORGANIZATION_TEST_ID}
                />

                <DropdownWithErrorMessage
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(dropdownValue: any, _onError, _index) => {
                        handleChange(FIELD_FORM_KEY)(dropdownValue);
                    }}
                    items={fieldDropdownItems}
                    onError={handleError(FIELD_FORM_KEY)}
                    onSubmit={handleSubmit(FIELD_FORM_KEY)}
                    onValid={handleValid(FIELD_FORM_KEY)}
                    placeholder={SELECT_FIELD}
                    ref={fieldDropdownRef}
                    testId={FIELD_TEST_ID}
                />

                <PrimaryButton onClick={uploadToJohnDeere} text={UPLOAD} />

                <Link onClick={disconnect} text={DISCONNECT_FROM_JOHN_DEERE} />
            </FormSectionContainer>
        </ModalOverlay>
    );
};

export default JohnDeereExportModal;

import { useMemo } from 'react';

import SplitContainer from 'components/SplitContainer';
import SubmitButton from 'components/SubmitButton';
import BackButton from 'components/buttons/BackButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BackNextButtons: FunctionComponent<Props> = ({
    className = '',
    isDisabled = false,
    isNextDisabled = isDisabled,
    isNextLoading = false,
    nextText,
    onBack,
    onNext,
    testId = TEST_ID
}) => {
    const { CONTINUE } = useTranslation();

    const singleButtonLayout = useMemo(() => (onNext ? '' : styles.singleButtonLayout), [onNext]);

    return (
        <SplitContainer className={`${className} ${singleButtonLayout}`} testId={testId}>
            <BackButton isDisabled={!onBack || isDisabled} onClick={onBack} />

            {onNext && (
                <SubmitButton
                    isDisabled={isNextDisabled}
                    isLoading={isNextLoading}
                    onClick={onNext}
                    text={nextText ?? CONTINUE}
                />
            )}
        </SplitContainer>
    );
};

export default BackNextButtons;

export const AAM_MIN_START = new Date('1/1/2023');

export const AAM_DRONE_MAX = 5;

export const AAM_DRONE_CHAR_MAX = 20;

// radius of 5km
export const AAM_FLIGHT_BOUNDS_SQ_KM = Math.PI * Math.pow(5.0, 2.0);

export const API_PAGE_SIZE_PARAM_NAME = 'size';

export const API_PAGE_TOKEN_PARAM_NAME = 'pageToken';

export const INPUT_CHARS_MAX = 50;

export const EQUIPMENT_CHAR_MAX = 20;

export const NAME_REGEX = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-.]+$/;

export const WORK_ORDER_ACRE_MAX = 10000;

export const WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_POLYGON_ACRES = 500;

export const WORK_ORDER_APPLICATION_SITE_BOUNDARY_MAX_SPREAD_ACRES = 500;

export const WORK_ORDER_APPLICATION_SITE_MAX = 50;

export const WORK_ORDER_APPLICATION_SITE_MAX_POLYGONS = 10;

export const WORK_ORDER_CHEMICAL_MAX = 10;

export const WORK_ORDER_CHEMICAL_RATE_GA_AC_MAX = 50;

export const WORK_ORDER_INGREDIENT_MAX = 10;

export const WORK_ORDER_NOTES_CHAR_MAX = 5000;

export const PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX = 5000;

export const WORK_ORDER_TARGET_SPRAY_GA_AC_MAX = 50;

export const MAX_FLUID_OZ = 64000;

export const MAX_FILE_SIZE = 20971520;

export const MAX_VOLUME_GALLONS = 500;

export const MAX_VOLUME_PINTS = 4000;

export const MAX_GPA = 50;

export const MAX_RATE_POUNDS_PER_ACRE = 500;

export const MAX_WEIGHT_POUNDS = 10000;

export const MAX_OZ = 160000;

export const MAX_OZ_PER_ACRE = 16;

export const MAX_PINTS_PER_ACRE = 4000;

export const MAX_KILOGRAMS = 4535.924;

export const MAX_KILOGRAMS_PER_SQUARE_METER = 0.0560426;

export const MAX_QUARTS = 5000;

export const MAX_QUARTS_PER_ACRE = 8000;

export const MAX_ADDRESS_TO_POLYGON_DISTANCE_MILES = 5;

import DownloadPDF from 'components/DownloadPDF';
import DownloadShapeFile from 'components/DownloadShapeFile';
import OptionsButtonTextWithArrow from 'components/OptionsButtonTextWithArrow';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const MapDownloadButton: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    legend,
    mapType,
    mapUrl,
    siteName,
    testId = TEST_ID
}) => {
    const { DOWNLOAD_AS } = useTranslation();

    return (
        <OptionsButtonTextWithArrow testId={testId} text={DOWNLOAD_AS}>
            <DownloadPDF
                aam={aam}
                chemicalNames={chemicalNames}
                legend={legend}
                mapType={mapType}
                mapUrl={mapUrl}
                siteName={siteName}
            />

            <DownloadShapeFile aamId={aam.id} chemicalNames={chemicalNames} />
        </OptionsButtonTextWithArrow>
    );
};

export default MapDownloadButton;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentText } from '@rantizo-software/rantizo-ui';

import EditableSection from 'components/EditableSection';
import LargeSemiboldText from 'components/LargeSemiboldText';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OrganizationCertificationEditCard: FunctionComponent<Props> = ({
    editable,
    organization,
    testId = TEST_ID
}) => {
    const {
        CERTIFICATE_OF_AUTHORIZATION_NUMBER,
        CERTIFICATIONS_AND_EXEMPTIONS,
        FAA_DOCKET_NUMBER,
        FAA_EXEMPTION_NUMBER,
        NO_CERTIFICATIONS
    } = useTranslation();

    const { coa, faaDocketNumber, faaExemptionNumber } = organization;

    const navigate = useNavigate();
    const { editCertificationsPage } = usePageRoutes();

    const handleEdit = useCallback(() => {
        navigate(editCertificationsPage);
    }, [editCertificationsPage, navigate]);

    return (
        <EditableSection
            editable={editable}
            onEdit={handleEdit}
            testId={testId}
            title={CERTIFICATIONS_AND_EXEMPTIONS}
        >
            {coa && (
                <>
                    <LargeSemiboldText text={CERTIFICATE_OF_AUTHORIZATION_NUMBER} />

                    <ContentText text={coa} />
                </>
            )}

            {faaDocketNumber && (
                <>
                    <LargeSemiboldText text={FAA_DOCKET_NUMBER} />

                    <ContentText text={faaDocketNumber} />
                </>
            )}

            {faaExemptionNumber && (
                <>
                    <LargeSemiboldText text={FAA_EXEMPTION_NUMBER} />

                    <ContentText text={faaExemptionNumber} />
                </>
            )}

            {!coa && !faaDocketNumber && !faaExemptionNumber && (
                <ContentText className={styles.noCertifications} text={NO_CERTIFICATIONS} />
            )}
        </EditableSection>
    );
};

export default OrganizationCertificationEditCard;

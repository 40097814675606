import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import InfiniteScroll from 'components/InfiniteScroll';
import ListPageLayout from 'components/ListPageLayout';
import MapListContainer from 'components/MapListContainer';
import MapListItem from 'components/MapListItem';
import MapsListPageLayoutHeader from 'components/MapsListPageLayoutHeader';
import PageContainer from 'components/PageContainer';
import PageLoader from 'components/PageLoader';
import WithFeatureGate from 'components/WithFeatureGate';

import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { MAPS_FEATURE, TEST_ID } from './constants';
import type { FunctionComponent, PagedReducedAAM, Props } from './types';

import styles from './styles.module.scss';

const MapsListPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { fetch: fetchMaps } = useData();
    const { NO_MAPS, SELECT_DATE: _date, TITLE } = useTranslation();

    const [loading, setLoading] = useState(true);

    const [maps, setMaps] = useState<PagedReducedAAM>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const navigate = useNavigate();
    const { generateMapPage } = usePageRoutes();

    const fetchMapData = useCallback(
        async (hasMore: boolean, nextToken: string | null) => {
            const response = await fetchMaps(hasMore ? nextToken : null);

            const { hasNext, nextPageToken, objects: newMaps } = response;

            setMaps((currentState: PagedReducedAAM) => {
                const newMapState = [...currentState.objects, ...newMaps];

                return { hasNext, nextPageToken, objects: newMapState };
            });
        },

        [fetchMaps]
    );

    const handleDelete = useCallback((id: string) => {
        setMaps((currentMaps: PagedReducedAAM) => ({
            ...currentMaps,
            objects: currentMaps.objects.filter(map => map.jobId !== id)
        }));
    }, []);

    const handleNewItemClick = useCallback(() => {
        navigate(generateMapPage);
    }, [generateMapPage, navigate]);

    const noMaps = useMemo(() => maps.objects.length === 0, [maps.objects.length]);

    useEffect(() => {
        const initialMapFetch = async () => {
            await fetchMapData(false, '');
        };

        if (loading) {
            initialMapFetch();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <PageContainer testId={testId}>
            <ListPageLayout className={styles.listLayout}>
                <MapsListPageLayoutHeader onAddClick={handleNewItemClick} pageTitle={TITLE} />

                <WithFeatureGate feature={MAPS_FEATURE} />

                <VerticalContainer>
                    {loading && <PageLoader />}

                    {noMaps && !loading && <p>{NO_MAPS}</p>}

                    {!noMaps && (
                        <InfiniteScroll
                            fetchNextPage={async () =>
                                await fetchMapData(maps.hasNext, maps.nextPageToken)
                            }
                            hasNextPage={maps.hasNext}
                            loader={<PageLoader />}
                            nextPageToken={maps.nextPageToken ?? undefined}
                        >
                            <MapListContainer>
                                {maps.objects.map(
                                    ({
                                        applicationStartDate,
                                        contact,
                                        growerName,
                                        id,
                                        jobId,
                                        mapType,
                                        productUsageReportId,
                                        siteName
                                    }) => (
                                        <MapListItem
                                            applicationDate={applicationStartDate}
                                            contactName={contact}
                                            growerName={growerName}
                                            handleDelete={handleDelete}
                                            id={id}
                                            jobId={jobId}
                                            key={id}
                                            mapType={mapType}
                                            productUsageReportId={productUsageReportId}
                                            siteName={siteName}
                                        />
                                    )
                                )}
                            </MapListContainer>
                        </InfiniteScroll>
                    )}
                </VerticalContainer>
            </ListPageLayout>
        </PageContainer>
    );
};

export default MapsListPage;

import { useCallback, useMemo, useState } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import FlightReportDeleteModal from 'components/FlightReportDeleteModal';
import FlightReportListItem from 'components/FlightReportListItem';
import FlightReportListRow from 'components/FlightReportListRow';
import InfiniteScroll from 'components/InfiniteScroll';
import ListFooter from 'components/ListFooter';
import ListHeader from 'components/ListHeader';
import PageLoader from 'components/PageLoader';
import PilotsDropdown from 'components/PilotsDropdown';

import useFlightReports from 'hooks/useFlightReports';

import useFlightReportDelete from './hooks/useFlightReportDelete';
import useFlightReportUpdate from './hooks/useFlightReportUpdate';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightReportList: FunctionComponent<Props> = ({
    droneReportId,
    faaReportId,
    hasMore,
    isEditable,
    nextToken,
    onDelete,
    onNextPage,
    onUpdate,
    testId = TEST_ID
}) => {
    const { flightReports } = useFlightReports();

    const { DATE, FLIGHT_TIME, FLIGHTS, LOCATION, PILOT } = useTranslation();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const { deleteFlightReports } = useFlightReportDelete({
        droneReportId,
        faaReportId
    });

    const { updateFlightReports } = useFlightReportUpdate({
        droneReportId,
        faaReportId
    });

    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const clearSelected = useCallback(() => {
        setSelectedIds([]);
    }, []);

    const handleDelete = useCallback(async () => {
        setShowDeleteModal(true);
    }, []);

    const handleDeleteFlightReports = useCallback(async () => {
        const successfullyDeletedIds = await deleteFlightReports({ flightReportIds: selectedIds });

        onDelete?.({ ids: successfullyDeletedIds });

        clearSelected();
    }, [clearSelected, deleteFlightReports, onDelete, selectedIds]);

    const handleError = useCallback(({ message }: { message: string }) => {
        alert(message);
    }, []);

    const handleCheckboxChange = useCallback((isChecked: boolean, id: string) => {
        setSelectedIds(previousValue =>
            isChecked
                ? [...previousValue, id]
                : previousValue.filter(selectedId => selectedId !== id)
        );
    }, []);

    const handleModalClose = useCallback(() => {
        setShowDeleteModal(false);
    }, []);

    const handleSelectToggle = useCallback(() => {
        if (selectedIds.length === 0) {
            setSelectedIds(flightReports.map(flightReport => flightReport.id));
        } else {
            clearSelected();
        }
    }, [clearSelected, flightReports, selectedIds]);

    const handlePilotsChange = useCallback(
        async (value: unknown) => {
            const successfullyUpdatedIds = await updateFlightReports({
                flightReportIds: selectedIds,
                pilot: value
            });

            onUpdate?.({ changes: { pilot: value }, ids: successfullyUpdatedIds });

            clearSelected();
        },
        [clearSelected, onUpdate, selectedIds, updateFlightReports]
    );

    const flightReportsItems = useMemo(
        () =>
            flightReports.map(flightReport => (
                <FlightReportListItem
                    flightReport={flightReport}
                    isEditable={isEditable}
                    isSelected={selectedIds.includes(flightReport.id)}
                    key={flightReport.id}
                    onCheckboxChange={handleCheckboxChange}
                />
            )),
        [flightReports, handleCheckboxChange, isEditable, selectedIds]
    );

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';

    return (
        <VerticalContainer testId={testId}>
            <VerticalContainer className={`${styles.flightReportList} ${viewOnlyClass}`}>
                <FlightReportListRow isEditable={isEditable}>
                    {isEditable && <ListHeader text="" />}

                    <ListHeader text={DATE} />

                    <ListHeader text={FLIGHT_TIME} />

                    <ListHeader text={FLIGHTS} />

                    <ListHeader className={styles.location} text={LOCATION} />

                    <ListHeader text={PILOT} />
                </FlightReportListRow>

                {showDeleteModal && (
                    <FlightReportDeleteModal
                        onClose={handleModalClose}
                        onConfirm={handleDeleteFlightReports}
                    />
                )}

                <InfiniteScroll
                    className={styles.infiniteScroll}
                    fetchNextPage={async () => await onNextPage()}
                    hasNextPage={hasMore}
                    loader={<PageLoader />}
                    nextPageToken={nextToken}
                >
                    {flightReportsItems}
                </InfiniteScroll>
            </VerticalContainer>

            {isEditable && (
                <ListFooter
                    isEditable={isEditable}
                    onDelete={handleDelete}
                    onError={handleError}
                    onSelectToggle={handleSelectToggle}
                    selectedItems={selectedIds}
                    totalItemCount={flightReports.length}
                >
                    <PilotsDropdown
                        isEnabled={Boolean(selectedIds.length)}
                        onChange={handlePilotsChange}
                    />
                </ListFooter>
            )}
        </VerticalContainer>
    );
};

export default FlightReportList;

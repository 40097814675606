import { useCallback, useMemo, useRef, useState } from 'react';

import { convertGeometryToGeoJSON } from '@@utils/mapUtils';
import { centroid, nearestPoint } from '@turf/turf';

import {
    ALTERNATE_FLIGHT_LINE_LAYER_ID,
    ALTERNATE_FLIGHT_LINE_SOURCE,
    ALTERNATE_LINE_WIDTH
} from './constants';
import type {
    Feature,
    FeatureCollection,
    Flight,
    GeoJSONSourceRaw,
    Geometry,
    Map,
    Point,
    SelectableFlight
} from './types';

import styles from './styles.module.scss';

const useFlightMap = (flights: Flight[], alternateFlights: Flight[] = []) => {
    const [mapRef, setMapRef] = useState<Map>();
    const [selectableFlights, setSelectableFlights] = useState<SelectableFlight[]>([]);
    const [mapView, setMapView] = useState<'point' | 'line'>('line');
    const showAlternateFlights = useRef<boolean>(false);

    const sortFlightsByTime = useCallback(
        (selectableFlights: SelectableFlight[]) =>
            selectableFlights.sort(
                (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
            ),
        []
    );

    const loadFlights = useCallback(async () => {
        const transformedFlights = flights.map(
            flight => ({ ...flight, checked: true }) as SelectableFlight
        );
        const sortedFlights = sortFlightsByTime(transformedFlights);

        setSelectableFlights(sortedFlights);
    }, [flights, sortFlightsByTime]);

    const mapData = useMemo<FeatureCollection>(() => {
        const geojsonData = flights.map(({ id, location }) => ({
            geometry: location as Geometry,
            properties: { id }
        }));

        const geojson = convertGeometryToGeoJSON(geojsonData);

        return geojson;
    }, [flights]);

    const centroidLocation = useMemo(() => {
        if (mapData !== undefined && flights.length > 0) {
            const point: Feature<Point> = centroid(mapData);

            const nearestNeighbor: Feature<Point> = nearestPoint(point, mapData);
            const flightId = nearestNeighbor.properties?.id;
            const address = flights.find(({ id }) => id === flightId)?.address;

            return { address: address, coords: point.geometry.coordinates as [number, number] };
        }
    }, [mapData, flights]);

    const toggleLayerVisibility = useCallback(
        (layerType: 'point' | 'line', id: string, visible: boolean) => {
            if (mapRef !== undefined) {
                mapRef.setLayoutProperty(
                    `flight_${layerType}_${id}`,
                    'visibility',
                    visible ? 'visible' : 'none'
                );

                mapRef.setLayoutProperty(
                    ALTERNATE_FLIGHT_LINE_LAYER_ID(id),
                    'visibility',
                    visible && showAlternateFlights.current ? 'visible' : 'none'
                );
            }
        },
        [mapRef, showAlternateFlights]
    );

    const calculateGeojson = useCallback((flights: Flight[]) => {
        const lineGeojsonData = flights.map(({ flightPath, id }) => ({
            geometry: flightPath as Geometry,
            properties: { id }
        }));
        const lineGeojson = convertGeometryToGeoJSON(lineGeojsonData);

        return { data: lineGeojson, type: 'geojson' } as GeoJSONSourceRaw;
    }, []);

    const addAlternateFlightsToMap = useCallback(
        (event: { type: string; target: Map }) => {
            const lineData = calculateGeojson(alternateFlights);

            event.target.addSource(ALTERNATE_FLIGHT_LINE_SOURCE, lineData);

            alternateFlights.forEach(({ id }) =>
                event.target.addLayer({
                    filter: ['==', 'id', id],
                    id: ALTERNATE_FLIGHT_LINE_LAYER_ID(id),
                    layout: {
                        visibility: showAlternateFlights.current ? 'visible' : 'none'
                    },
                    paint: {
                        'line-color': styles.alternateFlightLineColor,
                        'line-width': ALTERNATE_LINE_WIDTH
                    },
                    source: ALTERNATE_FLIGHT_LINE_SOURCE,
                    type: 'line'
                })
            );
        },
        [calculateGeojson, alternateFlights, showAlternateFlights]
    );

    const handleMapLoaded = useCallback(
        (event: { type: string; target: Map }) => {
            if (!mapRef && mapData) {
                event.target.addSource('flight_point_data', { data: mapData, type: 'geojson' });

                flights.forEach(({ id }) =>
                    event.target.addLayer({
                        filter: ['==', 'id', id],
                        id: `flight_point_${id}`,
                        layout: {
                            visibility: mapView === 'point' ? 'visible' : 'none'
                        },
                        paint: {
                            'circle-color': styles.strokeColor
                        },
                        source: 'flight_point_data',
                        type: 'circle'
                    })
                );

                const lineData = calculateGeojson(flights);

                event.target.addSource('flight_line_data', lineData);

                flights.forEach(({ id }) =>
                    event.target.addLayer({
                        filter: ['==', 'id', id],
                        id: `flight_line_${id}`,
                        layout: {
                            visibility: mapView === 'line' ? 'visible' : 'none'
                        },
                        paint: {
                            'line-color': styles.strokeColor,
                            'line-width': 2
                        },
                        source: 'flight_line_data',
                        type: 'line'
                    })
                );

                addAlternateFlightsToMap(event);

                setMapRef(event.target);
            }
        },
        [mapRef, mapData, flights, calculateGeojson, addAlternateFlightsToMap, mapView]
    );

    const handleToggleFlightPath = useCallback(() => {
        if (!mapRef) {
            return;
        }

        const oppositeType = mapView === 'line' ? 'point' : 'line';

        selectableFlights.forEach(({ checked, id }) => {
            toggleLayerVisibility(oppositeType, id, checked);
            toggleLayerVisibility(mapView, id, false);
            setMapView(oppositeType);
        });
    }, [mapRef, mapView, selectableFlights, toggleLayerVisibility]);

    const handleToggleAlternateFlights = useCallback(() => {
        if (!mapRef) {
            return;
        }
        showAlternateFlights.current = !showAlternateFlights.current;

        selectableFlights.forEach(({ id }) => {
            if (mapRef) {
                mapRef.setLayoutProperty(
                    ALTERNATE_FLIGHT_LINE_LAYER_ID(id),
                    'visibility',
                    showAlternateFlights.current ? 'visible' : 'none'
                );
            }
        });
    }, [mapRef, selectableFlights]);

    return {
        centroidLocation,
        handleMapLoaded,
        handleToggleAlternateFlights,
        handleToggleFlightPath,
        loadFlights,
        mapData,
        mapView,
        selectableFlights,
        setSelectableFlights,
        showAlternateFlights,
        toggleLayerVisibility
    };
};

export default useFlightMap;

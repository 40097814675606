import { type FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { ContentContainer } from '@rantizo-software/rantizo-ui';

import { useDevice } from 'components/DeviceProvider';
import SiteFooter from 'components/SiteFooter';
import SiteHeader from 'components/SiteHeader';
import Toast from 'components/Toast';

import type { Props, TypeMap } from './types';

import styles from './styles.module.scss';

const typeMap: TypeMap = {
    wide: styles.wide
};

const Layout: FunctionComponent<Props> = ({ type = '' }) => {
    const device = useDevice();

    const className = typeMap[type] || '';

    return (
        <>
            <SiteHeader />

            <Toast />

            <ContentContainer className={className}>
                <Outlet />
            </ContentContainer>

            {device.deviceType === 'desktop' && <SiteFooter />}
        </>
    );
};

export default Layout;

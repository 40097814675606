import type { UserResponse } from 'generatedTypes/user/models';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import Intercom from '@intercom/messenger-js-sdk';

import useAccount from 'hooks/useAccount';
import useFeatureFlags from 'hooks/useFeatureFlags';

import { APP_ID } from './constants';
import type { FunctionComponent } from './types';

const ChatSupport: FunctionComponent = () => {
    const { user } = useAccount();

    const { INTERCOM_CHAT } = useFeatureFlags();

    const shouldLoadIntercom = useMemo(() => {
        if (user) {
            const { createdAt, email, firstName, id, intercomHmac, lastName } =
                user as UserResponse;

            return (
                firstName && lastName && email && id && intercomHmac && createdAt && INTERCOM_CHAT
            );
        }

        return false;
    }, [INTERCOM_CHAT, user]);

    if (shouldLoadIntercom) {
        const { createdAt, email, firstName, id, intercomHmac, lastName } = user as UserResponse;

        Intercom({
            app_id: APP_ID,
            created_at: new Date(createdAt).getUTCSeconds(),
            email: email,
            name: `${firstName} ${lastName}`,
            user_hash: intercomHmac,
            user_id: id
        });
    }

    return <Outlet />;
};

export default ChatSupport;

import { Content } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const WideContentContainer: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID
}) => (
    <div className={`${className} ${styles.wideContentContainer}`} data-testid={testId}>
        <Content className={styles.content}>{children}</Content>
    </div>
);

export default WideContentContainer;

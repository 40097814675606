import { Field } from 'formik';

import { rem } from '@mantine/core';

import FormMinimalSelect from 'components/form/FormMinimalSelect';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FormFieldWithWiderUnit: FunctionComponent<Props> = ({
    disabled,
    fieldComponent,
    fieldLabel,
    fieldName,
    inputMode,
    isEditable = true,
    placeholder,
    unitName,
    unitValues
}) => (
    <Field
        rightSection={
            <Field
                className={`${styles.unitDropdown} ${isEditable ? styles.borderLine : ''}`}
                component={FormMinimalSelect}
                data={unitValues}
                disabled={disabled}
                isEditable={isEditable}
                name={unitName}
                rightSection={<></>}
                rightSectionWidth={'0px'}
            />
        }
        component={fieldComponent}
        disabled={disabled}
        inputMode={inputMode}
        isEditable={isEditable}
        label={fieldLabel}
        name={fieldName}
        placeholder={placeholder}
        rightSectionWidth={rem('120px')}
    />
);

export default FormFieldWithWiderUnit;

import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

import type { FileRequest, SaveDocumentRequest, UseFileUpload } from './types';

const useFileUpload: UseFileUpload = ({ documentsApi, uploadApi }) => {
    const { authenticatedDelete, authenticatedPost } = useFetch();

    const deleteDocument = useCallback(
        async (id: string) => {
            const { error } = await authenticatedDelete(`${documentsApi}/${id}`);

            if (error) {
                console.log(error);

                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedDelete, documentsApi]
    );

    const getUploadUrlResponse = useCallback(
        async (fileRequest: FileRequest) => {
            const response = await authenticatedPost(uploadApi, JSON.stringify(fileRequest));

            return response;
        },
        [authenticatedPost, uploadApi]
    );

    const saveDocument = useCallback(
        async (request: SaveDocumentRequest) => {
            const response = await authenticatedPost(documentsApi, JSON.stringify(request));

            return response;
        },
        [authenticatedPost, documentsApi]
    );

    return { deleteDocument, getUploadUrlResponse, saveDocument };
};

export default useFileUpload;

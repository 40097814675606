import { Field } from 'formik';

import FormInput from 'components/deprecating/FormInput';

import useTranslation from './hooks/useTranslation';

import { INPUT_CHARS_MAX, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const WorkOrderNumberInput: FunctionComponent<Props> = ({
    disabled = false,
    isEditable = true,
    name = 'workOrderNumber',
    testId = TEST_ID
}) => {
    const { WORK_ORDER_NUMBER } = useTranslation();

    return (
        <Field
            component={FormInput}
            disabled={disabled}
            isEditable={isEditable}
            label={WORK_ORDER_NUMBER}
            max={INPUT_CHARS_MAX}
            name={name}
            testId={testId}
        />
    );
};

export default WorkOrderNumberInput;

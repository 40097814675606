import type { Style } from './types';

const styles: Style = {
    flexDirection: 'row',
    height: 'auto',
    padding: '0.3in 0 0.4in',
    width: '100%'
};

export default styles;

import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Items, Props } from './types';

import styles from './styles.module.scss';

const FlightLogMenu: FunctionComponent<Props> = ({
    className = '',
    flightSource,
    onDelete,
    onView,
    testId = TEST_ID
}) => {
    const { DELETE, VIEW } = useTranslation();

    const items: Items = [
        {
            onClick: onView,
            text: VIEW
        }
    ];

    if (flightSource !== 'DJI_SYNCED') {
        items.push({
            isDelete: true,
            onClick: onDelete,
            text: DELETE
        });
    }

    return (
        <MenuButton
            className={`${className} ${styles.flightLogMenu}`}
            items={items}
            testId={testId}
        />
    );
};

export default FlightLogMenu;

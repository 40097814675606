import { useCallback } from 'react';

import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const FaaReportDeleteConfirmationModal: FunctionComponent<Props> = ({
    onClose,
    onConfirm,
    testId = TEST_ID
}) => {
    const { CONFIRM_DELETE_FAA_REPORT, CONFIRM_DELETE_FAA_REPORT_SUBTEXT_MESSAGE } =
        useTranslation();

    const handleDelete = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    return (
        <DeleteConfirmationModal
            onClose={onClose}
            onConfirm={handleDelete}
            testId={testId}
            text={CONFIRM_DELETE_FAA_REPORT_SUBTEXT_MESSAGE}
            title={CONFIRM_DELETE_FAA_REPORT}
        />
    );
};

export default FaaReportDeleteConfirmationModal;

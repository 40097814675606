/*
  ac: Acres
  ha: Hectares
  m2: Square Meters
  flozPerAc: Fluid Oz. per Acre
  gaPerAc: Gallons per Acre
  lPerHa: Liters per Hectare
  lPerM2: Liters per Square Meter
*/
import type { MassRateUnit, MassUnit, VolumeRateUnit, VolumeUnit } from './types';

export const CONVERSION_TABLE_TO_AC = {
    ac: 1,
    ha: 2.47105,
    m2: 2.47105e-4
};

export const CONVERSION_TABLE_TO_GA_PER_AC = {
    flozPerAc: 7.8125e-3,
    gaPerAc: 1,
    lPerHa: 1.06907e-1,
    lPerM2: 1.06907e3
};

export const CONVERSION_TABLE_TO_M2 = {
    ac: 4.04686e3,
    ha: 1e4,
    m2: 1
};

export const CONVERSION_TABLE_TO_L_PER_M2 = {
    flozPerAc: 7.30778e-6,
    gaPerAc: 9.353396e-4,
    lPerHa: 1e-4,
    lPerM2: 1
};

export const MILLISECONDS_PER_DAY = 8.64e7;

export const MILLISECONDS_PER_HOUR = 3.6e6;

export const MILLISECONDS_PER_MINUTE = 60000;

export const MILLISECONDS_PER_SECOND = 1000;

export const METERS_PER_SECOND_PER_MILES_PER_HOUR = 2.237;

export const DEGREES_C_ABOVE_K = 273.15;

export const CELSIUS_TO_FAHRENHEIT_MODIFIER = 9 / 5;

export const FAHRENHEIT_TO_CELSIUS_MODIFIER = 5 / 9;

export const DEGREES_F_ABOVE_C = 32;

export const LITERS_PER_SQUARE_METER_TO_LITERS_PER_HECTARE = 10000;

export const LITERS_PER_SQUARE_METER_TO_PINTS_PER_ACRE = 8552.53;

export const POUNDS_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER = 8921.7912;

export const OUNCE_PER_ACRE_PER_KILOGRAM_PER_SQUARE_METER = 142748.6599;

export const OUNCES_PER_ACRE_PER_LITER_PER_SQUARE_METER = 136840;

export const POUNDS_PER_KILO = 2.20462;

export const OUNCES_PER_KILO = 35.274;

export const OUNCES_PER_POUND = 16.0;

export const FLUID_OZ_PER_GALLON = 128.0;

export const FLUID_OUNCES_PER_LITER = 0.0295735;

export const QUARTS_PER_LITER = 0.946353;

export const METER_PER_FOOT = 0.3048;

export const DECIMAL_TO_MINUTES = 60;

export const DECIMAL_MINUTES_TO_SECONDS = 60;

export const LITER_TO_QUARTS_CONVERSION_FACTOR = 32.0;

export const LITER_TO_PINTS_CONVERSION_FACTOR = 16.0;

export const SQUARE_METERS_PER_ACRE = 4046.86;

export const LITERS_PER_GALLON = 3.78541;

export const LITERS_PER_QUART = 0.946353;

export const LITERS_PER_PINT = 0.473176;

export const LITERS_PER_FLUID_OUNCE = 0.0295735;

export const volumeUnits: VolumeUnit[] = ['FLUID_OUNCES', 'GALLONS', 'LITERS', 'PINTS', 'QUARTS'];

export const massUnits: MassUnit[] = ['KILOGRAMS', 'POUNDS', 'OUNCES'];

export const volumeRateUnits: VolumeRateUnit[] = [
    'GALLONS_PER_ACRE',
    'FLUID_OUNCES_PER_ACRE',
    'LITERS_PER_SQUARE_METER',
    'PINTS_PER_ACRE',
    'QUARTS_PER_ACRE'
];

export const massRateUnits: MassRateUnit[] = [
    'KILOGRAMS_PER_SQUARE_METER',
    'POUNDS_PER_ACRE',
    'OUNCES_PER_ACRE'
];

import { Field, Formik } from 'formik';

import { PageTitle, VerticalContainer } from '@rantizo-software/rantizo-ui';

import FormContainer from 'components/FormContainer';
import FormSectionContainer from 'components/FormSectionContainer';
import FormSelectAircraftModels from 'components/FormSelectAircraftModels';
import PageContainer from 'components/PageContainer';
import SubmitButton from 'components/SubmitButton';
import WithBackArrow from 'components/WithBackArrow';
import FormInput from 'components/deprecating/FormInput';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormSelectAircraftMakes from 'components/deprecating/FormSelectAircraftMakes';

import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import {
    FLIGHT_CONTROLLER_SERIAL_TEST_ID,
    MAKE_FIELD_TEST_ID,
    MODEL_FIELD_TEST_ID,
    NICKNAME_FIELD_TEST_ID,
    REGISTRATION_NUMBER_FIELD_TEST_ID,
    SERIAL_NUMBER_FIELD_TEST_ID,
    TEST_ID
} from './constants';
import type { FormikProps, FunctionComponent, InferType, Props } from './types';

/**
 * @deprecated No new version exists yet. We need to create a new version of this.
 */
const AircraftForm: FunctionComponent<Props> = ({
    initialValues,
    isExisting = false,
    isLoading,
    onBack,
    onSubmit,
    testId = TEST_ID,
    title
}) => {
    const aircraftSchema = useSchema();
    const { BUTTON, PLACEHOLDERS, SWATH_WIDTH } = useTranslation();

    type AircraftSchemaType = InferType<typeof aircraftSchema>;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={aircraftSchema}
        >
            {({ isSubmitting, values }: FormikProps<AircraftSchemaType>) => (
                <FormContainer>
                    <PageContainer testId={testId}>
                        <VerticalContainer>
                            {title && (
                                <WithBackArrow onClick={onBack}>
                                    <PageTitle text={title} />
                                </WithBackArrow>
                            )}

                            <FormSectionContainer>
                                <Field
                                    component={FormInput}
                                    label={PLACEHOLDERS.NICKNAME}
                                    name="nickname"
                                    testId={NICKNAME_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormSelectAircraftMakes}
                                    disabled={isExisting}
                                    label={PLACEHOLDERS.MAKE}
                                    name="make"
                                    testId={MAKE_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormSelectAircraftModels}
                                    disabled={isExisting || values.make === ''}
                                    droneMake={values.make}
                                    label={PLACEHOLDERS.MODEL}
                                    name="model"
                                    testId={MODEL_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormInput}
                                    disabled={isExisting}
                                    label={PLACEHOLDERS.REGISTRATION_NUMBER}
                                    name="registrationNumber"
                                    testId={REGISTRATION_NUMBER_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormInput}
                                    disabled={isExisting}
                                    label={PLACEHOLDERS.SERIAL_NUMBER}
                                    name="serialNumber"
                                    testId={SERIAL_NUMBER_FIELD_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormInput}
                                    disabled={isExisting}
                                    label={PLACEHOLDERS.FLIGHT_CONTROLLER_SERIAL}
                                    name="flightControllerSerial"
                                    testId={FLIGHT_CONTROLLER_SERIAL_TEST_ID}
                                    type="text"
                                />

                                <Field
                                    component={FormNumberInput}
                                    isEditable={true}
                                    label={SWATH_WIDTH}
                                    name="defaultSwathWidthMeters"
                                    precision={2}
                                    rightSection={<></>}
                                />
                            </FormSectionContainer>
                        </VerticalContainer>

                        <SubmitButton
                            isDisabled={isSubmitting || isLoading}
                            isLoading={isSubmitting || isLoading}
                            text={BUTTON}
                        />
                    </PageContainer>
                </FormContainer>
            )}
        </Formik>
    );
};

export default AircraftForm;

import { useCallback, useEffect, useState } from 'react';

import { SecondaryTitle } from '@rantizo-software/rantizo-ui';

import AAMDownloadShareButtons from 'components/AAMDownloadShareButtons';
import AsAppliedMapsDetails from 'components/AsAppliedMapDetails';
import FormSectionContainer from 'components/FormSectionContainer';
import Legend from 'components/Legend';
import MapContainer from 'components/MapContainer';
import MapLoader from 'components/MapLoader';
import Mapbox from 'components/Mapbox';
import SideBarContentContainer from 'components/SideBarContentContainer';

import useInterval from 'hooks/useInterval';
import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { CHECK_MAP_LOADED_INTERVAL, TEST_ID, WAIT_FOR_MAPS_TO_LOAD } from './constants';
import type {
    AsAppliedMap,
    FunctionComponent,
    ImageSourceRaw,
    Map,
    MapLegend,
    MapboxEvent,
    Props
} from './types';

import styles from './styles.module.scss';

const AsAppliedMapPage: FunctionComponent<Props> = ({
    aamId,
    children,
    jobId,
    mapType,
    onLoad,
    onUnload,
    productUsageReportId,
    testId = TEST_ID
}) => {
    const {
        getChemicalsFromProductUsageReport,
        getSiteNameFromProductUsageReport,
        loadAamById,
        loadMap,
        renderMap
    } = useData();

    const { fetchAddressFromCoordinates } = useMapboxGeocoding();

    const { APPLICATION_DATA, TITLE } = useTranslation();

    const [aam, setAam] = useState<AsAppliedMap>();
    const [coords, setCoords] = useState<number[][]>([]);
    const [dataUrl, setDataUrl] = useState<string>('');
    const [mapRef, setMapRef] = useState<Map>();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [isStyleLoaded, setIsStyleLoaded] = useState<boolean>(false);
    const [legend, setLegend] = useState<MapLegend[]>();
    const [mapUrl, setMapUrl] = useState<string>();
    const [chemicalNames, setChemicalNames] = useState<string | null>();
    const [applicationSiteName, setApplicationSiteName] = useState<string | null>();
    const [asAppliedMapsLocation, setAsAppliedMapsLocation] = useState<string>('');

    // TODO find a better solution than this at some point
    const handleInterval = useCallback(() => {
        const map = mapRef;

        if (map?.isStyleLoaded()) {
            // Delay setting canvas url by DELAY_SET_CANVAS_URL milliseconds, since maps are still empty
            // for a split second once style is loaded for some reason
            setTimeout(() => setMapUrl(map.getCanvas().toDataURL()), WAIT_FOR_MAPS_TO_LOAD);
            setIsStyleLoaded(true);

            if (mapRef && coords.length > 0) {
                const box: [number, number, number, number] = [
                    coords[3][0],
                    coords[3][1],
                    coords[1][0],
                    coords[1][1]
                ];

                mapRef.fitBounds(box, { linear: true, padding: 20 });
            }
        }
    }, [mapRef, coords]);

    const handleMapLoaded = useCallback((event: MapboxEvent) => {
        setMapRef(event.target);
        setMapLoaded(true);
    }, []);

    const fetchAam = useCallback(async () => {
        const fetchedAam = await loadAamById(aamId);

        setAam(fetchedAam);

        if (fetchedAam) {
            const centerPoint = fetchedAam.centerPoint.coordinates;
            const address = await fetchAddressFromCoordinates(centerPoint[1], centerPoint[0]);

            setAsAppliedMapsLocation(address);

            const mapResult = await loadMap(fetchedAam);

            if (mapResult) {
                const { coords: newCoords, dataUrl: newDataUrl, legend: newLegend } = mapResult;

                setCoords(newCoords);

                if (newDataUrl) {
                    setDataUrl(newDataUrl);
                }
                setLegend(newLegend);
            }
        }
    }, [aamId, fetchAddressFromCoordinates, loadAamById, loadMap]);

    const fetchChemicalNames = useCallback(async () => {
        const chemicalNames = await getChemicalsFromProductUsageReport(productUsageReportId);

        setChemicalNames(chemicalNames);
    }, [getChemicalsFromProductUsageReport, productUsageReportId]);

    const fetchApplicationSiteName = useCallback(async () => {
        const siteNames = await getSiteNameFromProductUsageReport(productUsageReportId);

        setApplicationSiteName(siteNames);
    }, [getSiteNameFromProductUsageReport, productUsageReportId]);

    useEffect(
        () => {
            fetchChemicalNames();
            fetchApplicationSiteName();
            fetchAam();

            onLoad?.();

            return () => {
                onUnload?.();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [aamId]
    );

    useEffect(() => {
        if (mapRef) {
            renderMap(mapRef, dataUrl, coords);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, dataUrl]);

    useInterval(handleInterval, mapLoaded && !isStyleLoaded ? CHECK_MAP_LOADED_INTERVAL : null);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer className={styles.formSectionAlignment}>
                <SecondaryTitle text={TITLE} />

                <MapContainer className={styles.map}>
                    {(!coords || !dataUrl) && <MapLoader />}

                    {coords && dataUrl && (
                        <Mapbox
                            mapSource={
                                {
                                    coordinates: coords,
                                    data: aam!.asAppliedMapFile,
                                    type: 'image'
                                } as ImageSourceRaw
                            }
                            initialOptions={{ preserveDrawingBuffer: true }}
                            onMapLoaded={handleMapLoaded}
                        />
                    )}
                </MapContainer>

                <SecondaryTitle text={APPLICATION_DATA} />

                {legend && mapType === 'AAM' && <Legend legend={legend} />}

                {aam && (
                    <AsAppliedMapsDetails
                        aam={aam}
                        chemicalNames={chemicalNames ?? ''}
                        mapType={mapType}
                        siteName={applicationSiteName ? applicationSiteName : asAppliedMapsLocation}
                    />
                )}

                {children}

                {aam && legend && mapUrl && mapType && (
                    <AAMDownloadShareButtons
                        aam={aam}
                        chemicalNames={chemicalNames ?? ''}
                        jobId={jobId}
                        legend={legend}
                        mapType={mapType}
                        mapUrl={mapUrl}
                        productUsageReportId={productUsageReportId}
                        siteName={applicationSiteName ? applicationSiteName : asAppliedMapsLocation}
                    />
                )}
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default AsAppliedMapPage;

import { IMAGE } from './constants';

const FieldViewIcon = () => (
    <svg
        fill="none"
        height="51"
        viewBox="0 0 51 51"
        width="51"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g filter="url(#filter0_d_11662_3686)">
            <rect fill="url(#pattern0_11662_3686)" height="43" rx="10" width="43" x="4" y="1" />
        </g>

        <defs>
            <filter
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
                height="51"
                id="filter0_d_11662_3686"
                width="51"
                x="0"
                y="0"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />

                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />

                <feOffset dy="3" />

                <feGaussianBlur stdDeviation="2" />

                <feComposite in2="hardAlpha" operator="out" />

                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />

                <feBlend
                    in2="BackgroundImageFix"
                    mode="normal"
                    result="effect1_dropShadow_11662_3686"
                />

                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_11662_3686"
                    mode="normal"
                    result="shape"
                />
            </filter>

            <pattern
                height="1"
                id="pattern0_11662_3686"
                patternContentUnits="objectBoundingBox"
                width="1"
            >
                <use transform="scale(0.00111111)" xlinkHref="#image0_11662_3686" />
            </pattern>

            <image height="900" id="image0_11662_3686" width="900" xlinkHref={IMAGE} />
        </defs>
    </svg>
);

export default FieldViewIcon;

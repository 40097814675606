import ProductUsageReportContentContainer from 'components/ProductUsageReport/ProductUsageReportContentContainer';
import WorkOrderLabelValuePair from 'components/WorkOrderLabelValuePair';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ProductUsageReportNotes: FunctionComponent<Props> = ({
    productUsageReport,
    testId = TEST_ID
}) => {
    const { NOTES } = useTranslation();

    return (
        <ProductUsageReportContentContainer testId={testId}>
            <WorkOrderLabelValuePair label={NOTES} value={productUsageReport.notes} />
        </ProductUsageReportContentContainer>
    );
};

export default ProductUsageReportNotes;

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('shareToFieldView');

    return {
        FIELD_VIEW: t('fieldView'),
        MANAGE_CONNECTIONS: t('manageConnections'),
        SHARE_ERROR_MESSAGE: t('shareError'),
        SHARE_SUCCESS_MESSAGE: t('shareSuccess')
    };
};

export default useTranslation;

import { useMemo } from 'react';

import FlightReportListRow from 'components/FlightReportListRow';
import ListText from 'components/ListText';
import Checkbox from 'components/form/Checkbox';

import useDate from 'hooks/useDate';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlightReportListItem: FunctionComponent<Props> = ({
    flightReport,
    isEditable,
    isSelected = false,
    onCheckboxChange,
    testId = TEST_ID
}) => {
    const { convertMillisecondsToDecimalTimeString } = useDate();

    const { date, durationMilliseconds, id, location, pilot, totalFlights } = flightReport;

    const displayDate = useMemo(() => new Date(date).toLocaleDateString(), [date]);

    const displayFlightTime = useMemo(
        () => convertMillisecondsToDecimalTimeString(durationMilliseconds),
        [convertMillisecondsToDecimalTimeString, durationMilliseconds]
    );

    return (
        <FlightReportListRow
            className={styles.flightReportListItem}
            isEditable={isEditable}
            testId={testId}
        >
            {isEditable && (
                <Checkbox
                    className={styles.checkbox}
                    isChecked={isSelected}
                    onChange={event => onCheckboxChange(event?.target?.checked ?? false, id)}
                />
            )}

            <ListText text={displayDate} />

            <ListText text={displayFlightTime} />

            <ListText text={totalFlights} />

            <ListText className={styles.location} text={location} />

            <ListText text={pilot} />
        </FlightReportListRow>
    );
};

export default FlightReportListItem;

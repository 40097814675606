import { useCallback, useState } from 'react';

import { round } from '@@utils/math';

import useTranslation from 'components/JohnDeereExportModal/hooks/useTranslation';
import type {
    JohnDeereLegend,
    JohnDeereRange,
    JohnDeereValue,
    MapExportParams,
    MapLegend
} from 'components/JohnDeereExportModal/types';

import type { SystemOfMeasurement } from 'hooks/useAsAppliedMaps/types';
import useJohnDeere from 'hooks/useJohnDeere';
import usePageRoutes from 'hooks/usePageRoutes';

const useData = () => {
    const {
        authorizeOrganization,
        exportMap,
        fetchJohnDeereFields,
        fetchJohnDeereOrganizations,
        fetchTokenOrRedirectAndLogIn,
        logout,
        token
    } = useJohnDeere();

    const [organizations, setOrganizations] = useState<JohnDeereValue[]>([]);
    const [fields, setFields] = useState<JohnDeereValue[]>([]);
    const [hasConnection, setHasConnection] = useState(false);

    const { FIELD_ERROR, ORGANIZATION_ERROR } = useTranslation();

    const loadOrganizations = useCallback(async () => {
        try {
            const organizations = await fetchJohnDeereOrganizations();

            setOrganizations(organizations);
        } catch (error) {
            console.error(error);
            alert(ORGANIZATION_ERROR);
        }
    }, [fetchJohnDeereOrganizations, ORGANIZATION_ERROR]);

    const { mapPage } = usePageRoutes();

    const loadJohnDeereData = useCallback(
        async (jobId: string, mapType: string) => {
            const token = await fetchTokenOrRedirectAndLogIn(mapPage(jobId, mapType));

            if (token) {
                setHasConnection(true);
                await loadOrganizations();
            }
        },
        [fetchTokenOrRedirectAndLogIn, loadOrganizations, mapPage]
    );

    const disconnectFromJohnDeere = useCallback(() => {
        logout();
        setHasConnection(false);
        setOrganizations([]);
        setFields([]);
    }, [logout]);

    const loadFieldsForOrganization = useCallback(
        async (organizationId: string) => {
            try {
                const organization = organizations.find(({ id }) => id === organizationId);

                setFields([]);

                if (organization) {
                    await authorizeOrganization(organization, window.location.href);
                    const fieldResponse = await fetchJohnDeereFields(organizationId);

                    setFields(fieldResponse);
                }
            } catch (error) {
                console.error(error);
                alert(FIELD_ERROR);
            }
        },
        [organizations, authorizeOrganization, fetchJohnDeereFields, FIELD_ERROR]
    );

    const coverageMapLegend = useCallback(
        (
            color: string,
            rate: number,
            systemOfMeasurement: SystemOfMeasurement
        ): JohnDeereLegend => {
            const unit = systemOfMeasurement === 'IMPERIAL' ? 'gal/ac' : 'l/m²';
            const label = `${round(rate, 2)} ${unit}`;

            return {
                ranges: [
                    {
                        hexColor: color,
                        label: label,
                        maximum: rate,
                        minimum: rate,
                        percent: 1.0
                    }
                ],
                unitId: 'gal1ac-1'
            };
        },
        []
    );

    const mapLegendToJohnDeereLegend = useCallback((mapLegend: MapLegend[]): JohnDeereLegend => {
        // Convert the MapLegend array to a JohnDeereRange array
        const ranges: JohnDeereRange[] = mapLegend.map(legend => {
            const unit = legend.systemOfMeasurement === 'IMPERIAL' ? 'gal/sec' : 'l/sec';
            const label = `${round(legend.minValue, 2)} - ${round(legend.maxValue, 2)} ${unit}`;

            return {
                hexColor: legend.color,
                label: label,
                maximum: legend.maxValue,
                minimum: legend.minValue,
                percent: legend.percentage
            };
        });

        return {
            ranges: ranges,
            unitId: 'gal1ac-1'
        };
    }, []);

    const exportMapToJohnDeere = useCallback(
        async (params: MapExportParams) => {
            const { aam, description, fieldId, mapType, metaData, organizationId, title } = params;

            if (!aam.mapImage) {
                throw new Error('Map Image Does Not Exist');
            }

            const johnDeereLegend =
                mapType === 'AAM'
                    ? mapLegendToJohnDeereLegend(aam.legend)
                    : coverageMapLegend(
                          aam.legend[0].color,
                          aam.appliedRate.value,
                          aam.fileSystemOfMeasurement
                      );

            const mapRequest = {
                description: description,
                extent: aam.extent,
                fieldId: fieldId,
                legend: johnDeereLegend,
                mapImage: aam.mapImage,
                metaData: metaData,
                organizationId: organizationId,
                title: title,
                token: token?.accessToken ?? ''
            };

            await exportMap(mapRequest);
        },
        [mapLegendToJohnDeereLegend, coverageMapLegend, token?.accessToken, exportMap]
    );

    return {
        disconnectFromJohnDeere,
        exportMapToJohnDeere,
        fields,
        hasConnection,
        loadFieldsForOrganization,
        loadJohnDeereData,
        organizations
    };
};

export default useData;

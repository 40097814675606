export const TEST_ID = 'detailsPage';

export const APPLICATOR_DROPDOWN_TEST_ID = 'applicatorDropdown';

export const EXPIRATION_DATE_FIELD_TEST_ID = 'expirationDate';

export const PROPOSED_DATE_FIELD_TEST_ID = 'proposedDate';

export const SCHEDULED_DATE_FIELD_TEST_ID = 'scheduledDate';

export const SCHEDULED_TIME_FIELD_TEST_ID = 'scheduledTime';

export const WORK_ORDER_FILE_UPLOAD_BUTTON_TEST_ID = 'workOrderFileUploadButton';

export const WORK_ORDER_NUMBER_FIELD_TEST_ID = 'workOrderNumber';

export const WORK_ORDER_STATUS_SELECT_TEST_ID = 'workOrderStatus';

import { useCallback } from 'react';

import useFileUploadHandler from 'hooks/useFileUploadHandler';
import useKmlValidation from 'hooks/useKmlValidation';

import { KML_FILE_UPLOAD_ENDPOINT } from './constants';
import type { FileUpload, FileUploadHandlerHook } from './types';

const useKmlFileUpload: FileUploadHandlerHook = ({ selectedAircraft }) => {
    const {
        addUploadedFile,
        getPresignedUrlFromEndpoint,
        getUploadedFiles,
        isValidFile,
        setUploadedFiles
    } = useFileUploadHandler({
        validFileExtension: 'KML'
    });

    const getPresignedUrl = useCallback(
        async (fileUpload: FileUpload) =>
            await getPresignedUrlFromEndpoint(fileUpload, KML_FILE_UPLOAD_ENDPOINT),
        [getPresignedUrlFromEndpoint]
    );

    const { sanitizeFile, validateKmlFileAttribute } = useKmlValidation();

    const modifyFileRequest = useCallback(
        async (fileUpload: FileUpload): Promise<FileUpload> => await sanitizeFile(fileUpload),
        [sanitizeFile]
    );

    const validateFile = useCallback(
        async (fileUpload: FileUpload) => {
            const { file } = fileUpload;

            if (!isValidFile(file)) {
                throw new Error('INVALID_EXTENSION');
            }

            const parser = new DOMParser();
            const text = await file.text();

            const parsedFile = parser.parseFromString(text, 'application/xml');

            const modeSelection = parsedFile.querySelector('Data[name="Mode Selection"]');
            const modeValue = modeSelection?.querySelector('value')?.textContent;

            if (!modeValue || !modeValue.includes('Auto')) {
                throw new Error('INVALID_FLIGHT_MODE');
            }

            validateKmlFileAttribute(parsedFile, 'Flight Controller ID');

            const flightControllerIdElement = parsedFile.querySelector(
                'Data[name="Flight Controller ID"]'
            );
            const flightControllerIdElementValue =
                flightControllerIdElement?.querySelector('value')?.textContent;

            if (flightControllerIdElementValue !== selectedAircraft.flightControllerSerial) {
                throw new Error('AIRCRAFT_MISMATCH');
            }

            validateKmlFileAttribute(parsedFile, 'Flight Time');
            validateKmlFileAttribute(parsedFile, 'Height');
            validateKmlFileAttribute(parsedFile, 'Route Spacing');
            validateKmlFileAttribute(parsedFile, 'Task Area');

            return false;
        },
        [isValidFile, selectedAircraft, validateKmlFileAttribute]
    );

    return {
        addUploadedFile,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        validateFile
    };
};

export default useKmlFileUpload;

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ResponsiveText: FunctionComponent<Props> = props => {
    const { text } = props;

    const {
        className = '',
        desktopText = text,
        mobileText = text,
        tabletText = text,
        testId = TEST_ID
    } = props;

    return (
        <span className={className} data-testid={testId}>
            <span className={styles.mobile}>{mobileText}</span>

            <span className={styles.tablet}>{tabletText}</span>

            <span className={styles.desktop}>{desktopText}</span>
        </span>
    );
};

export default ResponsiveText;

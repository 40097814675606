import { forwardRef } from 'react';

import { ClockIcon } from '@rantizo-software/rantizo-ui';

import InputWithIcons from 'components/InputWithIcons';
import InputWithLabel from 'components/InputWithLabel';

import { TEST_ID } from './constants';
import styles from './style.module.scss';
import type { Props } from './types';

const TimePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className = '',
        hasError = false,
        isRequired = false,
        label = '',
        name = '',
        onChange,
        onSubmit,
        placeholder = '',
        testId = TEST_ID,
        value
    } = props;

    return (
        <InputWithLabel
            className={`${styles.timePicker} ${className}`}
            testId={testId}
            text={label}
        >
            <InputWithIcons
                hasError={hasError}
                isRequired={isRequired}
                name={name}
                onChange={onChange}
                onSubmit={onSubmit}
                placeholder={placeholder}
                ref={ref}
                type="time"
                value={value}
            >
                <ClockIcon />
            </InputWithIcons>
        </InputWithLabel>
    );
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;

import MenuButton from 'components/MenuButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FilesMenu: FunctionComponent<Props> = ({ className = '', onDelete, testId = TEST_ID }) => {
    const { DELETE } = useTranslation();

    const items = [
        {
            isDelete: true,
            onClick: onDelete,
            text: DELETE
        }
    ];

    return (
        <MenuButton className={`${className} ${styles.filesMenu}`} items={items} testId={testId} />
    );
};

export default FilesMenu;

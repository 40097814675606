import ListAddButton from 'components/ListAddButton';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';
import WorkOrderTabs from 'components/WorkOrderTabs';

import useFeatureAccess from 'hooks/useFeatureAccess';

import useTranslation from './hooks/useTranslation';

import { FEATURE, TEST_ID } from './constants';
import type { Props } from './types';

const WorkOrderListPageLayoutHeader = ({
    onAddClick,
    onTabChange,
    pageTitle,
    testId = TEST_ID
}: Props) => {
    const { CREATE_WORK_ORDER } = useTranslation();

    const features = useFeatureAccess();

    const isFeatureAccessible = features[FEATURE];

    return (
        <ListPageLayoutHeader pageTitle={pageTitle} testId={testId}>
            <ListAddButton
                isDisabled={!isFeatureAccessible}
                onClick={onAddClick}
                text={CREATE_WORK_ORDER}
            />

            <WorkOrderTabs onChange={onTabChange} />
        </ListPageLayoutHeader>
    );
};

export default WorkOrderListPageLayoutHeader;

import { useCallback, useEffect } from 'react';

import useFieldView from 'hooks/useFieldView';

import type { Props } from './types';

const useFieldViewConnection = ({ code }: Props) => {
    const {
        createFieldviewConnection,
        deleteFieldViewConnection,
        fetchTokenOrRedirectAndLogIn,
        getFieldViewConnectionStatus,
        isFieldViewConnected,
        isLoading: isFieldViewLoading
    } = useFieldView();

    const handleDeleteFieldViewConnection = useCallback(async () => {
        await deleteFieldViewConnection();
        code.current = undefined;
    }, [code, deleteFieldViewConnection]);

    const fetchFieldViewConnectionStatus = useCallback(async () => {
        await getFieldViewConnectionStatus();
    }, [getFieldViewConnectionStatus]);

    const handleCreateFieldViewConnectionStatus = useCallback(async () => {
        if (code.current && isFieldViewConnected === false) {
            await createFieldviewConnection(code.current);
        }
    }, [code, createFieldviewConnection, isFieldViewConnected]);

    useEffect(
        () => {
            fetchFieldViewConnectionStatus();

            window.history.replaceState(null, '', window.location.pathname);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        handleCreateFieldViewConnectionStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFieldViewConnected]);

    return {
        fetchFieldViewConnectionStatus,
        fetchTokenOrRedirectAndLogIn,
        handleDeleteFieldViewConnection,
        isFieldViewConnected,
        isFieldViewLoading
    };
};

export default useFieldViewConnection;

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import OrganizationListItem from 'components/OrganizationListItem';

import useAccessToken from 'hooks/useAccessToken';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useOrganizations from 'hooks/useOrganizations';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Organization, Organizations, Props } from './types';

const OrganizationList: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { homePage } = usePageRoutes();
    const { fetchToken } = useAccessToken();
    const { ERROR } = useTranslation();
    const { fetchOrganizations, organizations: storedOrganizations } = useOrganizations();
    const { setCurrentOrganization } = useCurrentOrganization();
    const [organizations, setOrganizations] = useState<Organizations>(storedOrganizations);

    const loginToOrg = useCallback(
        async (org: Organization) => {
            const token = await fetchToken(org.auth0Id, false);

            if (token) {
                await setCurrentOrganization(org);
                navigate(homePage);

                return;
            }

            alert(ERROR);
        },
        [ERROR, fetchToken, homePage, navigate, setCurrentOrganization]
    );

    useEffect(() => {
        const fetch = async () => {
            const { data, error } = await fetchOrganizations();

            if (!error && data) {
                setOrganizations(data);
            }
        };

        if (storedOrganizations === null) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOrganizations]);

    useEffect(() => {
        const loginToOnlyOrg = async () => {
            const onlyOrg = storedOrganizations[0];

            await loginToOrg(onlyOrg);
        };

        if (storedOrganizations?.length === 1) {
            loginToOnlyOrg();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedOrganizations]);

    const handleClick = useCallback(async (org: Organization) => loginToOrg(org), [loginToOrg]);

    return organizations?.length > 0 ? (
        <VerticalContainer testId={testId}>
            {organizations.map(org => (
                <OrganizationListItem
                    key={org.id}
                    onClick={() => handleClick(org)}
                    text={org.name}
                />
            ))}
        </VerticalContainer>
    ) : null;
};

export default OrganizationList;

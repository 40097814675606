import * as Yup from 'yup';

import { area, kinks } from '@turf/turf';

import type {
    Feature,
    FeatureCollection,
    MixedSchema,
    NumberSchema,
    Point,
    StringSchema
} from './types';

declare module 'yup' {
    interface MixedSchema {
        boundaryMaxArea(maxAreaSquareMeters: number, errorMessage?: string): MixedSchema;
        boundaryValid(errorMessage?: string): MixedSchema;
        requiredIfAnySiblingPresent(errorMessage?: string): MixedSchema;
    }

    interface NumberSchema {
        requiredIfAnySiblingPresent(errorMessage?: string): NumberSchema;
    }

    interface StringSchema {
        requiredIfAnySiblingPresent(errorMessage?: string): StringSchema;
    }
}

function boundaryMaxArea(
    this: MixedSchema,
    maxAreaSquareMeters: number,
    errorMessage = '${path} exceeds maximum acreage'
) {
    return this.test({
        exclusive: false,
        message: errorMessage,
        name: 'boundaryMaxArea',
        test: value => {
            const typedValue = value as Feature;

            if (typedValue?.geometry?.type === 'Point') {
                return true;
            }

            return area(typedValue) < maxAreaSquareMeters;
        }
    });
}

function boundaryValid(this: MixedSchema, errorMessage = '${path} is not a valid boundary') {
    return this.test({
        exclusive: false,
        message: errorMessage,
        name: 'boundaryValid',
        test: value => {
            const feature = value as Feature;

            if (feature?.geometry?.type === 'Point') {
                return true;
            }

            return (kinks(feature) as FeatureCollection<Point>).features.length === 0;
        }
    });
}

function requiredIfAnySiblingPresent(
    this: MixedSchema | NumberSchema | StringSchema,
    errorMessage = '${path} is required'
) {
    return this.test({
        exclusive: false,
        message: errorMessage,
        name: 'requiredIfAnySiblingPresent',
        test: function (value, context) {
            if (value != null && value !== '') {
                return true;
            }
            const siblingValues = Object.values(context.parent).filter(val => val !== value);
            const anySiblingsFilled = siblingValues.some(
                siblingValue =>
                    typeof siblingValue !== 'object' &&
                    siblingValue != null &&
                    `${siblingValue}`.trim() !== ''
            );

            return (
                !anySiblingsFilled ||
                this.createError({
                    message: errorMessage,
                    path: context.path
                })
            );
        }
    });
}

Yup.addMethod(Yup.mixed, 'boundaryMaxArea', boundaryMaxArea);
Yup.addMethod(Yup.mixed, 'boundaryValid', boundaryValid);
Yup.addMethod(Yup.mixed, 'requiredIfAnySiblingPresent', requiredIfAnySiblingPresent);
Yup.addMethod(Yup.number, 'requiredIfAnySiblingPresent', requiredIfAnySiblingPresent);
Yup.addMethod(Yup.string, 'requiredIfAnySiblingPresent', requiredIfAnySiblingPresent);

export default Yup;

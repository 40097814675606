import { INPUT_CHARS_MAX } from 'config';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { ImageContainer } from '@rantizo-software/rantizo-ui';
import { centroid } from '@turf/turf';

import CloseButton from 'components/CloseButton';
import CoordinateFields from 'components/CoordinateFields';
import Divider from 'components/Divider';
import ErrorMessageList from 'components/ErrorMessageList';
import FormSectionContainer from 'components/FormSectionContainer';
import RowSpaceBetween from 'components/RowSpaceBetween';
import SecondaryButton from 'components/SecondaryButton';
import AddressFields from 'components/deprecating/AddressFields';
import FormInput from 'components/deprecating/FormInput';
import FormMapBoundary from 'components/form/FormMapBoundary';

import useLocation from 'hooks/useLocation';
import useMapboxGeocoding from 'hooks/useMapboxGeocoding';

import useTranslation from './hooks/useTranslation';

import { MAX_LATITUDE, MAX_LONGITUDE, MIN_LATITUDE, MIN_LONGITUDE, TEST_ID } from './constants';
import type { Feature, FunctionComponent, Point, Props, WorkOrderForm } from './types';

import styles from './styles.module.scss';

const ApplicationSiteFields: FunctionComponent<Props> = ({
    canClose = false,
    hasProductUsageReport = false,
    index,
    isEditable = true,
    onClose,
    testId = TEST_ID
}) => {
    const { APPLICATION_SITE, HIDE_MAP, SHOW_MAP } = useTranslation();
    const { setFieldValue, values } = useFormikContext<WorkOrderForm>();

    const [showMap, setShowMap] = useState(index === 0);

    const [mapResetKey, setMapResetKey] = useState<string>(uuid());

    const { currentLocation, fetchCurrentLocation } = useLocation();
    const [addressLocation, setAddressLocation] = useState<number[] | null>(null);

    const { getAddressLocation } = useMapboxGeocoding();

    const {
        boundary: siteBoundary = [],
        coordinates,
        location
    } = useMemo(() => values.applicationSites[index], [index, values.applicationSites]);

    const locationCoordinate = useMemo(() => {
        const { latitude, longitude } = coordinates || {};

        if (latitude && longitude) {
            const isValidLatitude = latitude >= MIN_LATITUDE && latitude <= MAX_LATITUDE;
            const isValidLongitude = longitude >= MIN_LONGITUDE && longitude <= MAX_LONGITUDE;

            if (isValidLatitude && isValidLongitude) {
                return [longitude, latitude];
            }
        } else if (addressLocation) {
            return addressLocation;
        }

        return currentLocation;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation, index, coordinates, addressLocation]);

    const fetchAddressLocation = useCallback(async () => {
        if (location) {
            setAddressLocation(await getAddressLocation(location));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => fetchCurrentLocation(), [fetchCurrentLocation]);

    useEffect(() => {
        fetchAddressLocation();
    }, [fetchAddressLocation]);

    useEffect(
        () => {
            const boundary: Feature[] = siteBoundary;
            const { latitude, longitude } = coordinates || {};

            if (boundary.length > 0) {
                const pointFeatures = boundary.filter(f => f.geometry?.type === 'Point');

                if (pointFeatures.length > 0) {
                    const featurePoint = pointFeatures[0]?.geometry as Point;

                    if (featurePoint) {
                        setFieldValue(
                            `applicationSites.${index}.coordinates.longitude`,
                            featurePoint.coordinates[0]
                        );

                        setFieldValue(
                            `applicationSites.${index}.coordinates.latitude`,
                            featurePoint.coordinates[1]
                        );
                    }
                } else {
                    // set the coordinates to the center of the boundary
                    const firstBoundaryCenter = centroid(boundary[0].geometry);

                    if (firstBoundaryCenter?.geometry?.coordinates) {
                        const { coordinates } = firstBoundaryCenter.geometry;

                        setFieldValue(
                            `applicationSites.${index}.coordinates.longitude`,
                            coordinates[0]
                        );

                        setFieldValue(
                            `applicationSites.${index}.coordinates.latitude`,
                            coordinates[1]
                        );
                    }
                }
            } else if (latitude && longitude && !isEditable) {
                const point = {
                    coordinates: [longitude, latitude],
                    type: 'Point'
                };

                setFieldValue(`applicationSites.${index}.boundary`, [
                    {
                        geometry: point,
                        id: uuid(),
                        type: 'Feature'
                    }
                ]);

                setMapResetKey(uuid());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [siteBoundary, isEditable]
    );

    return (
        <FormSectionContainer className={styles.applicationSiteFields} testId={testId}>
            <FormSectionContainer>
                <Field
                    label={
                        canClose ? (
                            <RowSpaceBetween>
                                {APPLICATION_SITE}

                                {isEditable && (
                                    <CloseButton className={styles.closeButton} onClick={onClose} />
                                )}
                            </RowSpaceBetween>
                        ) : (
                            APPLICATION_SITE
                        )
                    }
                    className={styles.siteName}
                    component={FormInput}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    max={INPUT_CHARS_MAX}
                    name={`applicationSites.${index}.siteName`}
                />

                <AddressFields
                    isDisabled={hasProductUsageReport}
                    isEditable={isEditable}
                    namePrefix={`applicationSites.${index}.`}
                />
            </FormSectionContainer>

            <CoordinateFields
                coordinates={coordinates}
                hasProductUsageReport={hasProductUsageReport}
                index={index}
                isEditable={isEditable}
            />

            <ErrorMessageList name={`applicationSites.${index}.coordinates`} />

            {showMap && (
                <Field
                    center={locationCoordinate}
                    className={styles.boundary}
                    component={FormMapBoundary}
                    isDisabled={hasProductUsageReport}
                    isEditable={isEditable}
                    key={mapResetKey}
                    name={`applicationSites.${index}.boundary`}
                />
            )}

            <ErrorMessageList name={`applicationSites.${index}.boundary`} />

            {values.applicationSites[index].imageUrl && (
                <ImageContainer src={values.applicationSites[index].imageUrl ?? ''} />
            )}

            <SecondaryButton
                onClick={() => setShowMap(showMap => !showMap)}
                text={showMap ? HIDE_MAP : SHOW_MAP}
            />

            <Divider />
        </FormSectionContainer>
    );
};

export default ApplicationSiteFields;

import { AlertIcon, BodyText } from '@rantizo-software/rantizo-ui';

import TrashIconButton from 'components/TrashIconButton';
import WithTooltip from 'components/WithTooltip';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FileListRow: FunctionComponent<Props> = ({ error, fileName, onDelete, testId = TEST_ID }) => (
    <div className={styles.fileListRow} data-testid={testId}>
        <BodyText className={styles.fileListText} text={fileName} />

        {!!error && (
            <WithTooltip text={error}>
                <AlertIcon className={styles.alertIcon} />
            </WithTooltip>
        )}

        {onDelete && <TrashIconButton className={styles.trashButton} onClick={onDelete} />}
    </div>
);

export default FileListRow;

import { useCallback, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useAccessToken from 'hooks/useAccessToken';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';
import useUsers from 'hooks/useUsers';

import { EDIT_ACCOUNT_API } from './constants';
import type { EditUserData, MyUser } from './types';

const useAccount = () => {
    const { getValue, setValue } = useGlobalStore({ namespace: 'useAccount' });

    const { decodedToken, fetchToken } = useAccessToken();
    const { isAuthenticated, user: userToken } = useAuth0();
    const { currentOrganization, fetchCurrentOrganization } = useCurrentOrganization();
    const { authenticatedPut } = useFetch();
    const { fetchUser } = useUsers();
    const user = getValue('myUser') as MyUser;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveMyUserToStore = useCallback(setValue('myUser'), []);

    const resolveUserData = useCallback(async () => {
        saveMyUserToStore(undefined);
        const { data, error } = await fetchUser({ userId: 'me' });

        if (error) {
            saveMyUserToStore(null);

            return;
        }

        saveMyUserToStore(data);
    }, [fetchUser, saveMyUserToStore]);

    const updateUserData = useCallback(
        async (data: EditUserData) => {
            const { firstName, lastName, phone } = data;

            const requestData = {
                firstName,
                lastName,
                phoneNumber: phone
                    ? {
                          countryCode: '1',
                          phone: phone?.replaceAll(/[-() ]/g, '')
                      }
                    : undefined
            };

            const response = await authenticatedPut(EDIT_ACCOUNT_API, JSON.stringify(requestData));

            if (response.data) {
                await resolveUserData();
            }

            return response;
        },
        [authenticatedPut, resolveUserData]
    );

    const resolveCurrentOrganization = useCallback(async () => {
        if (currentOrganization === undefined) {
            const org_id = decodedToken?.org_id as string | undefined;

            if (org_id) {
                await fetchCurrentOrganization();
            }
        }
    }, [currentOrganization, decodedToken?.org_id, fetchCurrentOrganization]);

    useEffect(() => {
        const fetchAccessToken = async () => {
            if (isAuthenticated) {
                await fetchToken();
            }
        };

        fetchAccessToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const resolveAccountData = async () => {
            if (user === null) {
                await resolveUserData();
            }
        };

        resolveAccountData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        resolveCurrentOrganization();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedToken?.org_id]);

    const isEmailVerified = userToken?.email_verified;
    const isAccountComplete =
        user === null
            ? false
            : user === undefined || (user?.firstName !== '' && user?.lastName !== '');
    const hasCurrentOrganization = currentOrganization !== undefined;
    const isOnboardingComplete = isEmailVerified && isAccountComplete && hasCurrentOrganization;

    return {
        hasCurrentOrganization,
        isAccountComplete,
        isEmailVerified,
        isOnboardingComplete,
        resolveUserData,
        saveMyUserToStore,
        updateUserData,
        user
    };
};

export default useAccount;

import { forwardRef, useRef } from 'react';

import Chip from 'components/Chip';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const NegativeChip = forwardRef<HTMLDivElement, Props>(
    ({ children, className = '', isEnabled = true, onClick, testId = TEST_ID, text = '' }, ref) => {
        const chipRef = useRef(ref);

        return (
            <Chip
                className={`${className} ${styles.negativeChip}`}
                isDisabled={!isEnabled}
                onClick={onClick}
                ref={chipRef.current}
                testId={testId}
            >
                {children || text}
            </Chip>
        );
    }
);

NegativeChip.displayName = 'NegativeChip';

export default NegativeChip;

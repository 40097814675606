import { useCallback, useMemo, useRef, useState } from 'react';

import Chip from 'components/Chip';
import WithDropdownMenu from 'components/form/WithDropdownMenu';

import usePilots from 'hooks/usePilots';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Pilots, Props } from './types';

import styles from './styles.module.scss';

const PilotsDropdown: FunctionComponent<Props> = ({
    isEnabled = true,
    onChange,
    selectedIndex = 0,
    testId = TEST_ID
}) => {
    const ref = useRef(null);

    const { CHANGE_PILOT } = useTranslation();

    const { pilots } = usePilots();

    const pilotDropdownItems: Pilots = useMemo(
        () =>
            pilots.map((pilot: string) => ({
                label: pilot,
                type: 'string',
                value: pilot
            })),
        [pilots]
    );

    const index = selectedIndex === -1 ? 0 : selectedIndex;

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        if (isEnabled) {
            setIsOpen(previousValue => !previousValue);
        }
    }, [isEnabled]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const disabledClass = !isEnabled ? styles.disabled : '';
    const openClass = isOpen ? styles.open : '';

    return (
        <WithDropdownMenu
            isOpen={isOpen}
            items={pilotDropdownItems}
            onChange={onChange}
            onClose={handleClose}
            ref={ref}
            selected={index}
            testId={testId}
        >
            <Chip
                className={`${disabledClass} ${openClass} ${styles.chip}`}
                isDisabled={!isEnabled}
                onClick={handleClick}
                ref={ref}
            >
                {CHANGE_PILOT}
            </Chip>
        </WithDropdownMenu>
    );
};

export default PilotsDropdown;

import { TrashIcon } from '@rantizo-software/rantizo-ui';

import IconButton from 'components/IconButton';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const TrashIconButton: FunctionComponent<Props> = ({
    className,
    isDisabled,
    onClick,
    testId = TEST_ID
}) => (
    <IconButton
        className={`${className} ${styles.trashIconButton}`}
        isDisabled={isDisabled}
        onClick={onClick}
        testId={testId}
    >
        <TrashIcon className={styles.trashIcon} />
    </IconButton>
);

export default TrashIconButton;

import type { JohnDeereExportFormSchema } from './types';

export const TEST_ID = 'johnDeereExportModal';

export const ORGANIZATION_TEST_ID = 'organizationId';

export const FIELD_TEST_ID = 'fieldId';

export const ORGANIZATION_FORM_KEY = 'organizationId';

export const FIELD_FORM_KEY = 'fieldId';

export const formSchema: JohnDeereExportFormSchema = {
    fieldId: '',
    organizationId: ''
};

export const JOHN_EXPORTED_MAP_REDIRECT = (orgId: string, fieldId: string) =>
    `https://map.deere.com/organizations/${orgId}/fields/${fieldId}/fieldAnalyzer/overview`;

import { useCallback } from 'react';

import { generatePagedQuery } from '@@utils/string';

import useFetch from 'hooks/useFetch';

import { CONTROLLER_API } from './constants';
import type { ControllerApiResponse } from './types';

const useControllers = () => {
    const { authenticatedDelete, authenticatedGet } = useFetch();

    const fetchController = useCallback(
        async (id: string) => {
            const uri = `${CONTROLLER_API}/${id}`;

            return await authenticatedGet(uri, null);
        },
        [authenticatedGet]
    );

    const fetchControllers = useCallback(
        async (pageToken?: string, pageSize = 10) => {
            const queryParams = generatePagedQuery(pageToken, pageSize);

            const uri = `${CONTROLLER_API}?${queryParams.toString()}`;

            const response = await authenticatedGet(uri, null);

            if (response.error) {
                throw new Error(JSON.stringify(response.error));
            }

            return response.data as ControllerApiResponse;
        },
        [authenticatedGet]
    );

    const fetchAllControllers = useCallback(async () => {
        try {
            let controllerResponse = await fetchControllers();
            let controllers = controllerResponse.objects;

            while (controllerResponse.hasNext) {
                controllerResponse = await fetchControllers(controllerResponse.nextPageToken);
                controllers = controllers.concat(controllerResponse.objects);
            }

            return {
                data: controllers,
                error: undefined
            };
        } catch (e) {
            return {
                data: undefined,
                error: e
            };
        }
    }, [fetchControllers]);

    const deleteController = useCallback(
        async (id: string) => {
            const uri = `${CONTROLLER_API}/${id}`;

            const response = await authenticatedDelete(uri, null);

            if (response.error) {
                throw new Error(JSON.stringify(response.error));
            }
        },
        [authenticatedDelete]
    );

    return {
        deleteController,
        fetchAllControllers,
        fetchController,
        fetchControllers
    };
};

export default useControllers;

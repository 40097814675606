import { Link as OriginalLink } from 'react-router-dom';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Link: FunctionComponent<Props> = ({
    children,
    className = '',
    onClick,
    target,
    testId = TEST_ID,
    text = '',
    to = ''
}) => {
    const linkTo = /^https?:\/\//.test(to) ? to : { pathname: to };

    return (
        <OriginalLink
            className={`${className} ${styles.link}`}
            data-testid={testId}
            onClick={onClick}
            target={target}
            to={linkTo}
        >
            {children || text}
        </OriginalLink>
    );
};

export default Link;

import { Outlet } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';

import type { FunctionComponent, Props } from './types';

const AuthenticationProvider: FunctionComponent<Props> = ({
    authorizationParams,
    clientId,
    domain
}) => (
    <Auth0Provider authorizationParams={authorizationParams} clientId={clientId} domain={domain}>
        <Outlet />
    </Auth0Provider>
);

export default AuthenticationProvider;

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDevice } from 'components/DeviceProvider';
import ItemContainer from 'components/ItemContainer';
import ListText from 'components/ListText';
import ProductUsageReportMenu from 'components/ProductUsageReportMenu';
import Time from 'components/Time';
import CanceledLabel from 'components/colored-labels/CanceledLabel';
import CompleteLabel from 'components/colored-labels/CompleteLabel';
import PendingLabel from 'components/colored-labels/PendingLabel';
import ScheduledLabel from 'components/colored-labels/ScheduledLabel';
import StartedLabel from 'components/colored-labels/StartedLabel';

import usePageRoutes from 'hooks/usePageRoutes';

import { OPTIONS_TEST_ID, PRODUCT_USAGE_REPORT_MENU_TEST_ID, TEST_ID } from './constants';
import type { ClickEvent, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportCard: FunctionComponent<Props> = ({
    isDraft,
    onDelete,
    summary,
    testId = TEST_ID
}) => {
    const { deviceType } = useDevice();
    const { draftProductUsageReportPage, productUsageReportEditPage, productUsageReportPage } =
        usePageRoutes();

    const navigate = useNavigate();

    const {
        applicationDate,
        applicationSiteName,
        customer,
        grower,
        id,
        phoneNumber,
        status,
        workOrderId
    } = summary;

    const editLink = productUsageReportEditPage(id);

    const labelMap: LabelMap = useMemo(
        () => ({
            CANCELED: <CanceledLabel />,
            COMPLETE: <CompleteLabel />,
            PENDING: <PendingLabel />,
            SCHEDULED: <ScheduledLabel />,
            STARTED: <StartedLabel />
        }),
        []
    );

    const handleView = useCallback(() => {
        const url =
            isDraft && workOrderId
                ? draftProductUsageReportPage(workOrderId, id)
                : productUsageReportPage(id);

        navigate(url);
    }, [draftProductUsageReportPage, id, isDraft, navigate, productUsageReportPage, workOrderId]);

    const handleClick = useCallback(
        (event: ClickEvent) => {
            // Will remove this comment after this gets reviewed. Had to change this since the old code wasn't working
            // @Milian please review this part of the code to make sure this is a react friendly solution.
            const testId = event.target.attributes.getNamedItem('data-testid')?.value;
            const isClickedElementDotsMenu =
                testId === PRODUCT_USAGE_REPORT_MENU_TEST_ID || testId === OPTIONS_TEST_ID;

            if (isClickedElementDotsMenu) {
                return;
            }

            handleView();
        },
        [handleView]
    );

    const handleEdit = useCallback(() => {
        navigate(editLink);
    }, [editLink, navigate]);

    type LabelMap = {
        [key: string]: JSX.Element;
    };

    const handleDelete = useCallback(
        () => onDelete({ display: true, idToDelete: id }),
        [id, onDelete]
    );

    return (
        <ItemContainer
            className={styles.productUsageReportCard}
            onClick={handleClick}
            testId={testId}
        >
            <Time
                text={new Date(applicationDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })}
            />

            <ListText text={customer ?? ''} />

            {deviceType === 'desktop' && (
                <>
                    <ListText text={grower ?? ''} />

                    <ListText text={phoneNumber ?? ''} />

                    <ListText text={applicationSiteName ?? ''} />
                </>
            )}

            {labelMap[status as keyof LabelMap]}

            <ProductUsageReportMenu
                onDelete={handleDelete}
                onEdit={handleEdit}
                onView={handleView}
                testId={OPTIONS_TEST_ID}
            />
        </ItemContainer>
    );
};

export default ProductUsageReportCard;

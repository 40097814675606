import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OnboardingButtonContainer: FunctionComponent<Props> = ({ children, testId = TEST_ID }) => (
    <VerticalContainer className={styles.onboardingButtonContainer} data-testid={testId}>
        {children}
    </VerticalContainer>
);

export default OnboardingButtonContainer;

import { forwardRef } from 'react';

import isFunction from '@@utils/isFunction';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const Chip = forwardRef<HTMLDivElement, Props>(
    (
        { children, className = '', isDisabled = false, onClick, testId = TEST_ID, text = '' },
        ref
    ) => {
        const disabledClassName = isDisabled ? styles.disabled : '';

        const clickableClass = isFunction(onClick) ? styles.clickable : '';

        return (
            <div
                className={`${className} ${clickableClass} ${disabledClassName} ${styles.chip}`}
                data-testid={testId}
                onClick={onClick}
                ref={ref}
            >
                {children || text}
            </div>
        );
    }
);

Chip.displayName = 'Chip';

export default Chip;

import { EventSource } from 'extended-eventsource';
import { useCallback, useEffect, useRef, useState } from 'react';

import OptionsItem from 'components/OptionsButton/OptionsItem';

import useAccessToken from 'hooks/useAccessToken';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { DEFAULT_CHEMICAL_NAME, MAX_CHEMICAL_NAME_CHARACTERS, TEST_ID } from './constants';
import type { FunctionComponent, Props, RequestShapeFileResponse } from './types';

// TODO refactor this to use the hook for sse events
const DownloadShapeFile: FunctionComponent<Props> = ({
    aamId,
    chemicalNames,
    testId = TEST_ID
}) => {
    const { DOWNLOAD_SHAPE_FILE } = useTranslation();
    const { fetchData, resolveShapefileUrl } = useData();
    const { fetchToken } = useAccessToken();

    const [data, setData] = useState<RequestShapeFileResponse>();
    const [error, setError] = useState<boolean>(false);
    const sse = useRef<EventSource>();

    const closeSse = useCallback(() => sse.current?.close(), []);

    const handleError = useCallback(() => {
        setError(true);
        closeSse();
    }, [closeSse, setError]);

    const handleClick = useCallback(async () => {
        // limit string for chemical name
        let limitedChemicalName = chemicalNames.slice(0, MAX_CHEMICAL_NAME_CHARACTERS);

        if (chemicalNames.length > MAX_CHEMICAL_NAME_CHARACTERS) {
            // add elapses
            limitedChemicalName = chemicalNames.slice(0, MAX_CHEMICAL_NAME_CHARACTERS - 3) + '...';
        }

        if (!limitedChemicalName) {
            limitedChemicalName = DEFAULT_CHEMICAL_NAME;
        }

        const { data: fetchedData, error: fetchError } = await fetchData(
            aamId,
            limitedChemicalName
        );

        if (fetchError) {
            handleError();

            return;
        }

        setData(fetchedData);
    }, [aamId, chemicalNames, fetchData, handleError]);

    const downloadFile = useCallback(
        async ({ shapeFileId }: { shapeFileId: string }) => {
            const { data: resolvedData, error: resolvedError } = await resolveShapefileUrl({
                shapeFileId
            });

            if (!resolvedError) {
                const { shapeFile } = resolvedData;

                if (shapeFile) {
                    window.location.href = shapeFile;

                    return;
                }
            }
        },
        [resolveShapefileUrl]
    );

    const handleMessage = useCallback(
        async (e: MessageEvent) => {
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            const message: any = JSON.parse(e.data);

            const { shapeFileId } = message;

            if (message.status === 'COMPLETE') {
                downloadFile({ shapeFileId });
            } else if (message.status === 'PENDING') {
                return;
            }

            handleError();
        },
        [downloadFile, handleError]
    );

    useEffect(() => {
        const openConnection = async () => {
            if (!data) {
                return;
            }

            const { id, shapeFileId, status }: RequestShapeFileResponse = data;

            if (status === 'PENDING') {
                const token = await fetchToken();
                const opts = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                };

                sse.current = new EventSource(
                    `${import.meta.env.VITE_RANTIZO_API_BASE_URL}/api/shapefile-jobs/${id}`,
                    opts
                );

                sse.current.onmessage = handleMessage;
                sse.current.onerror = handleError;
            } else if (status === 'COMPLETE' && shapeFileId !== null) {
                downloadFile({ shapeFileId });
            } else {
                handleError();
            }
        };

        openConnection();

        return () => {
            closeSse();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (error) {
            // navigate to error page
        }
    }, [error]);

    return <OptionsItem onClick={handleClick} testId={testId} text={DOWNLOAD_SHAPE_FILE} />;
};

export default DownloadShapeFile;

import { useCallback } from 'react';

import { ORGANIZATIONS_API } from 'hooks/useJohnDeere/constants';
import useJohnDeereBase from 'hooks/useJohnDeere/hooks/useJohnDeereBase';
import type { JohnDeereValue } from 'hooks/useJohnDeere/types';

const useJohnDeereFields = () => {
    const { authenticatedGet, printAndThrowErrorIfExists, tokenQueryString } = useJohnDeereBase();

    const fetchJohnDeereFields = useCallback(
        async (organizationId: string) => {
            const { data, error } = await authenticatedGet(
                `${ORGANIZATIONS_API}/${organizationId}/fields?${tokenQueryString}`
            );

            printAndThrowErrorIfExists(error);

            return data as JohnDeereValue[];
        },
        [authenticatedGet, printAndThrowErrorIfExists, tokenQueryString]
    );

    return {
        fetchJohnDeereFields
    };
};

export default useJohnDeereFields;

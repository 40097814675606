import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import WithErrorMessage from 'components/WithErrorMessage';
import Dropdown from 'components/form/Dropdown';

import useData from './hooks/useData';

import { TEST_ID } from './constants';
import type { DropdownItemType, OnError, Props } from './types';

const DroneMakeDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isDisabled = false,
            isEditable = true,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            testId = TEST_ID
        },
        ref
    ) => {
        const [items, setItems] = useState<DropdownItemType[]>([]);

        const { fetchData } = useData();

        useEffect(() => {
            const fetchDroneMakes = async () => {
                const { data } = await fetchData();

                if (data) {
                    const dropdownItems = data.map(make => ({
                        label: make,
                        type: 'string',
                        value: make
                    }));

                    setItems(dropdownItems);
                }
            };

            fetchDroneMakes();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const [errorMessage, setErrorMessage] = useState('');

        const handleError = useCallback(
            (_dropdownValue: unknown, error: string) => {
                onError?.(error);
                setErrorMessage(error);
            },
            [onError]
        );

        const handleChange = useCallback(
            (_selectedValue: unknown, _handleError: OnError, index: number) => {
                onChange?.(items[index].value);
            },
            [items, onChange]
        );

        const hasAnError = Boolean(hasError || errorMessage);

        const defaultSelectedIndex = useMemo(() => {
            const selectedIndex = items.findIndex(({ value }) => value === 'DJI');

            return selectedIndex >= 0 ? selectedIndex : undefined;
        }, [items]);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasAnError}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    isRequired={isRequired}
                    items={items}
                    label={label}
                    onChange={handleChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    ref={ref}
                    selected={defaultSelectedIndex}
                    testId={testId}
                />
            </WithErrorMessage>
        );
    }
);

DroneMakeDropdown.displayName = 'DroneMakeDropdown';

export default DroneMakeDropdown;

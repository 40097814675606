import { useCallback, useEffect } from 'react';

import { usePDF } from '@react-pdf/renderer';

import AsAppliedMapPDF from 'components/AsAppliedMapPDF';
import OptionsItem from 'components/OptionsButton/OptionsItem';

import useTranslation from './hooks/useTranslation';

import { RETRY_INTERVAL, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const DownloadPDF: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    legend,
    mapType,
    mapUrl,
    siteName,
    testId = TEST_ID
}) => {
    const document = (
        <AsAppliedMapPDF
            chemicalNames={chemicalNames}
            data={aam}
            legend={legend ?? []}
            mapLink={mapUrl ?? ''}
            mapType={mapType}
            siteName={siteName}
        />
    );

    const [instance, update] = usePDF({
        document
    });
    const { DOWNLOAD_PDF } = useTranslation();

    const notReady = instance.loading || instance.error !== null || !legend || !mapUrl;

    useEffect(() => {
        update(document);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aam, legend, mapUrl]);

    const handleDownload = useCallback(() => {
        if (notReady) {
            setTimeout(() => handleDownload(), RETRY_INTERVAL);
        }

        if (instance.url) {
            window.open(instance.url);
        }
    }, [instance.url, notReady]);

    return <OptionsItem onClick={handleDownload} testId={testId} text={DOWNLOAD_PDF} />;
};

export default DownloadPDF;

import { getIn } from 'formik';

import { TextInput, rem } from '@mantine/core';

import { TEST_ID } from 'components/deprecating/FormInput/constants';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated Use `src/components/form/Input` instead.
 */
const FormInput: FunctionComponent<Props> = (props: Props) => {
    const {
        className,
        disabled,
        field,
        form,
        isEditable = true,
        label,
        max,
        placeholder,
        rightSection,
        rightSectionWidth,
        testId = TEST_ID,
        type,
        withAsterisk
    } = props;
    const { name, value } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const disabledClass = disabled ? styles.disabled : '';
    const placeholderText = isEditable ? placeholder : '';
    const hideClass = !isEditable && !value ? styles.hide : '';

    return (
        <TextInput
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : ''
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                rightSection: {
                    display: 'flex!important'
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            className={`${className} ${styles.formInput} ${hideClass} ${viewOnlyClass} ${disabledClass}`}
            data-testid={testId}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            label={label}
            max={max}
            placeholder={placeholderText}
            rightSection={rightSection}
            rightSectionWidth={rightSectionWidth}
            type={type}
            withAsterisk={withAsterisk}
            {...field}
            value={value ?? ''}
        />
    );
};

export default FormInput;

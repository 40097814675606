import { useCallback } from 'react';

import { round } from '@@utils/math';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';
import useServerSentEvents from 'hooks/useServerSentEvents';

import { AAM_API, AAM_JOB_API, AAM_JOB_SUMMARIES, measurementMap } from './constants';
import type {
    AsAppliedMap,
    AsAppliedMapJob,
    AsAppliedMapsRequest,
    JobQuery,
    JobStatusHandler,
    MapLegend,
    ServerSentEventHandler,
    UnitOfMeasurement
} from './types';

const useAsAppliedMaps = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPost } = useFetch();

    const { closeAuthenticatedConnection, openAuthenticatedConnection } = useServerSentEvents();

    const { addQuery } = useQuerystring({});

    const requestAsAppliedMap = useCallback(
        async (asAppliedMapRequest: AsAppliedMapsRequest): Promise<AsAppliedMapJob> => {
            const payload = JSON.stringify(asAppliedMapRequest);
            const { data, error } = await authenticatedPost(AAM_API, payload);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as AsAppliedMapJob;
        },
        [authenticatedPost]
    );

    const fetchAsAppliedMap = useCallback(
        async (id: string): Promise<AsAppliedMap> => {
            const url = `${AAM_API}/${id}`;
            const { data, error } = await authenticatedGet(url);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as AsAppliedMap;
        },
        [authenticatedGet]
    );

    const fetchAsAppliedMapJobStatus = useCallback(
        async (jobId: string, handler: JobStatusHandler) => {
            const sseHandler: ServerSentEventHandler = {
                handleError: () => {
                    closeAuthenticatedConnection();
                    handler.onError();
                },
                handleMessage: e => {
                    const message: AsAppliedMapJob = JSON.parse(e.data);

                    if (message.status === 'COMPLETE') {
                        closeAuthenticatedConnection();
                        handler.onJobUpdate(message);
                    }
                }
            };

            const url = `${AAM_JOB_API}/${jobId}`;

            await openAuthenticatedConnection(url, sseHandler);
        },
        [closeAuthenticatedConnection, openAuthenticatedConnection]
    );

    const fetchAsAppliedMapJobs = useCallback(
        async (jobQuery: JobQuery | null, pageSize: number, pageToken: string | null) => {
            let query = addQuery({
                pageToken: pageToken,
                size: `${pageSize}}`
            });

            if (jobQuery) {
                const { maxApplicationStartDate, minApplicationStartDate } = jobQuery;

                query = addQuery(
                    {
                        maxApplicationStartDate,
                        minApplicationStartDate
                    },
                    query
                );
            }

            const url = `${AAM_JOB_SUMMARIES}?${query.toString()}`;

            const { data, error } = await authenticatedGet(url);

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            return data as {
                nextPageToken: string | null;
                hasNext: boolean;
                objects: AsAppliedMapJob[];
            };
        },
        [addQuery, authenticatedGet]
    );

    const fetchAllAsAppliedMapJobs = useCallback(
        async (jobQuery: JobQuery | null): Promise<AsAppliedMapJob[]> => {
            const pageSize = 25;
            let pageToken = null;
            let jobs: AsAppliedMapJob[] = [];
            let keepFetching = true;

            while (keepFetching) {
                const apiResults = await fetchAsAppliedMapJobs(jobQuery, pageSize, pageToken);

                keepFetching = apiResults.hasNext;
                pageToken = apiResults.nextPageToken;
                jobs = jobs.concat(apiResults.objects);
            }

            return jobs;
        },
        [fetchAsAppliedMapJobs]
    );

    const fetchCompleteAsAppliedMapJobs = useCallback(
        async (jobQuery: JobQuery | null): Promise<AsAppliedMapJob[]> => {
            const jobs = await fetchAllAsAppliedMapJobs(jobQuery);

            return jobs.filter(job => job.status === 'COMPLETE');
        },
        [fetchAllAsAppliedMapJobs]
    );

    const deleteAsAppliedMapJob = useCallback(
        async (id: string) => {
            const url = `${AAM_JOB_API}/${id}`;
            const { error } = await authenticatedDelete(url);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedDelete]
    );

    const getUnitLabel = (unitOfMeasurement: UnitOfMeasurement) =>
        measurementMap[unitOfMeasurement];

    const getDisplayLegendItems = useCallback(
        (legend: MapLegend[]) =>
            legend.map(({ color, maxValue, minValue, percentage, systemOfMeasurement }) => {
                const measurement = systemOfMeasurement === 'IMPERIAL' ? 'gal/sec' : 'l/sec';
                const percentageRounded = round(percentage * 100, 2);

                return {
                    color: color,
                    text: `${round(minValue, 2)} - ${round(maxValue, 2)} ${measurement} (${percentageRounded}%)`
                };
            }),
        []
    );

    return {
        closeAuthenticatedConnection,
        deleteAsAppliedMapJob,
        fetchAllAsAppliedMapJobs,
        fetchAsAppliedMap,
        fetchAsAppliedMapJobStatus,
        fetchAsAppliedMapJobs,
        fetchCompleteAsAppliedMapJobs,
        getDisplayLegendItems,
        getUnitLabel,
        requestAsAppliedMap
    };
};

export default useAsAppliedMaps;

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Title: FunctionComponent<Props> = ({
    children,
    className = '',
    testId = TEST_ID,
    text = ''
}) => (
    <h3 className={`${styles.title} ${className}`} data-testid={testId}>
        {children || text}
    </h3>
);

export default Title;

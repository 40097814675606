import { getIn } from 'formik';

import { Textarea, rem } from '@mantine/core';

import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

/**
 * @deprecated No new version exists yet. We need to create a new version of this.
 */
const FormTextArea: FunctionComponent<Props> = ({
    autosize,
    className,
    disabled,
    field,
    form,
    isEditable = true,
    label,
    minRows,
    placeholder,
    withAsterisk
}) => {
    const { name, value } = field;

    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const visibleError = touch && error && typeof error === 'string';

    const viewOnlyClass = !isEditable ? styles.viewOnly : '';
    const placeholderText = isEditable ? placeholder : '';
    const hideClass = !isEditable && !value ? styles.hide : '';

    return (
        <Textarea
            styles={{
                error: {
                    marginTop: visibleError ? rem('7px') : ''
                },
                input: {
                    marginTop: visibleError ? '0' : rem('20px')
                },
                wrapper: {
                    marginTop: '1px'
                }
            }}
            autosize={autosize}
            className={`${className} ${styles.formTextarea} ${hideClass} ${viewOnlyClass}`}
            disabled={!isEditable || disabled}
            error={visibleError ? error : undefined}
            label={label}
            minRows={minRows}
            placeholder={placeholderText}
            withAsterisk={withAsterisk}
            {...field}
        />
    );
};

export default FormTextArea;

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Highlight: FunctionComponent<Props> = ({ className = '', testId = TEST_ID }) => (
    <div className={`${className} ${styles.highlight}`} data-testid={testId}></div>
);

export default Highlight;

import { useMemo } from 'react';

import DatedList from 'components/DatedList';
import { useDevice } from 'components/DeviceProvider';
import Job from 'components/Job';
import ListHeader from 'components/ListHeader';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { DatedListItem, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const Jobs: FunctionComponent<Props> = ({ items = {}, onDelete, sortOrder, testId = TEST_ID }) => {
    const { deviceType } = useDevice();
    const { CONTACT, GROWER, PHONE_NUMBER, SITE_NAME, STATUS, TIME } = useTranslation();

    const workorderCards = useMemo(() => {
        const workordersList = {} as DatedListItem;

        Object.keys(items).map(date => {
            const jobsByDate = items[date];

            workordersList[date] = [
                deviceType === 'desktop' && (
                    <div className={styles.labelContainer} key={date}>
                        <ListHeader text={TIME} />

                        <ListHeader text={CONTACT} />

                        <ListHeader text={GROWER} />

                        <ListHeader text={PHONE_NUMBER} />

                        <ListHeader text={SITE_NAME} />

                        <ListHeader text={STATUS} />
                    </div>
                )
            ].concat(
                jobsByDate.map(
                    ({ customerName, growerName, id, phoneNumber, siteName, status, time }) => (
                        <Job
                            customerName={customerName}
                            date={date}
                            growerName={growerName}
                            id={id ?? ''}
                            key={id}
                            onDelete={onDelete}
                            phoneNumber={phoneNumber}
                            siteName={siteName}
                            status={status}
                            time={time}
                        />
                    )
                )
            );
        });

        return workordersList;
    }, [CONTACT, GROWER, PHONE_NUMBER, SITE_NAME, STATUS, TIME, deviceType, items, onDelete]);

    return <DatedList items={workorderCards} sortOrder={sortOrder} testId={testId} />;
};

export default Jobs;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentText } from '@rantizo-software/rantizo-ui';

import BaseEquipmentCard from 'components/BaseEquipmentCard';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AircraftCard: FunctionComponent<Props> = ({
    aircraft,
    className = '',
    onDelete,
    testId = TEST_ID
}) => {
    const navigate = useNavigate();
    const { editAircraftPage } = usePageRoutes();
    const { FLIGHT_CONTROLLER_SERIAL, REGISTRATION_NUMBER, SWATH_WIDTH } = useTranslation();

    const { defaultSwathWidthMeters, flightControllerSerial, id, registrationNumber } = aircraft;

    const handleEdit = useCallback(() => {
        navigate(editAircraftPage(id));
    }, [editAircraftPage, id, navigate]);

    return (
        <BaseEquipmentCard
            className={className}
            equipment={aircraft}
            onDelete={onDelete}
            onEdit={handleEdit}
            testId={testId}
        >
            <ContentText text={`${REGISTRATION_NUMBER}: ${registrationNumber}`} />

            {flightControllerSerial && (
                <ContentText text={`${FLIGHT_CONTROLLER_SERIAL}: ${flightControllerSerial}`} />
            )}

            {defaultSwathWidthMeters && <ContentText text={SWATH_WIDTH(defaultSwathWidthMeters)} />}
        </BaseEquipmentCard>
    );
};

export default AircraftCard;

import { Children } from 'react';

import { PageTitle, VerticalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const ListPageLayoutHeader = ({ children, pageTitle, testId = TEST_ID }: Props) => {
    const areaClasses = [styles.addItemButton, styles.tabs];

    return (
        <VerticalContainer className={styles.listPageLayoutHeader} testId={testId}>
            <PageTitle className={styles.pageTitle} text={pageTitle} />

            {Children.map(children, (child, index) => {
                const buttonAlignmentClass =
                    Children.count(children) === 1 && index === 0 ? styles.buttonAlignment : '';

                return (
                    <div className={`${areaClasses[index] || ''} ${buttonAlignmentClass}`}>
                        {child}
                    </div>
                );
            })}
        </VerticalContainer>
    );
};

export default ListPageLayoutHeader;

import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('manageConnectionsPage');

    return {
        CONNECTIONS_SECTION_HEADER: t('connectionsSectionHeader'),
        FIELD_VIEW_DESCRIPTION: t('fieldViewDescription'),
        FIELD_VIEW_TITLE: t('fieldViewTitle'),
        MANAGE_CONNECTIONS_PAGE_TITLE: t('manageConnectionsPageTitle')
    };
};

export default useTranslation;

import type { WorkOrder } from './types';

export const defaultWorkOrder: WorkOrder = {
    applicationSites: [],
    applicator: {
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        phoneNumber: ''
    },
    appliedAcres: 0,
    chemicalCost: 0,
    chemicals: [],
    customer: {
        address1: '',
        address2: '',
        city: '',
        company: '',
        contact: '',
        id: '',
        phoneNumber: '',
        state: '',
        zipCode: ''
    },
    estimatedTotal: 0,
    expirationDate: null,
    fieldApplicationFlatFee: 0,
    fieldApplicationHours: 0,
    fieldApplicationRate: 0,
    fieldApplicationRateUnit: 'ac',
    file: {
        id: '',
        objectKey: ''
    },
    grower: {
        address1: '',
        address2: '',
        city: '',
        company: '',
        contact: '',
        id: '',
        organizationId: '',
        phoneNumber: '',
        state: '',
        zipCode: ''
    },
    id: '',
    invoiceItems: [],
    invoicedApplicationSites: [],
    key: 0,
    notes: '',
    preHarvestInterval: 0,
    proposedAcres: 0,
    proposedAcresUnit: 'ac',
    proposedArea: 0.0,
    proposedDate: null,
    reEntryInterval: 0,
    reEntryIntervalDays: 0,
    reEntryIntervalHours: 0,
    scheduledDate: null,
    siteCommodity: '',
    targetSprayRate: 0.0,
    taxAmount: 0,
    taxAmountUnit: 'FLAT_RATE',
    workOrderNumber: '',
    workOrderStatus: ''
};

export const defaultInvoice = {
    appliedAcres: 0,
    chemicalCost: 0,
    estimatedTotal: 0,
    fieldApplicationFlatFee: null,
    fieldApplicationHours: 0,
    fieldApplicationRate: null,
    fieldApplicationRateUnit: 'PER_ACRE',
    invoiceItems: [],
    invoicedApplicationSites: [],
    otherDescription: '',
    otherFee: undefined,
    taxAmount: null,
    taxAmountUnit: 'FLAT_RATE'
};

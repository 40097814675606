import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('notFoundErrorPage');

    return {
        NOT_FOUND: ({ resourceName }: { resourceName: string }) => t('notFound', { resourceName })
    };
};

export default useTranslation;

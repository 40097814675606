import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const FlatModal: FunctionComponent<Props> = ({ children, className = '', testId = TEST_ID }) => (
    <VerticalContainer className={`${className} ${styles.flatModal}`} testId={testId}>
        {children}
    </VerticalContainer>
);

export default FlatModal;

import { Field, FieldArray, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { rem } from '@mantine/core';

import FormSectionContainer from 'components/FormSectionContainer';
import ChemicalAppliedForm from 'components/NewProductUsageReport/ProductDetailsForm/ChemicalAppliedForm';
import UnitLabel from 'components/UnitLabel';
import FormInput from 'components/deprecating/FormInput';
import FormNumberInput from 'components/deprecating/FormNumberInput';

import useConvert from 'hooks/useConvert';
import { massRateUnits, massUnits, volumeRateUnits, volumeUnits } from 'hooks/useConvert/constants';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type {
    ChemicalAppliedObject,
    FunctionComponent,
    MassRateUnit,
    MassUnit,
    ProductUsageReportForm,
    Props,
    RateUnit,
    ReactNode,
    VolumeRateUnit,
    VolumeUnit
} from './types';

const ProductDetailsForm: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { AC, CARRIER, TOTAL_ACRES_APPLIED } = useTranslation();
    const { convertDryMass, convertDryMassRate, convertLiquidVolume, convertLiquidVolumeArea } =
        useConvert();
    const { setFieldValue, values } = useFormikContext<ProductUsageReportForm>();

    const updateRateAppliedOnAmountAppliedChange = useCallback(
        async (chemical: ChemicalAppliedObject, index: number, totalAreaAppliedAcres: number) => {
            const { amountApplied, preferredAmountUnits, preferredRateUnits } = chemical;

            const amountUnitMatchesRateUnit = preferredRateUnits?.includes(
                preferredAmountUnits || ''
            );

            if (amountUnitMatchesRateUnit) {
                const newAmount = totalAreaAppliedAcres / (amountApplied ?? 0.0);

                await setFieldValue(`chemicalsApplied.${index}.rateApplied`, newAmount);

                return;
            }

            const amountAppliedIsWet = volumeUnits.includes(preferredAmountUnits as VolumeUnit);

            if (amountAppliedIsWet) {
                const getRateUnitFromVolumeUnit = (volumeUnit: VolumeUnit) => {
                    const rateUnitFromVolumeUnit: VolumeRateUnit | undefined = volumeRateUnits.find(
                        volumeRateUnit => volumeRateUnit?.includes(volumeUnit)
                    );

                    return rateUnitFromVolumeUnit;
                };

                const newRateUnitFromVolumeUnit = getRateUnitFromVolumeUnit(
                    preferredAmountUnits as VolumeUnit
                );

                const calculatedNewRateInAmountUnit =
                    (amountApplied ?? 0.0) / totalAreaAppliedAcres;

                const newRateInOriginalUnit = convertLiquidVolumeArea(
                    calculatedNewRateInAmountUnit,
                    newRateUnitFromVolumeUnit || 'LITERS_PER_SQUARE_METER',
                    preferredRateUnits as VolumeRateUnit
                );

                const rateAppliedField = `chemicalsApplied.${index}.rateApplied`;

                await setFieldValue(rateAppliedField, newRateInOriginalUnit);
            } else {
                const getRateUnitFromMassUnit = (massUnit: MassUnit) => {
                    const rateUnitFromMassUnit: MassRateUnit | undefined = massRateUnits.find(
                        massRateUnit => massRateUnit.includes(massUnit)
                    );

                    return rateUnitFromMassUnit;
                };

                const newRateUnitFromMassUnit = getRateUnitFromMassUnit(
                    preferredAmountUnits as MassUnit
                );

                const calculatedNewAmountInRateUnit =
                    (amountApplied ?? 0.0) / totalAreaAppliedAcres;

                const newAmountInOriginalUnit = convertDryMassRate(
                    calculatedNewAmountInRateUnit,
                    newRateUnitFromMassUnit || 'KILOGRAMS_PER_SQUARE_METER',
                    preferredRateUnits as MassRateUnit
                );

                const rateAppliedField = `chemicalsApplied.${index}.rateApplied`;

                await setFieldValue(rateAppliedField, newAmountInOriginalUnit);
            }
        },
        [setFieldValue, convertDryMassRate, convertLiquidVolumeArea]
    );

    const updateChemicalTotalVolumeApplied = useCallback(
        async (chemical: ChemicalAppliedObject, index: number, totalAreaAppliedAcres: number) => {
            const { preferredAmountUnits, preferredRateUnits, rateApplied } = chemical;
            const amountUnitMatchesRateUnit = preferredRateUnits?.includes(
                preferredAmountUnits || ''
            );

            if (amountUnitMatchesRateUnit) {
                const newAmount = totalAreaAppliedAcres * (rateApplied ?? 0.0);

                await setFieldValue(`chemicalsApplied.${index}.amountApplied`, newAmount);

                return;
            }

            const productAppliedIsWet = volumeRateUnits.includes(
                preferredRateUnits as VolumeRateUnit
            );

            if (productAppliedIsWet) {
                const getVolumeUnitFromRateUnit = (rateUnit: RateUnit) => {
                    const volumeUnitFromRateUnit: VolumeUnit | undefined = volumeUnits.find(
                        volumeUnit => rateUnit.includes(volumeUnit)
                    );

                    return volumeUnitFromRateUnit;
                };

                const newVolumeUnitFromRateUnit = getVolumeUnitFromRateUnit(
                    preferredRateUnits ?? 'LITERS_PER_SQUARE_METER'
                ) as VolumeUnit;

                const calculatedNewAmountInRateUnit = totalAreaAppliedAcres * (rateApplied ?? 0.0);

                const newAmountInOriginalUnit = convertLiquidVolume(
                    calculatedNewAmountInRateUnit,
                    newVolumeUnitFromRateUnit,
                    preferredAmountUnits as VolumeUnit
                );

                const amountAppliedField = `chemicalsApplied.${index}.amountApplied`;

                await setFieldValue(amountAppliedField, newAmountInOriginalUnit);
            } else {
                const getMassUnitFromRateUnit = (rateUnit: RateUnit) => {
                    const massUnitFromRateUnit: MassUnit | undefined = massUnits.find(massUnit =>
                        rateUnit.includes(massUnit)
                    );

                    return massUnitFromRateUnit;
                };

                const newMassUnitFromRateUnit = getMassUnitFromRateUnit(
                    preferredRateUnits ?? 'KILOGRAMS_PER_SQUARE_METER'
                ) as MassUnit;

                const calculatedNewAmountInRateUnit = totalAreaAppliedAcres * (rateApplied ?? 0.0);

                const newAmountInOriginalUnit = convertDryMass(
                    calculatedNewAmountInRateUnit,
                    newMassUnitFromRateUnit,
                    preferredAmountUnits as MassUnit
                );

                const amountAppliedField = `chemicalsApplied.${index}.amountApplied`;

                await setFieldValue(amountAppliedField, newAmountInOriginalUnit);
            }
        },
        [setFieldValue, convertLiquidVolume, convertDryMass]
    );

    const handleTotalAcresChange = useCallback(
        async (value: number) => {
            values.chemicalsApplied.forEach((_chemical, index) => {
                const formChemical = values.chemicalsApplied[index];

                updateChemicalTotalVolumeApplied(formChemical, index, value);
            });
        },
        [updateChemicalTotalVolumeApplied, values.chemicalsApplied]
    );

    const chemicalAppliedForms = useMemo(
        () =>
            values.chemicalsApplied.map((_value, index) => (
                <ChemicalAppliedForm
                    index={index}
                    key={index}
                    updateRateAppliedOnAmountAppliedChange={updateRateAppliedOnAmountAppliedChange}
                    updateTotalVolumeApplied={updateChemicalTotalVolumeApplied}
                />
            )) as ReactNode[],
        [
            values.chemicalsApplied,
            updateRateAppliedOnAmountAppliedChange,
            updateChemicalTotalVolumeApplied
        ]
    );

    return (
        <FormSectionContainer testId={testId}>
            <Field
                component={FormNumberInput}
                customOnChange={(value: number) => handleTotalAcresChange(value)}
                label={TOTAL_ACRES_APPLIED}
                name="totalAreaAppliedAcres"
                precision={2}
                rightSection={<UnitLabel label={AC} />}
                rightSectionWidth={rem('70px')}
            />

            {values.chemicalsApplied.length > 0 && (
                <FieldArray name="chemicalsApplied">{() => chemicalAppliedForms}</FieldArray>
            )}

            <Field component={FormInput} label={CARRIER} name="carrier" />
        </FormSectionContainer>
    );
};

export default ProductDetailsForm;
